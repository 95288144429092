import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TeamProfile from './cards/TeamProfile';
import Topbar from './Topbar';
import Footer from './Footer';

const team = require('../config/team.json');
const banner = require('../images/team-photo.png');

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['A500'],
        overflow: 'hidden',
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})

class Team extends Component {



    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname


        let profiles = [];
        
        profiles = team.map(person => {
            return <TeamProfile key={`profile-id-` + person.email} person={person} />
        });

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>

                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="RECIRC Team" style={{width: '100%'}} />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} md={8}>
                                <Typography variant="h1" className="text-center">Our People</Typography>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Grid container justify="center">     
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>                                          
                                { profiles }                        
                            </Grid>
                        </Grid>
                    </Grid>
                
                
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Team);