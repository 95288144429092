/* global person_id, person_name */

export const config = { 
    apiUrl: 'https://api.recirc.nuigalway.ie/api/v1',
    // apiUrl: 'http://recirc.davkell.com/api/v1',
    // apiUrl: 'http://recirc-v2.local/api/v1',
    
    noImageAvailablePath: '/images/no_image_available_1.jpeg',

    interestingSearches: {
        reception: [
            {                
                'label': 'Receptions of works by Katherine Philips',
                'url': '/receptions?query_type=must&term=%5B%7B%22name%22%3A%22author_id%22%2C%22label%22%3A%22Female%20Author%22%2C%22filter%22%3A%22equals%22%2C%22term%22%3A2445%2C%22termLabel%22%3A%22Katherine%20Philips%22%7D%5D',
                'result_count': 83,
                'result_label': 'receptions'
            },
            {
                'label': 'Receptions that are annotations',
                'url': '/receptions?query_type=must&term=%5B%7B"name"%3A"receptiontype"%2C"label"%3A"Reception%20Type"%2C"filter"%3A"in"%2C"term"%3A%5B%7B"id"%3A60%2C"value"%3A"annotation"%2C"label"%3A"Annotation"%2C"%24%24hashkey"%3A""%7D%5D%2C"termLabel"%3A"Annotation"%7D%5D&view=table',
                'result_count': 150,
                'result_label': 'receptions'
            },
            {
                'label': 'Receptions of Elizabeth I',
                'url': '/receptions?query_type=must&term=%5B%7B"name"%3A"author_id"%2C"label"%3A"Female%20Author"%2C"filter"%3A"equals"%2C"term"%3A2670%2C"termLabel"%3A"Elizabeth%20I"%7D%5D&view=table',
                'result_count': 576,
                'result_label': 'receptions'
            },
            {
                'label': 'Anonymous Receptions',
                'url': '/receptions?query_type=must&term=%5B%7B"name"%3A"receptionsourcework.owners"%2C"filter"%3A"equals"%2C"term"%3A"anonymous"%2C"label"%3A"Owner%2FCompiler%2FScribe"%2C"termLabel"%3A"Anonymous"%7D%2C%7B"label"%3A"Receiver"%2C"name"%3A"receiver.receiver"%2C"filter"%3A"equals"%2C"term"%3A"anonymous"%2C"termLabel"%3A"Anonymous"%7D%5D&view=table',
                'result_count': 635,
                'result_label': 'receptions'
            },
            {
                'label': 'Receptions by female authors',
                'url': '/receptions?query_type=must&term=%5B%7B"name"%3A"receivername.is_author"%2C"filter"%3A"equals"%2C"term"%3Atrue%2C"termLabel"%3A"Is%20Author"%7D%2C%7B"name"%3A"receivername.sex"%2C"filter"%3A"in"%2C"term"%3A%5B"Female"%5D%2C"termLabel"%3A"Female"%7D%5D&view=table',
                'result_count': 1372,
                'result_label': 'receptions'
            },
            {
                'label': 'Receptions of Elizabeth I that contain the word "death"',
                'url': '/receptions?query_type=must&term=%5B%7B"name"%3A"author_id"%2C"label"%3A"Female%20Author"%2C"filter"%3A"equals"%2C"term"%3A2670%2C"termLabel"%3A"Elizabeth%20I"%7D%2C%7B"name"%3A"quotation_reception"%2C"label"%3A"Quotation"%2C"filter"%3A"contains"%2C"term"%3A"death"%2C"termLabel"%3A"death"%7D%5D&view=table',
                'result_count': 94,
                'result_label': 'receptions'
            }
        ],
        people: [
            {
                'label': 'View the Authors',
                'url': '/people?query_type=must&term=%5B%7B%22name%22%3A%22is_author%22%2C%22filter%22%3A%22equals%22%2C%22term%22%3Atrue%2C%22termLabel%22%3A%22Is%20Author%22%7D%2C%7B%22name%22%3A%22sex%22%2C%22label%22%3A%22Sex%22%2C%22filter%22%3A%22in%22%2C%22term%22%3A%5B%22Female%22%5D%2C%22termLabel%22%3A%22Female%22%7D%5D',
                'result_count': 1878,
                'result_label': 'people'
            },
            {
                'label': 'View the Receivers',
                'url': '/people?query_type=must&term=%5B%7B"name"%3A"is_receiver"%2C"filter"%3A"equals"%2C"term"%3Atrue%2C"termLabel"%3A"Is%20Receiver"%7D%5D',
                'result_count': 457,
                'result_label': 'people'
            },
            {
                'label': 'View Owners / Compilers / Scribes of Source Documents',
                'url': '/people?query_type=must&term=%5B%7B"name"%3A"is_owner"%2C"filter"%3A"equals"%2C"term"%3Atrue%2C"termLabel"%3A"Is%20Owner%20Compiler%20Scribe"%7D%5D',
                'result_count': 264,
                'result_label': 'people'
            },
            {
                'label': 'View the Nuns',
                'url': '/people?query_type=must&term=%5B%7B%22name%22%3A%22is_nun%22%2C%22filter%22%3A%22equals%22%2C%22term%22%3Atrue%2C%22termLabel%22%3A%22Is%20Nun%22%7D%5D',
                'result_count': 326,
                'result_label': 'people'
            },
            {
                'label': 'All nuns who are authors',
                'url': '/people?query_type=must&term=%5B%7B"name"%3A"is_nun"%2C"filter"%3A"equals"%2C"term"%3Atrue%2C"termLabel"%3A"Is%20Nun"%7D%2C%7B"name"%3A"is_author"%2C"filter"%3A"equals"%2C"term"%3Atrue%2C"termLabel"%3A"Is%20Author"%7D%5D',
                'result_count': 324,
                'result_label': 'people'
            },
            {
                'label': 'Female Authors with Pseudonyms',
                'url': '/people?query_type=must&term=%5B%7B"name"%3A"sex"%2C"label"%3A"Sex"%2C"filter"%3A"in"%2C"term"%3A%5B"Female"%5D%2C"termLabel"%3A"Female"%7D%2C%7B"name"%3A"is_author"%2C"filter"%3A"equals"%2C"term"%3Atrue%2C"termLabel"%3A"Is%20Author"%7D%2C%7B"name"%3A"pseudonym"%2C"filter"%3A"equals"%2C"term"%3Atrue%2C"termLabel"%3A"Has%20Pseudonyms"%7D%5D',
                'result_count': 54,
                'result_label': 'people'
            }
            
        ],
        reception_source: [
            {                
                'label': 'All receptions in Letters',
                'url': '/sources?query_type=must&term=%5B%7B"name"%3A"source_type"%2C"label"%3A"Reception%20Source%20Type"%2C"filter"%3A"in"%2C"term"%3A%5B%7B"id"%3A113%2C"value"%3A"letter"%2C"label"%3A"Letter"%2C"%24%24hashkey"%3A""%7D%5D%2C"termLabel"%3A"letter"%7D%5D',
                'result_count': 907,
                'result_label': 'sources'
            },
            {
                'label': 'All receptions in Miscellanies (composite and fascicle)',
                'url': '/sources?query_type=must&term=%5B%7B"name"%3A"source_type"%2C"label"%3A"Reception%20Source%20Type"%2C"filter"%3A"in"%2C"term"%3A%5B%7B"id"%3A139%2C"value"%3A"fascicle"%2C"label"%3A"Fascicle"%2C"%24%24hashkey"%3A""%7D%2C%7B"id"%3A138%2C"value"%3A"composite_volume"%2C"label"%3A"Composite%20Volume"%2C"%24%24hashkey"%3A""%7D%2C%7B"id"%3A117%2C"value"%3A"miscellany"%2C"label"%3A"Miscellany"%2C"%24%24hashkey"%3A""%7D%5D%2C"termLabel"%3A"fascicle%20<code>or<%2Fcode>%20composite%20volume%2Cmiscellany"%7D%5D',
                'result_count': 216,
                'result_label': 'sources'
            },
            {
                'label': 'Auction Catalogues containing receptions of Margaret Cavendish',
                'url': '/sources?query_type=must&term=%5B%7B"name"%3A"receptions.author.id"%2C"label"%3A"Female%20Author"%2C"filter"%3A"equals"%2C"term"%3A1843%2C"termLabel"%3A"Margaret%20Cavendish"%7D%2C%7B"name"%3A"source_type"%2C"label"%3A"Reception%20Source%20Type"%2C"filter"%3A"in"%2C"term"%3A%5B%7B"id"%3A93%2C"value"%3A"auction_catalogue"%2C"label"%3A"Auction%20Catalogue"%2C"%24%24hashkey"%3A""%7D%5D%2C"termLabel"%3A"auction%20catalogue"%7D%5D',
                'result_count': 13,
                'result_label': 'sources'
            },
            {
                'label': 'Receptions in auction catalogues (with library catalogues and booklists)   ',
                'url': '/sources?query_type=must&term=%5B%7B"name"%3A"source_type"%2C"label"%3A"Reception%20Source%20Type"%2C"filter"%3A"in"%2C"term"%3A%5B%7B"id"%3A93%2C"value"%3A"auction_catalogue"%2C"label"%3A"Auction%20Catalogue"%2C"%24%24hashkey"%3A""%7D%2C%7B"id"%3A95%2C"value"%3A"booklist"%2C"label"%3A"Booklist"%2C"%24%24hashkey"%3A""%7D%2C%7B"id"%3A114%2C"value"%3A"library_catalogue"%2C"label"%3A"Library%20Catalogue"%2C"%24%24hashkey"%3A""%7D%5D%2C"termLabel"%3A"auction%20catalogue%20<code>or<%2Fcode>%20booklist%2Clibrary%20catalogue"%7D%5D',
                'result_count': 47,
                'result_label': 'sources'
            }
            
        ]
    },

    metricTemplates: {

        'authored_works_count': {
            'title': 'Author of ',
            'tagline_plural': 'works in our dataset',
            'tagline_single': 'work in our dataset',
            'url_text_plural': 'View them',
            'url_text_single': 'View it',
            'url': true
            
        },
        'associated_receptions_count': {
            'title': 'Connected to',
            'tagline_plural': 'receptions, in our dataset',
            'tagline_single': 'reception, in our dataset',
            'url_text_plural': 'Explore them',
            'url_text_single': 'Explore it',
            'url': true
        
        },
        'associated_reception_sources_count': {
            'title': 'Associated with',
            'tagline_plural': 'reception sources, in our dataset',
            'tagline_single': 'reception source, in our dataset',
            'url_text_plural': 'View them',
            'url_text_single': 'View it',

         
        },
        'connected_to_people_count': {
            'title': 'Is linked to',
            'tagline_plural': 'people in our dataset',
            'tagline_single': 'person in our dataset',
            'url_text_plural': 'View them',
            'url_text_single': 'View them',
            'url': true

        },

        'author_reception_percentile_rank': {
            'title': 'Is in the',
            'tagline': 'of women received, in our dataset',
            'tagline_plural': 'of women received, in our dataset',
            'tagline_single': 'of women received, in our dataset'
        }
    },

    sourceworkDistributions: [
        {
            'count'    : 907,
            'source_type': 'letter'
        },
        {
            'count'    : 179,
            'source_type': 'miscellany'
        },
        {
            'count'    : 101,
            'source_type': 'subsequent_edition'
        },
        {
            'count'    : 35,
            'source_type': 'polemic'
        },
        {
            'count'    : 34,
            'source_type': 'auction_catalogue'
        },
        {
            'count'    : 26,
            'source_type': 'composite_volume'
        },
        {
            'count'    : 15,
            'source_type': 'martyrology'
        },
        {
            'count'    : 11,
            'source_type': 'fascicle'
        },
        {
            'count'    : 11,
            'source_type': 'translation'
        },
        {
            'count'    : 10,
            'source_type': 'single_author_volume'
        },
        {
            'count'    : 9,
            'source_type': 'devotional_book'
        },
        {
            'count'    : 7,
            'source_type': 'bio_bibliography'
        },
        {
            'count'    : 7,
            'source_type': 'commonplace_book'
        },
        {
            'count'    : 7,
            'source_type': 'booklist'
        },
        {
            'count'    : 6,
            'source_type': 'diary'
        },
        {
            'count'    : 6,
            'source_type': 'legal_document'
        },
        {
            'count'    : 6,
            'source_type': 'biography'
        },
        {
            'count'    : 6,
            'source_type': 'library_catalogue'
        },
        {
            'count'    : 5,
            'source_type': 'account_book'
        },
        {
            'count'    : 4,
            'source_type': 'hagiography'
        },
        {
            'count'    : 4,
            'source_type': 'poetry_anthology'
        },
        {
            'count'    : 3,
            'source_type': 'chronicle'
        },
        {
            'count'    : 3,
            'source_type': 'natural_history'
        },
        {
            'count'    : 3,
            'source_type': 'sermon'
        },
        {
            'count'    : 3,
            'source_type': 'first_print_edition_manuscript_work'
        },
        {
            'count'    : 3,
            'source_type': 'essay_discourse'
        },
        {
            'count'    : 3,
            'source_type': 'history'
        },
        {
            'count'    : 3,
            'source_type': 'inventory'
        },
        {
            'count'    : 2,
            'source_type': 'obit_book'
        },
        {
            'count'    : 2,
            'source_type': 'receipt_book'
        },
        {
            'count'    : 2,
            'source_type': 'autobiography_memoir'
        },
        {
            'count'    : 1,
            'source_type': 'dialogue'
        },
        {
            'count'    : 1,
            'source_type': 'prophecy_dream'
        },
        {
            'count'    : 1,
            'source_type': 'conduct_advice_book'
        }
    ]
};