import React from 'react';
// import {config} from '../../config';
import { withRouter, Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import { Card, Table} from 'react-bootstrap';
import EnhancedTableHead from "../common/EnhancedTableHead";
import Grid from '@material-ui/core/Grid';
import styles from '../../style.module.css';
import { FaChevronCircleRight, FaInfoCircle } from 'react-icons/fa';

class SourcesCaptured extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            reception_source: [],
            isLoading: false,
            order: 'asc',
            orderBy: 'author_text'
        };
    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';

        this.setState({
            order: (isDesc ? 'asc' : 'desc'),
            orderBy: property
        });
    };

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    /**
     * Transform search result response into object
     * that can both be sorted, and contains React components (such
     * as Links) for display purposes
     */
    createData = (data) => {
        return data.map(src => {
            
            let author_text = src.author.display_name; 
            let author = (<Link to={`/people/person/${src.author.id}`} title="View Person">
                            {src.author.display_name}
                        </Link>
            );
            
            let reference_text = src.reference; 
            let reference = (<Link to={`/receptions/reception/${src.id}`}>
                    {src.reference}
                </Link> 
            );


            return {
                'id': src.id,
                'author': author,
                'author_text': author_text,
                'reference': reference,                
                'reference_text': reference_text,                
            }
        });
    };

    render() {

        const { reception_source } = this.props;
        let reception_count = 0;
        // console.log(reception_source)

        let contents = <tr className="alert alert-warn"><td colSpan="4" className="text-center">No receptions found</td></tr>;
        
        // ID here matches the keys for data sorting set
        // in this.createData()
        const headCells = [
            { id: 'id', numeric: false, disablePadding: true, label: '' },
            { id: 'reference_text', numeric: false, disablePadding: true, label: 'Document Reference' },
            { id: 'author_text', numeric: false, disablePadding: true, label: 'Female Author' }
        ];

        if (Array.isArray(reception_source.receptions) && reception_source.receptions.length > 0){
            reception_count = reception_source.receptions.length;
            let rows = this.createData(reception_source.receptions);
            contents = this.stableSort(rows, this.getSorting(this.state.order, this.state.orderBy))
                .map((data) => {                                    

                return (
                    <tr key={data.id}>
                        <td>
                            <Link to={`/receptions/reception/${data.id}`} className={'btn btn-sm btn-light'} title="View Reception Record">
                                View <FaChevronCircleRight/>
                            </Link>
                        </td>
                        <td>
                            { data.reference }
                        </td>
                        <td>
                            { data.author }
                        </td>
                    </tr>)
            });
        }


        return (

            <Grid item xs={12} >

                <Card>
                    <Card.Header >
                        <div className={styles.reception_captured_card_header}>
                            <div>
                                <Typography variant="h6" id="receptions-captured" name="receptions-captured">
                                    <strong>Receptions captured in this source </strong> 
                                </Typography>
                                <p className="text-muted mb-0">
                                    <small>
                                        <FaInfoCircle/> {reception_count} receptions found in this source.
                                    </small>
                                </p>
                            </div>

                        </div>
                    </Card.Header>
                    <Card.Body >
                        <Card className={styles.card_sources_captured}>
                            <Table striped bordered size={'sm'} responsive>
                                <EnhancedTableHead
                                    headCells={headCells}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />
                                <tbody className={styles.table_results_font_size}>
                                    {contents}
                                </tbody>
                            </Table>
                        </Card>
                    </Card.Body>
                </Card>

            </Grid>





        )
    }
}

export default withRouter(SourcesCaptured)