import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Nav from './Nav';
import Topbar from '../Topbar';
import Footer from '../Footer';
import styles_css from '../../style.module.css';
import { Link } from 'react-router-dom';

const banner = require('../../images/about-data-banner.png');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        // padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})

class Data extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="" />

                                <Typography variant="h1" className=" text-center mb-4 mt-4">About the Data</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} justify="center" container className={classes.grid}>

                            <Grid item xs={12} md={4}>
                               <Nav />
                            </Grid>

                            <Grid item xs={12} md={8}>


                                <Typography variant="h3" className="mb-2">Provenance of Data</Typography>

                                <p>RECIRC data are shaped by our four methodological approaches.</p>

                                <ul>
                                    <li>WP1 consulted the archives of the following religious orders: Augustinians, Louvain (Douai Abbey); Benedictines, Brussels (Archive of the Archdiocese of Mechelen, Belgium) and Dunkirk (Douai Abbey); Bridgettines, Syon (Exeter University); Poor Clares, Aire, Dunkirk, Gravelines and Rouen (Durham University); Sepulchrines (Colchester), as well as related materials held in the Bodleian Library (Cambrai, Paris, and Ghent Benedictines); British Library; Archives of the Archdiocese of Westminster; and Arthur Clifford’s Tixall editions.</li>
                                    <li>WP2 is based on the Hartlib Papers Online, the digitised version of the Samuel Hartlib archive, held at the University of Sheffield.</li>
                                    <li>WP3 conducted an audit of the manuscript collections held at the Bodleian Library, Oxford; the British Library, London; the Folger Shakespeare Library, Washington DC; and the Huntington Library, California. Of 750 manuscript miscellanies consulted, 128 contained receptions of women’s writing and were catalogued in detail.</li>
                                    <li>WP4 consulted auction catalogues, book lists and inventories, wills, and private library catalogues, drawing upon Early English Books Online and Private Libraries of Renaissance England as well as the published work of book historians.</li>
                                </ul>
                                
                                <Typography variant="h3" className="mb-2">Structure of Data and Definitions</Typography>
                                <ul>
                                    <li>There are two layers of reception in the RECIRC database: the source in which the reception evidence was found and the individual instance of reception itself. </li>
                                    <li>This correlates to the two agents of reception in the database: the Owner/Compiler/Scribe (the person[s] who owned, compiled, or transcribed the source document in which the reception is found) and the Receiver (the person who authored or signed the specific item of reception in the source document). Either of these two agents may be anonymous – for example, a poem about a female author may be anonymous but transcribed by a known Owner/Compiler/Scribe – but reception is considered anonymous only when both agents are unidentified.</li>
                                </ul>

                                 <Typography variant="h3" className="mb-2">Definitions</Typography>
                                <dl>
                                    <dt>Reception:</dt>
                                    <dd>a record of engagement with a female author and/or her work</dd>
                                
                                    <dt>Circulation:</dt>
                                    <dd>a record of where a female-authored work went</dd>

                                    <dt className="pb-3 pt-3">Types of Reception</dt>
                                    <dd>
                                        <Paper className="p-4">
                                            <table className="table table-sm table-striped">
                                                <tbody>
                                                    <tr><th scope="row">Adaptation</th><td>alteration of a work</td></tr>
                                                    <tr><th scope="row">Annotation</th><td>addition of note(s) to a work; note that engages with the work</td></tr>
                                                    <tr><th scope="row">Answer</th><td>explicit response to a work </td></tr>
                                                    <tr><th scope="row">Citation</th><td>reference to a work</td></tr>
                                                    <tr><th scope="row">Dedication</th><td>reference to author or work in a formal dedication</td></tr>
                                                    <tr><th scope="row">Drawing</th><td>visual representation of, or response to, a work or author</td></tr>
                                                    <tr><th scope="row">Excerpt</th><td>extract of a specific piece from a work</td></tr>
                                                    <tr><th scope="row">Extended commentary</th><td>lengthy passage of exposition on a work or author</td></tr>
                                                    <tr><th scope="row">Funeral sermon</th><td>sermon, eulogy or memorial about a female author</td></tr>
                                                    <tr><th scope="row">Marginalia</th><td>comment, mark or scribble in the margins of a page, not necessarily engaging with the work itself</td></tr>
                                                    <tr><th scope="row">Music-setting</th><td>a work by, or reference to, a female author that is set to music</td></tr>
                                                    <tr><th scope="row">Obituary</th><td>reference to work or author in a formal obituary</td></tr>
                                                    <tr><th scope="row">Paraphrase</th><td>rendering a quotation or work in synonymous language</td></tr>
                                                    <tr><th scope="row">Performance</th><td>physical enactment of a work</td></tr>
                                                    <tr><th scope="row">Poem</th><td>a poem about a female author and/or her work</td></tr>
                                                    <tr><th scope="row">Portrait</th><td>visual representation of an author or character/protagonist</td></tr>
                                                    <tr><th scope="row">Print edition</th><td>a printed copy from one discrete setting of type</td></tr>
                                                    <tr><th scope="row">Reading</th><td>general account of having read a work</td></tr>
                                                    <tr><th scope="row">Reading aloud in company</th><td>specific account of circumstance of reading</td></tr>
                                                    <tr><th scope="row">Reference to named author</th><td>naming of a female author</td></tr>
                                                    <tr><th scope="row">Reference to specific work</th><td>a precise reference to a specific work</td></tr>
                                                    <tr><th scope="row">Reference to unspecified work(s)</th><td>an allusion to an author’s work or works, which does not specify its name</td></tr>
                                                    <tr><th scope="row">Summary</th><td>précis of a work</td></tr>
                                                    <tr><th scope="row">Transcription</th><td>copy of a whole work, or substantial unit of a whole work</td></tr>
                                                    <tr><th scope="row">Translation</th><td>rendering of a work, or excerpt, into a language other than the original</td></tr>

                                                </tbody>
                                            </table>
                                        </Paper>
                                    </dd>
                                    
                                    <dt className="pb-3 pt-3">Types of Circulation</dt>
                                    <dd>
                                        <Paper className="p-4">
                                            <table className="table table-sm table-striped">
                                                <tbody>
                                                    <tr><th scope="row">Catalogue entry</th><td>listing of a female-authored work in a specific collection</td></tr>
                                                    <tr><th scope="row">Enclosure (with letter)</th><td>evidence of a female-authored text being sent with a letter</td></tr>
                                                    <tr><th scope="row">Endorsement</th><td>further information regarding a letter’s delivery.</td></tr>
                                                    <tr><th scope="row">Interception</th><td>evidence that a letter was intercepted and undelivered, e.g. archived in State Papers, additional endorsements, account of its being captured</td></tr>
                                                    <tr><th scope="row">Ownership inscription</th><td>evidence of a work’s ownership</td></tr>
                                                    <tr><th scope="row">Additional directions</th><td>evidence that a letter/enclosure was redirected</td></tr>
                                                    <tr><th scope="row">Named agent(s) of transmission</th><td>evidence of the letter bearer/messenger’s name</td></tr>
                                                </tbody>
                                            </table>
                                        </Paper>
                                    </dd>

                                    <dt className="pb-3 pt-3">Types of Reception Source</dt>
                                    <dd>
                                        <Paper className="p-4">
                                            <table className="table table-sm table-striped">
                                                <tbody>
                                                    <tr><th scope="row">Account book</th><td>a manuscript volume comprised mainly of household or business accounts, includes convent benefactors’ book as category</td></tr>
                                                    <tr><th scope="row">Auction catalogue</th><td>a list of books to be put up for sale at auction</td></tr>
                                                    <tr><th scope="row">Autobiography/memoir</th><td>a first-person account of an author’s life, or events in the life</td></tr>
                                                    <tr><th scope="row">Bio-bibliography</th><td>a systematic list of books organised according to author/printer/country/religious order/institution that includes biographical accounts of the authors</td></tr>
                                                    <tr><th scope="row">Biography</th><td>a third-person account of a life</td></tr>
                                                    <tr><th scope="row">Booklist</th><td>a list of the books held / books of a particular author, printer, country, religious order, institution / books with a particular theme</td></tr>
                                                    <tr><th scope="row">Chronicle</th><td>a detailed and continuous register of events in chronological order / a historical account of a religious house or order, arranged chronologically.</td></tr>
                                                    <tr><th scope="row">Commonplace book</th><td>a manuscript volume comprised mainly of quotations or passages from reading matter entered for ready reference under general subject headings</td></tr>
                                                    <tr><th scope="row">Composite volume</th><td>a volume made up of various physically and textually independent units bound together</td></tr>
                                                        <tr><th scope="row">Conduct/advice book</th><td>a printed volume prescribing how to live/behave/conduct oneself in a particular role</td></tr>
                                                        <tr><th scope="row">Devotional book</th><td>a volume comprised primarily of material on religious/devotional subjects</td></tr>
                                                        <tr><th scope="row">Dialogue</th><td>a text structured in the form of a conversation between two or more protagonists</td></tr>
                                                        <tr><th scope="row">Diary</th><td>a volume structured around episodic, dated entries, usually autobiographical</td></tr>
                                                        <tr><th scope="row">Essay/discourse</th><td>an extended prose treatment of a particular topic, non-fiction</td></tr>
                                                    <tr><th scope="row">Fascicle</th><td>a unit, bundle, part (of a volume or series)</td></tr>
                                                            <tr><th scope="row">First print edition of a manuscript work</th><td>the earliest edition in print of a work previously circulated in manuscript </td></tr>
                                                            <tr><th scope="row">Hagiography</th><td>a third-person life of a saint</td></tr>
                                                            <tr><th scope="row">Inventory</th><td>a list of items and goods held at a particular moment in time</td></tr>
                                                            <tr><th scope="row">Legal document</th><td>any legal text, excluding wills and legacies</td></tr>
                                                            <tr><th scope="row">Letter</th><td>a written communication addressed to a person, collective, or institution, usually sent by post or messenger</td></tr>
                                                            <tr><th scope="row">Library catalogue</th><td>a list of books gathered in a single library collection</td></tr>
                                                            <tr><th scope="row">Martyrology</th><td>a compendium of testimonies to the experiences of a number of martyrs</td></tr>
                                                            <tr><th scope="row">Miscellany</th><td>a collection of miscellaneous materials by various authors</td></tr>
                                                            <tr><th scope="row">Natural history</th><td>a narrative comprised mainly of scientific writing</td></tr>
                                                            <tr><th scope="row">Obituary book</th><td>a volume containing obituaries [religious orders only]</td></tr>
                                                            <tr><th scope="row">Poetry anthology</th><td>a volume in which only poetry is collected</td></tr>
                                                            <tr><th scope="row">Prophecy/dream</th><td>a divinely inspired prediction or revelation from God; a work inspired by a dream</td></tr>
                                                            <tr><th scope="row">Recipe/receipt Book</th><td>a volume comprised mainly of recipes, household and/or medical</td></tr>
                                                            <tr><th scope="row">Sermon</th><td>text of a homily preached as part of a church service</td></tr>
                                                            <tr><th scope="row">Single-author volume</th><td>a bound volume containing only the work of one author</td></tr>
                                                            <tr><th scope="row">Subsequent edition</th><td>any print edition of a female author’s work subsequent to the first edition</td></tr>
                                                            <tr><th scope="row">Translation</th><td>a female author’s work rendered into a language other than the original</td></tr>

                                                </tbody>
                                            </table>
                                        </Paper>
                                    </dd>
                                </dl>

                                <Typography variant="h3" className="mb-2">Research Parameters</Typography>
                                <p>
                                    RECIRC data are not exhaustive. They are shaped by our research questions, methodological approaches, and the archives consulted. Our top ten of most commonly received/circulated female authors reflects our research parameters. 
                                </p>

                                <div className="alert alert-info">
                                    <Typography variant="h4">10 most commonly received/circulated authors</Typography>
                                    <ul>
                                        <li><Link to="/people/person/2670">Queen Elizabeth I</Link></li>
                                        <li><Link to="/people/person/3195">Katherine Jones, Viscountess Ranelagh</Link></li>
                                        <li><Link to="/people/person/2433">Mary Percy, Abbess of Brussels Benedictine convent</Link></li>
                                        <li><Link to="/people/person/3194">Dorothy Moore Dury</Link></li>
                                        <li><Link to="/people/person/2618">Mary, Queen of Scots</Link></li>
                                        <li><Link to="/people/person/2445">Katherine Philips (‘Orinda’)</Link></li>
                                        <li><Link to="/people/person/3586">Agnes Lenthall, Brussels Benedictine nun</Link></li>
                                        <li><Link to="/people/person/3541">Maria Vavasour, Brussels Benedictine nun</Link></li>
                                        <li><Link to="/people/person/2671">Queen Mary I</Link></li>
                                        <li><Link to="/people/person/1704">Aphra Behn (‘Astraea’)</Link></li>
                                    </ul>
                                </div>
            
                                <p>
                                    The prominence of nuns reflects the work of WP1, for example. WP2 found so much evidence of women’s participation in the Hartlib Circle, there was no time to research additional scientific correspondence networks. Single-author manuscripts were excluded from the work of WP3. Book historians continue to recover the records of early modern libraries. An important finding, then, is that there is far more material than we could locate and analyse within the five-year period of this project.
                                </p>
                                <p>
                                    The data contained in the RECIRC database have been comprehensively cleaned; every effort has been made to ensure that at least two researchers have checked all our data. However, users are encouraged to consult original materials in addition to the RECIRC database.
                                </p>


                                <Typography variant="h3" className="mb-2">RECIRC in Numbers</Typography>

                                <p>The RECIRC database contains:</p>

                                <ul>
                                    <li>1,878 female authors from England, Ireland, Scotland, Wales, North America, the Low Countries, Denmark, Germany, France, Italy, Spain, Portugal, from classical to early modern times. </li>
                                    <li>7,319 works authored by these women.</li>
                                    <li>4,845 receptions of female authors and their works by 678 identified people in 1,431 different sources.</li>
                                    <li>264 Owner/Compiler/Scribes and 457 Receivers (43 of whom overlap roles)</li>
                                    <li>15% of received authors were received twice or more. Within our research parameters, we found no reception evidence for many female authors. The majority (85%) for whom we found reception evidence are represented by only one reception in the dataset.</li>
                                    <li>13% of receptions in the dataset are anonymous, meaning we cannot identify the reader, scribe, compiler, or owner who was engaging with the woman writer.</li>
                                </ul>
                                
                                <Typography variant="h3" className="mb-2">Major Archives and Resources Consulted </Typography>
                                <ul>
                                    <li>Archief van het aartsbisdom Mechelen (Archive of the Archdiocese of Mechelen), Mechelen, Belgium</li>
                                    <li>Archives of the Archdiocese of Westminster, London, UK</li>
                                    <li>Armagh Public Library, Armagh, UK</li>
                                    <li>Beinecke Library, Yale University, USA</li>
                                    <li>Bodleian Library, Oxford, UK</li>
                                    <li>British Library, London, UK</li>
                                    <li>Cambridge University Library, Cambridge, UK</li>
                                    <li>Canonesses of the Holy Sepulchre, Colchester, UK</li>
                                    <li>Chatsworth House, Derbyshire, UK </li>
                                    <li>Douai Abbey Archive, Woolhampton, UK</li>
                                    <li>Early English Books Online</li>
                                    <li>English Short Title Catalogue</li>
                                    <li>Folger Shakespeare Library, Washington DC, USA</li>
                                    <li>Hartlib Papers Online, University of Sheffield, UK</li>
                                    <li>Houghton Library, Harvard University, USA</li>
                                    <li>Huntington Library, San Marino, USA</li>
                                    <li>John Rylands Library, Manchester, UK </li>
                                    <li>National Archives, London, UK</li>
                                    <li>National Library of Ireland, Dublin, Ireland</li>
                                    <li>National Library of Scotland, Edinburgh, UK</li>
                                    <li>Pepys Library, University of Cambridge, UK</li>
                                    <li>Poor Clares, Darlington Manuscripts, University of Durham, UK</li>
                                    <li>Portland Collection, University of Nottingham, UK</li>
                                    <li>Private Libraries in Renaissance England</li>
                                    <li>Rosenback Museum and Library, Philadelphia, USA</li>
                                    <li>Syon Abbey Collection, University of Exeter, UK</li>
                                    <li>Universal Short Title Catalogue</li>

                                </ul>

                            </Grid>
                        </Grid>
                    </Grid>




                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Data);