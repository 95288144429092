import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Table} from 'react-bootstrap'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography'


const styles = () => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'transparent',
        overflow: 'hidden',
        color: 'lightgrey'
    },
    paper: {
        backgroundColor: 'white',
        marginTop: 20,

    }
})

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);


class PersonWorkDetail extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.hideDetails = this.hideDetails.bind(this);

        this.state = {
            isLoading: false,
            showDetails: false,
            work: [],
            person: "",
            open: false,
            show: false
        };
    }

    hideDetails(show) {
        //console.log("Hide details");
        this.props.onChange(show, "");

    }



    handleClose = (show) => {
        this.props.onChange(show, "");
    };

    render() {
        const { work } = this.props;

        return (
            <Dialog
                    onClose={this.handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={true}

                >
                    <DialogTitle
                        id="customized-dialog-title" onClose={e => this.hideDetails(false)}>
                        <div >
                            <Typography variant="h6"><strong>Work Details</strong> </Typography>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Table striped bordered size={'sm'} responsive>

                            <tbody style={{ fontSize: "12px"}}>

                            <tr>
                                <td><strong>Title</strong></td>
                                <td>{work.title}</td>
                            </tr>
                            <tr>
                                <td><strong>Authorship</strong></td>
                                <td>{work.authorship}</td>
                            </tr>
                            <tr>
                                <td><strong>Author / Collaborator</strong></td>
                                <td>Test</td>
                            </tr>
                            <tr>
                                <td><strong>Assigned Title?</strong></td>
                                <td>{work["title_assigned"]}</td>
                            </tr>
                            <tr>
                                <td><strong>Date</strong></td>
                                <td>{work["date"]}</td>
                            </tr>
                            <tr>
                                <td><strong>Place of Composition</strong></td>
                                <td>{work["place_of_composition"]}</td>
                            </tr>
                            <tr>
                                <td><strong>Previously Unknown Work</strong></td>
                                <td>{work["previously_unknown_work"]}</td>
                            </tr>
                            <tr>
                                <td><strong>Shelfmark</strong></td>
                                <td>{work["shelfmark"]}</td>
                            </tr>

                            </tbody>
                        </Table>
                    </DialogContent>

                </Dialog>

        );
    }

}

PersonWorkDetail.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PersonWorkDetail);