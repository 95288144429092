import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardItem from './cards/CardItem';
import Topbar from './Topbar';
import Footer from './Footer';
import styles_css from '../style.module.css';
import { FaHome } from 'react-icons/fa';

import blogData from '../config/blog-index.json';
import { FaUser, FaCalendarAlt } from 'react-icons/fa';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    },
    paper: {
        padding: '2em'
    }
})

class Blog extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
            post: {}
        }
    }

    componentDidMount(){
        const currentPath = this.props.location.pathname
        let post = blogData.filter(post => {
            return post.link === currentPath;
        });

        if (post.length > 0) {
            post = post[0];
        }

        return fetch(`/blog-data/${post.id}.json`)
            .then((response) => response.json())
            .then((responseJson) => {

                // console.log(responseJson)
                this.setState({
                    post: responseJson,
                    isLoading: false
                });

            })

    }


    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname;
        // let post = blogData.filter(post => {
        //     return post.link === currentPath;
        // });

        // if(post.length > 0){
        //     post = post[0];
        // }
        const { post, isLoading }= this.state;
        
        if(isLoading){
            return null;
        }

        let fulldate = new Date(post.date);
        // date = Date(date);
        fulldate = fulldate.toLocaleString();
        let date = fulldate.split(',');
        date = date[0];

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container >
                                        <Grid item xs={12} className={classes.grid}>

                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item">
                                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                                    </li>
                                                    <li className="breadcrumb-item"><Link to={`/blog`}>Blog</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                                                </ol>
                                            </nav>

                                        </Grid>
                                    </Grid>
                                    <Typography variant="h1" className="pb-4" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                                    <p className="text-muted">
                                        <FaUser /> {post.author}

                                        <FaCalendarAlt className="ml-5" /> {date}
                                    </p>

                                    <div className={styles_css.blogPost} dangerouslySetInnerHTML={{ __html: post.content.rendered }} />   
                                                            

                                                
                                    <Grid container >
                                        <Grid item xs={12} className={classes.grid}>

                                            <nav aria-label="breadcrumb">
                                                <ol className="breadcrumb">
                                                    <li className="breadcrumb-item">
                                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                                    </li>
                                                    <li className="breadcrumb-item"><Link to={`/blog`}>Blog</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page" dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
                                                </ol>
                                            </nav>

                                        </Grid>
                                    </Grid>


                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Blog);