import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { withRouter } from 'react-router-dom';

const logos = [
    {
        img: require('../../images/logo-erc.jpg'),
        alt: 'European Research Council'
    },
    {
        img: require('../../images/logo-nuig.jpg'),
        alt: "NUI Galway"
    },
    {
        img: require('../../images/logo-irc.jpg'),
        alt: "Irish Research Council"
    },
    {
        img: require('../../images/logo-moore.jpg'),
        alt: "Moore Institute"
    }
];




const styles = theme => ({
    root: {
        flexGrow: 1,
        // paddingBottom: 200
    },
    grid: {
        width: 1200,
        // marginTop: 20,
        // marginBottom: 20,
        // marginLeft: 20,
        // marginRight: 20,
        // [theme.breakpoints.down('sm')]: {
        //     width: 'calc(100% - 20px)'
        // }
    },
    logo: {
        // margin: '5px',
        // width: '95%'
    },
    paper: {
        padding: theme.spacing.unit * 1.5,
        backgroundColor: 'transparent',
        textAlign: 'center',
        boxShadow: 'none',
        dropShadow: 'none'
    },
});

class Logos extends Component {
    render() {
        const { classes, cols } = this.props;

        // Grid columns => 12/cols
        const colCount = (cols) ? cols : 6;
    

        let logoList = logos.map((logo) => {
            return (
                <Grid key={logo.img} item xs={colCount} style={{display: 'flex'}} className={classes.logo}>
                    <Paper className={classes.paper}>
                        <img src={ logo.img } alt="{ logo.alt }"/>
                    </Paper>
                </Grid>
            );
        });

        return (
            <div className={classes.root}>
                <Grid item xs={12}>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant="h4" gutterBottom>Supported By</Typography>
                    </div>
                </Grid>
                <Grid container spacing={16}>
                    { logoList }
                </Grid>
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(Logos));