import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Typography } from '@material-ui/core';
import { FaHome } from 'react-icons/fa';

// images
import reception_3290 from '../../images/case-auction-1.png';
import reception_1727 from '../../images/case-auction-2.png';
import reception_3259 from '../../images/case-auction-3.png';
import reception_search_1 from '../../images/case-auction-4.png';
import reception_search_2 from '../../images/case-auction-5.png';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    },
    paper: {
        padding: '2em'
    }
})

class AuctionCatalogues extends Component {

   
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper className={ classes.paper }>
                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Auction Catalogues</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>

                    <Typography variant="h1" className="pb-4">Auction Catalogues</Typography>
                    <p className="case-intro">
                        Examining auction catalogues - 'lists of books to be put up for sale at auction' - enables us to identify patterns in the ownership of female-authored works. RECIRC excluded trade-only catalogues, focusing instead on those associated with an individual, usually deceased. Other cognate categories include: booklist, inventory, library catalogue.
                    </p>

                    <Typography variant="h2" className="pb-2">Reception Type</Typography>
                    <p>Receptions within auction catalogues can be divided into two types:</p>

                    <ol className="list-unstyled">
                        <li>
                            <Typography variant="h4">1. Ownership of a female-authored text </Typography>
                            <ul>
                                <li>
                                    For example, <Link to="/receptions/reception/3290">reception #3290</Link> captures ownership of Margaret Cavendish’s 1668 Plays and in this instance, the owner of the auction catalogue attributed the text to Cavendish -  ‘Dutchess of Newcastles Plays - ibid. [London] 1668’ -  resulting in this reception being categorised as both a ‘reference to named author’ and a ‘reference to specific work’. 
                                    <br/>
                                    <Link to="/receptions/reception/3290">
                                        <img src={reception_3290} alt="Screenshot of a reception of Margaret Cavendish" className="mt-4 mb-4"/>
                                    </Link>
                                </li>
                                <li>
                                    On the other hand, while <Link to="/receptions/reception/1727">reception #1727</Link> captures ownership of Madeleine de Scudéry’s Clélie, the owner of this catalogue does not attribute the text to Scudéry - ‘Clelia Romance – 1678' - meaning this reception is categorised as a ‘reference to specific work’ alone. 
                                    <br />
                                    <Link to="/receptions/reception/1727">
                                        <img src={reception_1727} alt="Screenshot of a reception of Madeleine de Scudéry’s Clélie" className="mt-4 mb-4" />
                                    </Link>
                                </li>
                            </ul>
                        </li>
                            
                        <li>
                            <Typography variant="h4">2. Ownership of a text about a female author</Typography>
                            <ul>
                                <li>
                                    Here, in <Link to="/receptions/reception/3259">reception #3259</Link>, the catalogue records ownership of ‘Cambdens History of Queen Elizabeth - Lond. 1625’: William Camden’s Annales rerum Anglicarum, et Hibernicarum, regnante Elizabetha [History of Elizabeth], the final part of which was published in Latin in 1625. As this text is about Elizabeth I, but is not by her, the reception is catagorised as a ‘reference to named author’. Because Camden is the author of the item about Elizabeth, he is the ‘receiver’, and because Thomas Britton is the owner of the library being catalogued, he is the ‘owner/compiler/scribe’. See also <Link to="/cases/layers-and-agents-of-reception">Layers and Agents of Reception</Link>.
                                     <br />
                                    <Link to="/receptions/reception/3259">
                                        <img src={reception_3259} alt="Screenshot of a reception of Elizabeth I" className="mt-4 mb-4" />
                                    </Link>
                            </li>
                            </ul>
                        </li>
                    </ol>

                    <Typography variant="h2" className="pb-2">Catalogus librorum, tam antiquorum quam recentium</Typography>

                    <p>A specific auction catalogue offers a number of ways to search. This catalogue is a list of books put up for sale by <Link to="/people/person/3759">Robert Whitehall</Link> in 1700.</p>
                    <ul>
                        <li>
                            You can search for all its receptions of female authors by selecting <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.title","label":"Reception Source Title","filter":"contains","term":"Catalogus librorum, tam antiquorum quam recentium","termLabel":"Catalogus librorum, tam antiquorum quam recentium"}]&view=table'}>‘Reception Source’ in advanced search</Link>, and entering ‘<Link to="/sources/source/968">Catalogus librorum, tam antiquorum quam recentium</Link>’. This brings you to the Reception Source profile page.
                        </li>
                    </ul>
                    <p>
                        In total there are <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.title","label":"Reception Source Title","filter":"contains","term":"Catalogus librorum, tam antiquorum quam recentium","termLabel":"Catalogus librorum, tam antiquorum quam recentium"}]&view=table'}>14 receptions linked to this auction catalogue</Link>. Of these, 7 are references to female-authored texts: 2 texts each by the French authors <Link to="/people/person/2238">Anne Lesnier</Link> and <Link to="/people/person/1989">Marie-Catherine-Hortense de Villedieu</Link>, 2 by <Link to="/people/person/1843">Margaret Cavendish</Link>, and 1 by <Link to="/people/person/1681">Jane Barker</Link>. 6 of the 7 female-authored works are attributed in the catalogue. 
                    </p>

                    <p>There are also 6 texts that refer to <Link to="/people/person/2670">Elizabeth I</Link> (like Camden’s biography above) and 1 that refers to <Link to="/people/person/2671">Mary I</Link>.</p> 

                    <p>To find the receptions of Lesnier, Hortense de Villedieu, and Cavendish:</p>
                    <ul>
                        <li>
                            Choose the field ‘reference’ and enter ‘Catalogus librorum, tam antiquorum quam recentium’. Then, choose the field ‘reception type’, select the sub-filter ‘equals’, and pick ‘reference to specific work’. This <Link to={'/receptions?query_type=must&term=[{"name":"reference","label":"Reference","filter":"contains","term":"Catalogus librorum, tam antiquorum quam recentium","termLabel":"Catalogus librorum, tam antiquorum quam recentium"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":84,"value":"reference-to-named-work","label":"Reference to Specific Work"}],"termLabel":"Reference to Specific Work"}]&view=table'}>will produce 7 results</Link>.  
                            
                            <br/>

                            <Link to={'/receptions?query_type=must&term=[{"name":"reference","label":"Reference","filter":"contains","term":"Catalogus librorum, tam antiquorum quam recentium","termLabel":"Catalogus librorum, tam antiquorum quam recentium"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":84,"value":"reference-to-named-work","label":"Reference to Specific Work"}],"termLabel":"Reference to Specific Work"}]&view=table'}>
                                <img src={reception_search_1} alt="Search Results Screenshot" className="mt-4 mb-4"/>
                            </Link>
                        </li>
                        <li>
                            To find receptions about <Link to="/people/person/2670">Elizabeth I</Link>: choose the field ‘reference’ and enter ‘Catalogus librorum’. Then, choose the field ‘female author’ and enter ‘Elizabeth Tudor’. Next, choose ‘reception type’ and select ‘Reference to Named Author’. This <Link to={'/receptions?query_type=must&term=[{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":83,"value":"reference-to-named-author","label":"Reference To Named Author","$$hashkey":""}],"termLabel":"Reference To Named Author"},{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"},{"name":"reference","label":"Reference","filter":"contains","term":"Catalogus librorum, tam antiquorum quam recentium","termLabel":"Catalogus librorum, tam antiquorum quam recentium"}]&view=table'}>will produce 6 results</Link> 
                            

                            <br />

                            <Link to={'/receptions?query_type=must&term=[{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":83,"value":"reference-to-named-author","label":"Reference To Named Author","$$hashkey":""}],"termLabel":"Reference To Named Author"},{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"},{"name":"reference","label":"Reference","filter":"contains","term":"Catalogus librorum, tam antiquorum quam recentium","termLabel":"Catalogus librorum, tam antiquorum quam recentium"}]&view=table'}>
                                <img src={reception_search_2} alt="Search Results Screenshot" className="mt-4 mb-4" />
                            </Link>

                        </li>
                    </ul>

                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Auction Catalogues</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>
                    
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AuctionCatalogues);