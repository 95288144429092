import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

/**
 * Component to give nav options between a table and graph
 * view of search results
 * resultCount, showTable, showNetwork
 * @param {int} resultCount - number of search results returned
 * @param {bool} showTable - default [true] - display results as a table
 * @param {bool} showNetwork - default [false] - display results as a graph
 */

class ResultsViewNav extends Component {

    constructor(props, context) {
        super(props, context);

        this.toggleResultsView = this.toggleResultsView.bind(this);

    }

    /**
     * Event handler to toggle between a 
     * table view and a network graph view
     * of the search results
     * 
     * @param {bool} showResultsAsTable - default true
     */
    toggleResultsView(showResultsAsTable)
    {
        this.props.onChange(showResultsAsTable);
    }


    render() {
        const { resultCount, showTable, showNetwork } = this.props;
        
        let viewOptionText = (
            <button className="btn btn-sm btn-link" onClick={e => this.toggleResultsView(false)}>
                View results as Graph
            </button>
        );

        if(showTable){
            viewOptionText = (
                <button className="btn btn-sm btn-link" onClick={e => this.toggleResultsView(false)}>
                    View results as Graph
                </button>
            );    
        }else if(showNetwork){
            viewOptionText = (
                <button className="btn btn-sm btn-link" onClick={e => this.toggleResultsView(true)}>
                        View results as Table
                </button>
            );
        }


        return (
            <div >
                <Typography variant="caption"><strong>Found {resultCount} results | { viewOptionText} </strong> </Typography>
            </div>
        );
    }
}

export default withRouter(ResultsViewNav);