import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FaChevronCircleRight } from 'react-icons/fa'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Button, Card, Form} from 'react-bootstrap'
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowRight';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import EnhancedTableHead from "../common/EnhancedTableHead";
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import ResultsViewNav from "../common/ResultsViewNav";
import ResultsViewExport from "../common/ResultsViewExport";

import styles from '../../style.module.css';
import withStyles from '@material-ui/core/styles/withStyles';

class TablePaginationActions extends React.Component {
    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1));
    };

    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return <div className={classes.root}>
            <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={this.handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={this.handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>;
    }
}

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired
};

const actionsStyles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing.unit * 2.5
    }
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(TablePaginationActions);


class SourceResultsTable extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.hideDetails = this.hideDetails.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.switchResultsView = this.switchResultsView.bind(this);

        this.state = {
            isLoading: false,
            peopleSearchResponse: [],
            author: [],
            peopleList: [],
            receivers: [],
            showDetails: false,
            work: '',
            page: 0,
            rows: 0,
            rowsPerPage: 25,
            sources_length: 0,
            receptions_length: 0,
            order: 'desc',
            orderBy: 'reception_count'

        };
    }


    componentDidMount() {
        let receptions = []
        this.props.receptionSearchResponse.data.map((data) => {
            data._source.receptions.map((rec) => {
                rec.source_title = data._source.title
                receptions.push(rec)
            })
        })
        this.setState({
            sources_length: this.props.receptionSearchResponse.data.length,
            receptions_length: receptions.length,
            rows: this.props.receptionSearchResponse.data.length
        })
    }

    componentDidUpdate(prevProps) {

        if (this.props.receptionSearchResponse !== prevProps.receptionSearchResponse) {
            let receptions = []
            if (this.props.receptionSearchResponse.data != undefined) {
                this.props.receptionSearchResponse.data.map((data) => {
                    data._source.receptions.map((rec) => {
                        rec.source_title = data._source.title
                        receptions.push(rec)
                    })
                })
                this.setState({
                    sources_length: this.props.receptionSearchResponse.data.length,
                    receptions_length: receptions.length,
                    rows: this.props.receptionSearchResponse.data.length
                })
            }

        }
    }


    showDetails(show, work) {

        this.setState({
            showDetails: show,
            work: work
        })

    }

    hideDetails(show) {
        this.setState({
            showDetails: false,
            work: ""
        })

    }

    switchResultsView(showResultsAsTable)
    {
        this.props.onChange(showResultsAsTable)
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleRowsPerPage = (event, rowsPerPage) => {
        let rows = parseInt(rowsPerPage.key)
        this.setState({ rowsPerPage: rows });
    };

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';

        this.setState({
            order: (isDesc ? 'asc' : 'desc'),
            orderBy: property
        });
    };

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    /**
     * Transform search result response into object
     * that can both be sorted, and contains React components (such
     * as Links) for display purposes
     */
    createData = (data) => {
        return data.map(element => {
            let src = element._source;
            
            let owners = null;
            let owners_text = '';
            if (src.owners && src.owners.length > 0) {
                owners = src.owners.map((p, idx, arr) => {
                    let sep = '';
                    if (arr.length > 1 && idx < arr.length - 1) {
                        sep = ', ';
                    }

                    owners_text += p.display_name + ' ';
                    
                    return (
                        <span key={`o-${p.id}`}>
                            <Link to={`/people/person/` + p.id}>
                                {p.display_name}
                            </Link> {sep}
                        </span>
                    );
                });

            }

            let title = (
                <Link to={`/sources/source/${src.id}`}>
                    {src.title}
                </Link>
            );


            return {
                'id': src.id,
                'title_text': src.title,
                'title': title,
                'owners': owners,
                'owners_text': owners_text,
                'reception_count': src.receptions.length
            }
        });
    };

    render() {

        const { receptionSearchResponse } = this.props;
        // const { person } = this.props;
        const { isLoading } = this.props;
        const { sources_length }  = this.state;
        const { receptions_length }  = this.state;

        const { rowsPerPage, page } = this.state;
        let searchResponseLength = receptions_length;

        if (isLoading) {
            return (
                <span>"Loading...."</span>
            );
        }
        
        // ID here matches the keys for data sorting set
        // in this.createData()
        const headCells = [
            { id: 'id', numeric: false, disablePadding: true, label: '' },
            { id: 'title_text', numeric: false, disablePadding: true, label: 'Source Title' },
            { id: 'owners_text', numeric: false, disablePadding: true, label: 'Owner / Compiler / Scribe' },
            { id: 'reception_count', numeric: true, disablePadding: true, label: 'Number of Receptions' },
            
        ];

        let contents = (<tr></tr>);

        if ((receptionSearchResponse.data !== undefined) && (receptionSearchResponse.data.length >= 1)) {
            searchResponseLength = receptionSearchResponse.data.length;
            let rows = this.createData(receptionSearchResponse.data);
            contents = this.stableSort(rows, this.getSorting(this.state.order, this.state.orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data) => {                                    

                    return (

                        <TableRow className={styles.tableRow} key={`source-` + data.id}>
                             <TableCell>
                                 <Link to={`/sources/source/${data.id}`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="btn btn-sm btn-light">
                                    <FaChevronCircleRight style={{ marginRight: '5px' }}/> View
                                 </Link>
                             </TableCell>
                             <TableCell>
                                 { data.title }
                             </TableCell>                            
                             <TableCell>
                                 { data.owners }
                             </TableCell> 
                             <TableCell>
                                { data.reception_count }
                             </TableCell> 
                            
                        </TableRow>

                    );

                });
        
        

            return (

                <Grid item xs={12} >
                    <Card>
                            <Card.Header >
                                <div className={styles.table_results_header}>
                                    <ResultsViewNav 
                                        resultCount={receptionSearchResponse.data.length} 
                                        showTable={this.props.showTable} 
                                        showNetwork={this.props.showNetwork}
                                        onChange={this.switchResultsView}
                                    />

                                    <ResultsViewExport 
                                        query={this.props.query}
                                        type={'source'}                            
                                    />


                                    {/* <div >
                                        <Typography variant="caption"><strong>Found {sources_length} Sources and {receptions_length} associated Receptions | View results as Graph</strong> </Typography>
                                    </div>
                                    <div className={styles.table_results_right_widget}>
                                        <Typography variant="caption"><strong>Export</strong> </Typography>
                                        <Button size="sm" className={styles.table_results_gephi_button} variant="secondary">
                                            Gephi File (.gefx)
                                        </Button>
                                        <Form.Control className={styles.table_results_csv_button} size="sm" as="select">
                                            <option>CSV File</option>

                                        </Form.Control>

                                    </div> */}
                                </div>
                            </Card.Header>
                            <Card.Body className={styles.table_results_card_body}>
                                <Card className={styles.table_results_card_table}>
                                    <Table id={"receptions"} >
                                        <EnhancedTableHead
                                            headCells={headCells}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onRequestSort={this.handleRequestSort}
                                        />
                                        <TableBody className={styles.table_results_font_size}>
                                            {contents}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={3}
                                                    count={searchResponseLength}
                                                    rowsPerPage={10}
                                                    page={page}
                                                    SelectProps={{
                                                        native: false
                                                    }}
                                                    onChangeRowsPerPage={this.handleRowsPerPage}
                                                    onChangePage={this.handleChangePage}
                                                    labelRowsPerPage={"Rows to View"}
                                                    ActionsComponent={TablePaginationActionsWrapped} />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Card>

                            </Card.Body>
                        </Card>

                </Grid>


            )
        }else{
            return null;
        }
    }
}

export default withRouter(withStyles(styles)(SourceResultsTable))