import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Twitter from './Twitter';
import PubList from './PubList';
import { FaChevronRight, FaBookOpen, FaUsers, FaBook, FaProjectDiagram } from 'react-icons/fa';

import Topbar from './Topbar';
import Footer from './Footer';
import styles_css from '../style.module.css';
import { config } from '../config';

import img_banner from '../images/Slider_Letter-1170x658.jpg';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        width: 1200,
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
    twitterGrid: {
        height: 40,
        marginTop: 40,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        // color: theme.palette.text.secondary,
    },


    paperData: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        justifyContent: 'flex-start',
        // color: theme.palette.text.secondary,
        display: 'flex',
        minHeight: 120
    },

    discoverTitle: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        display: 'flex',
        minHeight: '145px',
    },

    paperTwitter: {
        height: 440,
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    rangeLabel: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: theme.spacing.unit * 2
    },
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 32
    },
    outlinedButtom: {
        textTransform: 'uppercase',
        margin: theme.spacing.unit
    },
    actionButtom: {
        margin: theme.spacing.unit,
        alignItems: 'center',
        width: 250
    },
    blockCenter: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center'
    },
    block: {
        padding: theme.spacing.unit * 2,
    },


    box2: {
        marginBottom: 10,
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20,
    },
    exploreBox: {
        textAlign: 'center',
        minHeight: 100
    },
    boxLink: {
        color: '#222',
        display: 'block',

        '&:hover': {
            color: '#000',
            background: '#eee',
            textDecoration: 'none',
        }
    },
    
    exploreDataBox: {
        paddingTop: 10,
        marginBottom: 10,
        height: 45,
        width: 250,
        justifyContent: 'flex-start',
        alignItems: 'left',
        marginRight: 1,
        marginLeft: 1,
        display: 'flex',
        fontSize: '1.25em',
    },
    exploreDataBox1: {
        paddingTop: 10,
        marginBottom: 10,
        height: 45,
        width: 40,
        justifyContent: 'flex-start',
        alignItems: 'left',
        marginRight: 1,
        marginLeft: 1,
        display: 'flex',
        

    },
    exploreDataBox2: {
        paddingTop: theme.spacing.unit * 2,
        marginBottom: 10,
        height: 45,
        marginRight: 10,
        marginLeft: 10,
        textAlign: 'center',
        display: 'inline',
        
    },
    exploreAboutBox: {
        marginBottom: 40,
        height: 65,
        textAlign: 'left',
        display: 'inline'
    },
    inlining: {
        display: 'inline-block',
        marginRight: 10
    },
    buttonBar: {
        display: 'flex'
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    divider: {
        margin: `${theme.spacing.unit * 2}px 0`,
    },
    noBorder: {
        borderBottomStyle: 'hidden'
    },
    loadingState: {
        opacity: 0.05
    },
    loadingMessage: {
        position: 'absolute',
        top: '40%',
        left: '40%'
    },
    banner_overlay: {
        backgroundColor: 'rgba(210, 167, 77, 0.9)',
        fontStyle: 'italic', 
        padding: '2em',
        position: 'absolute',
        top: '40%'
    }
});

class Main extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
            count: null
        };
    }

    componentDidMount() {
        fetch(config.apiUrl + '/config')
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    count: responseJson["count"]
                });

            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    isLoading: false
                });
            });
    }


    render() {
        const { classes } = this.props;
        const { count } = this.state;

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar isHome={true} />
                <div className={classes.root}>
                    <Grid container justify="center" alignItems="flex-start"  spacing={24}>
                        <Grid container className={classes.grid}>
                            <Grid alignItems="flex-start" className={classes.grid} container spacing={24}>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <div className={classes.box} style={{ position: 'relative'}}>
                                            <img src={ img_banner } alt=""/>
                                            <div className={classes.banner_overlay + ` col-xs-12 col-md-4`}>
                                                <Typography variant="h2" style={{ color: '#fff'}}>
                                                    Which female authors were read?
                                                </Typography>
                                            </div>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Grid container item xs={12} className={classes.grid} justify="flex-start" alignItems="flex-start" spacing={24}>
                                    <Grid item xs={12} style={{ padding: '0 12px 12px'}}>
                                        <Paper className={classes.paper} id="home-blurb">
                                            <div className={classes.box2}>
                                                <Typography >
                                                    RECIRC: The Reception and Circulation of Early Modern Women’s Writing, 1550-1700 is a research project about the impact made by women writers and their works in the sixteenth and seventeenth centuries. Led by Marie-Louise Coolahan, and funded by the European Research Council from 2014 to 2020, the project involved a team of 11 researchers based at the National University of Ireland Galway.
                                                </Typography>

                                            </div>
                                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                                <Typography style={{alignItems: 'center'}} color='primary' gutterBottom>
                                                    <a href="/about">
                                                        More about the project
                                                        <FaChevronRight style={{'paddingLeft': '.5em'}}/>
                                                    </a>
                                                </Typography>


                                            </div>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Paper id={styles_css.aboutDataWrapper} className={classes.paper}>
                                            <div className={classes.box2}>
                                                <Typography variant="h4" style={{textAlign: 'center'}}>
                                                    <a href="/about-data">
                                                        About the Data
                                                    </a>
                                                </Typography>
                                                <Typography style={{ alignItems: 'center' }} color='primary' gutterBottom>
                                                    <a href="/about-data">
                                                        Learn more about the data
                                                        <FaChevronRight style={{ 'paddingLeft': '.5em' }} />
                                                    </a>
                                                </Typography>
                                            </div>
                                            <Grid container spacing={8} justify="flex-start" alignItems="flex-start">
                                                <Grid item md={3} className={styles_css.aboutDataItem}>                                                
                                                    <span className={styles_css.roundedData}>
                                                        <FaProjectDiagram />
                                                    </span>
                                                    <span className={styles_css.aboutDataFigure}>
                                                        {count && count.receptions} 
                                                    </span>
                                                    <span className={styles_css.aboutDataTitle}>
                                                        Receptions
                                                    </span>
                                                </Grid>
                                                <Grid item md={3} className={styles_css.aboutDataItem}>         
                                                        <span className={styles_css.roundedData}>
                                                            <FaBook />
                                                        </span>
                                                        <span className={styles_css.aboutDataFigure}>
                                                            {count && count.reception_sources} 
                                                        </span>
                                                        <span className={styles_css.aboutDataTitle}>
                                                            Sources of Reception
                                                        </span>
                                                </Grid>
                                                <Grid item md={3} className={styles_css.aboutDataItem}>         
                                                        <span className={styles_css.roundedData}>
                                                            <FaUsers />
                                                        </span>
                                                        <span className={styles_css.aboutDataFigure}>
                                                            {count && count.people} 
                                                        </span>
                                                        <span className={styles_css.aboutDataTitle}>
                                                            People
                                                        </span>
                                                </Grid>
                                                <Grid item md={3} className={styles_css.aboutDataItem}>         
                                                        <span className={styles_css.roundedData}>
                                                            <FaBookOpen />
                                                        </span>
                                                        <span className={styles_css.aboutDataFigure}>
                                                            {count && count.works} 
                                                        </span>
                                                        <span className={styles_css.aboutDataTitle}>
                                                            Writings by Women
                                                        </span>
                                                </Grid>
                                                

                                            </Grid>
                                        </Paper>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <Grid container item xs={12} className={classes.grid} justify="flex-start" alignItems="flex-start" spacing={24}>
                                    <Grid item xs={12} className={styles_css.homeExploreBanner} >
                                        
                                        <h2>
                                            <Link to={`/explore`} >
                                                Search the RECIRC Database
                                            </Link>
                                        </h2>
                                        <p>
                                            Investigate which female authors were read, how texts by women circulated, and how women built reputations as writers
                                        </p>
                                        <Link to={`/explore`} id={styles_css.exploreHomeBtn}>
                                            Explore the Data <FaChevronRight />
                                        </Link>
                                    </Grid>                                 
                                </Grid>
                            </Grid>

                            

                            <Grid container className={classes.grid + ' mb-0'} item xs={12} spacing={24} >
                                <Grid item xs={12}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography variant="h2" gutterBottom>Discovering the Reception of Women Writers</Typography>
                                    </div>
                                </Grid>
                            </Grid>


                            <Grid container className={classes.grid + ' mt-0'} item xs={12} spacing={24}>
                                <Grid item xs={12} sm={6}>
                                    <Paper className={classes.discoverTitle}>
                                        <Link to="/cases/auction-catalogues">
                                            <div className={classes.box}>
                                                <Typography variant="h4" gutterBottom>
                                                    Auction Catalogues
                                                </Typography>
                                                <Typography variant="body2" className="text-muted" gutterBottom>
                                                    Examining auction catalogues - lists of books to be put up for sale at auction’ - enables us to identify patterns in the ownership of female-authored works.
                                                </Typography>
                                            </div>
                                        </Link>                                        
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Paper className={classes.discoverTitle}>
                                        <Link to="/cases/manuscript-miscellanies">
                                            <div className={classes.box}>
                                                <Typography variant="h4" gutterBottom>
                                                    Manuscript Miscellanies, Attribution and Pseudonymous Authors
                                                </Typography>
                                                <Typography variant="body2" className="text-muted"  gutterBottom>
                                                    Manuscript miscellanies are particularly rich resources for attribution practices – practices that open up questions about gender identity in the period. 
                                                </Typography>
                                            </div>
                                        </Link>                                        
                                    </Paper>
                                </Grid>

                    
                                <Grid item xs={12} sm={6}>
                                    <Paper className={classes.discoverTitle}>
                                        <Link to="/cases/hartlib-circle">
                                            <div className={classes.box}>
                                                <Typography variant="h4" gutterBottom>
                                                    The Hartlib Circle
                                                </Typography>
                                                <Typography variant="body2" className="text-muted"  gutterBottom>
                                                    The Hartlib circle was an intellectual correspondence network based around Samuel Hartlib (c. 1600-1662) that was active between 1641 and 1662.
                                                </Typography>
                                            </div>
                                        </Link>                                        
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Paper className={classes.discoverTitle}>
                                        <Link to="/cases/layers-and-agents-of-reception">
                                            <div className={classes.box}>
                                                <Typography variant="h4" gutterBottom>
                                                    Layers and Agents of Reception
                                                </Typography>
                                                <Typography variant="body2" className="text-muted"  gutterBottom>
                                                    Learn about the two kinds of agent, involved in reception, which RECIRC has categorized
                                                </Typography>
                                            </div>
                                        </Link>
                                    </Paper>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Paper className={classes.discoverTitle}>
                                        <Link to="/cases/reception-of-elizabeth-i">
                                            <div className={classes.box}>
                                                <Typography variant="h4" gutterBottom>
                                                    Reception of Elizabeth I
                                                </Typography>
                                                <Typography variant="body2" className="text-muted"  gutterBottom>
                                                    RECIRC has captured 569 receptions of Queen Elizabeth I, more than any other female author in the database.
                                                </Typography>
                                            </div>
                                        </Link>                                        
                                    </Paper>
                                </Grid>
                                
                                <Grid item xs={12} sm={6}>
                                    <Paper className={classes.discoverTitle}>
                                        <Link to="/cases/translations">
                                            <div className={classes.box}>
                                                <Typography variant="h4" gutterBottom>
                                                    Translations
                                                </Typography>
                                                <Typography variant="body2" className="text-muted"  gutterBottom>
                                                    Translation is one of our categories of reception, revealing the international dimensions of women's writing. 
                                                </Typography>
                                            </div>
                                        </Link>                                        
                                    </Paper>
                                </Grid>
                                


                            </Grid>
                            <Grid container className={classes.grid} item xs={12} spacing={24}>
                                <Grid item xs={12}>
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography variant="h3" gutterBottom>Explore other Learning Resources</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.grid} item xs={12} spacing={24}>
                                <Grid item xs={6}>
                                { /* 
                                    <div style={{display: 'flex', justifyContent: 'center'}}>
                                        <Typography variant="h6" gutterBottom>Open Access Publications</Typography>
                                    </div>
                                    <div>
                                        <div className={classes.box}>
                                            <PubList/>
                                        </div>

                                    </div>
                                */ }
                                </Grid>
                                <Grid item xs={6}>
                                    <Paper className={classes.paperTwitter}>
                                        <div>
                                            <div className={classes.box}>
                                                <Twitter />
                                            </div>

                                        </div>
                                    </Paper>
                                </Grid>

                            </Grid>


                        </Grid>
                    </Grid>
                    {/* <SwipeDialog
                        open={this.state.learnMoredialog}
                        onClose={this.dialogClose} />
                    <InstructionDialog
                        open={this.state.getStartedDialog}
                        onClose={this.closeGetStartedDialog}
                    /> */}
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(Main));