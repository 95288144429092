import React from 'react'
// import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { Route, Router, Switch } from 'react-router-dom'
// import Explore from './components/Explore'
import Cases from './components/Cases'
import Case from './components/Case'
import About from './components/About'
import AboutData from './components/AboutData'
    import AboutDataData from './components/AboutData/Data'
    import AboutDataUse from './components/AboutData/Use'
    import AboutDataCatalogue from './components/AboutData/Catalogue'
    import AboutDataSearch from './components/AboutData/Search'
    import AboutDataTechnical from './components/AboutData/Technical'
import Team from './components/Team'
import Explore from './components/Explore';
import Main from './components/Main'
import People from './components/People/People'
import Person from './components/Person/Person'
import Receptions from './components/Receptions/Receptions'
import Reception from './components/Reception/Reception'
import Works from './components/Works/Works'
import Work from './components/Work/Work'
import Sources from './components/SourcesOfReceptions/Sources'
import Source from './components/SourcesOfReception/Source'
import Publications from './components/Publications'
import Conference from './components/Conference'
import ScrollToTop from './components/ScrollTop'
import Contact from './components/Contact'
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';

import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';

// GA setup
const history = createBrowserHistory();
// const trackingId = "UA-44337674-18";
const trackingId = "G-PG47L1TWRH";
ReactGA.initialize(trackingId);

// removed for move to GA4
// Initialize google analytics page view tracking
history.listen(location => {
//     ReactGA.set({ page: location.pathname }); // Update the user's current page
//     ReactGA.pageview(location.pathname); // Record a pageview for the given page
       ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
});



export default props => (
    <Router history={history}>

        <ScrollToTop>
            <Switch>
                <Route exact path='/' component={ Main } />
                <Route exact path='/explore' component={ Explore } />
                <Route exact path='/cases' component={ Cases } />
                <Route exact path='/cases/:slug' component={ Case } />
                <Route exact path='/about' component={ About } />
                
                <Route exact path='/about-data' component={ AboutData } />
                    <Route exact path='/about-data/data' component={AboutDataData } />
                    <Route exact path='/about-data/data-use' component={AboutDataUse } />
                    <Route exact path='/about-data/women-writers-catalogue' component={AboutDataCatalogue } />
                    <Route exact path='/about-data/searching-data' component={AboutDataSearch } />
                    <Route exact path='/about-data/technical-overview' component={AboutDataTechnical } />
                    
                <Route exact path='/contact' component={Contact} />
                <Route exact path='/team' component={ Team } />
                <Route exact path='/people' component={ People } />
                <Route exact path='/receptions' component={ Receptions } />
                <Route exact path='/receptions/reception/:receptionId' component={ Reception } />
                <Route exact path='/people/person/:personId' component={ Person } />
                <Route exact path='/sources' component={ Sources } />
                <Route exact path='/works' component={ Works } />
                <Route exact path='/works/work/:workId' component={Work} />
                <Route exact path='/sources/source/:sourceId' component={ Source } />
                <Route exact path='/presentations-and-publications' component={ Publications } />
                <Route exact path='/conference2017' component={ Conference } />

            /* Ugly blog migration... */
                <Route exact path='/blog' component={Blog} />
                <Route exact path='/2018/04/mary-queen-scotss-poem-written-spa-buxton-wells' component={BlogPost} />
                <Route exact path='/2018/02/new-directions-early-modern-irish-womens-history-conference-report' component={BlogPost} />
                <Route exact path='/2018/01/new-directions-early-modern-irish-womens-history' component={BlogPost} />
                <Route exact path='/2017/12/shakespeare-emotions' component={BlogPost} />
                <Route exact path='/2017/11/transatlantic-circulation' component={BlogPost} />
                <Route exact path='/2017/11/ann-bathurst-poet' component={BlogPost} />
                <Route exact path='/2017/10/cfp-historians-women-religious-britain-ireland-annual-conference' component={BlogPost} />
                <Route exact path='/2017/10/womens-music-manuscripts' component={BlogPost} />
                <Route exact path='/2017/09/lost-letters-reputations' component={BlogPost} />
                <Route exact path='/2017/09/transcriptions-lady-dorothy-shirleys-poems' component={BlogPost} />
                <Route exact path='/2017/08/cary_buckingham' component={BlogPost} />
                <Route exact path='/2017/08/inscriptions-galway-dominican-convent-library-collection' component={BlogPost} />
                <Route exact path='/2017/07/women-hartlibs-ephemerides' component={BlogPost} />
                <Route exact path='/2017/07/womens-history-digital-world' component={BlogPost} />
                <Route exact path='/2017/06/digital-receptions-esther-inglis' component={BlogPost} />
                <Route exact path='/2017/06/recirc-visualizations' component={BlogPost} />
                <Route exact path='/2017/05/layers-reception-tiers-transmission' component={BlogPost} />
                <Route exact path='/2017/05/four-early-modern-books-james-hardiman-library-nui-galway-seen-recirc17' component={BlogPost} />
                <Route exact path='/2017/05/three-seventeenth-century-books-galway-dominican-convent-library-collection' component={BlogPost} />
                <Route exact path='/2017/04/seventeenth-century-receptions-lady-mary-carey' component={BlogPost} />
                <Route exact path='/2017/04/reception-reputation-circulation-early-modern-world-1500-1800-conference-galway-22-25-march-2017' component={BlogPost} />
                <Route exact path='/2017/03/dispatches-new-zealand-ireland' component={BlogPost} />
                <Route exact path='/2017/02/the-galway-poor-clares' component={BlogPost} />
                <Route exact path='/2017/01/happy-new-year-early-modern-style' component={BlogPost} />
                <Route exact path='/2016/12/suppression-migration-and-exile-towards-a-history-of-early-modern-irish-women-religious' component={BlogPost} />
                <Route exact path='/2016/11/the-reception-and-circulation-of-shakespeare-at-selfridges' component={BlogPost} />
                <Route exact path='/2016/11/nuns-writing-and-martyrology' component={BlogPost} />
                <Route exact path='/2016/11/the-heart-and-stomach-of-a-president-hillary' component={BlogPost} />
                <Route exact path='/2016/10/hartlibs-ephemerides-making-universal-knowledge-tweetable-since-1634' component={BlogPost} />
                <Route exact path='/2016/09/auditing-the-bodleian-librarys-collection-for-early-modern-anglophone-manuscript-miscellanies' component={BlogPost} />
                <Route exact path='/2016/09/recovering-womens-past-conference-edinburgh-8-10-september-2' component={BlogPost} />
                <Route exact path='/2016/09/2953' component={BlogPost} />
                <Route exact path='/2016/08/an-early-modern-english-manuscript-translation-of-marguerite-de-valoiss-discours-docte-et-subtil' component={BlogPost} />
                <Route exact path='/2016/08/recirc-by-the-numbers' component={BlogPost} />
                <Route exact path='/2016/08/english-catholic-nuns-neglected-female-authors-and-their-reception-and-circulation' component={BlogPost} />
                <Route exact path='/2016/08/curious-case-catherine-burke' component={BlogPost} />
                <Route exact path='/2016/07/manuscript-annotation-and-the-digital-humanities-the-archaeology-of-reading' component={BlogPost} />
                <Route exact path='/2016/07/the-2016-tudor-and-stuart-ireland-conference' component={BlogPost} />
                <Route exact path='/2016/07/2823' component={BlogPost} />
                <Route exact path='/2016/07/whats-the-big-idea-women-in-the-republique-des-lettres' component={BlogPost} />
                <Route exact path='/2016/06/a-newly-discovered-manuscript-adaptation-of-ephelias-the-twin-flame' component={BlogPost} />
                <Route exact path='/2016/06/2718' component={BlogPost} />
                <Route exact path='/2016/06/how-to-complain-in-an-english-convent-abroad' component={BlogPost} />
                <Route exact path='/2016/06/anne-finch-1661-1720-and-jacobite-manuscript-poetry' component={BlogPost} />
                <Route exact path='/2016/06/reconstructing-early-modern-womens-libraries' component={BlogPost} />
                <Route exact path='/2016/05/retrieving-early-modern-irish-womens-writing-verses-composed-by-lettice-digby-nee-fitzgerald-c-1580-1658-baroness-offaly' component={BlogPost} />
                <Route exact path='/2016/05/attribution-and-reputation-the-complexities-of-auction-book-catalogues' component={BlogPost} />
                <Route exact path='/2016/05/2498' component={BlogPost} />
                <Route exact path='/2016/05/female-authority-medicine-and-consumption-in-ms-rawlinson-d-947' component={BlogPost} />
                <Route exact path='/2016/04/reflections-for-poetry-day-ireland-2016' component={BlogPost} />
                <Route exact path='/2016/04/how-to-wield-authority-in-an-english-convent-abroad' component={BlogPost} />
                <Route exact path='/2016/04/eighteenth-century-womens-poetry-in-irish' component={BlogPost} />
                <Route exact path='/2016/04/ethics-publics-and-conference-tweeting' component={BlogPost} />
                <Route exact path='/2016/03/the-irish-dominican-convent-of-nossa-senhora-do-bom-sucesso-belem-portugal' component={BlogPost} />
                <Route exact path='/2016/03/from-petitioning-women-to-unruly-women-warrants-as-a-resource' component={BlogPost} />
                <Route exact path='/2016/03/why-were-glad-samuel-hartlib-read-other-peoples-mail' component={BlogPost} />
                <Route exact path='/2016/03/working-ergonomically-in-the-archives' component={BlogPost} />
                <Route exact path='/2016/03/marks-of-ownership-and-access-in-early-modern-manuscripts' component={BlogPost} />
                <Route exact path='/2016/02/how-to-be-understood-in-an-english-convent-abroad' component={BlogPost} />
            </Switch>
        </ScrollToTop>
    </Router>
)