import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from '../../style.module.css';

import { Sigma } from 'react-sigma';
import ReceptionNodePopover from "./ReceptionNodePopover";
import ReceptionEdgePopover from "./ReceptionEdgePopover";

// import { schemePaired } from "d3-scale-chromatic";
import { schemeCategory10 } from "d3-scale-chromatic";
import { schemeAccent } from "d3-scale-chromatic";
import { scaleOrdinal } from "d3-scale";

class NetworkReception extends React.Component {

    constructor(props, context) {
        super(props, context);

        

        this.state = {
            defaultColor: '#dbdbdb',
            isLoading: false,
            isActive: false,
            graph: false,
            receptionTypeScale: null,
            nodeRoles: [],
            
            // NodePopover related
            person: null,
            nodeEvent: null,
            showNodePopover: false,

            reception: null,
            edgeEvent: null,
            showEdgePopover: false,
            graphNeedsRefresh: false,

            edgeFiltersActiveIds: []
        };

        this.handleClickNode = this.handleClickNode.bind(this);
        this.handleClickEdge = this.handleClickEdge.bind(this);
        this.handleStageClick = this.handleStageClick.bind(this);

        // d3 ordinal colour scale functions to use
        // when colour coding nodes/edges
        this.edgeScale = null;
        this.nodeScale = null;
        this.nodeRoles = [];

        
    }

    getReceptionTypeEdgeColor(receptionTypes, isMuted)
    {
        if(typeof isMuted === 'undefined' || isMuted === true){
            return this.state.defaultColor;
        }

        let type = '';

        if (receptionTypes.length > 1) {
            type = 'multiple'
        } else {
            if (receptionTypes[0] && receptionTypes[0].value){
                type = receptionTypes[0].value;
            }else{
                return this.state.defaultColor;
            }
        }
        // console.log(type, this.edgeScale(type));

        return this.edgeScale(type);
    }

    getRoleLabel(role) 
    {
        let label = '';
        // eslint-disable-next-line default-case
        switch (role) {
            case 'author':
                label = 'Female Author';
                break;
            case 'owner':
                label = 'Owner / Compiler / Scribe';
                break;
            case 'named_receiver':
                label = 'Named Receiver';
                break;
            case 'rsw':
                label = 'Source Work';
                break;
        }
        return label;
    }

    /**
     * Return a node colour
     * @param {string} role : one of ['author' | 'owner' | 'named_receiver' | 'rsw']
     */
    getRoleColor(role) {
        
        if(this.nodeRoles.indexOf(role) === -1){
            this.nodeRoles.push(role);
        }

        return this.nodeScale(role);
    }


    /**
     * Manual construction of the graph. Gives more control
     * over the final graph
     * 
     * @param {Sigma} objSigma - Sigma graph object
     * @param {collection} graph - graph data to read/manipulate
     */
    buildGraph(objSigma, graphData) {
        
        objSigma.graph.clear();
        objSigma.graph.read(graphData);

        objSigma.graph.nodes().forEach(node => {
            node.x = Math.random();
            node.y = Math.random();
            node.size = objSigma.graph.degree(node.id);
            node.label = (node.Label) ? node.Label : node.label;
            node.color = this.getRoleColor(node.role);
        });
        
        objSigma.graph.edges().forEach(edge => {
            let isMuted = true;
            edge.isMuted = isMuted;
            edge.color = this.getReceptionTypeEdgeColor(edge.reception_type, isMuted);

            // need a weight calculation here...
            // edges[i].size = edges[i].size;
        });

        return objSigma;
    }

    /**
     * Build legend-style UI to allow edges to be
     * "filtered". Filtering involves muting the colour
     * of edges not selected.
     * 
     * @param {Sigma Graph} graph 
     */
    buildReceptionTypeEdgeFilters(graph)
    {
        let receptionTypeFilters = [];
        let idsPresent = [];
        let haveAddedMultipleFilter = false;

        graph.graph.edges().forEach(edge => {

            // add a filter for "multiple" - should only be added once if
            // a reception with more than 1 reception_type is found
            if(edge.reception_type.length > 1 && !haveAddedMultipleFilter){
                haveAddedMultipleFilter = true;
                receptionTypeFilters.push(
                    <li key={`li-rec-type-multiple`}>
                        <span className="edge-filter-item" style={{ borderLeft: `4px solid ` + this.edgeScale('multiple'), width: "15px", height: "15px", display: "inline-block", marginBottom: "-5px" }}></span>                            
                        <button className="btn btn-sm btn-link" onClick={event => this.edgeFilter(event, 0, true )}>
                            Multiple Types
                        </button> 
                    </li>
                );
            }

            // loop each reception type and if it hasn't already
            // been added
            edge.reception_type.forEach( reception_type => {
                
                if(idsPresent.indexOf(reception_type.id) === -1){
                    idsPresent.push(reception_type.id);

                    receptionTypeFilters.push(
                        <li key={`li-rec-type` + reception_type.id}>
                            <span className="edge-filter-item" style={{ borderLeft: `4px solid ` + this.edgeScale(reception_type.value), width: "15px", height: "15px", display: "inline-block", marginBottom: "-5px" }}></span>                            
                            <button className="btn btn-sm btn-link" onClick={event => this.edgeFilter(event, reception_type.id, true )}>
                                {reception_type.label}
                            </button> 
                        </li>
                    );

                }
            })
        });

        if(receptionTypeFilters.length > 0){
            return receptionTypeFilters;
        }
        return false;
    }


    buildLegend()
    {
        if(this.nodeRoles.length === 0){
            return false;
        }

        let legend = this.nodeRoles.map((item) => {
            return (
                <li key={`role-legend-` + item} className="list-inline-item legend-icon-wrapper">
                    <span className="legend-icon" style={{backgroundColor: this.nodeScale(item), width: "15px", height: "15px", borderRadius: "15px", display: "inline-block", marginRight: "5px"} }></span>
                    { this.getRoleLabel(item) }
                </li>
            );
        });

        return legend;
    }

    handleStageClick(e)
    {
        this.setState(state => ({
            showNodePopover: false,
            showEdgePopover: false
        }));
    }

    /**
     * Display a popover with additional info
     * when a node is clicked.
     * 
     * @param {Sigma Event} e 
     */
    handleClickNode(e){
        console.log(e);
        this.setState(state => ({
            showEdgePopover: false,
            showNodePopover: true,
            nodeEvent: e,
            person: e.data.node
        }));
    }    
    
    /**
     * Display a popover with additional info
     * when a node is clicked.
     * 
     * @param {Sigma Event} e 
     */
    handleClickEdge(e){
        // console.log(e);
        this.setState(state => ({
            showNodePopover: false,
            showEdgePopover: true,
            edgeEvent: e,
            reception: e.data.edge
        }));
    }    

    /**
     * 
     * @todo Finish the edge filter
     * 
     * 
     * @param {Event} e - event
     * @param {id} reception_type_id - Int value of the reception_type
     */
    edgeFilter(e, reception_type_id)
    {
        // @see: https://github.com/dunnock/react-sigma/blob/master/DOCS.md#filter
        //       https://github.com/jacomyal/sigma.js/tree/master/plugins/sigma.plugins.filter    
        
        // mute edges with this receptionType
        // collect list of node ids from this edge list
        // remove those nodes  

        console.log(e, reception_type_id, this.state.graph);
        let { graph, edgeFiltersActiveIds } = this.state;
        let filterIndex = edgeFiltersActiveIds.indexOf(reception_type_id);
        if(filterIndex > -1 ){
            edgeFiltersActiveIds.splice(filterIndex, 1);
        }else{
            edgeFiltersActiveIds.push(reception_type_id);
        }
        
    
        console.log(graph.graph.edges());
        
        let counter = {
            total: 0,
            multi_rec_type: 0,
            one_rec_type: 0,
            upd_edges: []
        };

        graph.graph.edges().forEach(edge => {
            edge.isMuted = true;
            
            if(edge.reception_type.length > 1 && reception_type_id === 0){
                edge.isMuted = false;
                edge.color = this.getReceptionTypeEdgeColor(edge.reception_type, edge.isMuted);
                
            }else if(edge.reception_type.length > 1){
                edge.reception_type.forEach(rt => {
                    if(rt.id === reception_type_id){
                        edge.isMuted = false;
                        edge.color = this.getReceptionTypeEdgeColor(rt, edge.isMuted);                
                    }
                });

            }else if(edge.reception_type.length === 1){

                if(edge.reception_type[0].id === reception_type_id){
                    counter.one_rec_type++;
                    edge.isMuted = false;
                    edge.color = this.getReceptionTypeEdgeColor(edge.reception_type, edge.isMuted);                
                    // console.log(edge.color, edge.reception_type[0], isMuted);

                    counter.upd_edges.push(edge);
                }
            }
            
            if(edge.isMuted){
                edge.color = this.getReceptionTypeEdgeColor(edge.reception_type, edge.isMuted);
                // counter.upd_edges.push(edge);
            }
            
            // edge.isMuted = isMuted;
        });        

        
        console.log(counter);
        // console.log(graph.graph.edges());

        // graph = graph.refresh({
        //     skipIndexation: true
        // });
        
        this.setState({
            graph,
            edgeFiltersActiveIds,
            graphNeedsRefresh: true
        });

    }

    manageGraphLifeCycle()
    {
        // if we have a graph with some nodes, 
        // set degree for nodes, and add colour to nodes & 
        // edges
        if (this.props.graph && this.props.graph.nodes.length > 0) {

            // setup colour-codes for use on nodes/edges
            this.edgeScale = scaleOrdinal(schemeCategory10);
            this.nodeScale = scaleOrdinal(schemeAccent);

            // manual graph construction
            let graph = this.buildGraph(this.props.sigma, this.props.graph);
            console.log(graph.graph.edges());

            // build edge filter links
            let edgeFilter = this.buildReceptionTypeEdgeFilters(graph);
            // let edgeFilter = false;

            let legend = this.buildLegend();

            // graph = graph.refresh({
            //     skipIndexation: true
            // });
    
            this.setState({
                graph,
                edgeFilter,
                legend,
                graphNeedsRefresh: true
            });
        }

    }


    componentDidMount()
    {
        this.manageGraphLifeCycle();
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            

            this.manageGraphLifeCycle();
        }
    }



    render() {
        let { 
            graph, edgeFilter, graphNeedsRefresh, legend, 
            nodeEvent, edgeEvent, 
            showNodePopover, showEdgePopover, 
            person, reception 
        } = this.state;

        let { settings } = this.props;
        let g;

        if(!graph){
            return null;
        }

        if(graphNeedsRefresh){
            console.log('refreshing graph...');
            g = graph.refresh({
                skipIndexation: true
            });
            console.log(g, graph);
        }else{
            g = graph;
        }
        

        let network = {
            nodes: g.graph.nodes(),
            edges: g.graph.edges()
        };
        

        return (
            
            <Sigma 
                renderer="canvas" 
                graph={network} 
                settings={settings} 
                style={{ maxWidth: "inherit", height: "800px" }} 
                onClickNode={e => this.handleClickNode(e) }
                onClickEdge={e => this.handleClickEdge(e) }
                onOutNode={e => this.handleStageClick(e)}
            >
                { legend && 
                    <div style={{ position: 'absolute', top: 0, left: 0 }} id={styles.graphLegend}>
                        <ul className="list-inline">
                            { legend }
                        </ul>
                    </div>
                }

                { /* edgeFilter && 
                    <div style={{position: 'absolute', bottom: 0, right: 0}} id={styles.edgeFilterList}>
                        <h5>Highlight Reception Types (Edges)</h5>
                        <ul className="list-unstyled">
                            { edgeFilter }
                        </ul>
                    </div>
                */ }
                
                {/* Behaviour for node click */}
                <ReceptionNodePopover
                    person={person} 
                    event={nodeEvent}                    
                    show={showNodePopover} 
                />
                
                <ReceptionEdgePopover
                    reception={reception} 
                    event={edgeEvent}                    
                    show={showEdgePopover} 
                />
            </Sigma>

        );
    }
}

export default withRouter(NetworkReception)