import React from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import {Container, Card, Row, ListGroup} from 'react-bootstrap'
import Grid from '@material-ui/core/Grid'
import { FaTag, FaUsers, FaProjectDiagram, FaBook } from 'react-icons/fa';
import styles from '../../style.module.css';




class ReceptionDetails extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            reception: {},
            isLoading: true,
            receiver_people: [],
            peopleSearchResponse: [],
            author: [],
            peopleList: [],
            receivers: [],
            source_of_reception: []
        };
    }



    componentDidMount() {

        this.setState({
            isLoading: false,
            reception: this.props
        });
        
    }


    render() {

        const { reception } = this.props;
        // const { source_of_reception } = this.props;
        const { reception_types } = this.props;
        const { circulation_types } = this.props;
        const { reception_sources } = this.props;

        let source_name = "";
        let owners = '';

        let rsw_source_type = [];
        let source_linked_receptions_count = null;
        let receiver_name = "";
        let receiver_assoc_reception_count = '';

        let author = "";
        let author_reception_count = null;

        let reception_type = [];
        let circulation_type = [];
        let reference_data = "";
        

        let is_anonymous = true;
        let is_receiver = false;
        let is_circ_type_present = false;
        let reception_types_display = [];
        let circ_types_display = [];
        let workLinks = [];

        if ( reception ) {


            if (reception.receivername !== null) {
                is_receiver = true;
                receiver_name = (
                    <Link to={`/people/person/` + reception.receivername.id}>
                        {reception.receivername.display_name}
                    </Link>
                );
                
                if (reception.receivername.metrics){
                    let receiver_assoc_reception_metric = reception.receivername.metrics.filter(metric => metric.key === 'associated_receptions_count');
                    let reception_plural = (receiver_assoc_reception_metric[0].measure.value > 1) ? 'receptions' : 'reception';
                    receiver_assoc_reception_count = (
                        <Typography variant="caption">
                            <FaProjectDiagram /> Associated with  {receiver_assoc_reception_metric[0].measure.value} { reception_plural }
                        </Typography>
                    );
                    }
            }


            //--------------------------
            //  RSW Owners
            //--------------------------            
            if (reception.receptionsourcework.owners && reception.receptionsourcework.owners.length > 0) {

                owners = reception.receptionsourcework.owners.map((p, idx, arr) => {
                    let sep = '';
                    if (arr.length > 1 && idx < arr.length - 1) {
                        sep = ', ';
                    }

                    return (
                        <span key={p.id}>
                            <Link to={`/people/person/` + p.id}>
                                {p.display_name}
                            </Link>{sep}
                        </span>
                    );
                });

            }else{
                owners = 'Anonymous';
            }

            //--------------------------
            //  Reception Source Name
            //--------------------------
            source_name = (
                <Link to={`/sources/source/` + reception.receptionsourcework.id }>
                    { reception.receptionsourcework.title }
                </Link>
            );

            
            // Female author
            author = (
                <Link to={`/people/person/` + reception.author.id}>
                    {reception.author.display_name}
                </Link>
            );

            //--------------------------
            //  Inline mini-metrics
            //--------------------------
            // label for # of receptions assoc with Female Author
            if(reception.author.metrics)
            {   
                let auth_reception_metric = reception.author.metrics.filter(metric => metric.key === 'associated_receptions_count');
                if (auth_reception_metric.length > 0){
                    author_reception_count = (
                        <Typography variant="caption">
                            <FaProjectDiagram /> Female author in  {auth_reception_metric[0].measure.value} receptions
                        </Typography>
                    );
                }
                
            }

            // count of receptions associated with the source
            if (reception.receptionsourcework && reception.receptionsourcework.reception_count) {
                source_linked_receptions_count = reception.receptionsourcework.reception_count;
            }

            reference_data = reception.reference
            
            circulation_type = reception.circulationtype
            let receiver = reception.receiver;

            if (receiver['named'] === true && reception.receivername !== null) {
                is_anonymous = false;
            }
            
            // Type of Reception Source 
            //  => add reception search link to term
            if (reception.receptionsourcework.source_type && reception_sources){
                reception_sources.forEach((type) => {
                    
                    if (type.value === reception.receptionsourcework.source_type){
                       
                        let term = [{
                            "name": "receptionsourcework.source_type",
                            "label": "Reception Source Type",
                            "filter": "in",
                            "term": [
                                {
                                    "id": type.id,
                                    "value": type.value,
                                    "label": type.label
                                }
                            ],
                            "termLabel": type.label
                        }];

                        let rsw_type = (                            
                            <Link key={`ss-` + type.id} to={`/receptions?query_type=must&term=` + encodeURIComponent(JSON.stringify(term))} target='_blank'>
                                {type.label}
                            </Link>
                        )
                        rsw_source_type.push(rsw_type);
                    }
                });
                if(rsw_source_type.length === 0){
                    rsw_source_type.push( reception.receptionsourcework.source_type );
                }
            } 

           

            // List the reception types assoc with reception
            reception_type = Object.keys(reception.receptiontype);
            if (reception_types.length > 0 && reception_type.length > 0) {
                let reception_types_display_arr = [];
                reception_types.forEach((type, idx, all) => {         
                    // need to be matched against a rececption_types 
                    // config object to get text labels
                    if (reception_type.indexOf(type.id.toString()) !== -1) {
                        let sep = ', ';

                        if (idx === (all.length - 1) || all.length === 1){
                            sep = '';
                        }

                        let term = [{
                            "name": "receptiontype",
                            "label": "Reception Type",
                            "filter": "in",
                            "term": [
                                {
                                    "id": type.id,
                                    "value": type.value,
                                    "label": type.label
                                }
                            ],
                            "termLabel": type.label
                        }];

                        let reception_type_search_link = (
                            
                            <span key={`rtsl-` + type.id}>
                                <Link to={`/receptions?query_type=must&term=` + encodeURIComponent(JSON.stringify(term)) } target='_blank'>
                                    {type.label}
                                </Link>{ sep } 
                            </span>
                        )
                        // reception_types_display_arr.push( type.label );
                        reception_types_display_arr.push(reception_type_search_link );
                    }                   
                });

                // reception_types_display = reception_types_display_arr.join(', ');
                reception_types_display = reception_types_display_arr;
            }


            // List the circulation types assoc with reception
            circulation_type = Object.keys(reception.circulationtype);
            if (circulation_type.length > 0 && circulation_types.length > 0) {
                is_circ_type_present = true;
                let circulation_types_display_arr = [];
                
                circulation_types.forEach((type) => {
                    // need to be matched against a circulation_types 
                    // config object to get text labels
                    if (circulation_type.indexOf(type.id.toString()) !== -1) {
                        circulation_types_display_arr.push(type.label);
                    }
                });

                circ_types_display = circulation_types_display_arr.join(', ');

            }

            if(reception.work && reception.work.length > 0){
                
                reception.work.forEach((work, idx, all) => {
                    let sep = ', ';
                    if( idx === (all - 1) || all.length === 1){
                        sep = '';
                    }
                    let link = (<span key={`work-link-` + work.id}>
                            <Link to={`/works/work/` + work.id }>
                                {work.title}
                            </Link>{ sep }
                        </span>
                    )
                    workLinks.push(link);
                });
                
            }
        }

        // console.log(is_receiver, is_anonymous, owners);

        let owner_panel = (
            <Grid className={styles.reception_grid_body} item xs={12} sm={6} md={3} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Card className={styles.reception_grid_card_body} style={{ padding: 0 }}>
                    <Card.Body>
                        <Card.Title>
                            <Typography variant="caption">
                                <FaUsers /> Owner / Compiler / Scribe
                            </Typography>
                        </Card.Title>
                        <Typography variant="h6">
                                {owners}
                        </Typography>                        
                    </Card.Body>
                    <Card.Footer className="text-muted">

                        <Typography variant="caption">
                            <FaBook /> Source of Reception: {source_name}
                        </Typography>

                        {rsw_source_type &&
                            <Typography variant="caption">
                                <FaTag />   {rsw_source_type}
                            </Typography>
                        }
                        
                        {source_linked_receptions_count &&
                            <Typography variant="caption">
                                <FaProjectDiagram /> Contains <strong> {source_linked_receptions_count}</strong> Linked Receptions
                            </Typography>
                        }
                    </Card.Footer>
                </Card>
            </Grid>
        );

        let reception_attributes = (
            <Grid item xs={12} >
                <Grid alignItems="center" justify="center" container>
                    <Grid item xs={8} className={styles.reception_attributes_grid_body}>
                        <Card>
                            <Card.Body>
                                <Card.Title>Reception Attributes</Card.Title>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <strong>Reception Type</strong> - {reception_types_display}
                                    </ListGroup.Item>
                                    {is_circ_type_present && (
                                        <ListGroup.Item>
                                            <strong>Type of Circulation</strong> - {circ_types_display}
                                        </ListGroup.Item>)}
                                    <ListGroup.Item>
                                        <strong>Document Reference</strong> - {reference_data}
                                    </ListGroup.Item>

                                    {workLinks.length > 0 &&
                                        <ListGroup.Item>
                                            <strong>Title of Work Received</strong> - {workLinks}
                                        </ListGroup.Item>
                                    }
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    </Grid>
                </Grid>
            </Grid >
        )
        
        return (


                <Card id="reception-details">
                    
                    <Card.Body className={styles.reception_card_body} >
                        
                        <Container >
                            <Row>
                                {/* OWS New Panel */}
                                { owner_panel }
                            </Row>
                            {/* Arrows */}
                            
                                {is_receiver && !is_anonymous && (
                                    <Row>
                                        <Grid className={styles.reception_received_grid_body} item xs={6} md={3} style={{ margin: '0 auto', padding: 0}}>
                                            <Typography variant="caption">Compiled</Typography>
                                            <Typography variant="h1" style={{ transform: 'rotate(135deg)' }} className={ styles.reception_arrow }>&#8594;</Typography>
                                            
                                        </Grid>
                                <Grid className={styles.reception_received_grid_body} item xs={6} md={3} style={{ margin: '0 auto', padding: 0}}>
                                            <Typography variant="caption">Is associated with</Typography>
                                            <Typography variant="h1" style={{ transform: 'rotate(45deg)' }} className={ styles.reception_arrow}>&#8594;</Typography>
                                            
                                        </Grid>
                                    </Row>
                                )}

                                {!is_receiver && (
                                    <Row>
                                        <Grid className={styles.reception_received_grid_body} item xs={6} md={3} style={{ margin: '0 auto', padding: '0 0 2em'}}>
                                            <Typography variant="caption">Is associated with</Typography>
                                            <Typography variant="h1" style={{ transform: 'rotate(90deg)' }} className={styles.reception_arrow}>&#8594;</Typography>
                                    
                                        </Grid>
                                    </Row>
                                )}
                        </Container>

                        {is_receiver && !is_anonymous && (
                            <Grid spacing={24} className={styles.reception_grid_card_body} alignItems="center" justify="center" container>

                                <Grid className={styles.reception_grid_body} item xs={5} md={3}>
                                    <Card className={styles.reception_grid_card_body} style={{padding: 0}}>
                                        <Card.Body>
                                            <Card.Title>
                                                <Typography variant="caption">
                                                    <FaUsers/> Receiver
                                                </Typography>
                                            </Card.Title>                                            
                                            <Typography variant="h6">
                                                { receiver_name }    
                                            </Typography>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                           {receiver_assoc_reception_count}
                                        </Card.Footer>
                                    </Card>
                                </Grid>
                                
                                <Grid className={styles.reception_received_grid_body} item xs={2} md={3} >
                                    <Typography variant="h1">&#8594;</Typography>
                                    <Typography variant="caption">Received</Typography>
                                </Grid>

                                <Grid className={styles.reception_grid_body} item xs={5} md={3} >
                                    <Card className={styles.reception_grid_card_body} style={{ padding: 0 }}>
                                        <Card.Body>
                                            <Card.Title> 
                                                <Typography variant="caption"><FaUsers/> Female Author</Typography>
                                            </Card.Title>
                                            <Typography variant="h6">{ author }</Typography>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                            { author_reception_count }
                                        </Card.Footer>

                                    </Card>
                                </Grid>

                        </Grid>
                        )}


                        {!is_receiver && is_anonymous && (
                            <Grid spacing={24} className={styles.reception_author_grid_body} alignItems="center" justify="center" container>

                                <Grid item xs={12} >
                                    <Grid spacing={3}  alignItems="center" justify="center" container>
                                        <Grid item xs={12} md={3} >
                                            <Card >
                                                <Card.Body>
                                                    <Card.Title> <Typography variant="caption"><FaUsers/> Female Author</Typography></Card.Title>
                                                    <Card.Text>
                                                        <Typography variant="h6">
                                                            { author }
                                                        </Typography>
                                                    </Card.Text>
                                                </Card.Body>
                                                <Card.Footer className="text-muted">
                                                    { author_reception_count }
                                                </Card.Footer>

                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Grid>)}



                        {!is_receiver && !is_anonymous && (
                            <Grid spacing={24} className={styles.reception_author_grid_body} alignItems="center" justify="center" container>

                                <Grid item xs={12} >
                                    <Grid spacing={3}  alignItems="center" justify="center" container>
                                        <Grid item xs={12} md={3} >
                                            <Card >
                                                <Card.Body>
                                                    <Card.Title> <Typography variant="caption"><FaUsers/> Female Author</Typography></Card.Title>                                                    
                                                    <Typography variant="h6">
                                                        { author }
                                                    </Typography>                                                    
                                                </Card.Body>
                                                <Card.Footer className="text-muted">
                                                    {author_reception_count}
                                                </Card.Footer>

                                            </Card>
                                        </Grid>
                                    </Grid>
                                </Grid>                            
                                
                            </Grid>
                            )
                        }

                        {is_receiver && is_anonymous && (
                            <Grid spacing={24} className={styles.reception_grid_card_body} alignItems="center" justify="center" container>

                                <Grid className={styles.reception_grid_body} item xs={3} >
                                    <Card className={styles.reception_grid_card_body} style={{ padding: 0 }}>
                                        <Card.Body>
                                            <Card.Title><Typography variant="caption"><FaUsers /> Receiver</Typography></Card.Title>
                                            <Card.Text>
                                                <Typography variant="h6">
                                                    {receiver_name}
                                                </Typography>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                            {receiver_assoc_reception_count}
                                        </Card.Footer>
                                    </Card>
                                </Grid>

                                <Grid className={styles.reception_received_grid_body} item xs={3} >
                                    <Typography variant="h1">&#8594;</Typography>
                                    <Typography variant="caption">Received</Typography>
                                </Grid>

                                <Grid className={styles.reception_grid_body} item xs={3} >
                                    <Card className={styles.reception_grid_card_body} style={{ padding: 0 }}>
                                        <Card.Body>
                                            <Card.Title> <Typography variant="caption"><FaUsers /> Female Author</Typography></Card.Title>
                                            <Card.Text>
                                                <Typography variant="h6">{author}</Typography>
                                            </Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="text-muted">
                                            {author_reception_count}
                                        </Card.Footer>

                                    </Card>
                                </Grid>

                            </Grid>
                        )}
                    </Card.Body>
                    <Card.Footer>
                        {/* Reception Attributes */}
                        {reception_attributes}
                    </Card.Footer>
                </Card>



        )
    }
}

export default withRouter(withStyles(styles)(ReceptionDetails))