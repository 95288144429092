import React from 'react';
import { withRouter } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import {Card} from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import styles from '../../style.module.css';





class PersonTimeline extends React.Component {

    constructor(props, context) {
        super(props, context);


        let date_types = [];
        date_types.push(
                { "name": "1700s", "value": 0, "id": 1700 },
                { "name": "1690s", "value": 0, "id": 1690 },
                { "name": "1680s", "value": 0, "id": 1680 },
                { "name": "1670s", "value": 0, "id": 1670 },
                { "name": "1660s", "value": 0, "id": 1660 },
                { "name": "1650s", "value": 0, "id": 1650 },
                { "name": "1640s", "value": 0, "id": 1640 },
                { "name": "1630s", "value": 0, "id": 1630 },
                { "name": "1620s", "value": 0, "id": 1620 },
                { "name": "1610s", "value": 0, "id": 1610 },
                { "name": "1600s", "value": 0, "id": 1600 },
                { "name": "1590s", "value": 0, "id": 1590 },
                { "name": "1580s", "value": 0, "id": 1580 },
                { "name": "1570s", "value": 0, "id": 1570 },
                { "name": "1560s", "value": 0, "id": 1560 },
                { "name": "1550s", "value": 0, "id": 1550 }
            );

        this.state = {
            isLoading: false,
            receiver_people: [],
            isMarkerShown: false,
            date_types
        };

    }


    componentDidMount() {

    }





    render() {

        let { receptions } = this.props;
        let { date_types } = this.state;

        
        if(!receptions || receptions.length === 0 || receptions.error ){
            return null;
        }

        let source_id_checklist = [];
        let count_rsw_missing_date = 0;

        receptions.forEach(reception => {
            let d = (reception.receptionsourcework.date_of_reception_source) ? reception.receptionsourcework.date_of_reception_source : false;
            let rsw_id = reception.receptionsourcework.id;
            if(d === false && source_id_checklist.indexOf(rsw_id) === -1){
                count_rsw_missing_date++;
            }

            if(d && source_id_checklist.indexOf(rsw_id) === -1 ){
                
                let date_src = parseInt(d.substring(0, 4), 10);
                source_id_checklist.push(rsw_id);

                if (date_src >= 1700) {
                    date_types.map(e => {
                        if (e.id === 1700){
                            e.value++;
                        }
                        return e
                    })                    
                } else if (date_src >= 1690 && date_src < 1700) {
                    date_types.map(e => {
                        if (e.id === 1690){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1680 && date_src < 1690) {
                    date_types.map(e => {
                        if (e.id === 1680){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1670 && date_src < 1680) {
                    date_types.map(e => {
                        if (e.id === 1670){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1660 && date_src < 1670) {
                    date_types.map(e => {
                        if (e.id === 1660){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1650 && date_src < 1660) {
                    date_types.map(e => {
                        if (e.id === 1650){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1640 && date_src < 1650) {
                    date_types.map(e => {
                        if (e.id === 1640){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1630 && date_src < 1640) {
                    date_types.map(e => {
                        if (e.id === 1630){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1620 && date_src < 1630) {
                    date_types.map(e => {
                        if (e.id === 1620){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1610 && date_src < 1620) {
                    date_types.map(e => {
                        if (e.id === 1610){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1600 && date_src < 1610) {
                    date_types.map(e => {

                        if (e.id === 1600){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1590 && date_src < 1600) {
                    date_types.map(e => {
                        if (e.id === 1590){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1580 && date_src < 1590) {
                    date_types.map(e => {
                        if (e.id === 1580){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1570 && date_src < 1580) {
                    date_types.map(e => {
                        if (e.id === 1570){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1560 && date_src < 1570) {
                    date_types.map(e => {
                        if (e.id === 1560){
                            e.value++;
                        }
                        return e
                    })
                } else if (date_src >= 1550 && date_src < 1560) {
                    date_types.map(e => {
                        if (e.id === 1550){
                            e.value++;
                        }
                        return e
                    })
                }
            }
        });
        

        date_types.reverse();

        return (

            <Grid item xs={12} md={6} className="mt-4">
                <Card>
                    <Card.Header >
                        <div className={styles.reception_captured_card_header}>
                            <div >
                                <Typography variant="h6">
                                    Timeline of Reception Sources
                                </Typography>
                                <small className="text-muted">
                                    <FaInfoCircle /> {source_id_checklist.length} dates available for Reception Sources, of { (source_id_checklist.length + count_rsw_missing_date) } in total, shown by decade
                                </small>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body >
                        <LineChart width={600} height={400} data={date_types}>
                            <Line type="monotone" dataKey="value" stroke="#8884d8" />                        
                                <XAxis dataKey="name" />
                                <YAxis dataKey="value" allowDecimals={false} name="Number of Reception Sources"/>
                                <Tooltip label={"Number of Reception Sources"} />
                            </LineChart>



                    </Card.Body>
                </Card>
            </Grid>


        )
    }
}

export default withRouter(PersonTimeline)