import React  from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import WorksResultsTable from './WorksResultsTable';
import WorksSearch from './WorksSearch'
import Loading from '../common/Loading';
import Footer from '../Footer';
import {Alert, Card} from 'react-bootstrap';
import { FaBookOpen, FaCogs, FaInfoCircle, FaExclamationTriangle} from 'react-icons/fa';
import styles_css from '../../style.module.css';
import { config } from '../../config'; 



const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    },
    gridMain: {
        width: 1300,
        paddingBottom: 30
    },
    gridRight: {
        padding: 20,
        width: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    inlining: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10
    },
    inliningBlock: {
        display: 'block',
        marginRight: 10,
        marginLeft: 25
    },
    inliningRight: {
        display: 'inline',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 10,
        marginLeft: 35
    },

})





class Works extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.handleChange = this.handleChange.bind(this);
        // this.handleRefine = this.handleRefine.bind(this);

        this.state = {
            isLoading: false,
            isLoadingConfig: false,
            works_count: 0,
            showSearchResults: false,
            showNoResults: false,
            reception_types: '',
            // receptionSources: '',
            receptionSourcesTypes: [],
            circulationtypes: [],
            languages: '',
            searchResponse: [],
            search_list: [],
            peopleSearchResponse: [],
            peopleList: [],
            receivers: [],
            person:[],
            allPeople:[],
            people_detail: [],
            updateRefineSearch: false,
            originalReceptions: [],
            query: null
        };
    }

    componentDidMount() {

        console.log(this.props.location);
        

        this.setState({
            isLoading: false,
            isLoadingConfig: true
        });

        // get remote config data
        fetch(config.apiUrl + '/config')
            .then((response) => response.json())
            .then((responseJson) => {
                if(responseJson['count']){
                    this.setState({
                        isLoadingConfig: false,
                        works_count: responseJson['count']['works']        
                    });
                }
            });
    }


    handleRemoveClick() {
        this.setState({
          searchResponse: [], 
          showNoResults: false, 
          showSearchResults: false, 
          originalReceptions: []
        });

    }

    handleChange(showSearchResults, searchResponse, query, isClearSearch, isError) {
        if(query){
            this.setState({
                query
            })
        }
        
        console.log(showSearchResults, searchResponse, isClearSearch, isError, query);
        
        if (isClearSearch) {
            this.handleRemoveClick()
        } else if (isError) {
            this.setState({
                isLoading: false,
                showSearchResults: false,
                showNoResults: true
            });
        } else {
            this.setState({
                searchResponse: [],            
                query    
            })
            if (searchResponse.data.length > 0) {
                this.setState({
                    isLoading: false,
                    searchResponse: searchResponse,
                    showSearchResults: true,
                    query
                });
            } else {
                this.setState({
                    isLoading: false,
                    showSearchResults: false,
                    showNoResults: true,
                    query
                });
            }



        }
    }


  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const { isLoading } = this.state;
    const { isLoadingConfig } = this.state;
    const { works_count } = this.state;
    const { showSearchResults } = this.state;
    const { query } = this.state;
    const { searchResponse } = this.state;
    // const { allPeople } = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container spacing={24} justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                <Grid container >
                    <Grid item xs={6} className={classes.grid}>

                        <FaBookOpen />
                        <Typography className={styles_css.reception_typography} variant="h6">Writings by Women</Typography>


                    </Grid>

                    <Grid item xs={6} className={classes.gridRight}>
                        <FaCogs />
                        <Typography className={styles_css.reception_typography} variant="h6">
                            <Link to="/about-data">
                                About this data
                            </Link>
                        </Typography>
                    </Grid>
                    { !isLoadingConfig && 
                        <div className={classes.inliningBlock}>
                            <Typography variant="body1" className="text-muted" gutterBottom>
                                <small><FaInfoCircle /> Explore {works_count} female-authored texts. Not all works in this section are connected to Receptions</small>
                            </Typography>                            
                        </div>
                    }
                </Grid>
            </Grid>

              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>
                     <WorksSearch 
                        location={this.props.location} 
                        history={ this.props.history} 
                        isLoading={isLoading} 
                        onChange={this.handleChange} 
                        showSearchResults={showSearchResults} 
                        searchResponse={searchResponse}                        
                    />
                      
                  </Grid>
              </Grid>
              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>

                    {
                        showSearchResults && <WorksResultsTable
                        
                            onChange={this.handleChange}
                            person={this.state.person}
                            receivers={this.state.receivers}
                            searchResponse={this.state.searchResponse}
                            query={`term=` + query}
                        />
                    }
                      {this.state.showNoResults &&
                        <Grid item xs={12} >
                            <Card className={styles_css.table_no_results_card_table}>
                                <Alert key={'alert'} variant={'warning'}>
                                        <FaExclamationTriangle /> No Results returned
                                </Alert>
                            </Card>
                        </Grid>

                      }
                  </Grid>


              </Grid>


          </Grid>
        </div>


          <Footer/>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Works);