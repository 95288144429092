import React,  { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from './Topbar';
import Footer from './Footer';
import styles_css from '../style.module.css';
import { FaInfoCircle } from 'react-icons/fa';

const banner = require('../images/img-about.jpg');

const styles = theme => ({
    root: {
        flexGrow: 1,       
        marginTop: 20,
        // padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})

class About extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} md={8}>
                                

                                <Typography variant="h1" className="text-center mb-4">The RECIRC Project</Typography>

                                <p>
                                    RECIRC: The Reception and Circulation of Early Modern Women’s Writing, 1550-1700 is a research project about the impact made by women writers and their works in the sixteenth and seventeenth centuries. Led by Marie-Louise Coolahan, and funded by the European Research Council from 2014 to 2020, the project involved a team of 11 researchers based at the National University of Ireland Galway. The focus included writers who were read in Ireland and Britain as well as women born and resident in Anglophone countries. Therefore, the subject of study was not limited to authors who wrote in English. RECIRC aimed to produce a large-scale, quantitative analysis of the reception and circulation of women's writing from 1550 to 1700. The RECIRC database is one of its major outputs. 
                                </p>

                                <p className="alert alert-warning mb-4">
                                    <FaInfoCircle /> Visit the "<Link to="/about-data">About the Data</Link>"  page to learn about the project's development, parameters and findings.
                                </p>

                                <Typography variant="h3">Research Questions</Typography>

                                <ul>
                                    <li>How did texts by women circulate?</li>
                                    <li>Which female authors were read? Who read them?</li>
                                    <li>How were they read?</li>
                                    <li>How did women build reputations as writers?</li>
                                    <li>How did gender shape ideas about authorship?</li>
                                </ul>
                                                            
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Grid container justify="center" className={`${styles_css.exploreHighlight} ${styles_css.explore}`} style={{ marginBottom: 0}}>
                        
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} md={5}>
                                <figure>
                                    <iframe width="560" height="315" className="p-2" src="https://www.youtube.com/embed/vyLAeCXSkOg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <Typography variant="h6">Principal Investigator</Typography>
                                        <p>Professor Marie-Louise Coolahan</p>
                                    </figcaption>
                                </figure>
                            </Grid>
                            <Grid item xs={false} sm={1} md={2}></Grid>
                            <Grid item xs={12} md={5}>
                                <iframe width="560" height="315" className="p-2" src="https://www.youtube.com/embed/LIh5Rv5tWuU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <figcaption>
                                    <Typography variant="h6">Research Assistant/Researcher</Typography>
                                    <p>Dr Bronagh McShane</p>
                                </figcaption>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    <Grid container justify="center" className={`${styles_css.exploreHighlight} ${styles_css.explore}`} style={{ backgroundColor: '#A4A397', marginTop: 0, paddingTop: '1em', paddingBottom: '1em'}}>

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid + ` text-center`}>
                            <Grid item xs={12}>
                                <Typography variant="h2" className="text-center mt-0 mb-2">
                                    Work Packages
                                </Typography>

                                <p className="pb-0 mb-0">The project investigated the impact of women writers by focusing on 4 main categories (labelled 'work packages'):</p>                                
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justify="center">
                        
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} md={5}>
                                <Typography variant="h2">Work Package One</Typography>
                                <p><strong>Transnational Religious Networks</strong></p>
                                <p><strong>Researchers</strong>: Dr Bronagh McShane and Dr Emilie K.M. Murphy</p>
                                <p>This work package investigates the transmission and translation of female-authored texts among the Catholic religious orders across Europe</p>
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <figure>
                                    <iframe width="560" height="280" src="https://www.youtube.com/embed/UBQgKrDIWLM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <p>Dr Emilie K.M. Murphy</p>
                                    </figcaption>
                                </figure>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                
                    <Grid container justify="center" className={`${styles_css.exploreHighlight} ${styles_css.explore}`} >

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            
                            
                            <Grid item xs={12} md={6}>
                                <figure>
                                    <iframe width="560" height="280" src="https://www.youtube.com/embed/bpoS27eG8ok" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <p>Dr Felicity Maxwell</p>
                                    </figcaption>
                                </figure>
                                
                                <figure>
                                    <iframe width="560" height="280" src="https://www.youtube.com/embed/ktujn3XB0uc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <p>Dr Evan Bourke</p>
                                    </figcaption>
                                </figure>
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <Typography variant="h2">Work Package Two</Typography>
                                <p><strong>The International Republic of Letters</strong></p>
                                <p><strong>Researchers</strong>: Dr Felicity Maxwell and Dr Evan Bourke</p>
                                <p>This work package analyses the international republic of letters as the locus for the circulation of texts and ideas by women and their forging of new intellectual networks across Europe.</p>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                
                
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            
                            <Grid item xs={12} md={5}>
                                <Typography variant="h2">Work Package Three</Typography>
                                <p><strong>The Manuscript Miscellany as Instrument of Circulation and Site of Reception</strong></p>
                                <p><strong>Researchers</strong>: Dr Sajed Chowdhury and Dr Erin A. McCarthy</p>
                                <p>This work package addresses the reception of women's writing in early modern manuscript culture by focusing on a specific category: the manuscript miscellany. It investigates various modes of manuscript reception, including the compilation, adaptation and excerpting of texts by women.</p>
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <figure>
                                    <iframe width="560" height="280" src="https://www.youtube.com/embed/QEYD6rFVg98" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <p>Dr Sajed Chowdhury</p>
                                    </figcaption>
                                </figure>
                                
                                <figure>
                                    <iframe width="560" height="280" src="https://www.youtube.com/embed/BU7371_S7es" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <p>Dr Erin A. McCarthy</p>
                                    </figcaption>
                                </figure>
                            </Grid>

                           
                        </Grid>
                        
                    </Grid>
                
                    <Grid container justify="center" className={`${styles_css.exploreHighlight} ${styles_css.explore}`} >

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} md={6}>
                                <figure>
                                    <iframe width="560" height="280" src="https://www.youtube.com/embed/OzvuR-LAIuY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <p>Dr Mark Empey</p>
                                    </figcaption>
                                </figure>

                            </Grid>

                            <Grid item xs={12} md={5}>
                                <Typography variant="h2">Work Package Four</Typography>
                                <p><strong>Transmission Trails and Book Ownership</strong></p>
                                <p><strong>Researcher</strong>: Dr Mark Empey</p>
                                <p>This work package maps the transmission of women's texts by focusing on the evidence of early modern library catalogues, patterns of book ownership and authorial attribution.</p>
                            </Grid>                        
                           
                        </Grid>
                        
                    </Grid>
                
                
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            

                            <Grid item xs={12} md={5}>
                                <Typography variant="h2">IRC Work Package</Typography>
                                <p><strong>The Reception and Circulation of Irish Women’s Writing, 1550-1800</strong></p>
                                <p><strong>Researcher</strong>: Dr Wes Hamrick</p>
                                <p>This project researches the transmission and translation of Irish-language women's writing, up to 1800. It also contributes to ‘Women's Poetry in Ireland, Scotland and Wales, 1400-1800’, which is producing a multi-lingual anthology and comparative critical study (<a href="http://womenspoetry.aber.ac.uk/en/">http://womenspoetry.aber.ac.uk/en/</a>).</p>
                            </Grid>        
                            
                            <Grid item xs={12} md={6}>
                                <figure>
                                    <iframe width="560" height="280" src="https://www.youtube.com/embed/FISyMTVPySc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <figcaption>
                                        <p>Dr Wes Hamrick</p>
                                    </figcaption>
                                </figure>

                            </Grid>
                           
                        </Grid>

                        <p className="alert alert-warning mb-5">
                            <FaInfoCircle /> Visit the "<Link to="/about-data">About the Data</Link>"  page to learn about the project's development, parameters and findings.
                        </p>
                        
                    </Grid>
                
                
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(About);