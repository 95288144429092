import React  from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import {Button} from 'react-bootstrap';
import { FaChevronRight, FaUsers } from 'react-icons/fa';
import Topbar from '../Topbar';
import PersonDetails from './PersonDetails';
import PersonWorks from './PersonWorks';
import PersonWorkDetail from './PersonWorkDetail';
import PersonExplore from './PersonExplore';
import PersonReceptionsLinkedChart from './PersonReceptionsLinkedChart';
import PersonGeography from './PersonGeography';
import PersonTimeline from './PersonTimeline';
import Footer from '../Footer';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import styles_css from '../../style.module.css';
import { config } from '../../config';



const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['200'],
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    paper: {
        padding: theme.spacing.unit * 3,

        color: theme.palette.text.secondary
    },
    box: {
        marginBottom: 280,
        height: 45
    },
    paperSearch: {
        padding: theme.spacing.unit * 3,
        textAlign: 'center',
        justify: "center",
        color: theme.palette.text.primary
    },
    grid: {
        maxWidth: 1000,
        display: 'flex',
        justifyContent: 'flex-start',
        // alignItems: 'center'

    },
    gridMain: {
        maxWidth: 1300,
        paddingBottom: '30px',
        marginBottom: '20px',
    },

    boxSearch: {
        marginBottom: 10,
        padding: theme.spacing.unit * 3,
        borderTop: `1px solid black`,
        borderBottom: `1px solid black`,
        borderLeft: `1px solid black`,
        borderRight: `1px solid black`,
        height: 200,

    },
    gridRight: {
        padding: 20,
        maxWidth: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    inlining: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10
    },
    inliningBlock: {
        display: 'block',
        marginRight: 10,
        marginLeft: 25
    },
    inliningRight: {
        display: 'inline',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 10,
        marginLeft: 35
    },

})



const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);





class Person extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.myRef = React.createRef();
        this.timePlaceRef = React.createRef();
        this.showDetail = this.showDetail.bind(this);
        this.hideDetails = this.hideDetails.bind(this);

        this.state = {
            isLoading: false,
            showDetails: false,
            work: [],
            person: "",
            open: false,
            authored_works: []
        };
    }



    scrollToMyRef = () => window.scrollTo(0, this.myRef.offsetTop)
    scrollToTimePlaceRef = () => window.scrollTo(0, this.timePlaceRef.offsetTop)

    showDetail(showdetails, work) {
        this.setState({
            showDetails: showdetails,
            work: work
        })
    }

    hideDetails(hide, work) {
        this.setState({
            showDetails: hide,
            work: work
        })
    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };



    search(person) {
        fetch(config.apiUrl + `/person/${person}`)
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    person: responseJson,
                }, function(){

                });

            }).catch((error) => {
                console.error(error);
            });
        
        // get person's receptions
        fetch(config.apiUrl + `/reception/person/${person}`)
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    receptions: responseJson,
                });
                // console.log(responseJson);
            }).catch((error) => {
                console.error(error);
            });
    }

    componentDidMount() {

        let personId = this.props.match.params.personId;
        //console.log(this.props.match.params)
        this.search(personId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.person !== prevProps.person) {
            
        }else{
            return false;
        }

     }


    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname;
        const { person, receptions } = this.state;
        const { showDetails } = this.state;
        
        const { work } = this.state;
        let display_name = ""
        let is_female_author = false;

        if (person ) {
            display_name = person.display_name;
            if (person.sex === 'Female' && person.is_author === true) {
                is_female_author = true;
            }

        }
        
        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container spacing={24} justify="center">
                        <Grid spacing={24} justify="center" container className={classes.gridMain} style={{ alignItems: 'centre'}}>
                            <Grid container style={{ alignItems: 'centre' }}>
                                <Grid item xs={12} md={6} style={{ alignItems: 'centre', paddingTop: '1em'}}>

                                    <FaUsers />
                                    <Typography style={{display: 'inline', marginLeft:10, padding:10}} variant="h6">
                                        <Link to={`/people`}>   People</Link>

                                    </Typography>
                                    <FaChevronRight />
                                    <Typography style={{display: 'inline', marginLeft:10, padding:10}} variant="h6">{display_name}
                                    </Typography>


                                </Grid>
                                <Grid item xs={12} md={6} className={classes.gridRight}>
                                    <ul className="nav justify-content-center">
                                        {is_female_author && 
                                            <li className="nav-item">
                                                <Link smooth to={'#works'} className="nav-link">
                                                    Works
                                                </Link>
                                            </li>
                                        }

                                        <li className="nav-item">
                                            <Link smooth to={'#time-and-place'} className="nav-link">
                                                Time &amp; Place
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Button onClick={this.handleClickOpen} style={{width:120, justifyContent:'flex-top', textAlign: 'center'}} size="sm" variant="secondary">
                                                Citing this Data
                                            </Button>
                                        </li>
                                    </ul>

                                        <Dialog
                                            onClose={this.handleClose}
                                            aria-labelledby="customized-dialog-title"
                                            open={this.state.open}
                                        >
                                            <DialogTitle onClose={this.handleClose}>
                                                Citing this data
                                            </DialogTitle>
                                            <DialogContent>
                                                <Typography gutterBottom>
                                                    Data is published under a <a href="https://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-Non Commercial 4.0 International License (CC-BY-NC 4.0)</a>. 
                                                    Further information on how to use and cite this data is available from the <Link to="/about-data/data-use">About the Data</Link> page.
                                                </Typography>
                                                <Typography gutterBottom>
                                                   To cite this data in your work, please use the following:
                                                </Typography>
                                                <Typography style={{padding: '1em 2em', backgroundColor:'#e8edf4', color: 'black'}} gutterBottom>
                                                   RECIRC: The Reception &amp; Circulation of Early-Modern Women's Writing, 1550-1700,
                                                    URL: { window.location.href }, Accessed: <em>&lt;Today's Date&gt;</em>
                                                </Typography>
                                            </DialogContent>
                                        </Dialog>

                                </Grid>

                            </Grid>

                        </Grid>

                        <Grid spacing={24} justify="center" container className={classes.gridMain} alignItems="baseline">
                            <Grid container spacing={24}>
                                <PersonDetails person={person} />
                                <Grid item xs={12} md={6} className={classes.grid}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} className={classes.grid}>
                                            {/* Person Metrics */}
                                            <PersonExplore person={person} metrics={person.metrics} />
                                        </Grid>
                                        <Grid item xs={12} className={classes.grid}>
                                            {/* Column Chart */}
                                            <PersonReceptionsLinkedChart history={this.props.history} person={person} receptions={ receptions }/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                    <Grid item xs={12}>
                                        <div ref={ (ref) => this.myRef=ref } >
                                            {is_female_author && 
                                                <PersonWorks onChange={this.showDetail} person={person}/>
                                            }
                                        </div>
                                    </Grid>

                                {showDetails && 
                                    <PersonWorkDetail onChange={this.hideDetails} work={work}/>
                                }

                            </Grid>

                            <Grid id="time-and-place" spacing={24} container className={classes.gridMain} >
                                <PersonTimeline receptions={receptions} />
                                <PersonGeography receptions={receptions} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>


                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Person);