import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import {Card} from 'react-bootstrap';
// import { compose, withProps } from "recompose"
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import { Map, TileLayer, CircleMarker, Popup } from "react-leaflet";
import styles from '../../style.module.css';


class PersonGeography extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            lat: 48.69096,
            lng: 9.14062,
            zoom: 4,

        };
    }


    render() {
        const { receptions } = this.props;
        if(!receptions || receptions.length === 0 || receptions.error ){
            return null;
        }
        
        const position = [this.state.lat, this.state.lng]


        let circleMarkers = [];
        let missingLocation = 0;
        // let totalReceptionCount = receptions.length;

        receptions.forEach(reception => {
            if(reception.geo && reception.geo !== null ){
                let marker = (
                    <CircleMarker center={[+reception.geo.lon, +reception.geo.lat]} color="red" radius={3}>
                        <Popup>
                            <strong>{reception.geo.place}</strong>:
                            <Link to={`/receptions/reception/` + reception.id}>
                                { reception.reference }
                            </Link>
                        </Popup>
                    </CircleMarker>
                )

                circleMarkers.push(marker);
            }else{
                missingLocation++;
            }    
        });

        if (circleMarkers.length === 0){
            return null;
        }
        
        return (
            
            <Grid item xs={12} md={6} className="mt-4">
                <Card>
                    <Card.Header >
                        <div className={styles.reception_captured_card_header}>
                            <div >
                                <Typography variant="h6">
                                    Geography of Reception Sources
                                </Typography>
                                <small className="text-muted">
                                    { (missingLocation > circleMarkers.length )? <FaExclamationTriangle /> : <FaInfoCircle /> } {circleMarkers.length} of { (missingLocation + circleMarkers.length) } receptions have location data and are mapped below. 
                                </small>
                            </div>

                        </div>
                    </Card.Header>
                    <Card.Body style={{ overflow: 'hidden' }}>

                        <Map center={position} zoom={this.state.zoom} style={{minHeight: '400px'}}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                                url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
                                subdomains="abcd"
                                maxZoom="19"
                            />
                            
                            { circleMarkers.length > 0 && circleMarkers }
                        </Map>



                    </Card.Body>
                </Card>


            </Grid>


        )
    }
}

export default withRouter(PersonGeography)