import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from '../../style.module.css';

import { Sigma } from 'react-sigma';
import SourceNodePopover from "./SourceNodePopover";
import ReceptionEdgePopover from "./ReceptionEdgePopover";

// import { schemePaired } from "d3-scale-chromatic";
import { schemeCategory10 } from "d3-scale-chromatic";
import { schemeAccent } from "d3-scale-chromatic";
import { scaleOrdinal } from "d3-scale";

class NetworkSource extends React.Component {

    constructor(props, context) {
        super(props, context);

        

        this.state = {
            defaultColor: '#dbdbdb',
            isLoading: false,
            isActive: false,
            graph: false,
            receptionTypeScale: null,
            nodeRoles: [],
            
            // NodePopover related
            person: null,
            nodeEvent: null,
            showNodePopover: false,

            reception: null,
            edgeEvent: null,
            showEdgePopover: false,
            graphNeedsRefresh: false,

            edgeFiltersActiveIds: []
        };

        this.handleClickNode = this.handleClickNode.bind(this);
        this.handleClickEdge = this.handleClickEdge.bind(this);
        this.handleStageClick = this.handleStageClick.bind(this);

        // d3 ordinal colour scale functions to use
        // when colour coding nodes/edges
        this.edgeScale = null;
        this.nodeScale = null;
        this.nodeRoles = [];

        
    }

    getReceptionTypeEdgeColor(receptionTypes, isMuted)
    {
        return this.state.defaultColor;
        // if(typeof isMuted === 'undefined' || isMuted === true){
        //     return this.state.defaultColor;
        // }

        // let type = '';

        // if (receptionTypes.length > 1) {
        //     type = 'multiple'
        // } else {
        //     if (receptionTypes[0] && receptionTypes[0].value){
        //         type = receptionTypes[0].value;
        //     }else{
        //         return this.state.defaultColor;
        //     }
        // }

        // return this.edgeScale(type);
    }

    getRoleLabel(role) 
    {
        let label = '';
        // eslint-disable-next-line default-case
        switch (role) {
            case 'author':
                label = 'Female Author';
                break;
            case 'owner':
                label = 'Owner / Compiler / Scribe';
                break;
            case 'named_receiver':
                label = 'Named Receiver';
                break;
            case 'rsw':
                label = 'Source Work';
                break;
        }
        return label;
    }

    /**
     * Return a node colour
     * @param {string} role : one of ['author' | 'owner' | 'named_receiver' | 'rsw']
     */
    getRoleColor(role) {
        
        if(this.nodeRoles.indexOf(role) === -1){
            this.nodeRoles.push(role);
        }

        return this.nodeScale(role);
    }


    /**
     * Manual construction of the graph. Gives more control
     * over the final graph
     * 
     * @param {Sigma} objSigma - Sigma graph object
     * @param {collection} graph - graph data to read/manipulate
     */
    buildGraph(objSigma, graphData) {
        
        objSigma.graph.clear();
        objSigma.graph.read(graphData);

        objSigma.graph.nodes().forEach(node => {
            node.x = Math.random();
            node.y = Math.random();
            node.size = objSigma.graph.degree(node.id);
            node.label = (node.Label) ? node.Label : node.label;
            node.color = this.getRoleColor(node.role);
        });
        
        objSigma.graph.edges().forEach(edge => {
            let isMuted = true;
            edge.isMuted = isMuted;
            edge.color = this.getReceptionTypeEdgeColor(edge.reception_type, isMuted);

            // need a weight calculation here...
            // edges[i].size = edges[i].size;
        });

        return objSigma;
    }


    buildLegend()
    {
        if(this.nodeRoles.length === 0){
            return false;
        }

        let legend = this.nodeRoles.map((item) => {
            return (
                <li key={`role-legend-` + item} className="list-inline-item legend-icon-wrapper">
                    <span className="legend-icon" style={{backgroundColor: this.nodeScale(item), width: "15px", height: "15px", borderRadius: "15px", display: "inline-block", marginRight: "5px"} }></span>
                    { this.getRoleLabel(item) }
                </li>
            );
        });

        return legend;
    }

    handleStageClick(e)
    {
        this.setState(state => ({
            showNodePopover: false,
            showEdgePopover: false
        }));
    }

    /**
     * Display a popover with additional info
     * when a node is clicked.
     * 
     * @param {Sigma Event} e 
     */
    handleClickNode(e){
        console.log(e);
        this.setState(state => ({
            showEdgePopover: false,
            showNodePopover: true,
            nodeEvent: e,
            person: e.data.node
        }));
    }    
    
    /**
     * Display a popover with additional info
     * when a node is clicked.
     * 
     * @param {Sigma Event} e 
     */
    handleClickEdge(e){
        // console.log(e);
        this.setState(state => ({
            showNodePopover: false,
            showEdgePopover: true,
            edgeEvent: e,
            reception: e.data.edge
        }));
    }    


    manageGraphLifeCycle()
    {
        // if we have a graph with some nodes, 
        // set degree for nodes, and add colour to nodes & 
        // edges
        if (this.props.graph && this.props.graph.nodes.length > 0) {

            // setup colour-codes for use on nodes/edges
            this.edgeScale = scaleOrdinal(schemeCategory10);
            this.nodeScale = scaleOrdinal(schemeAccent);

            // manual graph construction
            let graph = this.buildGraph(this.props.sigma, this.props.graph);
         

            let legend = this.buildLegend();
    
            this.setState({
                graph,
                legend,
                graphNeedsRefresh: true
            });
        }

    }


    componentDidMount()
    {
        this.manageGraphLifeCycle();
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {
            this.manageGraphLifeCycle();
        }
    }



    render() {
        let { 
            graph, 
            // edgeFilter, 
            graphNeedsRefresh, 
            legend, 
            nodeEvent, 
            edgeEvent, 
            showNodePopover, 
            showEdgePopover, 
            person, 
            reception 
        } = this.state;

        let { settings } = this.props;
        let g;

        if(!graph){
            return null;
        }


        if(graphNeedsRefresh){
            console.log('refreshing graph...');
            g = graph.refresh({
                skipIndexation: true
            });
            console.log(g, graph);
        }else{
            g = graph;
        }
        

        let network = {
            nodes: g.graph.nodes(),
            edges: g.graph.edges()
        };
        
        // console.log(network, settings);

        return (
            
            <Sigma 
                renderer="canvas" 
                graph={network} 
                settings={settings} 
                style={{ maxWidth: "inherit", height: "800px" }} 
                onClickNode={e => this.handleClickNode(e) }
                onClickEdge={e => this.handleClickEdge(e) }
                onOutNode={e => this.handleStageClick(e)}
            >
                { legend && 
                    <div style={{ position: 'absolute', top: 0, left: 0 }} id={styles.graphLegend}>
                        <ul className="list-inline">
                            { legend }
                        </ul>
                    </div>
                }
                
                {/* Behaviour for node click */}
                {/* <SourceNodePopover
                    person={person} 
                    event={nodeEvent}                    
                    show={showNodePopover} 
                />
                
                <ReceptionEdgePopover
                    reception={reception} 
                    event={edgeEvent}                    
                    show={showEdgePopover} 
                /> */}
            </Sigma>

        );
    }
}

export default withRouter(NetworkSource)