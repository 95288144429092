import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { FaChevronCircleRight } from 'react-icons/fa'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Button, Card, Form} from 'react-bootstrap'
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import EnhancedTableHead  from "./EnhancedTableHead";
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowRight';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import styles from '../../style.module.css';
import withStyles from '@material-ui/core/styles/withStyles';
import ResultsViewNav from './ResultsViewNav';
import ResultsViewExport from "./ResultsViewExport";

class TablePaginationActions extends React.Component {
    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1));
    };

    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return <div className={classes.root}>
            <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={this.handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={this.handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>;
    }
}

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired
};




const actionsStyles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing.unit * 2.5
    }
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(TablePaginationActions);


class CommonResultsTable extends React.Component {


    constructor(props, context) {
        super(props, context);

        // this.hideDetails = this.hideDetails.bind(this);
        // this.showDetails = this.showDetails.bind(this);
        this.switchResultsView = this.switchResultsView.bind(this);

        this.state = {
            isLoading: false,
            peopleSearchResponse: [],
            author: [],
            peopleList: [],
            receivers: [],
            showDetails: false,
            work: '',
            page: 0,
            rows: this.props.receptionSearchResponse,
            rowsPerPage: 50,
            order: 'asc',
            orderBy: 'female_author_text'

        };
    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';
        
        this.setState({
            order: (isDesc ? 'asc' : 'desc'),
            orderBy: property
        });        
    };

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }


    switchResultsView(showResultsAsTable)
    {
        this.props.onChange(showResultsAsTable)
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {

        if (this.props.receptionSearchResponse !== prevProps.receptionSearchResponse) {
        }
    }



    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleRowsPerPage = (event, rowsPerPage) => {
        let rows = parseInt(rowsPerPage.key)
        this.setState({ rowsPerPage: rows });
    };


    /**
     * Transform search result response into object
     * that can both be sorted, and contains React components (such
     * as Links) for display purposes
     */
    createData = (data) => {
        return data.map(element => {
            let src = element._source;

            let receivername = '';
            let receiver_text = '';

            if (typeof src.receivername === "object") {
                // receivername = src.receivername.display_name;
                receiver_text += (src.receivername.display_name) ? src.receivername.display_name + ' ' : '';
                receivername = (
                    <span key={src.receivername.id}>
                        <Link to={`/people/person/` + src.receivername.id}>
                            {src.receivername.display_name}
                        </Link>
                    </span>
                );
            }


            let owners = '';
            let owners_text = '';
            if (src.receptionsourcework.owners && src.receptionsourcework.owners.length > 0) {

                owners = src.receptionsourcework.owners.map((p, idx, arr) => {
                    let sep = '';
                    if (arr.length > 1 && idx < arr.length - 1) {
                        sep = ', ';
                    }
                    owners_text += p.display_name + ' ';

                    return (
                        <span key={p.id}>
                            <Link to={`/people/person/` + p.id}>
                                {p.display_name}
                            </Link> {sep}
                        </span>
                    );
                });

            }

            let author_text = src.author.display_name;
            let author = (
                <Link to={`/people/person/${src.author.id}`}>
                    {src.author.display_name}
                </Link>
            );

            return {
                'reception': src.id,
                'female_author_text': author_text,
                'female_author': author,
                'receiver_text': receiver_text,
                'receiver': receivername,
                'owners_text': owners_text,
                'owners': owners,
                'rsw_title': src.receptionsourcework.title,
                'rsw_id': src.receptionsourcework.id
            }
        });
    };

    render() {

        const { receptionSearchResponse } = this.props;
        const { isLoading } = this.props;

        const { rowsPerPage, page } = this.state;
        let searchResponseLength = 0;

        // ID here matches the keys for data sorting set
        // in this.createData()
        const headCells = [
            { id: 'reception', numeric: true, disablePadding: true, label: 'Reception' },
            { id: 'female_author_text', numeric: false, disablePadding: true, label: 'Female Author' },
            { id: 'receiver_text', numeric: false, disablePadding: true, label: 'Receiver' },
            { id: 'owners_text', numeric: false, disablePadding: true, label: 'Owner/Compiler/Scribe' },
            { id: 'rsw_title', numeric: false, disablePadding: true, label: 'Source Work Title' },
        ];

        

        if (isLoading) {
            return null;
        }

        let contents = (<tr></tr>);
        // console.log(receptionSearchResponse);
        if (receptionSearchResponse.data.length >= 1) {
            searchResponseLength = receptionSearchResponse.data.length;
            let rows = this.createData(receptionSearchResponse.data);
            contents = this.stableSort(rows, this.getSorting(this.state.order, this.state.orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((data) => {
                            return (

                                <TableRow className={styles.tableRow} key={data.reception}>
                                    <TableCell style={{ paddingLeft: '5px', paddingRight: '5px'}}>
                                        <Link to={`/receptions/reception/${data.reception}`} className={'btn btn-sm btn-light'} title="View Reception Record" style={{ width: '75px'}}>
                                            <FaChevronCircleRight style={{ marginRight: '3px' }} />View
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {data.female_author}
                                    </TableCell>
                                    <TableCell>{data.receiver }</TableCell> 
                                    <TableCell>{data.owners }</TableCell>
                                    <TableCell>
                                        <Link to={`/sources/source/${data.rsw_id}`}>
                                            {data.rsw_title}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            );
                        
                    });
        }

        

        return (

                <Grid item xs={12} >
                    <Card>
                            <Card.Header >
                                <div className={styles.table_results_header}>

                                    
                                    <ResultsViewNav 
                                        resultCount={receptionSearchResponse.data.length} 
                                        showTable={this.props.showTable} 
                                        showNetwork={this.props.showNetwork}
                                        onChange={this.switchResultsView}
                                    />


                                    <ResultsViewExport 
                                        query={this.props.query}
                                        type={'reception'}                            
                                    />

                                </div>
                            </Card.Header>
                            <Card.Body className={styles.table_results_card_body}>
                                <Card className={styles.table_results_card_table}>
                                    <Table id={"receptions"} className="responsive">

                                        <EnhancedTableHead                   
                                            headCells={headCells}                         
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onRequestSort={this.handleRequestSort}
                                        />
                                        <TableBody className={styles.table_results_font_size}>
                                            {contents}
                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    colSpan={3}
                                                    count={searchResponseLength}
                                                    rowsPerPage={10}
                                                    page={page}
                                                    SelectProps={{
                                                        native: false
                                                    }}
                                                    onChangeRowsPerPage={this.handleRowsPerPage}
                                                    onChangePage={this.handleChangePage}
                                                    labelRowsPerPage={"Rows to View"}
                                                    ActionsComponent={TablePaginationActionsWrapped} />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </Card>

                            </Card.Body>
                        </Card>

                </Grid>


        )
    }
}

export default withRouter(CommonResultsTable)