import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Nav from './Nav';
import Topbar from '../Topbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

const banner = require('../../images/about-data-banner.png');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        // padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})

class Search extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="" />

                                <Typography variant="h1" className=" text-center mb-4 mt-4">About the Data</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} justify="center" container className={classes.grid}>

                            <Grid item xs={12} md={4}>
                               <Nav />
                            </Grid>

                            <Grid item xs={12} md={8}>

                                <Typography variant="h2" className="mb-3">How to Search the Data</Typography>

                                <p>
                                    Interesting ways to explore the RECIRC database are suggested in '<Link to="/cases">Discovering the Reception of Women Writers</Link> and 'Explore Interesting Searches' at '<Link to="/receptions">Search Reception</Link>', '<Link to="/sources">Search Sources</Link>' and '<Link to="/people">Search People</Link>'.
                                </p>
                             
                            </Grid>
                        </Grid>
                    </Grid>




                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Search);