/**
 * Create a "fake" HTMLElement that will
 * act as an anchor for the popover
 *
 * @class VirtualReference
 */
export default class VirtualReference {

    constructor(x, y, size) {

        this.top = x;
        this.left = y;
        this.bottom = y + size;
        this.right = x + size;
        this.width = size * 2;
        this.height = size * 2
    }

    getBoundingClientRect() {
        return {
            top: this.top,
            left: this.left,
            bottom: this.bottom,
            right: this.right,
            width: this.width,
            height: this.height,
        };
    }

    get clientWidth() {
        return this.getBoundingClientRect().width;
    }

    get clientHeight() {
        return this.getBoundingClientRect().height;
    }
}