import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter, Link } from 'react-router-dom';
import { config } from '../../config';
import { FaCloudDownload, FaProjectDiagram } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";

/**
 * Component to give nav options between a table and graph
 * view of search results
 * resultCount, showTable, showNetwork
 * @param {int} resultCount - number of search results returned
 * @param {bool} showTable - default [true] - display results as a table
 * @param {bool} showNetwork - default [false] - display results as a graph
 */

class ResultsViewExport extends Component {

    constructor(props, context) {
        super(props, context);

       this.state = {
           query: null
       }

    }

 


    render() {
        const { query, type } = this.props;
        
        
        return (
            <ul className="list-inline mb-0 pb-0 mt-0">
                <li className="list-inline-item">
                    <Typography variant="caption"><strong>Export</strong> </Typography>
                </li>
                
                { 
                (type !== 'person' && type !== 'works') &&
                    <li className="list-inline-item">
                        <a href={config.apiUrl + `/export/`+ type + `/gexf/?` + query} className="btn btn-secondary btn-sm ml-3" target="_blank" rel="noopener noreferrer noindex nofollow">
                            <FaProjectDiagram /> Graph File (.gefx)
                        </a>
                    </li>
                }
                <li className="list-inline-item">
                    <a href={config.apiUrl + `/export/` + type + `/csv/?` + query} className="btn btn-secondary btn-sm ml-3" target="_blank" rel="noopener noreferrer noindex nofollow">
                        <FaDownload /> CSV File (.csv)
                    </a>
                </li>
            </ul>
        );
    }
}

export default withRouter(ResultsViewExport);