import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from '../../style.module.css';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Card, Table} from 'react-bootstrap'
import { config } from '../../config';

class SourceWorkDetails extends React.Component {

    constructor(props, context) {
        super(props, context);



        this.state = {
            isLoading: false,
            receptionworks: null
        };
    }



    componentDidMount() {
        
    }


    render() {

        const { receptionworks } = this.props;

        let workstable

        if(receptionworks){

            workstable = (
                <tbody key="workstable" >
                    { receptionworks.works_total_items &&
                        <tr key="no_of_items">
                            <th className={styles.source_works_detail_table} scope="row">Number of Items</th>
                            <td>{ receptionworks.works_total_items }</td>
                        </tr>
                    }
                    { receptionworks.works_total_female_authored_items &&
                        <tr key="works_total_female_authored_items">
                            <th className={styles.source_works_detail_table} scope="row">Female-Authored Items</th>
                            <td>{ receptionworks.works_total_female_authored_items }</td>
                        </tr>
                    }
                    { receptionworks.works_total_attributed_items &&
                        <tr key="works_total_attributed_items">
                            <th className={styles.source_works_detail_table} scope="row">Total Attributed Items</th>
                            <td>{ receptionworks.works_total_attributed_items }</td>
                        </tr>
                    }
                    { receptionworks.works_total_anonymous_items &&
                        <tr key="works_total_anonymous_items">
                            <th className={styles.source_works_detail_table} scope="row">Total Anonymous Items</th>
                            <td>{ receptionworks.works_total_anonymous_items }</td>
                        </tr>
                    }
                    { receptionworks.works_total_unattributed_female_authored_items &&
                        <tr key="works_total_unattributed_female_authored_items">
                            <th className={styles.source_works_detail_table} scope="row">Unattributed Female-Authored Items</th>
                            <td>{ receptionworks.works_total_unattributed_female_authored_items }</td>
                        </tr>
                    }
                    { receptionworks.works_total_attributed_female_items &&
                        <tr key="works_total_attributed_female_items">
                            <th className={styles.source_works_detail_table} scope="row">Attributed Female-Authored Items</th>
                            <td>{ receptionworks.works_total_attributed_female_items }</td>
                        </tr>
                    }
                </tbody>
            );
        }

        if( !workstable ){
            return null;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%'}}>
                <div >
                    <Typography variant="h6">
                        Work Details
                    </Typography>
                </div>


            <Grid item>
                <Card style={{ backgroundColor:'white', width: '100%'}}>
                    <Card.Body style={{ backgroundColor:'white',  width: '100%', borderWidth: 0}}>                                                   

                            { workstable && 
                                <Table striped size={'sm'} responsive style={{fontSize: "12px"}}>
                                   
                                    {workstable}
                                    
                                </Table>
                            }

                    </Card.Body>

                </Card>

            </Grid>
            </div>

        )
    }
}

export default withRouter(SourceWorkDetails)