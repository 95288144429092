import React from 'react';
import {config} from '../../config';
import { Link, withRouter } from 'react-router-dom';
import styles from '../../style.module.css';
import Typography from '@material-ui/core/Typography'
import { Card, Table} from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa';
import EnhancedTableHead from "../common/EnhancedTableHead";



class PersonWorks extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.showDetails = this.showDetails.bind(this);

        this.state = {
            authored_works: [],
            show_details: false,
            order: 'desc',
            orderBy: 'reception_count'
        };
    }

    showDetails(show, work) {
        this.props.onChange(true, work);

    }

    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';

        this.setState({
            order: (isDesc ? 'asc' : 'desc'),
            orderBy: property
        });
    };

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    /**
     * Transform search result response into object
     * that can both be sorted, and contains React components (such
     * as Links) for display purposes
     */
    createData = (data) => {
        if(!data){
            return;
        }

        return data.map(work => {
            let reception_count = 0;

            if (work.reception){
                reception_count = work.reception.length;
            }

            let title = (
                    <Link to={`/works/work/` + work.id }>
                        {work.title }
                    </Link>
            );
            
            return {
                'id': work.id,
                'title_text':  work.title,
                'title': title,
                'date': work.date,
                'reception_count': reception_count
            }
        });
    };

    search(person) {
        return fetch(config.apiUrl + `/work/authored/${person}`)
            .then((response) => response.json())
            .then((responseJson) => {                
                if (responseJson.error){
                    this.setState({
                        authored_works: false,
                    });
                }else{
                    this.setState({
                        authored_works: responseJson,
                    });
                }

            })
            .catch((error) =>{
                console.error(error);
            });

    }

    componentDidMount() {
        let personId = this.props.match.params.personId;
        this.search(personId);

    }


    render() {

        const { authored_works } = this.state;

         // ID here matches the keys for data sorting set
        // in this.createData()
        const headCells = [
            { id: 'title_text', numeric: false, disablePadding: true, label: 'Title of Work' },
            { id: 'date', numeric: false, disablePadding: true, label: 'Date' },
            { id: 'reception_count', numeric: true, disablePadding: true, label: 'Number of Receptions' },         
        ];

        let content = (<tr></tr>);

        if (authored_works.length > 0 ){
            let rows = this.createData(authored_works);
            content = this.stableSort(rows, this.getSorting(this.state.order, this.state.orderBy))
                        .map((work) => {
                            return (
                                <tr key={`work-` + work.id}>
                                    <td>{work.title}</td>
                                    <td>{work.date}</td>                  
                                    <td>{ work.reception_count }</td>      
                                </tr>
                            );
            });
        }


        return (


                <Card id="works">
                    <Card.Header >
                        <div className={styles.reception_captured_card_header} >
                            <div >
                                <Typography variant="h6"><strong>All Works By This Female Author</strong> </Typography>
                                <small className="text-muted">
                                    <FaInfoCircle /> {authored_works && <span>{authored_works.length}</span>} work{authored_works.length > 1 && 's' } identified as being authored by this person. They may not be linked with a reception.
                                </small>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body >
                        <Card className={styles.person_works_card}>
                        { authored_works && 
                            <Table striped bordered size={'sm'} responsive>
                                <EnhancedTableHead
                                    headCells={headCells}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />                                
                                <tbody className={styles.table_results_font_size}>
                                    {content}
                                </tbody>
                            </Table>
                        }
                        { !authored_works && 
                            <Table striped bordered hover size={'sm'} responsive>
                                <tbody>
                                    <tr className="alert alert-info">
                                        <td>The database does not contain works for this person</td>                                    
                                    </tr>
                                </tbody>
                            </Table>
                        }
                        </Card>

                    </Card.Body>

                </Card>






        )
    }
}

export default withRouter(PersonWorks)