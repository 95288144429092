import React  from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import {ButtonGroup, Button, Card} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Col} from 'react-bootstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import SourceSearchAutoComplete from './SourceSearchAutoComplete';
import { FaInfoCircle, FaCheck, FaPlusCircle, FaMinusCircle, FaTimes, FaSearch } from 'react-icons/fa';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import SourceSelectDialog from "./SourceSelectDialog";
import Picky from 'react-picky';
import 'react-picky/dist/picky.css'; // Include CSS
import styles from '../../style.module.css';
import {sourcesSearchTermBuilder} from '../common/Utils'
import { config } from '../../config';





const types = [
    {
        value: 'Female Author',
        label: "Female Author",
        filter: ['All', 'Named', 'Is Nun', 'Has Aristocratic Title'],
        default: true
    },
    {
        value: 'Receiver',
        label: "Receiver",
        filter: "Named",
        default: true
    },
    {
        value: 'Owner/Compiler/Scribe',
        label: "Owner/Compiler/Scribe",
        filter: "Named",
        default: true
    },
    {
        value: 'Reception Type',
        label: 'Reception Type',
        filter: "Equals",
        default: true
    },
    {
        value: 'Circulation Type',
        label: 'Circulation Type',
        filter: "Equals",
        default: true
    },
    {
        value: 'Reception Source Language',
        label: "Reception Source Language",
        filter: "Equals",
        default: true
    },
    {
        value: 'Reception Source Type',
        label: 'Reception Source Type',
        filter: "Equals",
        default: true
    },
    {
        value: 'Reception Source Title',
        label: 'Reception Source Title',
        filter: "Contains",
        default: true
    },

    {
        value: 'Reference',
        label: 'Reference',
        filter: ["Contains", "Equals"],
        default: true
    },
];

const filter = [

    {
        value: 'All',
        label: 'All',
        types: ['Female Author', 'Receiver', 'Owner/Compiler/Scribe']
    },
    {
        value: 'Named',
        label: "Named",
        types: ['Female Author', 'Receiver', 'Owner/Compiler/Scribe']
    },
    {
        value: 'Male',
        label: 'Male',
        types: ['Receiver', 'Owner/Compiler/Scribe']
    },
    {
        value: 'Female',
        label: 'Female',
        types: ['Receiver', 'Owner/Compiler/Scribe']
    },
    {
        value: 'Anonymous',
        label: 'Anonymous',
        types: ['Receiver', 'Owner/Compiler/Scribe']
    },
    {
        value: 'Is Nun',
        label: 'Is Nun',
        types: ['Female Author', 'Receiver', 'Owner/Compiler/Scribe']
    },
    {
        value: 'Has Aristocratic Title',
        label: 'Has Aristocratic Title',
        types: ['Female Author', 'Receiver', 'Owner/Compiler/Scribe']
    },
    {
        value: 'Contains',
        label: 'Contains',
        types: ['Reference', 'Reception Source Title' ]
    },
    {
        value: 'Equals',
        label: 'Equals',
        types: ['Reception Type', 'Circulation Type', "Reception Source Language", 'Reception Source Type', 'Reference']
    }
];

const person = [{
    value: "Female Author",
    label: "search",
    types: 'Female Author',
    options: ['All', 'Named', 'Is Nun', 'Has Aristocratic Title']
}];

const receiver = [{
    value: "Receiver",
    label: "search",
    types: 'Receiver',
    options: ['All', 'Named', 'Is Nun', 'Has Aristocratic Title']
}];

const owner_compiler_scribe = [{
    value: "Owner/Compiler/Scribe",
    label: "search",
    types: 'Owner/Compiler/Scribe',
    options: ['All', 'Named', 'Is Nun', 'Has Aristocratic Title']
}];

const reception_source_type = [{
    value: "Reception Source Type",
    label: "search",
    types: 'Reception Source Type',
    options: ['account book', 'auction catalogue', 'autobiography/memoir', 'bio-bibliography', 'biography', 'booklist', 'chronicle', 'commonplace book', 'composite volume', 'conduct/advice book', 'devotional book', 'dialogue', 'diary', 'essay/discourse', 'fascicle', 'first print edition of a manuscript work', 'hagiography', 'history', 'inventory', 'legal document', 'letter', 'library catalogue', 'martyrology', 'miscellany', 'natural history', 'obituary book', 'poetry anthology', 'polemic', 'prophecy/dream', 'recipe/receipt book ', 'sermon', 'single-author volume', 'subsequent edition', 'translation']
}]

const reception_source_language = [{
        value: "Reception Source Language",
        label: "search",
        types: 'Reception Source Language',
        options: ['English', 'Arabic', 'Chaldean', 'Danish', 'Dutch', 'French', 'Gaelic', 'German', 'Greek', 'Hebrew', 'Italian', 'Latin', 'Portuguese', 'Samaritan', 'Scots', 'Spanish', 'Syriac', 'Welsh']
}];

const circulation_type = [{
        value: "Circulation Type",
        label: "search",
        types: 'Circulation Type',
        options: ['catalogue entry', 'enclosure with letter', 'endorsement', 'interception', 'ownership inscription', 'additional directions', 'named agent of transmission']
}];

const reception_type = [{
    value: "Reception Type",
    label: "search",
    types: 'Reception Type',
    options: ['adaptation', 'annotation', 'answer', 'citation', 'dedication', 'drama', 'drawing', 'excerpt', 'extended commentary', 'funeral sermon', 'marginalia', 'music-setting', 'obit', 'paraphrase', 'performance', 'poem', 'portrait', 'print edition', 'reading', 'reading aloud in company', 'reference to named author', 'reference to specific work', 'reference to unspecified work(s)', 'summary', 'transcription', 'translation']
}];

const reference = [{
    value: "Reference",
    label: "search",
    types: 'Reference',
    options: ['adaptation', 'annotation', 'answer', 'citation', 'dedication', 'drama', 'drawing', 'excerpt', 'extended commentary', 'funeral sermon', 'marginalia', 'music-setting', 'obit', 'paraphrase', 'performance', 'poem', 'portrait', 'print edition', 'reading', 'reading aloud in company', 'reference to named author', 'reference to specific work', 'reference to unspecified work(s)', 'summary', 'transcription', 'translation']
}];

const source_title = [{
    value: "Reception Source Title",
    label: "search",
    types: 'Reception Source Title'
}];


function jsUcfirst(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class SourceSearch extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSearchValue = this.handleSearchValue.bind(this);
        this.handleBasicSearchClick = this.handleBasicSearchClick.bind(this);
        this.handleAdvancedClick = this.handleAdvancedClick.bind(this);
        this.handleBasicClick = this.handleBasicClick.bind(this);

        this.state = {
            isLoading: false,
            isLoadingResults: false,
            showSearchResults: false,
            updateSearchButton: false,
            receptionSearchResponse: [],
            updateSearch: false,
            searchRow: [],
            selectedType: "Reception Source Type",
            userInput: "",
            selectedFilter: "Equals",
            searchEnabled: true,
            open: false,
            selectedValue: "",
            searchCount: 0,
            basicSearch: false,
            receptionTypeSearchTerm: [],
            query_type: 'must',
            current_terms: [],
            nun_list: [],
            enable_add_button: true,
            receptionTypeSearchTerm: [],
            selected_person: [],
            reception_types: [],
            query: null

        };
    }


    populateConfig() {
        if (this.state.reception_types.length === 0 && this.props.reception_types.length === 0) {
            fetch(config.apiUrl + '/config')
                .then((response) => response.json())
                .then((responseJson) => {
                    this.setState({
                        reception_types: responseJson['receptionTypes'],
                        receptionSourcesTypes: responseJson["receptionSources"],
                        languages: responseJson['languages'],
                        circulationtypes: responseJson['circulationTypes'],
                    });
                }).catch((error) => {
                    console.error(error);
                });
        }
    }


    componentDidMount() {
        this.populateConfig();
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {

            this.setState({
                isLoading: this.props.isLoading,
                searchCount: this.props.resultCount
            });

            this.populateConfig();
        


            if (this.props.searchRows !== prevProps.searchRows) {

                let searchRows = this.props.searchRows;
                if (searchRows.length > 0 && this.state.searchRow) {
                    this.setState({
                        searchRow: searchRows,
                        updateSearch: true,
                        showSearchResults: true,
                        showTable: true,
                        isLoading: this.props.isLoading,
                        searchCount: this.props.receptionSearchResponse.length});
                }


            }
        }

    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = value => {
        this.setState({ selectedValue: value, open: false });
    };

    handleSearchClick(e) {
        e.preventDefault();
        this.setState({ isLoading: true }, () => {
            let display_name = this.state.searchRow;
            let reception_types = this.props.reception_types;
            let receptionSourcesTypes = this.props.receptionSourcesTypes;
            let circulationTypes = this.props.circulationTypes;
            let languageTypes = this.props.languages;
            let queryType = this.state.query_type;
            let current_terms = this.state.current_terms;
            let selected_person = this.state.selected_person;
            let term_list = sourcesSearchTermBuilder(selected_person, current_terms, display_name, reception_types, receptionSourcesTypes, circulationTypes, languageTypes, queryType)
            let params = {
                "query_type": queryType,
                "term": term_list
            }
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            this.setState({term_list: term_list})
            display_name.map(() => {
                fetch(config.apiUrl + '/search/reception-source/advanced?' + query)
                    .then((response) => response.json())
                    .then((responseJson) => {

                        if (responseJson.error != undefined) {
                            this.props.onChange(false, responseJson, false, query, true, false);
                            this.setState({
                                isLoading: false
                            });
                        } else {
                            this.props.onChange(true, responseJson, false, query, false, this.state.isLoading);
                            this.setState({
                                showSearchResults: true,
                                updateSearchButton: false,
                                searchCount: responseJson.total,
                                isLoading: false,
                                query
                            });
                        }





                    }).catch((error) => {
                        console.error(error);
                        this.props.onChange(false, error, false, query, true, this.state.isLoading);
                });
                return "";
            });


        })
    }



    getResultCount() {
        this.setState({ isLoadingResults: true }, () => {
            let display_name = this.state.searchRow;
            let reception_types = this.props.reception_types;
            let receptionSourcesTypes = this.props.receptionSourcesTypes;
            let circulationTypes = this.props.circulationTypes;
            let languageTypes = this.props.languages;
            let queryType = this.state.query_type;
            let current_terms = this.state.current_terms;
            let selected_person = this.state.selected_person;
            let term_list = sourcesSearchTermBuilder(selected_person, current_terms, display_name, reception_types, receptionSourcesTypes, circulationTypes, languageTypes, queryType)

            let params = {
                "query_type": queryType,
                "term": term_list
            }
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            this.setState({term_list: term_list})
            display_name.map(() => {


                fetch(config.apiUrl + '/search/reception-source/advanced?' + query)
                    .then((response) => response.json())
                    .then((responseJson) => {

                        if (responseJson.toString().startsWith("No receptions found"))
                        {
                            this.setState({
                                searchCount: 0,
                                showSearchResults: true,
                            });

                        }
                        else {
                            this.setState({
                                searchCount: responseJson.total,
                                isLoadingResults: false,
                                query
                            });
                        }


                    })
                    .catch((error) => {
                        console.error(error);
                    });
                return "";
            });


        })
    }

    handleBasicSearchClick(e) {
        e.preventDefault();
        let searchVals = {
            name: e.target.elements.item(1).value
        }

        this.setState({ isLoading: true }, () => {
            let display_name = this.state.searchRow;
            let reception_types = this.props.reception_types;
            let receptionSourcesTypes = this.props.receptionSourcesTypes;
            let circulationTypes = this.props.circulationTypes;
            let languageTypes = this.props.languages;
            let queryType = this.state.query_type;
            let selected_person = this.state.selected_person;
            let term_list = sourcesSearchTermBuilder(selected_person, display_name, reception_types, receptionSourcesTypes, circulationTypes, languageTypes, queryType)

            fetch(config.apiUrl + '/search/reception-source/advanced?' + term_list)
                .then((response) => response.json())
                .then((responseJson) => {

                    this.props.onChange(true, responseJson);
                    this.setState({
                        isLoading: false,
                        showSearchResults: true,
                        updateSearchButton: false,
                        searchCount: responseJson.total,
                        query: term_list
                    });


                }).catch((error) => {
                console.error(error);
            });
            return "";
        });
    }

    handleAddClick(e) {
        e.preventDefault();
        let people = [];
        let multiSelect = [];
        let people_detailed = this.props.people_detail;

        if( e.target.elements.item(1).value === "Reception Type") {
            multiSelect.push(this.state.searchTerm);
        } else if( e.target.elements.item(1).value === "Circulation Type") {
             multiSelect.push(this.state.searchTerm);
        } else if( e.target.elements.item(1).value === "Reception Source Language") {
            multiSelect.push(this.state.searchTerm);
        } else if( e.target.elements.item(1).value === "Reception Source Type") {
            multiSelect.push(this.state.searchTerm);
        }
        let searchVals = {}
        if(multiSelect.length > 0)
        {
            searchVals = {
                name: multiSelect,
                search_type: "In",
                search_value: e.target.elements.item(1).value,
                id_val: ""
            }
        } else {
            let id_term = people_detailed.filter(p => {
                if (p != null && e.target.elements.item(5) != null) {
                    if (p.label === e.target.elements.item(5).value) {
                        return p.id
                    }
                }
                return ''
            });
            let name = ''
            if (e.target.elements.item(5) != null) {
                name = e.target.elements.item(5).value
            }
            searchVals = {
                name: name,
                search_type: e.target.elements.item(3).value,
                search_value: e.target.elements.item(1).value,
                person: id_term
            }
        }

        this.getResultCount();
        people.push(searchVals);
        console.log(searchVals);
        
        this.setState({
            updateSearch: true,
            updateSearchButton: true,
            searchRow: this.state.searchRow.concat(people),
            selectedType: "",
            selectedFilter: "",
            searchTerm: "",
            selectedValue: '',
            searchEnabled: true,
            enable_add_button: true
        });
    }

    handleAdvancedClick() {
        this.props.onChange(true, "", true);
        this.setState({
            basicSearch: false,
            searchRow: [],
            updateSearch: false,
            selectedType: "",
            selectedFilter: "",
            userInput: "",
            showSearchResults: false,
            selectedValue: ''
        });
    }

    handleBasicClick() {
        this.props.onChange(true, "", true);
        this.setState({
            searchRow: [],
            updateSearch: false,
            selectedType: "",
            selectedFilter: "",
            userInput: "",
            showSearchResults: false,
            selectedValue: '',
            basicSearch: true
        });
    }

    handleRemoveClick(idx) {
        if (this.state.searchRow.filter((s, sidx) => idx !== sidx).length === 0){
            this.setState({
                updateSearch: false
            })
        }
        this.setState({
            searchRow: this.state.searchRow.filter((s, sidx) => idx !== sidx),
        });
        this.getResultCount()
    }

    handleResetClick() {

        this.props.onChange(true, "", true);
        this.setState({
            searchRows: [],
            searchRow: [],
            updateSearch: false,
            selectedType: "",
            selectedFilter: "",
            userInput: "",
            showSearchResults: false,
            selectedValue: '',
            current_terms: [],
            enable_add_button: true,
            searchCount: 0
        });
        this.props.history.push({search: ''});
    }

    handleTypeChange(e) {
        e.preventDefault();

    }



    handleChange = prop => event => {

        let type_value = event.target.value;
        let filter_list = ['Is Nun', 'All', 'Has Aristocratic Title', 'Male', 'Female', 'Anonymous']

        if (type_value != null) {
            if (filter_list.includes(type_value)) {
                this.setState({ searchEnabled: false, enable_add_button: false });
            } else {
                this.setState({ searchEnabled: false, enable_add_button: true });
            }

        }

        this.setState({ [prop]: event.target.value });
    };

    handleChangeFilter = prop => event => {

        let type_value = event.target.value;
        if (type_value != null) {
            this.setState({ searchEnabled: false, enable_add_button: false });
        }

        this.setState({ [prop]: event.target.value });
    };


    handleChangeReceptionType = prop => event => {
        let type_value = event;

        if (type_value != null) {
            this.setState({ searchEnabled: false, enable_add_button: false });
        }
        console.log(prop, type_value);

        this.setState({ [prop]: type_value });
    };



    handleSearchValue(name, id) {
        let person = {
            name: name,
            id: id
        }
        this.setState({ selected_person: person, searchTerm: name, enable_add_button: false });
    };



    toggleChangeOfSearchType(e) {
        this.setState({ query_type: e });
        this.getResultCount();
    }


    render() {
        const { classes } = this.props;
        const { updateSearch } = this.state;
        const { showSearchResults } = this.state;
        const { searchEnabled } = this.state;
        const { searchTerm, query } = this.state;
        const { searchCount } = this.state;
        const allPeople  = (this.props.allPeople ? this.props.allPeople : []);
        const { enable_add_button } = this.state;
        const reception_types = (this.state.reception_types ? this.state.reception_types : this.props.reception_types);

        let initial_state = "";
        let rowCount = false;
        if (this.state.searchRow.length > 1) {
            rowCount = true
        }

        return (
            <Grid item xs={8} >
                <Card>
                    <Card.Body>
                        {this.state.basicSearch  && (
                            <div className={styles.search_card_div}>

                                <Typography variant="h6" gutterBottom>
                                    Basic Search
                                </Typography>
                                <div className={styles.search_card_div}>
                                    <Form ref="form" onSubmit={this.handleBasicSearchClick}>
                                        <Form.Row >
                                            <Form.Group  as={Col} md="7" controlId="person_named">
                                                <div >

                                                    {reception_source_type.map((input) => {
                                                    initial_state = (
                                                    <TextField
                                                    select disabled={false}
                                                    variant="outlined"
                                                    label="Choose reception source type"
                                                    value={this.state.searchTerm || ''}
                                                    onChange={this.handleChange('searchTerm')}
                                                    className={styles.search_basic_textfield}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">1.</InputAdornment>,
                                                    }}>
                                                    {input.options.map(option => {
                                                            return (
                                                                    <MenuItem key={option} value={jsUcfirst(option) || ''}>
                                                                        {jsUcfirst(option)}
                                                                    </MenuItem>)
                                                        }

                                                    )}
                                                    </TextField>)
                                                        return ''
                                                })}
                                                    {initial_state}
                                                </div>
                                            </Form.Group>
                                            <Form.Group size="sm" className={styles.search_basic_submit_form} as={Col} md="2" controlId="person_submit">

                                                <ButtonGroup size="sm">
                                                    <Button size="sm" type="submit" className={styles.search_basic_submit_button}
                                                            variant="secondary">
                                                            <span>
                                                                <FaSearch/>
                                                            </span> Search</Button>
                                                </ButtonGroup>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group size="sm" className={styles.search_basic_advanced_form} as={Col} md="3" controlId="person_submit">

                                                <ButtonGroup size="sm">
                                                    <Button size="sm" onClick={this.handleAdvancedClick} type="text" variant="link" className={styles.search_basic_advanced_button}>
                                                        Advanced Search</Button>
                                                </ButtonGroup>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                </div>
                            </div>
                        )}
                        {!this.state.basicSearch  && (
                            <div className={styles.search_card_div}>
                                <Typography variant="h6" gutterBottom>
                                    Build your own search of the receptions
                                </Typography>

                                <div className={styles.search_card_div}>

                                    <Form ref="form" onSubmit={this.handleAddClick}>

                                        <Form.Row >
                                            <Form.Group as={Col} md="3" controlId="person_select">

                                                <TextField
                                                    select
                                                    variant="outlined"
                                                    label="Choose a Field"
                                                    value={this.state.selectedType}
                                                    onChange={this.handleChange('selectedType')}
                                                    className={styles.search_advanced_reference_textfield}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">1.</InputAdornment>,
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}>
                                                    {types.map(option => (
                                                        <MenuItem selected={option.default} key={option.value} value={option.value || ''}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Form.Group>
                                            <Form.Group  as={Col} md="3" controlId="person_input">
                                                <TextField
                                                    select
                                                    variant="outlined"
                                                    label="Choose Filter"
                                                    value={this.state.selectedFilter}
                                                    onChange={this.handleChange('selectedFilter')}
                                                    className={styles.search_advanced_reference_textfield}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">2.</InputAdornment>,
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}>
                                                    {filter.map(option => {
                                                            let optionTypes = option.types;
                                                            if (optionTypes.includes(this.state.selectedType)) {
                                                                return (
                                                                    <MenuItem key={option.value} value={option.value || ''}>
                                                                        {option.label}
                                                                    </MenuItem>)
                                                            }
                                                            return ''
                                                        }

                                                    )}
                                                </TextField>
                                            </Form.Group>
                                            <Form.Group  as={Col} md="4" controlId="person_named">

                                                {

                                                    person.map( () => {

                                                        let searchEnabledAutoComplete = (!searchEnabled && !this.state.selectedFilter.length > 0)
                                                        if ("Female Author" === this.state.selectedType && this.state.selectedFilter === "Named") {

                                                            initial_state = (
                                                                <div >

                                                                    <SourceSearchAutoComplete searchType="advanced" searchEnabled={searchEnabledAutoComplete} onChange={this.handleSearchValue}
                                                                                              searchTerm={searchTerm}  selectedValue={this.state.selectedValue} suggestions={allPeople}/>

                                                                    {/* <Typography variant="caption" gutterBottom>
                                                                        <a  className={styles.search_advanced_person_link} onClick={this.handleClickOpen}>
                                                                            <FaSearch/> Choose name from a list of ({allPeople.length} names)
                                                                        </a>
                                                                        <SourceSelectDialog
                                                                            people={allPeople}
                                                                            selectedValue={this.state.selectedValue}
                                                                            open={this.state.open}
                                                                            onClose={this.handleClose}
                                                                        />
                                                                    </Typography> */}

                                                                </div>)

                                                        }
                                                        return ''
                                                    })}
                                                {

                                                    receiver.map( () => {

                                                        let searchEnabledAutoComplete = (!searchEnabled && !this.state.selectedFilter.length > 0)
                                                        if ("Receiver" === this.state.selectedType && this.state.selectedFilter === "Named") {

                                                            initial_state = (
                                                                <div >

                                                                    <SourceSearchAutoComplete searchType="advanced" searchEnabled={searchEnabledAutoComplete} onChange={this.handleSearchValue}
                                                                                                 searchTerm={searchTerm}  selectedValue={this.state.selectedValue} suggestions={allPeople}/>

                                                                    {/* <Typography variant="caption" gutterBottom>
                                                                        <a  style={{textDecoration: "underline"}} onClick={this.handleClickOpen}>
                                                                            <FaSearch/> Choose name from a list of ({allPeople.length} names)
                                                                        </a>
                                                                        <SourceSelectDialog
                                                                            people={allPeople}
                                                                            selectedValue={this.state.selectedValue}
                                                                            open={this.state.open}
                                                                            onClose={this.handleClose}
                                                                        />
                                                                    </Typography> */}

                                                                </div>)
                                                        }
                                                        return ''
                                                    })}
                                                {

                                                    owner_compiler_scribe.map( () => {

                                                        let searchEnabledAutoComplete = (!searchEnabled && !this.state.selectedFilter.length > 0)
                                                        if ("Owner/Compiler/Scribe" === this.state.selectedType && this.state.selectedFilter === "Named") {

                                                            initial_state = (
                                                                <div >

                                                                    <SourceSearchAutoComplete searchType="advanced" searchEnabled={searchEnabledAutoComplete} onChange={this.handleSearchValue}
                                                                                                 searchTerm={searchTerm}  selectedValue={this.state.selectedValue} suggestions={allPeople}/>

                                                                    {/* <Typography variant="caption" gutterBottom>
                                                                        <a  className={styles.search_advanced_person_link} onClick={this.handleClickOpen}>
                                                                            <FaSearch/> Choose name from a list of ({allPeople.length} names)
                                                                        </a>
                                                                        <SourceSelectDialog
                                                                            people={allPeople}
                                                                            selectedValue={this.state.selectedValue}
                                                                            open={this.state.open}
                                                                            onClose={this.handleClose}
                                                                        />
                                                                    </Typography> */}

                                                                </div>)
                                                        }
                                                        return ''
                                                    })}
                                                {

                                                    reference.map( () => {

                                                        if ("Reference" === this.state.selectedType) {
                                                            initial_state = (
                                                                <div>
                                                                    <TextField
                                                                        disabled={searchEnabled}
                                                                        variant="outlined"
                                                                        label="Set Search Terms"
                                                                        value={this.state.searchTerm || ''}
                                                                        onChange={this.handleChangeFilter('searchTerm')}
                                                                        className={styles.search_advanced_reference_textfield}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">3.</InputAdornment>,
                                                                            classes: {
                                                                                input: classes.resize
                                                                            },
                                                                        }}>
                                                                    </TextField>
                                                                </div>)
                                                        }
                                                        return ''
                                                    })
                                                }
                                                {

                                                    source_title.map( () => {

                                                        if ("Reception Source Title" === this.state.selectedType) {
                                                            initial_state = (
                                                                <div>
                                                                    <TextField
                                                                        disabled={searchEnabled}
                                                                        variant="outlined"
                                                                        label="Set Search Terms"
                                                                        value={this.state.searchTerm || ''}
                                                                        onChange={this.handleChangeFilter('searchTerm')}
                                                                        className={styles.search_advanced_reference_textfield}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">3.</InputAdornment>,
                                                                            classes: {
                                                                                input: classes.resize
                                                                            },
                                                                        }}>
                                                                    </TextField>
                                                                </div>)
                                                        }
                                                        return ''
                                                    })
                                                }
                                                
                                                {
                                                    reception_type.map((input) => {
                                                        if ("Reception Type" === this.state.selectedType) {
                                                            initial_state = (
                                                                <Picky
                                                                    disabled={searchEnabled}
                                                                    // options={input.options}
                                                                    options={reception_types}
                                                                    value={this.state.searchTerm || []}
                                                                    multiple={true}
                                                                    onChange={this.handleChangeReceptionType('searchTerm')}
                                                                    dropdownHeight={250}
                                                                    valueKey="value"
                                                                    labelKey="label"
                                                                />);
                                                        }
                                                        return ""
                                                    })
                                                }
                                                {
                                                    circulation_type.map((input) => {
                                                        if ("Circulation Type" === this.state.selectedType) {
                                                            initial_state = (
                                                                <Picky
                                                                    options={input.options}
                                                                    value={this.state.searchTerm || []}
                                                                    multiple={true}
                                                                    onChange={this.handleChangeReceptionType('searchTerm')}
                                                                    dropdownHeight={250}
                                                                />
                                                            )
                                                        }
                                                        return ""
                                                    })
                                                }

                                                {
                                                    reception_source_language.map((input) => {
                                                        if ("Reception Source Language" === this.state.selectedType) {
                                                            initial_state = (
                                                                <Picky
                                                                    options={input.options}
                                                                    value={this.state.searchTerm || []}
                                                                    multiple={true}
                                                                    onChange={this.handleChangeReceptionType('searchTerm')}
                                                                    dropdownHeight={250}
                                                                />)
                                                        }
                                                        return ""
                                                    })
                                                }

                                                {
                                                    reception_source_type.map((input) => {
                                                        if ("Reception Source Type" === this.state.selectedType) {
                                                            initial_state = (
                                                                <Picky
                                                                    options={input.options}
                                                                    value={this.state.searchTerm || []}
                                                                    multiple={true}
                                                                    onChange={this.handleChangeReceptionType('searchTerm')}
                                                                    dropdownHeight={250}
                                                                />)

                                                        }
                                                        return ""
                                                    })
                                                }

                                                {initial_state}
                                            </Form.Group>
                                            <Form.Group size="sm" className={styles.search_basic_submit_form} as={Col} md="2" controlId="person_submit">

                                                <ButtonGroup size="sm">
                                                    <Button size="sm" type="submit" disabled={enable_add_button} className={styles.search_basic_submit_button}

                                                            variant="secondary">
                                                            <span>
                                                                <FaPlusCircle/>
                                                            </span> Add</Button>
                                                </ButtonGroup>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>
                                </div>
                            </div>
                        )}
                        {updateSearch && (
                            <Form>
                                <Card>
                                    <Card.Header>
                                        <Typography variant="h6" id="tableTitle">
                                            Selected Search Fields
                                        </Typography>
                                    </Card.Header>
                                    <Card.Body >
                                        <Card key="adv_search">
                                            <Table  className={styles.search_advanced_field_table}>
                                                <TableBody >
                                        {this.state.searchRow.map((row, idx) => {
                                            let rowValue = "";
                                            if (row.name instanceof Array) {
                                                row.name.map((rowval) => {
                                                    console.log(rowval);
                                                    if (rowval !== undefined) {
                                                        rowval.map((rw) => {
                                                            let label = (rw.label ? rw.label : rw);

                                                            if (rowValue !== "") {
                                                                rowValue = rowValue.concat(" OR " + label)
                                                            }
                                                            else {
                                                                rowValue = rowValue.concat(label)
                                                            }
                                                            return ""
                                                        })
                                                    }
                                                    return ""
                                                })
                                            } else {
                                                rowValue = row.name
                                            }
                                            return (
                                                <TableRow key={idx}>
                                                    <TableCell  >{row.search_value}</TableCell>
                                                    <TableCell  >
                                                        <Chip
                                                            label={row.search_type}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell >{rowValue}</TableCell>
                                                    <TableCell align="right">
                                                        <FaMinusCircle disabled={showSearchResults} onClick={() => this.handleRemoveClick(idx)}/>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        )}
                                            {rowCount && (
                                                    <TableRow key="Logic">
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="right">
                                                            <fieldset>
                                                                <Form.Group>
                                                                    <Form.Check inline
                                                                                type={'radio'}
                                                                                id={`and`}
                                                                                defaultChecked={true}
                                                                                label={"Match ALL terms (AND)"}
                                                                                name="formHorizontalRadios"
                                                                                onChange={() => this.toggleChangeOfSearchType("must")}

                                                                    />
                                                                    <Form.Check inline
                                                                                type={'radio'}
                                                                                id={`or`}
                                                                                label={"Match ANY terms (OR)"}
                                                                                name="formHorizontalRadios"
                                                                                onChange={() => this.toggleChangeOfSearchType("should")}
                                                                    />
                                                                </Form.Group>
                                                            </fieldset>
                                                        </TableCell>
                                                    </TableRow>)}

                                                </TableBody>
                                            </Table>
                                        </Card>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Form.Row className={styles.search_advanced_field_form}>
                                            <Form.Group  controlId="person_named">
                                                <Button style={{ backgroundColor: '#17a2b852', border: 'none', marginTop: '2px' }} variant="info"
                                                        disabled={true}>
                                                    <Typography variant="caption">
                                                        <FaInfoCircle/> Will return {searchCount} result(s).
                                                    </Typography>
                                                </Button>
                                            </Form.Group>
                                            <Form.Group controlId="person_named">
                                                <ButtonGroup>
                                                    <Button style={{backgroundColor:'#c4e0af'}} variant="secondary"
                                                            //disabled={showSearchResults}
                                                            onClick={this.handleSearchClick}>

                                                             {this.state.isLoadingResults ?
                                                                 <CircularProgress size={12} className={classes.progress} /> :
                                                                 <Typography className={styles.search_advanced_search_complete_button} variant="caption"><FaCheck/> Search</Typography>
                                                             }

                                                    </Button>
                                                </ButtonGroup>
                                            </Form.Group>
                                            <Form.Group controlId="person_input">
                                                <Button style={{backgroundColor:'white'}} variant="secondary"
                                                        onClick={() => this.handleResetClick()} >
                                                    <Typography variant="caption">
                                                        <FaTimes></FaTimes> Reset Search
                                                    </Typography>
                                                </Button>
                                            </Form.Group>
                                        </Form.Row>
                                        {this.state.isLoading &&
                                                <LinearProgress />
                                        }
                                    </Card.Footer>
                                </Card>
                            </Form>
                        )}
                    </Card.Body>
                </Card>

            </Grid>
        )
    }
}

export default withStyles(styles)(SourceSearch);

