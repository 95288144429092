import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Typography } from '@material-ui/core';
import { FaHome } from 'react-icons/fa';

// images
import img_misc_1 from '../../images/case-misc-1.png';
import img_misc_2 from '../../images/case-misc-2.png';
import img_misc_3 from '../../images/case-misc-3.png';
import img_misc_4 from '../../images/case-misc-4.png';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    },
    paper: {
        padding: '2em'
    }
})

class ManuscriptMiscellanies extends Component {

   
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper className={ classes.paper }>
                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manuscript Miscellanies, Attribution and Pseudonymous Authors</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>

                    <Typography variant="h1" className="pb-4">Manuscript Miscellanies, Attribution and Pseudonymous Authors</Typography>
                    
                    <p className="case-intro">
                        Because RECIRC is interested in early modern ideas about women’s authorship as writers, we included all attributions to women. In other words, if an early modern reader might plausibly have believed that a poem was written by a woman, we assigned it to that woman and included it. Manuscript miscellanies are particularly rich resources for attribution practices – practices that open up questions about gender identity in the period. 
                    </p>

                    <Typography variant="h2" className="pb-2">Uncertain Authorship</Typography>
                    
                    <p>
                        Where a poem is associated with a named woman, even where scholars are uncertain about her authorship, we have included it. See, for example, ‘<Link to="/works/work/9799">Death be not proud, thy hand gave not this blow</Link>’, attributed via initials to <Link to="/people/person/2530">Lucy Russell, countess of Bedford</Link>, in two manuscripts; with John Donne in seven, and ‘I. B.’ in another. 
                    </p>
                    <ul>
                        <li>
                            You can <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2530,"termLabel":"Lucy Harington Russell, Countess of Bedford"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":86,"value":"transcription","label":"Transcription"}],"termLabel":"Transcription"}]&view=table'}>search for all ten copies of this poem</Link> by selecting the ‘Female Author’ field, entering Russell, adding ‘Transcription’ to the ‘Reception Type’ field.

                             <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2530,"termLabel":"Lucy Harington Russell, Countess of Bedford"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":86,"value":"transcription","label":"Transcription"}],"termLabel":"Transcription"}]&view=table'}>
                                <img src={img_misc_1} alt="Screenshot of a Uncertain Authorship search example" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>

                    <Typography variant="h2" className="pb-2">Contested Authorship</Typography>
                    <p>
                        <Link to="/people/person/2700">Anne Vavasour</Link>, Elizabethan courtier, because of her scandalous affair with Edward Vere, earl of Oxford, is associated with two different poems: ‘<Link to="/works/work/6162">Though I seem strange sweet friend be thou not so</Link>’ and ‘<Link to="/works/work/10741">Sitting alone upon my thought in melancholy mood</Link>’. We have included these as works by Vavasour, although our sources differ in attributing them to her, to others, and copying them entirely unattributed.
                    </p>
                    <ul>
                        <li>
                            You can  <Link to={'/receptions?query_type=must&term=[{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":84,"value":"reference-to-named-work","label":"Reference to Specific Work"}],"termLabel":"Reference to Specific Work"},{"name":"author_id","label":"Female Author","filter":"equals","term":2700,"termLabel":"Anne Vavasour"}]&view=table'}>compare the attributions to these poems</Link> by searching for ‘Anne Vavasour’ in the ‘Female Author’ field; then adding ‘Though I seem strange’ and ‘Sitting alone’ separately under ‘Reference to Specific Work’. 
                             <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":84,"value":"reference-to-named-work","label":"Reference to Specific Work"}],"termLabel":"Reference to Specific Work"},{"name":"author_id","label":"Female Author","filter":"equals","term":2700,"termLabel":"Anne Vavasour"}]&view=table'}>
                                <img src={img_misc_2} alt="Screenshot of a Uncertain Authorship search example" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>

                    <Typography variant="h2" className="pb-2">Individual Miscellany View</Typography>
                    
                    <p>
                        You might want to pursue an individual miscellany – to see how many other receptions of women writers it contains; to see how many of those receptions are attributed, unattributed, or misattributed; or to gain a fuller sense of the miscellany itself. For example, both poems associated with Vavasour are compiled in three different miscellanies (although not together on consecutive leaves).
                    </p>
                    <ul>
                        <li>
                            You can click on the ‘Reception Source’ link in any reception to <Link to="/sources/source/1463">access the Reception Source Profile page</Link>.
                            <br />
                            <Link to="/sources/source/1463">
                                <img src={img_misc_3} alt="Reception Source Example" className="mt-4 mb-4" />
                            </Link>
                        </li>

                        

                        <li>
                            You can search for <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.title","label":"Reception Source Title","filter":"contains","term":"British Library, London, UK, Harley MS 7392","termLabel":"British Library, London, UK, Harley MS 7392"}]&view=table'}>all receptions we have identified in an individual source</Link> by selecting ‘Reception Source Title’ and entering the title/shelfmark. You can then click on individual receptions to investigate further. 
                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.title","label":"Reception Source Title","filter":"contains","term":"British Library, London, UK, Harley MS 7392","termLabel":"British Library, London, UK, Harley MS 7392"}]&view=table'}>
                                <img src={img_misc_4} alt="Receptions in a Source Example" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>

                    <Typography variant="h2" className="pb-2">Pseudonymous Authors</Typography>
                    
                    <p>
                        One of the transcriptions of ‘Though I seem strange’ <Link to="/receptions/reception/3800">is attributed to ‘A Lady’</Link>. RECIRC policy to include all attributions to a woman includes pseudonymous ladies and gentlewomen. However, each case is entered as a distinct author, meaning that ‘A Lady’ is not the same as ‘A Lady 2’ (3, or 4), not to mention ‘a sad lady’ and ‘A Young Lady 2’. 
                    </p>
                    <ul>
                        <li>Use this link to a pre-populated search to <Link to={'/people?query_type=must&term=[{"name":"sex","label":"Sex","filter":"in","term":["Female"],"termLabel":"Female"},{"name":"is_author","filter":"equals","term":true,"termLabel":"Is Author"},{"name":"pseudonym","filter":"equals","term":true,"termLabel":"Has Pseudonyms"}]'}>locate all pseudonymous female authors</Link>.</li>
                    </ul>


                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Manuscript Miscellanies, Attribution and Pseudonymous Authors</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>
                    
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ManuscriptMiscellanies);