import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Typography } from '@material-ui/core';
import { FaHome } from 'react-icons/fa';

// images
import img_layers_1 from '../../images/case-layers-1.png';
import img_layers_2 from '../../images/case-layers-2.png';
import img_layers_3 from '../../images/case-layers-3.png';
import img_layers_4 from '../../images/case-layers-4.png';
import img_layers_5 from '../../images/case-layers-5.png';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    },
    paper: {
        padding: '2em'
    }
})

class LayersAgents extends Component {

   
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper className={ classes.paper }>
                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Layers and Agents of Reception</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>

                    <Typography variant="h1" className="pb-4">Layers and Agents of Reception</Typography>
                    
                    <div className="case-intro">
                        <p>
                            RECIRC has categorized two kinds of agent, involved in reception: 
                        </p>
                        <ul>
                            <li><strong>owner/compiler/scribes</strong>, referring to those who owned, compiled, or transcribed the source</li>
                            <li><strong>receivers</strong>, referring to those responsible for a particular item of reception within the source.</li>
                        </ul>
                    </div>

                    
                    <Typography variant="h2" className="pb-2">Owner/Compiler/Scribe</Typography>
                    <p>
                        This is the person (or persons) who owned, compiled, or transcribed the source – be it a book catalogue, a manuscript miscellany, a chronicle history, or an individual letter. Individual letters are considered sources where they circulated as separates rather than as part of a volume. In many cases, the owner/compiler/scribe is the sole identifiable agent of reception.
                    </p>
                        
                    <ul>
                        <li>
                            To search for <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.owners","filter":"equals","term":"exists","termLabel":"All","label":"Owner/Compiler/Scribe"},{"name":"receptionsourcework.source_type","label":"Reception Source Type","filter":"in","term":[{"id":138,"value":"composite_volume","label":"Composite Volume"},{"id":139,"value":"fascicle","label":"Fascicle"},{"id":117,"value":"miscellany","label":"Miscellany"}],"termLabel":"Composite Volume OR Fascicle OR Miscellany"}]&view=table'}>all owner/compiler/scribes of manuscript miscellanies</Link>, go to the <Link to="/receptions">advanced search for receptions</Link>, select ‘Owner/Compiler/Scribe’ and the filter ‘All’. Then select ‘Reception Source Type’ and enter ‘miscellany’, ‘composite volume’ and ‘fascicle’. 
                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.owners","filter":"equals","term":"exists","termLabel":"All","label":"Owner/Compiler/Scribe"},{"name":"receptionsourcework.source_type","label":"Reception Source Type","filter":"in","term":[{"id":138,"value":"composite_volume","label":"Composite Volume"},{"id":139,"value":"fascicle","label":"Fascicle"},{"id":117,"value":"miscellany","label":"Miscellany"}],"termLabel":"Composite Volume OR Fascicle OR Miscellany"}]&view=table'}>
                                <img src={img_layers_1} alt="Screenshot of all owner/compiler/scribes of manuscript miscellanies" className="mt-4 mb-4" />
                            </Link>
                        </li>
                        <li>
                            See also <Link to={'/sources?query_type=must&term=[{"name":"source_type","label":"Reception Source Type","filter":"in","term":[{"id":139,"value":"fascicle","label":"Fascicle"},{"id":138,"value":"composite_volume","label":"Composite Volume"},{"id":117,"value":"miscellany","label":"Miscellany"}],"termLabel":"fascicle <code>or</code> composite volume,miscellany"}]&view=table'}>Reception Sources that are Miscellanies</Link>
                        </li>

                    </ul>

                    <Typography variant="h2" className="pb-2">Receiver</Typography>

                   <p>This is the person who composed or otherwise signed the specific item of reception within a source.</p>
                   <ul>
                        <li>
                            To <Link to={'/receptions?query_type=must&term=[{"name":"receivername","filter":"equals","term":"exists","termLabel":"All","label":"Receiver"}]&view=table'}>search for all receptions having receivers</Link>, go to advanced search, select ‘Receiver’ and the filter ‘All’. 
                            
                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receivername","filter":"equals","term":"exists","termLabel":"All","label":"Receiver"}]&view=table'}>
                                <img src={img_layers_2} alt="Screenshot of search for all receivers" className="mt-4 mb-4" />
                            </Link>
                        </li>
                        <li>
                            See also: <Link to={'/people?query_type=must&term=[{"name":"is_receiver","filter":"equals","term":true,"termLabel":"Is Receiver"}]'}>People who are Receivers</Link>.
                        </li>
                        <li>
                            To search for <Link to={'/receptions?query_type=must&term=[{"name":"receivername","filter":"equals","term":"exists","termLabel":"All","label":"Receiver"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":90,"value":"praise-poem","label":"Poem"}],"termLabel":"Poem"}]&view=table'}>all receivers who wrote poems about female authors</Link>, select the field ‘Receiver’ and filter ‘All’. Then select the field ‘Reception Type’: ‘Poem’.
                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receivername","filter":"equals","term":"exists","termLabel":"All","label":"Receiver"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":90,"value":"praise-poem","label":"Poem"}],"termLabel":"Poem"}]&view=table'}>
                                <img src={img_layers_3} alt="Screenshot of all receivers who wrote poems about female authors" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>    
                    
                    <Typography variant="h2" className="pb-2">Anonymous Receivers</Typography>
                    <p>
                        Where neither owner/compiler/scribe nor individual receiver can be identified, the reception is considered wholly anonymous. 
                    </p>
                    <ul>
                        <li>
                            To <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.owners","filter":"equals","term":"anonymous","label":"Owner/Compiler/Scribe","termLabel":"Anonymous"},{"label":"Receiver","name":"receiver.receiver","filter":"equals","term":"anonymous","termLabel":"Anonymous"}]&view=table'}>search for anonymous receptions</Link>, go to advanced search and select ‘Receiver’ and the filter ‘Anonymous’, then ‘Owner/Compiler/Scribe’ and the filter ‘Anonymous’. 

                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.owners","filter":"equals","term":"anonymous","label":"Owner/Compiler/Scribe","termLabel":"Anonymous"},{"label":"Receiver","name":"receiver.receiver","filter":"equals","term":"anonymous","termLabel":"Anonymous"}]&view=table'}>
                                <img src={img_layers_4} alt="Screenshot of search for anonymous receptions" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>
                    
                    <Typography variant="h2" className="pb-2">Layers of Compiler and Receiver: Thomas Dekker’s elegy on Queen Elizabeth I</Typography>
                    <p>
                        One layered case is the elegy ‘On Queen Elizabeth’s Death’ by Thomas Dekker: the poet Dekker is responsible for the poem about Elizabeth, but his poem is compiled by the manuscript owner/compiler/scribe. 
                    </p>
                    <ul>
                        <li>
                            To search for all <Link to={'/receptions?query_type=must&term=[{"name":"receiver_name","label":"Receiver","filter":"equals","term":3566,"termLabel":"Thomas Dekker"},{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"}]&view=table'}>instances of Dekker’s poem in the database</Link>, go to advanced search and select ‘Receiver’, entering ‘Dekker’; then select ‘Female Author’ and enter ‘Elizabeth Tudor’.
                        </li>
                        <li>
                            The <Link to={'/receptions?query_type=must&term=[{"name":"receiver_name","label":"Receiver","filter":"equals","term":3566,"termLabel":"Thomas Dekker"},{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"}]&view=graph'}>network visualization</Link> below shows the receiver, Dekker, in [Green]; the female author, Elizabeth, in [Purple] and each of the 11 owner/compiler/scribes in [Orange]. Each relationship is triangular: every owner/compiler/scribe is connected to Dekker and Elizabeth, and Dekker is connected to Elizabeth via 11 connections.
                             <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receiver_name","label":"Receiver","filter":"equals","term":3566,"termLabel":"Thomas Dekker"},{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"}]&view=graph'}>
                                <img src={img_layers_5} alt="Screenshot of search for anonymous receptions" className="mt-4 mb-4" />
                            </Link>
                        </li>
                        <li>
                            See also Case Study: <Link to="/cases/hartlib-circle">Hartlib Circle</Link>, where Hartlib is both receiver (where there is evidence for his intervention on the level of the individual item) and owner/compiler/scribe (where he is responsible for the compilation as a whole)
                        </li>
                    </ul>
                     
 
                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Layers and Agents of Reception</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>
                     
 
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(LayersAgents);