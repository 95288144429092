import {config} from "../../config";

export function termsBuilder(current_terms, display_name, reception_types, receptionSourcesTypes, circulationTypes, languageTypes, queryType) {
//    console.log(current_terms, display_name, reception_types, receptionSourcesTypes, circulationTypes, languageTypes, queryType);
    current_terms = [];
    display_name.map((item) => {
        let filter = item.search_type
        let label = item.search_value
        let name = item.name
        let person = item.person
        let person_id;
        let is_link = item.linked

        if (person != null ) {
            if (person.length > 0) {
                if (label === "Reference" || "Reception Source Title" || "Quotation") {
                    person_id = name                
                } else if (label === "Female Author") {
                    person_id = person[0].id;
                }  else if (label === "Receiver") {
                    person_id = person[0].id;
                } else if (label === "Owner/Compiler/Scribe") {
                    person_id = person[0].id;
                }
            } else {
                if (label === "Reference" || "Reception Source Title" || "Quotation") {
                    person_id = name               
                }
            }
        } else {
            person_id = []
            let i;
            let filterNames = []
            if (is_link) {
                filterNames = name.split(' or ');
            } else {
                if (name != undefined)
                    filterNames = name[0]
            }
            if (filterNames != undefined) {
                for (i = 0; i < filterNames.length; i++ ){
                    let filter_type = filterNames[i]
                    let id_term = []
                    if (label === 'Reception Type') {
                        id_term = reception_types.filter(p => {
                            if (p != null) {
                                if (p.value === filter_type.value) {
                                    return p.id
                                }
                            }
                            return ''
                        });                        
                    } else if (label === 'Circulation Type') {
                        id_term = circulationTypes.filter(p => {
                            let filterName = filter_type;
                            filterName.replace("(", "")
                            filterName.replace(")", "")

                            if (p != null) {
                                if (p.label.toLowerCase() === filterName) {
                                    return p.id
                                }
                            }
                            return '';

                        });
                    } else if (label === 'Reception Source Type') {
                        id_term = receptionSourcesTypes.filter(p => {
                            let filterName = filter_type;
                            filterName.replace("(", "")
                            filterName.replace(")", "")
                            if (p != null) {
                                if (p.label.toLowerCase() === filterName) {
                                    return p.id
                                }
                            }
                            return '';
                        });
                    } else if (label === 'Reception Source Language') {
                        id_term = languageTypes.filter(p => {
                            let filterName = filter_type;
                            filterName.replace("(", "")
                            filterName.replace(")", "")
                            if (p != null) {
                                if (p.label.toLowerCase() == filterName.toString().toLowerCase()) {
                                    return p.id
                                }
                            }
                            return '';
                        });
                    }

                    if (id_term.length > 0){
                        let ne = {
                            id: id_term[0].id,
                            value: (filterNames[i].value ? filterNames[i].value : filterNames[i]),
                            label: id_term[0].label,
                            $$hashkey: ""
                        }
                    
                        person_id.push(ne)
                    }
                }
            }

            if (is_link) {
                let filterNames = name.split(' or ');
//                // console.log(filterNames)
            }
            // name = name.toString().replace(",", " <code>or</code> ")
            if(Array.isArray(name)){
                name = name.toString().replace(",", " <code>or</code> ")
            }
            
            person_id = JSON.stringify(person_id);
        }



        let name_type = '';
        if (typeof name === 'string'){
            name = name.trim();
        }

        filter = filter.toLowerCase();
        if (filter === "named" ){
            filter = "equals";
        } 

        
        if (label === 'Receiver'){
            name_type = 'receiver_name';
        }else if (label === 'Reception Type'){
            name_type = 'receptiontype';
        } else if (label === 'Owner/Compiler/Scribe'){
            name_type = 'receptionsourcework.owners.id';
        }else if (label === 'Circulation Type'){
            name_type = 'circulationtype'
        }else if (label === 'Reception Source Type'){
            name_type = 'receptionsourcework.source_type';
        }else if (label === 'Reception Source Language'){
            name_type = 'receptionsourcework.languages';
        }else if (label === 'Reference'){
            name_type = 'reference';
        }else if (label === 'Quotation'){
            name_type = 'quotation_reception';
        }else if (label === 'Reception Source Title'){
            name_type = 'receptionsourcework.title';
        }else{
            name_type = 'author_id';
        }
        

        if (filter === "all") {
            if (name_type === 'receiver_name') {
                let terms = {
                    name: "receivername",
                    filter: "equals",
                    term: 'exists',
                    termLabel: "All",
                    label: "Receiver"
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework.owners.id') {
                let terms = {
                    name: "receptionsourcework.owners", 
                    filter: "equals",
                    term: "exists",
                    termLabel: "All",
                    label: "Owner/Compiler/Scribe"
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "author.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "All",
                    label: "Female Author"
                }
                current_terms.push(terms);
            }

            return ''
        }

        if (filter === "is nun") {
            if (name_type === 'receiver_name') {
                let terms = {
                    name: "receivername.is_nun",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Nun",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "receptionsourcework.owners.is_nun",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Nun",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "author.is_nun",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Nun",
                }
                current_terms.push(terms);
            }

            return ''
       }

        if (filter === "anonymous") {
            let terms;
            if (name_type === 'receiver_name') {
                terms = {
                    label: 'Receiver',
                    name: "receiver.receiver",
                    filter: "equals",
                    term: "anonymous",
                    termLabel: "Anonymous",
                };
            }else if(name_type === 'receptionsourcework'){
                terms = {
                    name: "receptionsourcework.owners",
                    filter: "equals",
                    term: "anonymous",
                    label: 'Owner/Compiler/Scribe',
                    termLabel: "Anonymous",
                };
            }
            current_terms.push(terms);

            return ''
        }


       if (filter === "has aristocratic title") {

           if (name_type === 'receiver_name') {
               let terms = {
                   name: "receivername.aristocratictitle",
                   filter: "equals",
                   term: true,
                   termLabel: "Aristoratic Title",
               }
               current_terms.push(terms);
           } else if (name_type === 'receptionsourcework') {
               let terms = {
                   name: "receptionsourcework.owners.aristocratictitle",
                   filter: "equals",
                   term: true,
                   termLabel: "Aristoratic Title",
               }
               current_terms.push(terms);
           } else {
               let terms = {
                   name: "author.aristocratictitle",
                   filter: "equals",
                   term: true,
                   termLabel: "Aristoratic Title",
               }
               current_terms.push(terms);
           }
       }

       if (filter === "is author") {

           if (name_type === 'receiver_name') {
               let terms = {
                   name: "receivername.is_author",
                   filter: "equals",
                   term: true,
                   termLabel: "Is Author",
               }
               current_terms.push(terms);
           } else if (name_type === 'receptionsourcework') {
               let terms = {
                   name: "receptionsourcework.owners.is_author",
                   filter: "equals",
                   term: true,
                   termLabel: "Is Author",
               }
               current_terms.push(terms);
           }
       }

        if (filter === "male") {
            if (name_type === 'receiver_name') {
                let terms = {
                    name: "receivername.sex",
                    filter: "in",
                    term: ["Male"],
                    termLabel: "Male",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "receptionsourcework.owners.sex",
                    filter: "in",
                    term: ["Male"],
                    termLabel: "Male",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "author.sex",
                    filter: "in",
                    term: ["Male"],
                    termLabel: "Male",
                }
                current_terms.push(terms);
            }
        }

        if (filter === "female") {
            if (name_type === 'receiver_name') {
                let terms = {
                    name: "receivername.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "Female",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "receptionsourcework.owners.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "Female",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "author.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "Female",
                }
                current_terms.push(terms);
            }
        }

       if (name_type === 'reference' ) {
           let terms = {
               name: name_type,
               label: label,
               filter: filter,
               term: person_id,
               termLabel: name
           }
           current_terms.push(terms);
           return ''
       } else if (name_type === 'quotation_reception' || name_type === 'receptionsourcework.title') {
           let terms = {
               name: name_type,
               label: label,
               filter: filter,
               term: person_id,
               termLabel: name
           }
//           console.log(terms);
           current_terms.push(terms);
           return ''
       } else if (name_type === 'languages') {
           let terms = {
               name: name_type,
               label: label,
               filter: filter,
               term: JSON.parse(person_id),
               termLabel: name
           }
           current_terms.push(terms);
           return ''
       } else {
//           console.log({ person_id, label, filter, name_type, current_terms});
           let checkedFilters = ['all', 'is nun', 'anonymous', 'has aristocratic title', 'is author', 'male', 'female'];
           if (person_id !== undefined && checkedFilters.indexOf(filter) === -1) {
               if (name_type === 'receptionsourcework') {
                   let terms = {
                       name: 'receptionsourcework.owners.id',
                       label: label,
                       filter: filter,
                       term: JSON.parse(person_id),
                       termLabel: name
                   }
                   current_terms.push(terms);
               }else if(filter === 'equals'){
                   let terms = {
                       name: name_type,
                       label: label,
                       filter: filter,
                       term: person[0].id,
                       termLabel: name
                   }
                   current_terms.push(terms);
               }else {
                   let t = JSON.parse(person_id);
                   let termLabel = person_id;
//                   console.log(t);
                   if(Array.isArray(t) && t[0] && t[0].label){
                       let termArr = [];
                        t.forEach(item => {
                            termArr.push(item.label);
                        });
                        termLabel = termArr.join(' OR ');
                   }else{
                       termLabel = name.label ? name.label : name
                   }

                   let terms = {
                       name: name_type,
                       label: label,
                       filter: filter,
                       term: JSON.parse(person_id),
                       termLabel: termLabel
                   }
                //    let terms = {
                //        name: name_type,
                //        label: label,
                //        filter: filter,
                //        term: JSON.parse(person_id),
                //        termLabel: (name.label ? name.label : name)
                //    }
//                   console.log(terms, person_id, filter, label);

                   current_terms.push(terms);
               }

           }
           return ''
       }
    })
    return JSON.stringify(current_terms);
}



export function sourcesSearchTermBuilder(selected_person, current_terms, display_name, reception_types, receptionSourcesTypes, circulationTypes, languageTypes, queryType) {
    current_terms = [];
    display_name.map((item) => {
        let filter = item.search_type;
        let label = item.search_value;
//        // console.log(item, item.name);
        // let name = item.name;
        // let name = (Array.isArray(item.name) ) ? item.name[0][0].label : item.name;
        let name;
        if (Array.isArray(item.name)){
            // name = [];
            name = item.name[0].map(n => {
//                console.log(n);
                return (n.label ? n.label : n);
            });
        }else{
            name = item.name;
        }

//        // console.log(name, item);
        let person = item.person
        let person_id;
        let is_link = item.linked



        if (person != null ) {
            if (selected_person.name != undefined) {

                if (label === "Reference" || 'Reception Source Title') {
                    person_id = name
                } else {
                    person_id = selected_person.id;
                }
            }
            if (person.length > 0) {
                if (label === "Reference" || 'Reception Source Title') {
                    person_id = name
                } else {
                    person_id = person[0].id;
                }
            } else {
                if (label === "Reference" || 'Reception Source Title') {
                    person_id = name
                }

            }
        } else {
            person_id = []
            let i;
            let filterNames = []
            if (is_link) {
                filterNames = name.split(' or ');
            } else {
                if (name !== undefined){
                    if (Array.isArray(name)){
                        filterNames = name;
                    }else{
                        filterNames.push(name)
                    }
                    
                }
            }

            
            if (filterNames !== undefined) {
//                console.log(filterNames, name);
                for (i = 0; i < filterNames.length; i++ ){
                    let filter_type = filterNames[i]

                    let id_term = []
                    if (label === 'Reception Type') {
//                        console.log(filterNames, filter_type, reception_types);
                        id_term = reception_types.filter(p => {
                            if (p !== null) {
                                if (p.label === ((filter_type.label) ? filter_type.label : filter_type ) ) {
                                    return p.id
                                }
                            }
                            return ''
                        });
                    } else if (label === 'Circulation Type') {
                        id_term = circulationTypes.filter(p => {
                            let filterName = filter_type;
                            filterName.replace("(", "")
                            filterName.replace(")", "")

                            if (p != null) {
                                if (p.label.toLowerCase() === filterName) {
                                    return p.id
                                }
                            }
                            return '';

                        });
                    } else if (label === 'Reception Source Type') {
                        id_term = receptionSourcesTypes.filter(p => {
                            let filterName = filter_type;
                            filterName.replace("(", "")
                            filterName.replace(")", "")
                            if (p != null) {
                                if (p.label.toLowerCase() === filterName) {
                                    return p.id
                                }
                            }
                            return '';
                        });
                    } else if (label === 'Reception Source Language') {
                        id_term = languageTypes.filter(p => {
                            let filterName = filter_type;
                            filterName.replace("(", "")
                            filterName.replace(")", "")
                            if (p != null) {
                                if (p.label.toLowerCase() === filterName.toString().toLowerCase()) {
                                    return p.id
                                }
                            }
                            return '';
                        });
                    }

                    if (id_term.length > 0){
                        // let terms = filterNames[i].replace(" ", "_");
                        let ne = {
                            id: id_term[0].id,
                            value: (filterNames[i].value ? filterNames[i].value : filterNames[i].replace(" ", "_")),
                            label: id_term[0].label,
                            $$hashkey: ""
                        }
                        person_id.push(ne)
                    }
                }
            }

            name = name.toString().replace(",", " <code>or</code> ")
            person_id = JSON.stringify(person_id);

        }

        let name_type = '';
        if (typeof name === 'string'){
            name = name.trim();
        }

        filter = filter.toLowerCase()
        if (filter === "named" )
            filter = "equals"


        if (label === 'Receiver')
            name_type = 'receptions.receiver_name'
        else if (label === 'Owner/Compiler/Scribe')
            name_type = 'receptionsourcework'
        else if (label === 'Reception Type')
            name_type = 'receptions.receptiontype'
        else if (label === 'Circulation Type')
            name_type = 'receptions.circulationtype'
        else if (label === 'Reception Source Type')
            name_type = 'source_type'
        else if (label === 'Reception Source Language')
            name_type = 'languages'
        else if (label === 'Reference')
            name_type = 'reference'
        else if (label === 'Reception Source Title')
            name_type = 'title';
        else
            name_type = 'receptions.author.id'

        if (filter === "all") {
            if (name_type === 'receiver_name') {
                let terms = {
                    name: "receivername.sex",
                    filter: "in",
                    term: ["Male", "Female", "Unknown"],
                    termLabel: "All",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "owners.sex",
                    filter: "in",
                    term: ["Male", "Female", "Unknown"],
                    termLabel: "Aristoratic Title",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "receptions.author.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "All",
                }
                current_terms.push(terms);
            }

            return ''
        }

        if (filter === "is nun") {
            if (name_type === 'receiver_name') {
                let terms = {
                    name: "receivername.is_nun",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Nun",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "owners.is_nun",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Nun",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "receptions.author.is_nun",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Nun",
                }
                current_terms.push(terms);
            }

            return ''
        }


        if (filter === "anonymous") {
            let terms;

            if (name_type === 'receptions.receiver_name') {
                terms = {
                    label: 'Receiver',
                    name: "receptions.receiver_name",
                    filter: "equals",
                    term: "anonymous",
                    termLabel: "Anonymous",
                };
            }else if(name_type === 'receptionsourcework'){
                terms = {
                    name: "owners",
                    filter: "equals",
                    term: "anonymous",
                    label: 'Owner/Compiler/Scribe',
                    termLabel: "Anonymous",
                };
            }
            current_terms.push(terms);
            return ''
        }


        if (filter === "has aristocratic title") {

            if (name_type === 'receiver_name') {
                let terms = {
                    name: "receivername.aristocratictitle",
                    filter: "equals",
                    term: true,
                    termLabel: "Aristoratic Title",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "owners.aristocratictitle",
                    filter: "equals",
                    term: true,
                    termLabel: "Aristoratic Title",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "receptions.author.aristocratictitle",
                    filter: "equals",
                    term: true,
                    termLabel: "Aristoratic Title",
                }
                current_terms.push(terms);
            }
        }

        if (filter === "male") {
            if (name_type === 'receptions.receiver_name') {
                let terms = {
                    name: "receivername.sex",
                    filter: "in",
                    term: ["Male"],
                    termLabel: "Male",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "owners.sex",
                    filter: "in",
                    term: ["Male"],
                    termLabel: "Male",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "receptions.author.sex",
                    filter: "in",
                    term: ["Male"],
                    termLabel: "Male",
                }
                current_terms.push(terms);
            }
        }

        if (filter === "female") {
            if (name_type === 'receptions.receiver_name') {
                let terms = {
                    name: "receivername.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "Female",
                }
                current_terms.push(terms);
            } else if (name_type === 'receptionsourcework') {
                let terms = {
                    name: "owners.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "Female",
                }
                current_terms.push(terms);
            } else {
                let terms = {
                    name: "receptions.author.sex",
                    filter: "in",
                    term: ["Female"],
                    termLabel: "Female",
                }
                current_terms.push(terms);
            }
        }

        if (name_type === 'reference' || name_type === 'title' ) {
            let terms = {
                name: name_type,
                label: label,
                filter: filter,
                term: person_id,
                termLabel: name
            }
            current_terms.push(terms);
            return ''
        } else if (name_type === 'languages') {
            let terms = {
                name: name_type,
                label: label,
                filter: filter,
                term: JSON.parse(person_id),
                termLabel: name
            }
            current_terms.push(terms);
            return ''
        } else {
            if (person_id != undefined) {
                if (name_type === 'receptionsourcework') {
                    let terms = {
                        name: 'owners.id',
                        label: label,
                        filter: filter,
                        term: JSON.parse(person_id),
                        termLabel: name
                    }
                    current_terms.push(terms);
                }
                else {
                    let terms = {
                        name: name_type,
                        label: label,
                        filter: filter,
                        term: JSON.parse(person_id),
                        termLabel: name
                    }
                    current_terms.push(terms);
//                    console.log(terms, current_terms);
                }

            }
            return ''
        }
    })
    return JSON.stringify(current_terms);
}

export function peopleSearchTermBuilder(current_terms, display_name, religiousOrder, religiousHouse, queryType) {
    current_terms = [];
        display_name.map((item) => {
            let filter = item.search_type
            let label = item.search_value
            let name = item.name
            let person = item.person
            let person_id;
            let is_link = item.linked
            let sex_values  = ['Male', 'Female', 'Unknown']

            if (person != null ) {
                if (person.length > 0) {
                    if (label === "Person") {
                        person_id = person[0].id;
                    }
                } else {
                    if (filter === "Contains") {
                        person_id = name
                    }
                }
            } else {
                person_id = []
                let i;
                let filterNames = []
                if (is_link) {
                    filterNames = name.split(' or ');
                } else {
                    if (name != undefined)
                        filterNames = name[0]
                }
                if (filterNames != undefined) {
                    for (i = 0; i < filterNames.length; i++ ){
                        let filter_type = filterNames[i]
                        let id_term = []
                        if (label === 'Religious Order') {
                            id_term = religiousOrder.filter(p => {
                                if (p != null) {
                                    if (p.label === filter_type) {
                                        return p.id
                                    }
                                }
                                return ''
                            });
                        } else if (label === 'Religious House') {
                            id_term = religiousHouse.filter(p => {
                                let filterName = filter_type;
                                filterName.replace("(", "")
                                filterName.replace(")", "")

                                if (p != null) {
                                    if (p.label === filterName) {
                                        return p.id
                                    }
                                }
                                return '';

                            });
                        } else if (label === 'Sex') {
                            id_term = sex_values.filter(p => {
                                let filterName = filter_type;
                                filterName.replace("(", "")
                                filterName.replace(")", "")
                                if (p != null) {
                                    if (p === filterName) {
                                        return p
                                    }
                                }
                                return '';
                            });
                        }

                        if (id_term.length > 0){
                            if (label != 'Sex') {
                                let ne = {
                                    id: id_term[0].id,
                                    value: filterNames[i],
                                    label: id_term[0].label,
                                    $$hashkey: ""
                                }
                                person_id.push(ne)
                            } else {
                                person_id.push(filterNames[i])
                            }

                        }
                    }
                }

                name = name.toString().replace(",", " <code>or</code> ")
                person_id = JSON.stringify(person_id);
            }



            let name_type = '';
            if (typeof name === 'string')
                name = name.trim();

            filter = filter.toLowerCase()
            if (filter === "named" )
                filter = "equals"


            if (label === 'Religious Order')
                name_type = 'religiousorder.id'
            else if (label === 'Religious House')
                name_type = 'religioushouse.id'
            else if (label === 'Sex')
                name_type = 'sex'
            else
                name_type = 'id'

            if (filter === "is nun") {
                let terms = {
                    name: "is_nun",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Nun",
                }
                current_terms.push(terms);
                return ''
            }
            if (filter === "all") {
                let terms = {
                    name: "sex",
                    filter: "in",
                    term: ["Female", "Male", "Unknown"],
                    termLabel: "All",
                }
                current_terms.push(terms);
                return ''
            }
            if (filter === "contains") {
                let terms = {
                    name: "name",
                    filter: "contains",
                    term: person_id,
                    termLabel: person_id,
                }
                current_terms.push(terms);
                return ''
            }

            if (filter === "has aristocratic title") {
                let terms = {
                    name: "aristocratictitle",
                    filter: "equals",
                    term: true,
                    termLabel: "Aristoratic Title",
                }
                current_terms.push(terms);
                return ''
            }

            if(filter === 'is author'){
                let terms = {
                    name: "is_author",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Author",
                }
                current_terms.push(terms);
                return ''
            }
            
            if(filter === 'is receiver'){
                let terms = {
                    name: "is_receiver",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Receiver",
                }
                current_terms.push(terms);
                return ''
            }

            if(filter === 'is owner / compiler / scribe'){
                let terms = {
                    name: "is_owner",
                    filter: "equals",
                    term: true,
                    termLabel: "Is Owner / Compiler / Scribe",
                }
                current_terms.push(terms);
                return ''
            }

            if (filter === 'has pseudonyms') {
                let terms = {
                    name: "pseudonym",
                    filter: "equals",
                    term: true,
                    termLabel: "Has Pseudonyms",
                }
                current_terms.push(terms);
                return ''
            }


                if (person_id !== undefined) {
                    let terms = {
                        name: name_type,
                        label: label,
                        filter: filter,
                        term: JSON.parse(person_id),
                        termLabel: name
                    }
                    current_terms.push(terms);
                }

                return ''

        })

        return JSON.stringify(current_terms);
}
