import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import CardItem from './cards/CardItem';
import Topbar from './Topbar';
import Footer from './Footer';
import SectionHeader from './typo/SectionHeader';

import AuctionCatalogues from '../components/Cases/AuctionCatalogues';
import ManuscriptMiscellanies from '../components/Cases/ManuscriptMiscellanies';
import HartlibCircle from '../components/Cases/HartlibCircle';
import LayersAgents from '../components/Cases/LayersAgents';
import ReceptionElizabeth from '../components/Cases/ReceptionElizabeth';
import Translations from '../components/Cases/Translations';



const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    }
})

class Case extends Component {


    render() {
        const { classes } = this.props;
        const currentPath = (this.props.location ? this.props.location.pathname : null);
        if(!currentPath){
            return null;
        }

        // This should be in the routes.js file...
        let caseStudy = null;
        switch (currentPath) {
            case '/cases/auction-catalogues':
                caseStudy = <AuctionCatalogues />;
                break;
            case '/cases/manuscript-miscellanies':
                caseStudy = <ManuscriptMiscellanies />;
                break;
            case '/cases/hartlib-circle':
                caseStudy = <HartlibCircle />;
                break;
            case '/cases/layers-and-agents-of-reception':
                caseStudy = <LayersAgents />;
                break;
            case '/cases/reception-of-elizabeth-i':
                caseStudy = <ReceptionElizabeth />
                break;
            case '/cases/translations':
                caseStudy = <Translations />
                break;
        
            default:
                break;
        }
        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                { caseStudy }
                            
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Case);