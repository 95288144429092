import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { config } from '../../config';


class PersonMetric extends React.Component {


    /**
     * Add the correct suffix to percentile rank
     * figures, such as 2nd / 3rd / 4th
     * @param {int} i 
     */
    nth(i){
        var j = i % 10,
        k = i % 100;
        if (j === 1 && k !== 11) {
            return  "st";
        }
        if (j === 2 && k !== 12) {
            return  "nd";
        }
        if (j === 3 && k !== 13) {
            return  "rd";
        }
        return  "th";
    }


    render() {

        const { metric, person } = this.props;
        const metricType = metric.key;
        let template = false;        
        let postLabel = false;

        if(metricType){
            template = (config.metricTemplates[metricType] ) ? config.metricTemplates[metricType] : false;        

            if(metric.key === 'author_reception_percentile_rank'){
                // preLabel = (Math.floor(metric.measure.value) >= 50) ? 'Top ' : 'Bottom '
                postLabel =   this.nth(Math.floor(metric.measure.value));
                let val = metric.measure.value;
                if(val < 25){
                    postLabel = 'Bottom 25%';
                }else if(val < 50){
                    postLabel = 'Bottom 50%';
                }else if(val < 75){
                    postLabel = 'Top 50%';
                }else if( val < 90){
                    postLabel = 'Top 25%';
                }else if( val < 95){
                    postLabel = 'Top 10%';
                }else if( val < 97){
                    postLabel = 'Top 5%';
                }else if( val < 98){
                    postLabel = 'Top 3%';
                }else if( val < 99){
                    postLabel = 'Top 2%';
                }else if( val < 100){
                    postLabel = 'Top 1%';
                }
            } 
        }

        let person_id, person_name, urls;
       
        person_id = person.id;
        person_name = person.display_name;

        urls = {
            'associated_receptions_count': `/receptions?query_type=must&term=%5B%7B%22name%22%3A%22person%22%2C%22label%22%3A%22Person%22%2C%22filter%22%3A%22equals%22%2C%22term%22%3A${person_id}%2C%22termLabel%22%3A%22 ${person_name} %22%7D%5D`,
            'connected_to_people_count': `/receptions?view=graph&query_type=must&term=%5B%7B%22name%22%3A%22person%22%2C%22label%22%3A%22Person%22%2C%22filter%22%3A%22equals%22%2C%22term%22%3A${person_id}%2C%22termLabel%22%3A%22 ${person_name} %22%7D%5D`,
            'authored_works_count': '#works'
        };
    
        let source_link;
        if (template.url === true){
            source_link = (
                <Card.Text>
                    <small >
                        <Link smooth="true" to={urls[metric.key]}>
                            {(metric.measure.value === 1) ? template.url_text_single : template.url_text_plural}
                        </Link>
                    </small>
                </Card.Text>
            );
       }


        return (

            <Card className="text-center" key={ metric.key }>
                <Card.Body>
                    <Card.Title>{ template.title }</Card.Title>
                    <Card.Text style={{ fontSize: '1.75em', color: '#222 important', fontFamily: '"Crimson Text", "Georgia", "serif"'}}>
                        
                        { !postLabel && Math.floor(metric.measure.value) }
                        { postLabel && <span>{postLabel}</span> }
                    </Card.Text>
                    <Card.Text>
                        <small>{(metric.measure.value === 1) ? template.tagline_single : template.tagline_plural}</small>
                    </Card.Text>
                    { source_link }                    
                </Card.Body>
            </Card>
        )
    }
}

export default withRouter(PersonMetric)