import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import { FaChevronCircleRight } from 'react-icons/fa'
import { Card, Form, Table} from 'react-bootstrap'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import EnhancedTableHead from "../common/EnhancedTableHead";
import ResultsViewExport from "../common/ResultsViewExport";

const styles = theme => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey['100']}`,
        backgroundColor: 'white',

    },
    inline: {

        display: 'inline'

    },
    flex: {
        display: 'inline',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    productLogo: {
        display: 'inline-block',
        borderLeft: `1px solid ${theme.palette.grey['A100']}`,
        marginLeft: 32,
        paddingLeft: 24
    },
    tagline: {
        display: 'inline-block',
        marginLeft: 10
    },
    iconContainer: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 6,
    },
    iconButton: {
        display: 'inline',
        float: 'right',
        justifyContent: 'center'
    },
    logoButton: {
        float: 'centre'
    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    tabItem: {
        paddingTop: 20,
        paddingBottom: 20,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    logoItem: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center'
    }
})




class WorksResultsTable extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            searchResponse: [],
            order: 'desc',
            orderBy: 'reception_count'
        };
    }


    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';

        this.setState({
            order: (isDesc ? 'asc' : 'desc'),
            orderBy: property
        });
    };

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    /**
     * Transform search result response into object
     * that can both be sorted, and contains React components (such
     * as Links) for display purposes
     */
    createData = (data) => {
        
        return data.map(work => {
            
            let author_text = '';
            let author = work.person.map((person, idx, arr) => {
                author_text += person.display_name + ' ';
                let sep = '';
                if (arr.length > 1 && idx < arr.length - 1) {
                    sep = ', ';
                }

                return (
                    <span key={person.id}>
                        <Link to={`/people/person/${person.id}`}>
                            {person.display_name}
                        </Link>{sep}
                    </span>);
            });
                
           
            let title = (
                <Link to={`/works/work/${work.id}`}>{work.title}</Link>
            );
            
            
            return {
                'id': work.id,
                'title_text': work.title,
                'title': title,
                'author': author,
                'author_text': author_text,
                'reception_count': work.mini_reception.length
            }
        });
    };



    render() {

        const { searchResponse, query } = this.props;
        const { isLoading } = this.state;

        if (isLoading) {
            return (
                <span>"Loading...."</span>
            );
        }

        const headCells = [
            { id: 'title_text', numeric: false, disablePadding: true, label: 'Title' },
            { id: 'author_text', numeric: false, disablePadding: true, label: 'Author(s)' },
            { id: 'reception_count', numeric: true, disablePadding: true, label: 'Number of Receptions' },
        ];

        let contents = (<tr></tr>);
        //console.log(searchResponse)

        if (searchResponse.data && searchResponse.data.length > 0) {
            
            let rows = this.createData(searchResponse.data);
            contents = this.stableSort(rows, this.getSorting(this.state.order, this.state.orderBy))
                            .map((work) => {

                    return (
                        <tr key={work.id}>                            
                            <td>{work.title}</td>
                            <td>{work.author}</td>
                            <td>{work.reception_count}</td>                            
                        </tr>
                    );
                });
        }

        return (

                <Grid item xs={12} >
                    <Card style={{ }}>
                            <Card.Header >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div >
                                        <Typography variant="caption"><strong>Found {searchResponse.total} results</strong> </Typography>
                                    </div>
                                   
                                    <ResultsViewExport
                                        query={query}
                                        type={'works'}
                                    />
                                    
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Card style={{ padding: '10' }}>
                                    <Table striped bordered size={'sm'} responsive>
                                        <EnhancedTableHead
                                            headCells={headCells}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onRequestSort={this.handleRequestSort}
                                        />                                         
                                        <tbody className={styles.table_results_font_size}>
                                            {contents}
                                        </tbody>
                                    </Table>
                                </Card>

                            </Card.Body>
                        </Card>

                </Grid>


        )
    }
}

export default withRouter(withStyles(styles)(WorksResultsTable))