import React from 'react';
import { Link } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import VirtualReference from './VirtualReference';
import { FaTimes } from "react-icons/fa";


/**
 * @uses https://material-ui.com/components/popper/
 * 
 */
 class ReceptionEdgePopover extends React.Component {

     constructor(props, context) {
         super(props, context);

         this.state = {
             open: false,
             event: null,
             anchorEl: null,
             reception: null
         };

        this.handleClickClosePopover = this.handleClickClosePopover.bind(this);
     }

    handleClickClosePopover(e) {

        this.setState({
            open: false
        });
    }

    managePopup()
    {
        let { event, show, reception  } = this.props;
        
        // console.log(event, show, reception);

        // create the "fake" element, using
        // location/size data from the node click event
        let virtualReferenceElement = new VirtualReference(
            event.data.captor.clientX, 
            event.data.captor.clientY, 
            5
        );

        const getBoundingClientRect = () => virtualReferenceElement.getBoundingClientRect();

        this.setState({
            open: show,
            anchorEl: {
                clientWidth: getBoundingClientRect().width,
                clientHeight: getBoundingClientRect().height,
                getBoundingClientRect
            },
            reception: reception,
            event: event
        });
    }

    

    componentDidMount()
    {
        if (this.props.event !== null) {
            this.managePopup();
        }
    }

    componentDidUpdate(prevProps)
    {
        // if we have a reception, and it's not the 
        // same one as last time...
        if ((!prevProps.reception || (this.props.reception.reception_id !== prevProps.reception.reception_id)) && this.props.event !== null) {
            this.managePopup();
        }
    }

    render(){
        let { reception } = this.props;
        let { open } = this.state;
    
        // if (open === false) {
        //     return null;
        // }  

        const id = open ? 'faked-reference-popper' : undefined;
        let reception_types = [];
        console.log(reception);
        if(reception && reception.reception_type.length > 0 ){
            let sep = '';
            reception.reception_type.forEach((type, i, all) => {
                if(all.length > 1){
                    sep = ', ';
                }

                if(i === (all.length - 1)){
                    sep = '';
                }

                reception_types.push(
                    <span key={`rec-type-edge-` + type.id}>
                        { type.label + sep} 
                    </span>
                );
            });
        }

        return (
        
                <Popper 
                    id={id} 
                    className="popover edge-popover"
                    open={this.state.open} 
                    anchorEl={this.state.anchorEl} 
                    transition 
                    placement="bottom-start"
                    // disablePortal={true}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'undefined',
                        }
                        // },
                        // arrow: {
                        //     enabled: true,
                        //     element: arrowRef,
                        // }
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                            
                                <Typography variant="h6" className="popover-header">
                                    <button onClick={e => this.handleClickClosePopover(e)} className="float-right btn btn-xs">
                                        <FaTimes />
                                        <span className="sr-only">Close</span>
                                    </button>

                                    {reception.receptionsourcework}
                                </Typography>
                                    <Typography variant="body1" className="popover-body">
                                        <small>
                                            { reception_types && 
                                                <span>
                                                    <strong>Reception Types: </strong>
                                                    { reception_types }
                                                    <br/>
                                                </span>
                                            }
                                            <Link to={`/receptions/reception/` + reception.reception_id} style={{display: 'block', 'borderTop': '1px solid #dbdbdb', 'paddingTop': '5px', 'marginTop': '5px'}}>
                                                View Reception
                                            </Link>
                                        </small>
                                    </Typography>                            
                            </Paper>
                        </Fade>
                    )}
                </Popper>
        
        );
    }
 }

export default ReceptionEdgePopover;