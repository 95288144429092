const Menu = [
    {
        label: "Home",
        pathname: "/"
    },
    {
        label: "Search the Database",
        pathname: "/explore"
    },
    {
        label: "Case Studies",
        pathname: "/cases"
    },
    {
        label: "About",
        pathname: "/about"
    },
    {
        label: "Contact",
        pathname: "/contact"
    },
    {
        label: "Team Members",
        pathname: "/team"
    },
    {
        label: "Publications",
        pathname: "/presentations-and-publications"
    },
    {
        label: "Blog",
        pathname: "/blog"
    },
    {
        label: "Conference",
        pathname: "/conference2017"
    }

];

export default Menu;