import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
    loadingMessage: {
        position: 'absolute',
        top: '40%',
        left: '40%'
    }
});

function Loading(props) {
    const { classes, loading } = props;
    return (

            <div style={loading ? { display: 'block' } : { display: 'none' }} className={classes.loadingMessage}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
    );
}

export default withStyles(styles)(Loading);