import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/ChevronRight';

import Typography from '@material-ui/core/Typography';


const styles = theme => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey['100']}`,
        backgroundColor: 'white',

    },
    inline: {

        display: 'inline'

    },
    flex: {
        display: 'inline',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    productLogo: {
        display: 'inline-block',
        borderLeft: `1px solid ${theme.palette.grey['A100']}`,
        marginLeft: 32,
        paddingLeft: 24
    },
    tagline: {
        display: 'inline-block',
        marginLeft: 10
    },
    iconContainer: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 6,
    },
    iconButton: {
        display: 'inline',
        float: 'right',
        justifyContent: 'center'
    },
    logoButton: {
        float: 'centre'
    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    tabItem: {
        paddingTop: 20,
        paddingBottom: 20,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    logoItem: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center'
    }
})

class PubList extends Component {

    state = {
        value: 0,
        menuDrawer: false
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    mobileMenuOpen = (event) => {
        this.setState({ menuDrawer: true });
    }

    mobileMenuClose = (event) => {
        this.setState({ menuDrawer: false });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    current = () => {
        if(this.props.currentPath === '/home') {
            return 0
        }
        if(this.props.currentPath === '/dashboard') {
            return 1
        }
        if(this.props.currentPath === '/signup') {
            return 2
        }
        if(this.props.currentPath === '/wizard') {
            return 3
        }
        if(this.props.currentPath === '/cards') {
            return 4
        }

    }

    render() {

        const { classes } = this.props;

        return (
            <List component="nav" dense className={classes.root}>
                <ListItem  divider>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={<Typography type="body2"><strong>Sajed Chowdhury</strong>, '<a href="https://doi.org/10.1080/0268117X.2019.1596832">The poetics of "making" in the manuscript writings of Constance Aston Fowler</a>', <em>The Seventeenth Century</em> (2019): https://doi.org/10.1080/0268117X.2019.1596832</Typography>} />
                </ListItem>
                <ListItem  divider>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={<Typography type="body2"><strong>Emilie K. M. Murphy</strong>, 'Language and Power in an English Convent in Exile, c.1621 - c.1631', <em>The Historical Journal</em> 61:1 (2019): 101-25</Typography>} />
                </ListItem>
                <ListItem  divider>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={<Typography type="body2"><strong>Marie-Louise Coolahan and Mark Empey</strong>, 'Women's Book Ownership and the Reception of Early Modern Women's Texts, 1545-1700', in Leah Knight, Micheline White and Elizabeth Sauer (eds), <em>Women's Bookscapes in Early Modern Britain: Reading, Ownership, Circulation</em> (Ann Arbor: University of Michigan Press, 2018), pp. 231-52</Typography>} />
                </ListItem>
                <ListItem  divider>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={<Typography type="body2"><strong>Bronagh McShane</strong>, 'Visualising the Reception and Circulation of Early Modern Nuns' Letters', <em>The Journal of Historical Network Research</em> 2:1 (2018): 1-25</Typography>} />
                </ListItem>
                <ListItem  divider>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={<Typography type="body2"><strong>Erin A. McCarthy</strong>, 'Reading Women Reading Donne in Early Modern Manuscript Miscellanies: A Quantitative Approach', <em>Review of English Studies</em> 69 (2018): 661–85</Typography>} />
                </ListItem>
                <ListItem  divider>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={<Typography type="body2"><strong>Marie-Louise Coolahan</strong>, 'Nuns' Writing: Translation, Textual Mobility and Transnational Networks', in Patricia Phillippy (ed), <em>A History of Early Modern Women's Writing</em> (Cambridge: Cambridge University Press, 2018), pp 257-75</Typography>} />
                </ListItem>
                <ListItem  divider>
                    <ListItemIcon>
                        <ListIcon />
                    </ListItemIcon>
                    <ListItemText inset primary={<Typography type="body2"><strong>Felicity Maxwell</strong>, 'Calling for Collaboration: Women and Public Service in Dorothy Moore's Transnational Protestant Correspondence', <em>Literature Compass: The Seventeenth Century</em>, 14.4 (2017)</Typography>} />
                </ListItem>
                
                <ListItem  divider>
                    <ListItemText inset primary={<div><Typography type="body2"><a href={'http://recirc.nuigalway.ie/presentations-and-publications/'}>View More Publications</a></Typography></div>} />
                </ListItem>
            </List>
        )
    }
}

export default withRouter(withStyles(styles)(PubList))

