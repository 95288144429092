import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Card} from 'react-bootstrap'
import Table from '@material-ui/core/Table';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import EnhancedTableHead from "../common/EnhancedTableHead";
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowRight';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import styles from '../../style.module.css';
import withStyles from '@material-ui/core/styles/withStyles';
import ResultsViewExport from "../common/ResultsViewExport";

class TablePaginationActions extends React.Component {
    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(event, Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1));
    };



    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return <div className={classes.root}>
            <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton onClick={this.handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Next Page">
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton onClick={this.handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="Last Page">
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>;
    }
}

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired
};

const actionsStyles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing.unit * 2.5
    }
});

const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(TablePaginationActions);


class PeopleResultsTable extends React.Component {


    constructor(props, context) {
        super(props, context);

        this.hideDetails = this.hideDetails.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.state = {
            isLoading: false,
            peopleSearchResponse: [],
            author: [],
            peopleList: [],
            receivers: [],
            showDetails: false,
            work: '',
            page: 0,
            rows: this.props.receptionSearchResponse,
            rowsPerPage: 50,
            order: 'asc',
            orderBy: 'name_text',
            query: null

        };
    }



    componentDidMount() {
    }

    componentDidUpdate(prevProps) {

        if (this.props.receptionSearchResponse !== prevProps.receptionSearchResponse) {
        }
    }


    showDetails(show, work) {

        this.setState({
            showDetails: show,
            work: work
        })

    }

    hideDetails() {
        this.setState({
            showDetails: false,
            work: ""
        })

    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleRowsPerPage = (event, rowsPerPage) => {
        let rows = parseInt(rowsPerPage.key)
        this.setState({ rowsPerPage: rows });
    };


    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';

        this.setState({
            order: (isDesc ? 'asc' : 'desc'),
            orderBy: property
        });
    };

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    /**
     * Transform search result response into object
     * that can both be sorted, and contains React components (such
     * as Links) for display purposes
     */
    createData = (data) => {
        return data.map(element => {
            let src = element._source;
            let religious_house = ''
            let religious_order = ''
            if (src.religioushouse !== undefined) {
                // religious_house = src.religioushouse[0].label
                let sep = '';
                src.religioushouse.forEach((r, idx, all) => {
                    if (all.length > 1) {
                        sep = '; '
                    }
                    if ((all.length - 1) === idx) {
                        sep = '';
                    }
                    religious_house += r.label + sep
                })
            }
            if (src.religiousorder !== undefined) {
                let sep = '';
                src.religiousorder.forEach((r, idx, all) => {
                    if(all.length > 1){
                        sep = '; '
                    }
                    if((all.length - 1) === idx ){
                        sep = '';
                    }
                    religious_order += r.label + sep
                });
            }

            let name_text = src.display_name;
            let name = (
                <Link to={`/people/person/${src.id}`}>
                    {src.display_name}
                </Link>
            );
            return {
                'id': src.id,
                'name_text': name_text,
                'name': name,
                'dob': (src.date_of_birth) ? src.date_of_birth : '',
                'dod': (src.date_of_death) ? src.date_of_death : '',
                'sex': src.sex,
                'religious_order': religious_order,
                'religious_house': religious_house,
            }
        });
    };

    render() {

        const { receptionSearchResponse, query } = this.props;
        const { isLoading } = this.props;

        const { rowsPerPage, page } = this.state;
        let searchResponseLength = 0;

        // ID here matches the keys for data sorting set
        // in this.createData()
        const headCells = [
            { id: 'name_text', numeric: false, disablePadding: true, label: 'Person Name' },
            { id: 'dob', numeric: false, disablePadding: true, label: 'Date of Birth' },
            { id: 'dod', numeric: false, disablePadding: true, label: 'Date of Death' },
            { id: 'sex', numeric: false, disablePadding: true, label: 'Sex' },
            { id: 'religious_order', numeric: false, disablePadding: true, label: 'Religious Order' },
            { id: 'religious_house', numeric: false, disablePadding: true, label: 'Religious House' },
        ];

        if (isLoading) {
            return (
                <span>"Loading...."</span>

            );
        }

        let contents = (<tr></tr>);
        if (receptionSearchResponse.data.length >= 1) {
            
            searchResponseLength = receptionSearchResponse.data.length;
            let rows = this.createData(receptionSearchResponse.data);
            contents = this.stableSort(rows, this.getSorting(this.state.order, this.state.orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((data) => {
                   
                                return (

                                    <TableRow key={`pr-row-` + data.id}>
                                        <TableCell>{data.name}</TableCell>
                                        <TableCell>{data.dob}</TableCell>
                                        <TableCell>{data.dod}</TableCell>
                                        <TableCell>{data.sex}</TableCell>
                                        <TableCell>{data.religious_order}</TableCell>
                                        <TableCell>{data.religious_house}</TableCell>
                                    </TableRow>
                                );

                            });
        }

        return (

            <Grid item xs={12} >
                <Card>
                    <Card.Header >
                        <div className={styles.table_results_header}>
                            <div >
                                <Typography variant="caption"><strong>Found {receptionSearchResponse.data.length} results {/*| View results as Graph */}</strong> </Typography>
                            </div>

                            <ResultsViewExport
                                query={query}
                                type="person"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body className={styles.table_results_card_body}>
                        <Card className={styles.table_results_card_table}>
                            <Table id={"receptions"} >
                                <EnhancedTableHead
                                    headCells={headCells}
                                    order={this.state.order}
                                    orderBy={this.state.orderBy}
                                    onRequestSort={this.handleRequestSort}
                                />

                                <TableBody className={styles.table_results_font_size}>
                                    {contents}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            colSpan={3}
                                            count={searchResponseLength}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                native: false
                                            }}
                                            onChangeRowsPerPage={this.handleRowsPerPage}
                                            onChangePage={this.handleChangePage}
                                            labelRowsPerPage={"Rows to View"}
                                            ActionsComponent={TablePaginationActionsWrapped} />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </Card>

                    </Card.Body>
                </Card>

            </Grid>


        )
    }
}

export default withRouter(PeopleResultsTable)