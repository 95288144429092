import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
   
})

class Nav extends Component {

    render() {
        const { classes } = this.props;
        // const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <Paper className="pt-4 pb-4">
                    
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <Link to="/about-data/" className="nav-link">Project Overview</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-data/data" className="nav-link">Data Provenance, Structure &amp; Definitions</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-data/data-use" className="nav-link">Data Use &amp; Citation</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-data/women-writers-catalogue" className="nav-link">Catalogue of Female Authors and Their Works</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-data/searching-data" className="nav-link">How to Search the Data</Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/about-data/technical-overview" className="nav-link">Technical Overview</Link>
                        </li>
                    </ul>
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Nav);