import React  from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import {Button} from 'react-bootstrap';
import { FaChevronRight, FaProjectDiagram } from 'react-icons/fa';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import Topbar from '../Topbar';
import ReceptionDetails from './ReceptionDetails';
import ReceptionImage from './ReceptionImage';
import styles_css from '../../style.module.css';
import Footer from '../Footer';
import { config } from '../../config';




const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['200'],
        overflow: 'hidden',
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    paper: {
        padding: theme.spacing.unit * 3,

        color: theme.palette.text.secondary
    },
    box: {
        marginBottom: 280,
        height: 45
    },
    paperSearch: {
        padding: theme.spacing.unit * 3,
        textAlign: 'center',
        justify: "center",
        color: theme.palette.text.primary
    },
    grid: {
        maxWidth: 1000,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    },
    gridMain: {
        maxWidth: 1300,
        paddingBottom: 30
    },

    boxSearch: {
        marginBottom: 10,
        padding: theme.spacing.unit * 3,
        borderTop: `1px solid #dbdbdb`,
        borderBottom: `1px solid #dbdbdb`,
        borderLeft: `1px solid #dbdbdb`,
        borderRight: `1px solid #dbdbdb`,
        height: 200,

    },
    gridRight: {
        padding: 20,
        maxWidth: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    inlining: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10
    },
    inliningBlock: {
        display: 'block',
        marginRight: 10,
        marginLeft: 25
    },
    inliningRight: {
        display: 'inline',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 10,
        marginLeft: 35
    },

});


const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);

class Reception extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
            showDetails: false,
            work: [],
            reception: null,
            source_of_reception: [],
            reception_types: [],
            circulation_types: [],
            open: false
        };
    }


    search(receptionId) {

        return fetch( config.apiUrl + `/reception/${receptionId}`)
            .then((response) => response.json())
            .then((responseJson) => {
                
                // console.log(responseJson)
                this.setState({
                    reception: responseJson
                });

            })
            .catch((error) =>{
                this.setState({
                    isLoading: false
                });

                console.error(error);
            }).then((response => {
                fetch( config.apiUrl + '/config')
                    .then((response) => response.json())
                    .then((responseJson) => {

                        this.setState({
                            isLoading: false,
                            reception_types: responseJson["receptionTypes"],
                            circulation_types: responseJson["circulationTypes"],
                            reception_sources: responseJson['receptionSources']
                        });

                    })
                    .catch((error) =>{
                        console.error(error);
                        this.setState({
                            isLoading: false
                        });
                    });
            }));

    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    componentDidMount() {

        this.setState({
            isLoading: true
        });

        this.search(this.props.match.params.receptionId)

    }


    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname;

        const { reception } = this.state;
        const { reception_types } = this.state;
        const { circulation_types } = this.state;
        const { reception_sources } = this.state;

        let { isLoading } = this.state;
        
        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />

                    <div className={classes.root}>
                        <Grid container spacing={24} justify="center">
                            <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                                <Grid container>
                                    <Grid item>
                                        <Typography variant="h1">
                                            Reception Details 
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container >
                                    <Grid item xs={12} md={6} className={classes.grid}>

                                    <FaProjectDiagram />
                                        <Typography className={styles_css.reception_typography} variant="h6">
                                            <Link to={`/receptions`}>Receptions</Link>

                                        </Typography>
                                        <FaChevronRight />
                                        <Typography className={styles_css.reception_typography_works} variant="h6">
                                            Reception Details
                                            {reception &&
                                                <span className="text-muted" style={{ paddingLeft: '0.5em' }}>
                                                    <small>ID #{reception.id}</small>
                                                </span>
                                            }
                                        </Typography>


                                    </Grid>
                                    <Grid item xs={12} md={6} className={classes.gridRight}>
                                        <ul className="nav justify-content-center">
                                            <li className="nav-item">                                        
                                                <Link smooth to={`#reception-details`} className="nav-link">
                                                    Reception Attributes
                                                </Link>
                                            </li>    

                                            { (reception && reception.quotation_reception) && 
                                                <li className="nav-item">                                        
                                                    <Link smooth to={`#transcription`} className="nav-link">
                                                        Image &amp; Transcription
                                                    </Link>
                                                </li>
                                            }

                                            <li className="nav-item">
                                                <Button onClick={this.handleClickOpen} className={styles_css.citation_button} size="sm" variant="secondary">
                                                    Citing this Data
                                                </Button>
                                            </li>
                                        </ul>

                                            <Dialog
                                                onClose={this.handleClose}
                                                aria-labelledby="customized-dialog-title"
                                                open={this.state.open}
                                            >
                                                <DialogTitle onClose={this.handleClose}>
                                                    Citing this data
                                                    </DialogTitle>
                                                <DialogContent>
                                                    <Typography gutterBottom>
                                                        Data is published under a <a href="https://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-Non Commercial 4.0 International License (CC-BY-NC 4.0)</a>.
                                                            Further information on how to use and cite this data is available from the <Link to="/about-data/data-use">About the Data</Link> page.
                                                        </Typography>
                                                    <Typography gutterBottom>
                                                        To cite this data in your work, please use the following:
                                                        </Typography>
                                                    <Typography style={{ padding: '1em 2em', backgroundColor: '#e8edf4', color: 'black' }} gutterBottom>
                                                        RECIRC: The Reception &amp; Circulation of Early-Modern Women's Writing, 1550-1700,
                                                            URL: {window.location.href}, Accessed: <em>&lt;Today's Date&gt;</em>
                                                    </Typography>
                                                </DialogContent>
                                            </Dialog>
                                    </Grid>

                                </Grid>

                            </Grid>
                            <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                                
                                    <Grid container spacing={24}>
                                        <Grid item xs={12} >
                                            
                                            {(isLoading === false) &&
                                                <ReceptionDetails circulation_types={circulation_types} reception_types={reception_types} reception={reception} reception_sources={reception_sources}/>
                                            }
                                        </Grid>
                                        <Grid item xs={12} >
                                            {(isLoading === false) &&
                                                <ReceptionImage reception={reception} />
                                            }
                                        </Grid>


                                    </Grid>

                            
                            </Grid>

                        </Grid>
                    </div>
                

                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Reception);