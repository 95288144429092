import React  from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {ButtonGroup, Button, Card} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {Col} from 'react-bootstrap';
import { TextField}  from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import styles from '../../style.module.css';
import { config } from '../../config';
const qs = require('query-string');


class WorksSearch extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        // this.handleSearchValue = this.handleSearchValue.bind(this);

        this.state = {
            isLoading: false,
            showSearchResults: false,
            updateSearchButton: false,
            searchResponse: [],
            updateSearch: false,
            searchRow: [],
            selectedType: "",
            userInput: "",
            selectedFilter: "",
            searchEnabled: true,
            open: false,
            selectedValue: "",
            searchCount: 0,
            searchId: 0

        };
    }



    componentDidMount() {
        console.log(this.props);

        fetch( config.apiUrl + '/config')
            .then((response) => response.json())
            .then((responseJson) => {
            this.setState({
                reception_types: responseJson['receptionTypes'],
                receptionSourcesTypes: responseJson["receptionSources"],
                languages: responseJson['languages'],
                circulationtypes: responseJson['circulationTypes'],
            });
        });


        if (this.props.location){
            let term = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term
            if(term){
                this.doTitleSearch(term);
            }
        }
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.location.search !== prevProps.location.search) {

            let term = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term

            if (term) {
                this.doTitleSearch(term);
            }
        }
    }

    doTitleSearch(term)
    {
        this.setState({ isLoading: true }, () => {

            fetch( config.apiUrl + '/work/search-title?term=' + term)
                .then((response) => response.json())
                .then((responseJson) => {
                    // console.log(responseJson)

                    
                    this.setState({
                        isLoading: false,
                        showSearchResults: true,
                        updateSearchButton: false,
                        searchCount: responseJson.total,
                        searchTerm: term,
                        query: term
                    });

                    this.props.onChange(true, responseJson, term);


                }).catch((error) => {
                    console.error(error);
                    this.props.onChange(true, "", term, false, true);
                });


            this.props.history.push({
                pathname: '/works',
                search: '?term=' + term 
            });
            
        });
    }


    handleChange(e){        
        // return pressed...
        if (e.keyCode === 13) {            
            this.doTitleSearch( e.target.value );
        }    
    }

    handleSearchClick(e) {

        e.preventDefault();
        this.doTitleSearch( e.target.elements.item(1).value );
        
    }


    render() {
       
        const { searchEnabled } = this.props;
        let { searchTerm } = this.state;


        return (


            <Grid item xs={8} >
                <Card>
                    <Card.Body>
                        <div className={styles.search_card_div}>
                            <Typography variant="h6">
                                Search the Works Catalogue
                            </Typography>
                            

                            <div className={styles.search_card_div}>
                                <Form ref="form" onSubmit={this.handleSearchClick}>

                                    <Form.Row >

                                        <Form.Group  as={Col} md="10" controlId="person_named">
                                            <div >

                                                 <TextField 
                                                    variant="outlined"
                                                    disabled={searchEnabled}
                                                    label={ (searchTerm) ? null :  "Enter Work Title" }
                                                    size="md"
                                                    type="text"
                                                    autoComplete="off"
                                                    style={{minWidth: '90%'}}
                                                />

                                            </div>
                                        </Form.Group>
                                        <Form.Group size="sm" className={styles.search_basic_submit_form} as={Col} md="2" controlId="person_submit">

                                            <ButtonGroup size="sm">
                                                <Button size="sm" type="submit" className={styles.search_basic_submit_button}

                                                        variant="secondary">
                                                            <span>
                                                                <FaSearch/>
                                                            </span> Search</Button>
                                            </ButtonGroup>
                                        </Form.Group>

                                    </Form.Row>
                                </Form>
                            </div>
                        </div>


                    </Card.Body>
                </Card>

            </Grid>
        )
    }
}

export default (WorksSearch);

