import React  from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import PeopleResultsTable from './PeopleResultsTable';

import PeopleSearch from './PeopleSearch';
import InterestingSearches from '../common/InterestingSearches';

import Footer from '../Footer';
import {Alert, Card} from 'react-bootstrap';
import { FaUsers, FaCogs, FaInfoCircle} from 'react-icons/fa';
import styles_css from '../../style.module.css';

import { config } from '../../config';


const qs = require('query-string');
const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    },
    gridMain: {
        width: 1300,
        paddingBottom: 30
    },
    gridRight: {
        padding: 20,
        width: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    inlining: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10
    },
    inliningBlock: {
        display: 'block',
        marginRight: 10,
        marginLeft: 25
    },
    inliningRight: {
        display: 'inline',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 10,
        marginLeft: 35
    },

})





class People extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.handleChange = this.handleChange.bind(this);
        this.handleRefine = this.handleRefine.bind(this);

        this.state = {
            isLoading: false,
            isLoadingConfig: true,
            showSearchResults: false,
            showNoResults: false,
            reception_types: '',
            receptionSources: '',
            receptionSourcesTypes: [],
            circulationtypes: [],
            languages: '',
            receptionSearchResponse: [],
            search_list: [],
            peopleSearchResponse: [],
            peopleList: [],
            receivers: [],
            person:[],
            allPeople:[],
            people_detail: [],
            updateRefineSearch: false,
            originalReceptions: [],
            search_terms: '',
            searchRows: [],
            error_message: '',
            basic_search: true
        };
    }

    componentDidMount() {
        if (this.props.history.action === "POP") {
            console.log("Back button pressed")
        }

        let queryType = ''
        let term_list = ''

        queryType = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).query_type
        term_list = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term

        if (queryType !== undefined && term_list !== undefined) {
            
            this.setState({
                basic_search: false
            });

            let params = {
                "query_type": queryType,
                "term": term_list
            }
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            this.setState({term_list: term_list})
            let term_json = JSON.parse(term_list);
            let searchRow = []
            term_json.forEach(row => {
                
                let labels = row.termLabel;
                labels = labels.replace(/<\/?[^>]+(>|$)/g, "");
                let item = {
                    "name": labels,
                    "search_type": row.filter,
                    "search_value": row.label,
                    "linked": true
                }

                searchRow.push(item)
            });

            this.setState({searchRows: searchRow})
            this.props.history.push({
                pathname: '/people',
                search: '?' + query
            })
            fetch(config.apiUrl + '/search/person/advanced?' + query)
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.error !== undefined) {
                        this.handleChange(false, responseJson, false, query, true, this.state.isLoading);
                    } else {
                        this.handleChange(true, responseJson);
                        this.setState({
                            showSearchResults: true,
                            updateSearchButton: false,
                            searchCount: responseJson.total,
                            isLoading: false,
                            isBasicSearch: false,
                            query
                        });
                    }


                }).catch((error) => {
                this.handleChange(false, error, false, query, true, this.state.isLoading);
            });
        }


        fetch( config.apiUrl + '/config')
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    isLoadingConfig: false,
                    people_count: (responseJson['count']) ? responseJson['count']['people'] : null,
                    reception_types: responseJson['receptionTypes'],
                    receptionSourcesTypes: responseJson["receptionSources"],
                    languages: responseJson['languages'],
                    circulationtypes: responseJson['circulationTypes'],
                    religiousOrder: responseJson['religiousOrder'],
                    religiousHouse: responseJson["religiousHouse"],
                });
            }).then((() => {
            fetch(config.apiUrl + '/person?page=false')

                .then((response) => response.json())
                .then((responseJson) => {

                    let final_people_list = [];
                    let detail_people_list = [];

                    responseJson['data'].map((item) => {
                        let people_list = {};
                        people_list["label"] = item['display_name'];
                        people_list["id"] = item['id'];
                        detail_people_list.push(people_list);
                        final_people_list.push(item['display_name'])
                        return final_people_list;
                    });
                    this.setState({
                        isLoading: false,
                        allPeople: final_people_list,                        
                        people_detail: detail_people_list
                    });

                })
                .catch((error) =>{
                    console.error(error);
                });
        }))

    }

    componentDidUpdate(prevProps) {

        if (this.props.location.search !== prevProps.location.search) {
            let queryType = ''
            let term_list = ''
            queryType = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).query_type
            term_list = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term

            if (queryType !== undefined && term_list !== undefined) {
                
                this.setState({
                    basic_search: false
                });

                let params = {
                    "query_type": queryType,
                    "term": term_list
                }


                let term_json = JSON.parse(term_list);
                let searchRow = []
                term_json.forEach((row) => {
                    let labels = row.termLabel;
                    labels = labels.replace(/<\/?[^>]+(>|$)/g, "");
                    let item = {
                        "name": labels,
                        "search_type": row.filter,
                        "search_value": row.label
                    }

                    searchRow.push(item)
                })
                this.setState({searchRows: searchRow})

                let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');
                this.setState({term_list: term_list})
                this.props.history.push({
                    pathname: '/people',
                    search: '?' + query
                })
                fetch(config.apiUrl + '/search/person/advanced?' + query)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.error !== undefined) {
                            this.handleChange(false, responseJson, false, query, true, this.state.isLoading);
                        } else {
                            this.handleChange(true, responseJson);
                            this.setState({
                                showSearchResults: true,
                                updateSearchButton: false,
                                searchCount: responseJson.total,
                                isLoading: false,
                                isBasicSearch: false,
                                query
                            });
                        }


                    }).catch((error) => {
                        this.handleChange(false, error, false, query, true, this.state.isLoading);
                });
            }
        }
    }


    search(person) {
        return fetch(config.apiUrl + `/person/${person}`)
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    person: this.state.person.concat([responseJson]),
                });

            }).catch((error) =>{
                console.error(error);
            });

    }


    handleRemoveClick() {
        this.setState({peopleSearchResponse: [], showNoResults: false, showSearchResults: false, originalReceptions: []});

    }

    handleChange(showSearchResults, receptionSearchResponse, isClearSearch, query, isError, isLoaded) {
        if (isError) {
            this.setState({
                isLoading: false,
                showSearchResults: false,
                showNoResults: true,
                error_message: receptionSearchResponse.message

            });

        }
        else if (isClearSearch) {
            this.handleRemoveClick()
        } else {

            this.setState({
                search_list: [],
                receptionSearchResponse: [],
                peopleSearchResponse: [],
                peopleList: [],
                isLoading: true,
                receivers: []
            })
            // set the search terms as a prop
            if(query !== undefined) {
                this.props.history.push({
                    pathname: '/people',
                    search: '?' + query
                })
            }


            this.setState({
                isLoading: false,
                showSearchResults: true,
                receptionSources: receptionSearchResponse,
                receptionSearchResponse: receptionSearchResponse,
                originalReceptions: receptionSearchResponse,
                peopleSearchResponse: receptionSearchResponse,
                search_terms: query,
                query
            });
        }

    }

    handleRefine(showSearchResults, receptionSearchResponse) {

        this.setState({peopleSearchResponse: receptionSearchResponse,
            receptionSearchResponse: receptionSearchResponse,
            updateRefineSearch: true});


    }


  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const { isLoading } = this.state;
    const { showSearchResults } = this.state;
    const { receptionSearchResponse } = this.state;
    const { allPeople } = this.state;
    const {isLoadingConfig} = this.state;
    const {people_count} = this.state;


    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container spacing={24} justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                <Grid container >
                    <Grid item xs={6} className={classes.grid}>

                        <FaUsers />
                        <Typography className={styles_css.reception_typography} variant="h6">People</Typography>


                    </Grid>

                    <Grid item xs={6} className={classes.gridRight}>
                        <FaCogs />
                        <Typography className={styles_css.reception_typography} variant="h6">
                            <Link to="/about-data">
                                About this data
                            </Link>
                        </Typography>
                    </Grid>
                    
                    {!isLoadingConfig &&
                        <div className={classes.inliningBlock}>
                                <Typography className={'text-muted'} gutterBottom>
                                <small><FaInfoCircle /> Explore {people_count} people. </small>
                            </Typography>
                        </div>
                    }

                    {/* <div className={classes.inliningBlock}>
                        <Typography variant="body2">
                            Explore {allPeople.length} people, 368 Female authors with receptions, XXXX female authors, XXX Receivers
                        </Typography>
                    </div> */}
                </Grid>
            </Grid>

              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>
                     <PeopleSearch isLoading={isLoading}
                                   onChange={this.handleChange}
                                   allPeople={allPeople}
                                   religiousOrder={this.state.religiousOrder}
                                   religiousHouse={this.state.religiousHouse}
                                   people_detail={this.state.people_detail}
                                   showSearchResults={showSearchResults}
                                   receptionSearchResponse={receptionSearchResponse}
                                   searchRows={this.state.searchRows}
                                   resultCount={this.state.searchCount}
                                    people_count={people_count} 
                                    basic_search={this.state.basic_search}
                                />
                      <Grid item xs={4} >
                            <Card>
                                <Card.Body>
                                    <InterestingSearches searchType="people" allSearches={config.interestingSearches} />
                                </Card.Body>
                            </Card>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>
                  {/* {showSearchResults && <PeopleRefineSearch
                      onChange={this.handleRefine}
                      religiousOrder={this.state.religiousOrder}
                      religiousHouse={this.state.religiousHouse}
                      receptionSearchResponse={this.state.peopleSearchResponse}
                      updateRefineSearch={this.state.updateRefineSearch}
                      originalReceptions={this.state.originalReceptions}/> } */}
                  {showSearchResults && <PeopleResultsTable
                        onChange={this.handleChange}
                        person={this.state.person}
                        receivers={this.state.receivers}
                        isLoading={this.state.isLoading}
                        receptionSearchResponse={this.state.peopleSearchResponse}
                        query={this.state.query} 
                    />}

                      {this.state.showNoResults &&
                      <Grid item xs={8} >
                          <Card className={styles_css.table_no_results_card_table}>
                              <Alert key={'alert'} variant={'danger'}>
                                  Error - {this.state.error_message}.
                              </Alert>
                          </Card>
                      </Grid>

                      }
                  </Grid>


              </Grid>


          </Grid>
        </div>


          <Footer/>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(People);