import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from './Topbar';
import Footer from './Footer';
import Nav from "./AboutData/Nav";
import styles_css from '../style.module.css';
import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const banner = require('../images/about-data-banner.png');

const styles = theme => ({
    root: {
        flexGrow: 1,       
        marginTop: 20,
        // padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    }
})

class AboutData extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="" />

                                <Typography variant="h1" className=" text-center mb-4 mt-4">About the Data</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} justify="center" container className={classes.grid}>

                            <Grid item xs={12} md={4}>
                                <Nav />
                            </Grid>

                            <Grid item xs={12} md={8}>
                                
                                <Typography variant="h2" className="mb-3">Project Overview</Typography>
                                
                                <p className="alert alert-warning mb-4">
                                    <FaInfoCircle /> Visit the "<Link to="/about">About the Project</Link>"  page to learn about the background to the project.
                                </p>


                                <Typography variant="h3" className="mb-2">History of the Project</Typography>
                                
                                <p>
                                    RECIRC: The Reception and Circulation of Early Modern Women’s Writing, 1550-1700 is a research project about the impact made by women writers and their works in the sixteenth and seventeenth centuries. Led by Marie-Louise Coolahan, and funded by the European Research Council from 2014 to 2020, the project involved a team of 11 researchers based at the National University of Ireland Galway. The focus included writers who were read in Ireland and Britain as well as women born and resident in Anglophone countries. Therefore, the subject of study was not limited to authors who wrote in English. RECIRC aimed to produce a large-scale, quantitative analysis of the reception and circulation of women's writing from 1550 to 1700. The RECIRC database is one of its major outputs. 
                                </p>

                                <Typography variant="h3" className="mb-2">Research questions</Typography>
                                <ul>
                                    <li>How did texts by women circulate?</li>
                                    <li>Which female authors were read? Who read them?</li>
                                    <li>How were they read?</li>
                                    <li>How did women build reputations as writers?</li>
                                    <li>How did gender shape ideas about authorship?</li>
                                </ul>


                                <Typography variant="h3" className="mb-2">Work packages</Typography>
                                <p>The project investigated the impact of women writers by focusing on 4 main categories (labelled ‘work packages’):</p>
                                <ul>
                                    <li><strong>WP1</strong>: Transnational Religious Networks: the transmission and translation of female-authored texts among the Catholic religious orders in Europe.</li>
                                    <li><strong>WP2</strong>: The International Republic of Letters: the circulation of texts and ideas by women and their forging of new intellectual networks across Europe.</li>
                                    <li><strong>WP3</strong>: The Manuscript Miscellany as Instrument and Site of Reception: the compilation, adaptation, and excerpting of texts by women, in manuscript miscellanies (handwritten compilations of miscellaneous materials). </li>
                                    <li><strong>WP4</strong>: Transmission Trails and Book Ownership: the acquisition of female-authored books by early modern collectors.</li>
                                </ul>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    
                   
                
                
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AboutData);