import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        flexGrow: 1,
    }
});

/**
 * Component to build a list of interesting 
 * search links from data provided
 * 
 * @param searchType - what key in config.interestingSearches does this map to
 * @param allSearches - full list of possible searches for all keys
 */

class InterestingSearches extends Component {


    render() {
        const { searchType, allSearches } = this.props;
        const searches = (allSearches[searchType] ) ? allSearches[searchType] : false;
        let searchList = '';

        if(searches !== false){
            searchList = searches.map((search) => {
                return (
                    <li key={search.label}>
                        <Typography variant="caption" gutterBottom>
                            <Link to={search.url}>
                                { search.label } ({search.result_count} {search.result_label})
                            </Link>
                        </Typography>
                    </li>
                );
            });
        }else{
            return null;
        }

        return (
            <div>
                <Typography variant="h6" gutterBottom>
                    Explore interesting searches
                </Typography>
                <ul>
                    { searchList }
                </ul>
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(InterestingSearches));