import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import DescriptionIcon from '@material-ui/icons/Description';
import ButtonBar from '../buttons/ButtonBar';
import { FaTwitter } from "react-icons/fa";

const styles = theme => ({
    paper: {
        padding: theme.spacing.unit * 3,
        textAlign: 'left',
        color: theme.palette.text.secondary
    },
   
    itemContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    baseline: {
        alignSelf: 'baseline',
        marginLeft: theme.spacing.unit * 4,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            alignItems: 'center',
            width: '100%',
            marginTop: theme.spacing.unit * 2,
            marginBottom: theme.spacing.unit * 2,
            marginLeft: 0
        }
    },
    btn_secondary: {
        backgroundColor: '#d2a74d',
        border: 'none',
        borderRadius: 0
    }
})

class CardItem extends Component {

    render() {
        const { classes, person } = this.props;
        
        const profile_image = require('../../images/' + person.profile_img);

        return (
            <div className={classes.root + ` mb-5`}>
                <Paper className={classes.paper}>
                    <div className={classes.itemContainer}>
                        
                        <div className={classes.baseline + ` media`}>
                           <img src={profile_image} alt={ person.name } className="align-self-start mr-3"/>
                           <div className="media-body">
                                <Typography variant="h3" className="mb-3">{ person.name }</Typography>
                                <p className="pr-4">
                                    {person.bio}
                                </p>
                                <ul className="list-inline">
                                    { person.url && 
                                        <li className="list-inline-item">
                                            <a href={person.url} className={ classes.btn_secondary + ` btn btn-secondary`}>
                                                Find out more
                                            </a>
                                        </li>
                                    }
                                    { person.twitter && 
                                        <li className="list-inline-item">
                                            <a href={person.twitter} className={classes.btn_secondary + ` btn btn-secondary`}>
                                                <FaTwitter /> Follow me on Twitter
                                            </a>
                                        </li>
                                    }
                                    { person.email && 
                                        <li className="list-inline-item">
                                            E: { person.email}                                                
                                        </li>
                                    }
                                </ul>
                           </div>
                        </div>
                    </div>
                </Paper>
            </div>
        )
    }
}

export default withStyles(styles)(CardItem);