import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Nav from './Nav';
import Topbar from '../Topbar';
import Footer from '../Footer';
import styles_css from '../../style.module.css';

const banner = require('../../images/about-data-banner.png');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        // padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})

class Use extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="" />

                                <Typography variant="h1" className=" text-center mb-4 mt-4">About the Data</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} justify="center" container className={classes.grid}>

                            <Grid item xs={12} md={4}>
                               <Nav />
                            </Grid>

                            <Grid item xs={12} md={8}>

                                <Typography variant="h2" className="mb-3">How to Use and Cite the Data</Typography>

                                <Typography variant="h3" className="mb-2">Export options</Typography>

                                <p>
                                    Search results may be exported as a .csv file, which can then be opened in software such as Excel, or a standard text editor.
                                </p>
                                <p>
                                    In cases where search results can be represented as a network graph, the results can be exported in <a href="https://gephi.org/gexf/format/">graph format (.GEXF)</a>. This can then be imported into software such as <a href="http://gephi.org">Gephi</a>, or used with packages such as <a href="https://networkx.github.io/">NetworkX</a>, to conduct network analysis.
                                </p>

                                <Typography variant="h3" className="mb-2">Citing the data</Typography>

                                <ul>
                                    <li>Our data is licensed under a <a href="https://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-Non Commercial 4.0 International License (CC-BY-NC 4.0)</a>.</li>
                                    <li>To cite an individual reception, source, person, or work: ‘<cite>RECIRC, URL, date accessed</cite>’.</li>
                                    <li>To cite quantitative results derived from the RECIRC database: ‘<cite>RECIRC, recirc.nuigalway.ie, date accessed</cite>’</li>
                                </ul>

                                
                            </Grid>
                        </Grid>
                    </Grid>




                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Use);