import React from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid'
import {Card} from 'react-bootstrap'
import { BarChart, Bar, LabelList, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { config } from '../../config';





class PersonReceptionsLinkedChart extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleClick = this.handleClick.bind(this);

        this.state = {
            isLoading: true,
            receptions: [],
            person: {},
        };
    }


    handleClick(e){
        let { person } = this.props;
        let term = [{
                "name": e.role,
                "label": e.label,
                "filter": "equals",
                "term": person.id,
                "termLabel": person.display_name
        }];

        let query = '/receptions?query_type=must&term=' + encodeURIComponent(JSON.stringify(term));

        this.props.history.push(query)
        
        
    }


    render() {

        // const { receptions } = this.state;
        const {person, receptions} = this.props;
        let data;
        let author      = 0;
        let receiver    = 0;
        let owner       = 0;

        if (!receptions || receptions.length === 0 || receptions.error || !person ){
            return (
                <Grid item xs={12} >
                    <Card className="text-center">
                        
                    </Card>
                </Grid>

            )
        }

        // console.log(person.id);
        receptions.forEach(rec => {
            if (rec.author_id === person.id){
                author++;
            } else if (rec.receiver_name === person.id){
                receiver++;
            } else if (Array.isArray( rec.receptionsourcework.owners )){
                rec.receptionsourcework.owners.forEach(owner => {
                    if (owner.id === person.id){
                        owner++;
                    }
                }); 
            }
        });

        data = [
            {
                "Author": author,
                "amt": author,
                "role": 'author_id',
                "label": 'Author'           
            },
            {
                "Named Receiver": receiver ,
                "amt": receiver,
                "role": 'receiver_name',
                "label": 'Named Receiver'       
            },
            {
                "Owner/Compiler/Scribe": owner,
                "amt": owner,
                "role": 'receptionsourcework.owners.id',
                "label": 'Owner/Compiler/Scribe'           
            }
        ];

        // console.log(data);

        return (
            <Grid item xs={12} >
                <Card className="text-center">
                    <Card.Body >
                        <Card.Text className="text-primary">
                            <Typography variant="caption"><strong>Receptions Connected with {person.display_name}</strong> </Typography>
                        </Card.Text>

                        <BarChart width={550} height={250} barGap={0} barCategoryGap={'5%'} data={data} margin={{top: 15, right: 5, bottom: 5, left: 5}}>
                            <XAxis dataKey="name" label={{ value: 'Role within Linked Receptions', position: 'center' }} />
                            <YAxis tick={false} label={{ value: 'No of Linked Receptions', angle: -90, position: 'center' }} />
                            <Tooltip cursor={{ fill: 'transparent' }} />
                            <Legend />
                            <Bar dataKey="Named Receiver" fill="#8884d8" onClick={e => this.handleClick(e) } style={{cursor: 'pointer'} }>
                                <LabelList dataKey="amt" position="top" />
                            </Bar>
                            <Bar dataKey="Author" fill="#82ca9d" onClick={e => this.handleClick(e)} style={{ cursor: 'pointer' }}>
                                <LabelList dataKey="amt" position="top" />
                            </Bar>
                            <Bar dataKey="Owner/Compiler/Scribe" fill="LightBlue" onClick={e => this.handleClick(e)} style={{ cursor: 'pointer' }}>
                                <LabelList dataKey="amt" position="top" />
                            </Bar>
                            {/* <Bar dataKey="Letter Sender" fill="LightGrey" /> */}
                        </BarChart>
                    </Card.Body>
                </Card>
            </Grid>
        )
    }
}

export default withRouter(PersonReceptionsLinkedChart)