import React,  { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardItem from './cards/CardItem';
import Topbar from './Topbar';
import Footer from './Footer';
import styles_css from '../style.module.css';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        maxWidth: 1200
    },
    discoverTitle: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        display: 'flex',
        minHeight: '175px',
    },
    paperData: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        justifyContent: 'flex-start',
        // color: theme.palette.text.secondary,
        display: 'flex',
        minHeight: 120
    },
})

class Cases extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>

   

                            <Grid container item xs={12} className={classes.grid + ' mb-0'} justify="flex-start" alignItems="flex-start" spacing={24}>
                                <Grid item xs={12} md={8} style={{ display: 'flex', margin: '0 auto' }} className="mb-0 pb-0">
                                    <div style={{ display: 'block', textAlign: 'center' }}>
                                        <Typography variant="h1" gutterBottom style={{ 'paddingTop': '0.5em', 'marginBottom': '.125em', fontSize: '48px' }}>Discovering the Reception of Women Writers</Typography>
                                        <p className={styles_css.pageSubtitle + ' text-muted'}>
                                            Explore areas informed by the RECIRC database, and learn about the search options it provides.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>


                                <Grid container className={classes.grid + ' mt-0'} item xs={12} spacing={24}>
                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.discoverTitle}>
                                            <Link to="/cases/auction-catalogues">
                                                <div className={classes.box}>
                                                    <Typography variant="h4" gutterBottom>
                                                        Auction Catalogues
                                                </Typography>
                                                    <Typography variant="body2" className="text-muted" gutterBottom>
                                                        Examining auction catalogues - lists of books to be put up for sale at auction’ - enables us to identify patterns in the ownership of female-authored works.
                                                </Typography>
                                                </div>
                                            </Link>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.discoverTitle}>
                                            <Link to="/cases/manuscript-miscellanies">
                                                <div className={classes.box}>
                                                    <Typography variant="h4" gutterBottom>
                                                        Manuscript Miscellanies, Attribution and Pseudonymous Authors
                                                </Typography>
                                                    <Typography variant="body2" className="text-muted" gutterBottom>
                                                        Manuscript miscellanies are particularly rich resources for attribution practices – practices that open up questions about gender identity in the period.
                                                </Typography>
                                                </div>
                                            </Link>
                                        </Paper>
                                    </Grid>


                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.discoverTitle}>
                                            <Link to="/cases/hartlib-circle">
                                                <div className={classes.box}>
                                                    <Typography variant="h4" gutterBottom>
                                                        The Hartlib Circle
                                                </Typography>
                                                    <Typography variant="body2" className="text-muted" gutterBottom>
                                                        The Hartlib circle was an intellectual correspondence network based around Samuel Hartlib (c. 1600-1662) that was active between 1641 and 1662.
                                                </Typography>
                                                </div>
                                            </Link>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.discoverTitle}>
                                            <Link to="/cases/layers-and-agents-of-reception">
                                                <div className={classes.box}>
                                                    <Typography variant="h4" gutterBottom>
                                                        Layers and Agents of Reception
                                                </Typography>
                                                    <Typography variant="body2" className="text-muted" gutterBottom>
                                                        Learn about the two kinds of agent, involved in reception, which RECIRC has categorized
                                                </Typography>
                                                </div>
                                            </Link>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.discoverTitle}>
                                            <Link to="/cases/reception-of-elizabeth-i">
                                                <div className={classes.box}>
                                                    <Typography variant="h4" gutterBottom>
                                                        Reception of Elizabeth I
                                                </Typography>
                                                    <Typography variant="body2" className="text-muted" gutterBottom>
                                                        RECIRC has captured 569 receptions of Queen Elizabeth I, more than any other female author in the database.
                                                </Typography>
                                                </div>
                                            </Link>
                                        </Paper>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.discoverTitle}>
                                            <Link to="/cases/translations">
                                                <div className={classes.box}>
                                                    <Typography variant="h4" gutterBottom>
                                                        Translations
                                                </Typography>
                                                    <Typography variant="body2" className="text-muted" gutterBottom>
                                                        Translation is one of our categories of reception, revealing the international dimensions of women's writing.
                                                </Typography>
                                                </div>
                                            </Link>
                                        </Paper>
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Cases);