import React,  { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from './Topbar';
import Footer from './Footer';
import styles_css from '../style.module.css';
import Paper from '@material-ui/core/Paper';
import { FaVolumeUp } from 'react-icons/fa';

const banner = require('../images/banner-conference.jpg');

const styles = theme => ({
    root: {
        flexGrow: 1,       
        marginTop: 20,
        // padding: 20,
        paddingBottom: 200
    },
    grid: {
        maxWidth: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})


class Conference extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} className="text-center">
                                <img src={banner} alt="" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} md={8}>
                                
                                <Typography variant="h1" className="text-center mb-4">
                                    Reception, Reputation and Circulation in
                                    the Early Modern World, 1500-1800
                                </Typography>
                                <Typography variant="h3" className="text-center mb-4" style={{fontStyle: 'italic'}}>
                                Moore Institute, National University of Ireland, Galway<br/>
                                22-25 March 2017
                                </Typography>

                                <p className={styles_css.pageSubtitle + ' text-muted'}>
                                    This international conference brought together scholars working on the reception of texts, the reputations of authors and individuals, and the circulation of people and things in the early modern world.
                                </p>
                                <p className='text-muted text-center'>
                                    To view paper abstracts, click on the ‘Abstracts’ button below. To listen to podcasts from the conference, click on the links below.
                                </p>

                                
                                <nav id={styles_css.exploreInternalNav}>
                                    <ul className="nav nav-fill">
                                        <li className="nav-item">
                                            <a href="/pdf/Programme_FINAL.pdf" className="nav-link">
                                                Final Conference Programme
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/pdf/Abstracts-Booklet-1.pdf" className="nav-link">
                                                Abstracts
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/pdf/CFP_2017V4.pdf" className="nav-link">
                                                Call for Papers
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a href="/pdf/RECIRC_conf_poster.pdf" className="nav-link">
                                                Conference Poster
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                                    
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Grid container justify="center">
                    <Paper className={ classes.paper + ` mb-5`}>
                        
                            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                                <Grid item xs={12} md={8}>
                                
                                    <Typography variant="h2" className="text-center mb-4">Wednesday, March 22<sup>nd</sup></Typography>

                                    <p style={{textAlign: 'center'}}><strong>Plenary session 1:  Networks of Circulation</strong></p>
                                    <p><strong>Ruth Ahnert</strong> (Queen Mary University of London) and <strong>Sebastian Ahnert</strong> (Cambridge), ‘Reconstructing Correspondence Networks in the State Papers Archive’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/ruth-ahnert-and-sebastian-ahnert?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Robin Buning</strong> (Huygens ING), ‘Collecting Biographies of the Members of Samuel Hartlib’s Circle: A Prosopographical Approach to Networking the Republic of Letters’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/robin-buning?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Julia Flanders</strong> (Northeastern), ‘Writing, Reception, Intertextuality: Networking Women’s Writing’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/julia-flanders?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 1A:  Nuns, ‘Whores’, and Language</strong></p>
                                    <p><strong>Emilie Murphy</strong> (York), ‘“that we might have means to learn French”: Language Competence and Acquisition in English Convents in Exile’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/emilie-murphy?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Heather Froehlich</strong> (Strathclyde), ‘Writing the Whore in Early Modern Drama’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/heather-froehlich?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 1B: </strong><strong>Routes of Communication</strong></p>
                                    <p><strong>Nina Lamal</strong> (St Andrews), ‘From the Battlefield: The Circulation of Soldiers’ Letters during the Dutch Revolt’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/nina-lamal?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Ingeborg van Vugt</strong> (Scuola Normale Superiore di Pisa/University of Amsterdam), ‘Mapping the Prohibited Book: Using Multi-layered Networks to Disclose the Early Modern Book World’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/ingeborg-van-vugt?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Plenary session 2:  Queens</strong></p>
                                    <p><strong>Rosalind Smith</strong> (Newcastle, Australia), ‘“Daughter of Debate”: Untangling the Poetic Reputation of Mary Stuart, Queen of Scots’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/rosalind-smith?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Micheline White</strong> (Carleton), ‘The (In)visible Queen: The Circulation of Unattributed Prayers by Katherine Parr’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/micheline-white?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 2A:  Career Authors and Reputation</strong></p>
                                    <p><strong>Nina Geerdink</strong> (Utrecht), ‘Bread and Branding: The (Self-)Representation of Female Dutch Authors Writing for Profit’</p>
                                    <p><strong>Thomas Colville</strong> (King’s College London), ‘Thomas Burnet’s Rotten Egg: Recovering Intellectual Reputation through Digital Archive Excavation’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/thomas-colville?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Esther Villegas de la Torre</strong> (University of Nottingham/Universitat Pompeu Fabra), ‘Gender, Print, and Fame in Seventeenth-Century Authorship’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/esther-villegas?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 2B:  Reputations Lost and Found</strong></p>
                                    <p><strong>Ramona Wray</strong> (Queen’s University Belfast), ‘Cary and the Crypt: Memory, Materiality and Maternity in the Tanfield/Cary Archive’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/ramona-wray?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Natasha Simonova</strong> (Oxford), ‘“Upon the Shelves of the Learned”: The Reception History of Unpopular Books’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/natasha-simonava?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Violetta Trofimova</strong> (St. Petersburg State University), ‘Across Boundaries: Comtesse de la Suze and her Elegies in Early Modern France, England and Russia’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/violetta-trofimova?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>							

                                </Grid>
                            </Grid>
                        
                        </Paper>
                    </Grid>

                    <Grid container justify="center" >
                        <Paper className={ classes.paper + ` mb-5`}>
                        
                            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                                <Grid item xs={12} md={8}>
                                
                                    <Typography variant="h2" className="text-center mb-4">Thursday, March 23<sup>rd</sup></Typography>

                                    <p style={{textAlign: 'center'}}><strong>Plenary session 3:  Reception and Orality</strong></p>

                                    <p><strong>Katherine Larson</strong> (Toronto), ‘Texts in and of the Air: The Matter of Song’</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 3A:  Receptions in Drama and Music</strong></p>
                                    <p><strong>Xuege Wu</strong> (Queen’s University Belfast), ‘“My heart to Salome is tied too fast to leave her love for friendship”: <em>The Tragedy of Mariam</em> and the Reception of Classical Friendship’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/xuege-wu?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Lindsay Ann Reid</strong> (National University of Ireland, Galway), ‘The Fair Maid of Dunsmore’</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 3B:  Paratexts, Translation, Networks: </strong><strong>Receptions of Spanish Writing</strong></p>
                                    <p><strong>Nieves Baranda</strong> (Universidad Nacional de Educación a Distancia), ‘Assessing Reception, Reputation and Circulation through Women Writers’ Paratexts in Early Modern Spain’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/nieves-baranda?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Jessie Labadie</strong> (Randolph-Macon College), ‘The Afterlife of Maria de Zayas y Sotomayor’s Novellas in France’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/jesse-labadie?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here.</a></p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Plenary session 4:  Cross-Cultural and Cross-Temporal Encounters </strong></p>
                                    <p><strong>Gillian Dow</strong> (Southampton), ‘“If it’s a flop, too bad for the author; we’ll claim it’s a literal translation”: Reviewing Women Writers in 1750s Britain’</p>
                                    <p><strong>Jerome de Groot</strong> (Manchester), ‘Fugitives, Fields, Pubs and Trees: Constructing Memory and the Royal Oak’</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 4A:  Reputations on the Move: Vitae of Nuns and Saints</strong></p>
                                    <p><strong>Jennifer Hillman</strong> (Chester), ‘Mademoiselle de Vienville and her “glorious enterprise”: Writing the Life of Mère Mectilde du Saint-Sacrement (osb)’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/jennifer-hillman?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Danielle Clarke</strong> (University College Dublin), ‘Teresa de Avila, Translation, Reception and Generic Innovation’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/danielle-clarke?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>John McCafferty</strong> (University College Dublin), ‘Brigid of Kildare: Stabilizing a Wandering Early Medieval Saint’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/john-mccafferty?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{textAlign: 'center'}}><strong>Parallel session 4B: Brits and Books: The British Book Trade </strong><strong>at Home and Abroad</strong></p>
                                    <p><strong>Michael Durrant</strong> (Bangor), ‘“Suit Trouble”: Henry Hills’ Posthumous Reputation’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/michael-durrant?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Rachel Schnepper</strong> (Grinnell College), ‘Networks of Belief and Production: Digital Humanities and the Revolutionary English Book Trade’</p>
                                    <p><strong>Daniela Giosuè</strong> (Università degli Studi della Tuscia), ‘Bargaining for Books in Early Modern Europe: The Example of Some Seventeenth-Century British Travellers’</p>							
                                </Grid>
                            </Grid>
                        
                        </Paper>
                    </Grid>

                    <Grid container justify="center" >
                        <Paper className={ classes.paper + ` mb-5`}>
                        
                            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                                <Grid item xs={12} md={8}>
                                
                                    <Typography variant="h2" className="text-center mb-4">Friday, March 24<sup>th</sup></Typography>

                                    <p style={{ textAlign: 'center'}}><strong>Parallel session 5A: &nbsp;Managing One’s Reputation:&nbsp;</strong><strong>Manuscript vs. Print Circulation</strong></p>
                                    <p><strong>Jessica Maratsos</strong> (American University of Paris), ‘By Hand and by Press: Vittoria Colonna in Circulation’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/jessica-maratsos?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Magdaléna Jánošíková</strong> (Queen Mary University of London), ‘“Bees swarmed against me”: Eliezer Eilburg, a Sixteenth-Century Jew: Mending Reputation in the Context of the Polish-Jewish Golden Age’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/magdale-na-ja-nosikova?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Yvonne Noble</strong> (Canterbury), ‘The Occlusion of Anne Finch’</p>
                                    <p>&nbsp;</p><p style={{ textAlign: 'center'}}><strong>Parallel session 5B: &nbsp;Literary Interchanges: France and the Four Kingdoms (England, Scotland, Wales, Ireland)</strong></p>
                                    <p><strong>Peter Auger</strong> (Queen Mary University of London), ‘Du Bartas and the “Pizza Effect”’</p>
                                    <p><strong>Mary Chadwick</strong> (Huddersfield), ‘The Bread and Butter Chronicle: Authorship, Reception and Community in Manuscript Magazines’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/mary-chadwick?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Wes Hamrick</strong> (National University of Ireland, Galway), ‘Thomas Gray, Alexander Pope and “British” Literary History’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/wes-hamrick?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p><p style={{ textAlign: 'center'}}><strong>Plenary session 5: Matter, Materiality and Circulation</strong></p>
                                    <p><strong>Juliet Fleming</strong> (New York University), ‘Where Is On?’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/juliet-fleming?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Helen Smith</strong> (York), ‘Miscellany Madames’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/helen-smith?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{ textAlign: 'center'}}><strong>Parallel session 6A: &nbsp;Irish Women’s Correspondence:&nbsp;</strong><strong>Reception and Connections</strong></p>
                                    <p><strong>Ann-Maria Walsh</strong> (University College Dublin), ‘The Boyle Sisters: Collaborating, Facilitating, and the Familial Correspondence Network’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/ann-maria-walsh?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Michelle DiMeo</strong> (Chemical Heritage Foundation), ‘Lady Ranelagh, Natural Philosophy and Ireland: Corresponding with the Hartlib Circle, 1656-59’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/michelle-dimeo?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Naomi McAreavey</strong> (University College Dublin), ‘The Reputation and Networks of the Duchess of Ormonde’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/naomi-mcareavey?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{ textAlign: 'center'}}><strong>Parallel session 6B: &nbsp;Visual Representations and Reception</strong></p>
                                    <p><strong>Karen Lloyd</strong> (Stony Brook), ‘A Disembodied Devotion: The Peruvian Madonna of Copacabana in Early Modern Italy’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/karen-lloyd?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Michele Osherow</strong> (Maryland), ‘Keeping the Girls in Stitches: Working the Susanna Narrative in Seventeenth-Century Embroidery’</p>
                                    <p><strong>Leah Knight</strong> (Brock), ‘The Art of Evidence and the Evidence of Art: Textual Reception in Anne Clifford’s&nbsp;<em>Great Picture’</em></p>
                                    <p>&nbsp;</p><p style={{ textAlign: 'center'}}><strong>Plenary session 6: &nbsp;Irish Book History</strong></p>
                                    <p><strong>Marc Caball</strong> (University College Dublin), ‘Worlds of Knowledge: Books and their Owners in Early Modern Ireland’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/marc-caball?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Jason McElligott</strong> (Marsh’s Library, Dublin), ‘Un-Enlightened Readers: Stealing Books in Eighteenth-Century Dublin’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/jason-mcelligott?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>							
                                </Grid>
                            </Grid>
                        
                        </Paper>
                    </Grid>

                    <Grid container justify="center" >
                        <Paper className={ classes.paper}>
                        
                            <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                                <Grid item xs={12} md={8}>
                                
                                    <Typography variant="h2" className="text-center mb-4">Saturday, March 25<sup>th</sup></Typography>

                                    <p style={{ textAlign: 'center' }}><strong>Plenary session 7: &nbsp;Confessional Networks</strong></p>
                                    <p><strong>Liesbeth Corens</strong> (Cambridge), ‘English Catholic Counter-Archives: Creating a Community without Borders’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/liesbeth-corens-cambridge?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Jaime Goodrich</strong> (Wayne State), ‘Reading the Network: Comparative History and the English Benedictines of Pontoise’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/jaime-goodrich-wayne-state?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{ textAlign: 'center' }}><strong>Parallel session 7A: &nbsp;Annotation, Commemoration and Misattribution:&nbsp;</strong><strong>Receptions of Herbert and Donne</strong></p>
                                    <p><strong>Joel Swann</strong> (Portsmouth), ‘Early Annotators of <em>The Temple</em> (1633)’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/joel-swann?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Jenna Townend</strong> (Loughborough), ‘“Or something like it, for I han’t the Book by me”: Misquotation, Misattribution, and the Reception of George Herbert’s <em>The Temple</em> (1633)’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/jenna-townend?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Charles Green</strong> (Birmingham), ‘“A Tomb your Muse must to his Fame supply”: A Survey of Seventeenth-Century Commemorative Elegies, Graffiti, and Annotations on John Donne’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/charles-green-birmingham?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{ textAlign: 'center' }}><strong>Parallel session 7B: &nbsp;Aphra Behn: Literary Sources and&nbsp;</strong><strong>Acts of Reception</strong></p>
                                    <p><strong>Gillian Wright</strong> (Birmingham), ‘Aphra Behn’s “Voyage to the Island of Love”: Sources and Influences’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/gillian-wright-birmingham?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Claire Bowditch</strong> (Loughborough), ‘Translation and Collaboration: Aphra Behn and the Inns of Court’</p>
                                    <p><strong>Elaine Hobby</strong> (Loughborough), ‘The Performance and Publication of Aphra Behn’s <em>The Rover</em> in the Early Modern World’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/elaine-hobby?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p>&nbsp;</p><p style={{ textAlign: 'center' }}><strong>Plenary session 8: Cross-Cultural and Cross-Temporal Encounters II</strong></p>
                                    <p><strong>Alexander Samson</strong> (University College London), ‘The Translation, Dedication and Circulation of Spanish Books in Early Modern England’</p>
                                    <p><FaVolumeUp /> To listen to the podcast, <a href="https://soundcloud.com/mooreinstitute/alexander-samson-university?in=mooreinstitute/sets/reception-reputation-and-circulation" target="_blank" rel="noopener noreferrer">click here</a>.</p>
                                    <p><strong>Eleanor </strong><strong>Rycroft</strong> (Bristol), ‘Engaging the Twenty-First-Century Audience with Early Modern Plays’</p>					
                                </Grid>
                            </Grid>
                        
                        </Paper>
                    </Grid>
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Conference);