import React  from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import ReceptionResultsTable from '../common/CommonResultsTable';
import ReceptionGraph from '../Network/ReceptionGraph';
import ReceptionSearch from './ReceptionSearch'
import Footer from '../Footer';
import {Alert, Card} from 'react-bootstrap';
import { FaCogs, FaInfoCircle, FaProjectDiagram} from 'react-icons/fa';
import styles_css from '../../style.module.css';
import { config } from '../../config';
// import Loading from '../common/Loading';
import InterestingSearches from '../common/InterestingSearches';
// import { thisExpression } from '@babel/types';


const qs = require('query-string');
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.grey['200'],
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        // width: 1000,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    },
    gridMain: {
        // width: 1300,
        maxWidth: 1200,
        paddingBottom: 30
    },
    gridRight: {
        padding: 20,
        // width: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    inlining: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10
    },
    inliningBlock: {
        display: 'block',
        marginRight: 10,
        marginLeft: 25
    },
    inliningRight: {
        display: 'inline',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 10,
        marginLeft: 35
    },

})





class Receptions extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.handleChange = this.handleChange.bind(this);
        this.handleRefine = this.handleRefine.bind(this);
        this.toggleResultsView = this.toggleResultsView.bind(this);

        this.state = {
            isLoading: true,
            isLoadingConfig: true,

            showSearchResults: false,
            showNoResults: false,
            showNetwork: false,
            showTable: false,
            reception_types: '',
            receptionSources: '',
            receptionSourcesTypes: [],
            circulationtypes: [],
            languages: '',
            receptionSearchResponse: [],
            search_list: [],
            peopleSearchResponse: [],
            peopleList: [],
            receivers: [],
            person:[],
            allPeople:[],
            people_detail: [],
            updateRefineSearch: false,
            originalReceptions: [],
            search_terms: '',
            searchRows: [],
            error_message: ''
        };
    }

    componentDidMount() {
        if (this.props.history.action === "POP") {
//            console.log("Back button pressed")
        }

        let queryType = '';
        let term_list = '';
        let view = 'table';

        queryType = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).query_type;
        view = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).view;
        term_list = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term;
//        console.log(term_list);
        if (queryType !== undefined && term_list !== undefined) {
            
            view = (view === 'graph' ? 'graph' : 'table');
            
            let params = {
                "query_type": queryType,
                "term": term_list,
                'view': view
            }
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            this.setState({term_list: term_list})
            let term_json = JSON.parse(term_list);
//            console.log(term_json);
            let searchRow = []
            term_json.map((row) => {
//                console.log(row)
                let labels = row.termLabel;
                labels = labels.replace(/<\/?[^>]+(>|$)/g, "");
                let item = {
                    "name": labels,
                    "search_type": row.filter,
                    "search_value": row.label,
                    "linked": true
                }

                searchRow.push(item)
                return true;
            })
            this.setState({searchRows: searchRow})
//            // console.log(searchRow)
            this.props.history.push({
                pathname: '/receptions',
                search: '?' + query
            })

            this.setState({ isLoading: true }, () => {
                fetch(config.apiUrl + '/search/reception/advanced?' + query)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.error !== undefined) {
                            this.handleChange(false, responseJson, false, query, true, this.state.isLoading);
                        } else {
                            this.handleChange(true, responseJson);
                            let showNetwork = false;
                            let showTable = true;

                            if(view === 'graph'){
                                showNetwork = true;
                                showTable = false;
                            }

                            this.setState({
                                showSearchResults: true,
                                showTable: showTable,
                                showNetwork: showNetwork,
                                updateSearchButton: false,
                                searchCount: responseJson.total,
                                isLoading: false,
                                query
                            });
                        }


                    }).catch((error) => {
                        this.handleChange(false, error, false, query, true, this.state.isLoading);
                });
            });
        }


        fetch(config.apiUrl + '/config')
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoadingConfig: false,
                    reception_count: (responseJson['count']) ? responseJson['count']['receptions'] : null, 
                    reception_types: responseJson['receptionTypes'],
                    receptionSourcesTypes: responseJson["receptionSources"],
                    languages: responseJson['languages'],
                    circulationtypes: responseJson['circulationTypes'],
                });
            }).then((response => {
            fetch( config.apiUrl + '/person?page=false')
                .then((response) => response.json())
                .then((responseJson) => {

                    let final_people_list = [];
                    let detail_people_list = [];

                    responseJson['data'].map((item) => {
                        let people_list = {};
                        people_list["label"] = item['display_name'];
                        people_list["id"] = item['id'];
                        detail_people_list.push(people_list);
                        final_people_list.push(item['display_name'])
                        return final_people_list;
                    });

                    this.setState({
                        isLoading: false,
                        allPeople: final_people_list,
                        people_detail: detail_people_list
                    });


                })
                .catch((error) =>{
                    console.error(error);
                });
        }))
    }

    componentDidUpdate(prevProps) {

        if (this.props.location.search !== prevProps.location.search) {
            let queryType = ''
            let term_list = ''
            let view = 'table';

//            //console.log(this.props.location.search)
            queryType = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).query_type
            term_list = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term;
            view = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).view;

//            // console.log(queryType)
            if (queryType !== undefined && term_list !== undefined) {
                view = (view === 'graph' ? 'graph' : 'table');

                let params = {
                    "query_type": queryType,
                    "term": term_list,
                    'view': view
                }


                let term_json = JSON.parse(term_list);
//                console.log(term_json);
                let searchRow = []
                term_json.map((row) => {
//                    console.log(row)
                    let labels = row.termLabel;
                    labels = labels.replace(/<\/?[^>]+(>|$)/g, "");
                    let item = {
                        "name": labels,
                        "search_type": row.filter,
                        "search_value": row.label
                    }

                    searchRow.push(item);
                    return true;
                })
                this.setState({searchRows: searchRow})

                let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');
                this.setState({term_list: term_list})
                
                this.props.history.push({
                    pathname: '/receptions',
                    search: '?' + query
                })

                this.setState({ isLoading: true }, () => {
                    fetch(config.apiUrl + '/search/reception/advanced?' + query)
                        .then((response) => response.json())
                        .then((responseJson) => {
                            if (responseJson.error !== undefined) {
                                this.handleChange(false, responseJson, false, query, true, this.state.isLoading);
                            } else {
                                this.handleChange(true, responseJson);

                                let showNetwork = false;
                                let showTable = true;

                                if (view === 'graph') {
                                    showNetwork = true;
                                    showTable = false;
                                }


                                this.setState({
                                    showSearchResults: true,
                                    updateSearchButton: false,
                                    searchCount: responseJson.total,
                                    isLoading: false,
                                    showNetwork,
                                    showTable,
                                    query
                                });
                            }


                        }).catch((error) => {
                            this.handleChange(false, error, false, query, true, this.state.isLoading);
                    });
                });
            }
        }
    }

    toggleResultsView(showResultsAsTable)
    {
        this.setState({
            showNetwork: !this.state.showNetwork,
            showTable: !this.state.showTable
        });
    }

    search(person) {
        return fetch(config.apiUrl + `/person/${person}`)
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    person: this.state.person.concat([responseJson]),
                });

            }).catch((error) =>{
                console.error(error);
            });

    }

    handleRemoveClick() {
        this.setState({
          peopleSearchResponse: [], 
          showNoResults: false, 
          showSearchResults: false, 
          originalReceptions: []
        });

    }

    handleChange(showSearchResults, receptionSearchResponse, isClearSearch, query, isError, isLoaded) {
        if (isError) {
//            console.log(receptionSearchResponse)
            this.setState({
                isLoading: false,
                showSearchResults: false,
                showNoResults: true,
                error_message: receptionSearchResponse.message

            });

        }
        else if (isClearSearch) {
            this.handleRemoveClick()
        } else {
            if (!isError){
                this.setState({
                    search_list: [],
                    receptionSearchResponse: [],
                    peopleSearchResponse: [],
                    peopleList: [],
                    isLoading: true,
                    receivers: []
                })
                // set the search terms as a prop
                if(query !== undefined) {
                    this.props.history.push({
                        pathname: '/receptions',
                        search: '?' + query
                    })
                }

                this.setState({
                    isLoading: false,
                    showSearchResults: true,
                    receptionSources: receptionSearchResponse,
                    receptionSearchResponse: receptionSearchResponse,
                    originalReceptions: receptionSearchResponse,
                    peopleSearchResponse: receptionSearchResponse,
                    search_terms: query,
                    query
                });
                
                // if nothing is setting the results table or graph view
                // default to the table view
                if(!(this.state.showNetwork && this.state.showTable)){
                 this.setState({
                     showTable: true
                 });
                }
            }

        }

    }


    handleRefine(showSearchResults, receptionSearchResponse) {

        this.setState({peopleSearchResponse: receptionSearchResponse,
            receptionSearchResponse: receptionSearchResponse,
            updateRefineSearch: true});


    }



  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const { isLoading } = this.state;
    const { showSearchResults } = this.state;
    const { receptionSearchResponse } = this.state;
    const { allPeople } = this.state;
    const { reception_count } = this.state;
    const { isLoadingConfig } = this.state;

    const {showNetwork, showTable} = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container spacing={24} justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                <Grid container >
                    <Grid item xs={12} md={6} className={classes.grid}>

                        <FaProjectDiagram />
                        <Typography className={styles_css.reception_typography} variant="h6">Receptions</Typography>


                    </Grid>

                    <Grid item xs={12} md={6} className={classes.gridRight}>
                        <FaCogs />
                        <Typography className={styles_css.reception_typography} variant="h6">
                            <Link to="/about-data">
                                About this data
                            </Link>    
                        </Typography>
                    </Grid>

                    {!isLoadingConfig &&
                        <div className={classes.inliningBlock}>
                            <Typography variant="body1" className="text-muted" gutterBottom>
                                <small><FaInfoCircle /> Explore {reception_count} receptions.</small>
                            </Typography>
                        </div>
                    }
                </Grid>
            </Grid>

              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>
                     <ReceptionSearch isLoading={isLoading}
                                    onChange={this.handleChange}
                                    languages={this.state.languages}
                                    reception_types={this.state.reception_types}
                                    receptionSourcesTypes={this.state.receptionSourcesTypes}
                                    circulationTypes={this.state.circulationtypes}
                                    allPeople={allPeople}
                                    people_detail={this.state.people_detail}
                                    showSearchResults={showSearchResults}
                                    receptionSearchResponse={receptionSearchResponse}
                                    searchRows={this.state.searchRows}
                                    resultCount={this.state.searchCount}
                                    showNetwork={showNetwork}    
                                    showTable={showTable}    
                                    history={this.props.history}
                                />
                      <Grid item xs={12} md={4}>
                          <Card>
                              <Card.Body>    
                                    <InterestingSearches searchType="reception" allSearches={config.interestingSearches} />                                  
                              </Card.Body>
                          </Card>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>
                  
                    {
                        // results table
                        (showSearchResults && showTable) &&
                            <ReceptionResultsTable
                                person={this.state.person}
                                receivers={this.state.receivers}
                                isLoading={this.state.isLoading}
                                receptionSearchResponse={this.state.peopleSearchResponse}
                                showTable={true}
                                showNetwork={false} 
                                onChange={this.toggleResultsView} 
                                query={this.state.query}
                            />
                    }
                    
                    {
                        // network table
                        (showSearchResults && showNetwork) && 
                            <ReceptionGraph
                                person={this.state.person}
                                receivers={this.state.receivers}
                                isLoading={this.state.isLoading}
                                receptionSearchResponse={this.state.peopleSearchResponse} 
                                showTable={false} 
                                showNetwork={true} 
                                onChange={this.toggleResultsView} 
                                query={this.state.query}
                            />
                    }

                    { // no results
                        this.state.showNoResults &&
                            <Grid item xs={8} >
                                <Card className={styles_css.table_no_results_card_table}>
                                    <Alert key={'alert'} variant={'danger'}>
                                        Error - {this.state.error_message}.
                                    </Alert>
                                </Card>
                            </Grid>

                      }
                  </Grid>


              </Grid>


          </Grid>
        </div>


          <Footer/>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Receptions);