import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Typography } from '@material-ui/core';
import { FaHome } from 'react-icons/fa';

// images
import img_translation_1 from '../../images/case-translation-1.png';
import img_translation_2 from '../../images/case-translation-2.png';
import img_translation_3 from '../../images/case-translation-3.png';
import img_translation_4 from '../../images/case-translation-4.png';
import img_translation_5 from '../../images/case-translation-5.png';



const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    },
    paper: {
        padding: '2em'
    }
})

class Translations extends Component {

   
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper className={ classes.paper }>
                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Translations</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>

                    <Typography variant="h1" className="pb-4">Translations</Typography>
                    
                    <div className="case-intro">
                        <p>
                            Translation is one of our categories of reception, revealing the international dimensions of women’s writing. 
                        </p>
                    </div>

                    <ul>
                        <li>
                            To search for <Link to={'/receptions?query_type=must&term=[{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":87,"value":"translation","label":"Translation"}],"termLabel":"Translation"}]&view=table'}>all receptions that are translations</Link>, select ‘Reception Type’ in the advanced search field and tick ‘Translation’.
                             <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":87,"value":"translation","label":"Translation"}],"termLabel":"Translation"}]&view=table'}>
                                <img src={img_translation_1} alt="Screenshot of search for all receptions that are translations" className="mt-4 mb-4" />
                            </Link>
                            <br/>
                            This produces 116 results, among which are: 
                        </li>
                    </ul>

                    
                    <Typography variant="h3" className="pb-2">Marguerite de Valois (1553-1615)</Typography>
                    <p>
                        An anonymous translation into English of <Link to="/people/person/2953">Marguerite de Valois’s</Link> Discours docte et subtil (1614), <Link to="/receptions/reception/1367">transcribed in the manuscript miscellany, Bodleian Library, MS Rawlinson C. 574</Link>. Multiple English translations of her Mémoires (1628) were published.</p> 
                    <ul>
                        <li>
                            <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2953,"termLabel":"Marguerite de Valois"},{"name":"circulationtype","label":"Circulation Type","filter":"in","term":[{"id":22,"value":"catalogue_entry","label":"Catalogue Entry"}],"termLabel":"Catalogue Entry"}]&view=table'}>Search for copies held in libraries</Link> by entering ‘Marguerite de Valois’ in the ‘Female Author’ field, and ‘Catalogue entry’ in the ‘Circulation Type’ field. 
                            
                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2953,"termLabel":"Marguerite de Valois"},{"name":"circulationtype","label":"Circulation Type","filter":"in","term":[{"id":22,"value":"catalogue_entry","label":"Catalogue Entry"}],"termLabel":"Catalogue Entry"}]&view=table'}>
                                <img src={img_translation_2} alt="Screenshot of search for English translations of Marguerite de Valois’s Mémoires (1628)" className="mt-4 mb-4" />
                            </Link>

                        </li>
                    </ul>

                    <Typography variant="h3" className="pb-2">Mary Percy (1570-1642)</Typography>
                    <p>
                        A translated excerpt from English to French reflects convent controversy. ‘A brief and sincere Relation of the beginnings, grounds, and issues of the late Controversy between the <Link to="/people/person/2433">Lady Mary Percy Abbess</Link> and her Religious’ was collectively produced at the English Benedictine convent in Brussels, and translated as ‘<Link to="/receptions/reception/3332">Extraict d'un certain escrit ou libel fameuse communique en Angleterre / Extract of a certain writing or famous libel communicated in England</Link>’ in the 1630s. 
                    </p>

                    <p>
                        One of our top-ten authors, in terms of reception, Mary Percy was appointed abbess of this convent in 1616. Her prohibition of Jesuit priests from acting as convent confessors led dissenting nuns to complain vociferously in letters to the Flemish archbishop of Mechelen, Jacobus Boonen, and found a new convent at Ghent. Hence, her name is invoked voluminously in the correspondence of the Mechelen archive. 
                    </p>
                    <ul>
                        <li>
                            To <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2433,"termLabel":"Mary Percy"}]&view=table'}>search for all receptions of Percy</Link>, enter her name in the People basic search.
                            
                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2433,"termLabel":"Mary Percy"}]&view=table'}>
                                <img src={img_translation_3} alt="Screenshot of search for all receptions of Percy" className="mt-4 mb-4" />
                            </Link>

                        </li>
                    </ul>

                    <p>
                        Many of the English nuns required translators, especially when representing their position to the archibishop. Gabriel Colford, father to one of the nuns, and financial manager for the convent, acted for many like Mary Percy herself [<Link to="/receptions/reception/2080">REC #2080</Link>] and fellow convent founder, Sr. Gertrude Arundell [<Link to="/receptions/reception/3403">REC #3403</Link>, which supplies both her English original and Colford’s French translation; <Link to="/receptions/reception/3402">#3402</Link> = anonymous underlining as annotation].
                    </p>
                    <ul>
                        <li>
                            To <Link to={'/receptions?query_type=must&term=[{"name":"author.is_nun","filter":"equals","term":true,"termLabel":"Is Nun"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":87,"value":"translation","label":"Translation"}],"termLabel":"Translation"}]&view=table'}>search for all translations of works by nuns</Link>, select the ‘Female Author’ field in advanced search, and select the filter ‘Is Nun’. Then select ‘Translation’ from the ‘Reception Type’ field. 
                            
                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"author.is_nun","filter":"equals","term":true,"termLabel":"Is Nun"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":87,"value":"translation","label":"Translation"}],"termLabel":"Translation"}]&view=table'}>
                                <img src={img_translation_4} alt="Screenshot of search for all translations of works by nuns" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>

                    <Typography variant="h2" className="pb-2">Languages of Sources</Typography>
                    <p>
                        RECIRC has tagged languages as they occur in our sources, but not the language in which a reception is written. Hence, multilingual sources may be searched by language (although individual receptions cannot).
                    </p>
                    <ul>
                        <li>
                            To  <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.languages","label":"Reception Source Language","filter":"in","term":[{"id":31,"value":"English","label":"English"}],"termLabel":"English"},{"name":"receptionsourcework.languages","label":"Reception Source Language","filter":"in","term":[{"id":48,"value":"Arabic","label":"Arabic"}],"termLabel":"Arabic"},{"name":"receptionsourcework.languages","label":"Reception Source Language","filter":"in","term":[{"id":46,"value":"Hebrew","label":"Hebrew"}],"termLabel":"Hebrew"}]&view=table'}>search for all sources that contain materials in English, Hebrew and Arabic</Link>: select ‘Reception Source Language’ field in advanced search, and tick ‘Arabic’, ‘English’ and ‘Hebrew’. This will produce 107 results, including <Link to="/sources/source/571">Catalogus librorum</Link> (1700).

                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.languages","label":"Reception Source Language","filter":"in","term":[{"id":31,"value":"English","label":"English"}],"termLabel":"English"},{"name":"receptionsourcework.languages","label":"Reception Source Language","filter":"in","term":[{"id":48,"value":"Arabic","label":"Arabic"}],"termLabel":"Arabic"},{"name":"receptionsourcework.languages","label":"Reception Source Language","filter":"in","term":[{"id":46,"value":"Hebrew","label":"Hebrew"}],"termLabel":"Hebrew"}]&view=table'}>
                                <img src={img_translation_5} alt="Screenshot of search for all sources that contain materials in English, Hebrew and Arabic" className="mt-4 mb-4" />
                            </Link>

                        </li>
                        <li>See also <Link to="/cases/auction-catalogues">Case Study: Auction Catalogues</Link>.</li>
                    </ul>

                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Translations</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>
                    
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Translations);