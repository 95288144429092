import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Nav from './Nav';
import Topbar from '../Topbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

const banner = require('../../images/about-data-banner.png');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        // padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})

class Catalogue extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="" />

                                <Typography variant="h1" className=" text-center mb-4 mt-4">About the Data</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} justify="center" container className={classes.grid}>

                            <Grid item xs={12} md={4}>
                               <Nav />
                            </Grid>

                            <Grid item xs={12} md={8}>

                                <Typography variant="h2" className="mb-3">Catalogue of Female Authors and Their Works</Typography>

                                <p>
                                    The RECIRC database can also be used as a catalogue of <Link to={'/people?query_type=must&term=[{"name":"is_author","filter":"equals","term":true,"termLabel":"Is Author"},{"name":"sex","label":"Sex","filter":"in","term":["Female"],"termLabel":"Female"}]'}>women writers</Link> and <Link to="/works">their works</Link>. It contains 1,878 female authors – from England, Ireland, Scotland, Wales, North America, the Low Countries, Denmark, Germany, France, Italy, Spain, Portugal, from classical to early modern times – and 7,319 works authored by these women. 
                                </p>
                                <ul>
                                    <li>For Anglophone authors, each known text (e.g. poem) is listed by title and first line or incipit, with spelling modernised. </li>
                                    <li>For non-Anglophone women, each printed work is listed, with spelling modernised and English translation of the title supplied. Individual texts were entered on a case-by-case basis, as reception evidence was encountered. If an individual text by a non-Anglophone author is not in the database, this indicates that no reception evidence for it was found. </li>
                                    <li>‘Correspondence’ indicates that an author was known to write letters. If no specific letter is in the database, this indicates that we found no reception evidence for individual letters by that woman.</li>
                                </ul>
    
    
                                <Typography variant="h3" className="mb-2">Sources consulted in compiling RECIRC’s catalogue of female authors and their works </Typography>

                                <p><strong>Note</strong>: this is a select list of large-scale sources. Many scholarly editions of single authors were also consulted.</p>
                                <ul>                                
                                    <li>A Biographical Encyclopedia of Early Modern Englishwomen: Exemplary Lives and Memorable Acts, 1500-1650, ed. Carole Levin, Ann Riehl Bertolet, and Jo Eldridge Carney (Abingdon and New York: Routledge, 2016).</li>
                                    <li>BIESES: Bibliografía de Escritoras Españolas: <a href="https://www.bieses.net/">https://www.bieses.net/</a>.</li>
                                    <li>The Cambridge Guide to Women’s Writing in Engllish, ed. Lorna Sage (Cambridge: Cambridge University Press, 1999).</li>
                                    <li>The Catalogue of English Literary Manuscripts, 1450-1700: <a href="http://www.celm-ms.org.uk/">http://www.celm-ms.org.uk/</a>.</li>
                                    <li>Chambers Biographical Dictionary of Women, ed. Melanie Parry (Edinburgh: Chambers, 1996).</li>
                                    <li>Virginia Cox, Women’s Writing in Italy, 1400-1650 (Baltimore: Johns Hopkins University Press, 2008).</li>
                                    <li>Patricia Crawford, ‘Women’s Published Writings 1600-1700’, in Women in English Society, 1500-1800, ed. Mary Prior (Lodnon: Methuen, 1985).</li>
                                    <li>Early Modern Women Poets (1520-1700), An Anthology, ed. Jane Stevenson and Peter Davidson (Oxford: Oxford University Press, 2001).</li>
                                    <li>The Field Day Anthology of Irish Writing: Irish Women’s Writing and Traditions, ed. Angela Bourke et al., 2 vols. (Cork: Cork University Press, 2002).</li>
                                    <li>The Other Voice in Early Modern Europe book series, ed. Margaret L. King and Albert Rabil, Jr. (University of Chicago Press, 1996-2010; Iter and CRRS, Toronto, 2009-2014; Iter and ACMRS, 2015 to present).</li>
                                    <li>Early English Books Online: <a href="https://search.proquest.com/eebo/index">https://search.proquest.com/eebo/index</a>. </li>
                                    <li>English Short Title Catalogue: <a href="http://estc.bl.uk/F/?func=file&file_name=login-bl-estc">http://estc.bl.uk/F/?func=file&file_name=login-bl-estc</a>.</li>
                                    <li>French Women Poets of Nine Centuries: The Distaff and the Pen, ed. Norman R. Shapiro (Baltimore: Johns Hopkins University Press, 2008).</li>
                                    <li>Hartlib Papers Online: <a href="https://www.dhi.ac.uk/hartlib/">https://www.dhi.ac.uk/hartlib/</a>.</li>
                                    <li>A History of Scottish Women’s Writing, ed. Douglas Gifford and Dorothy McMillan (Edinburgh: Edinburgh University Press, 1997).</li>
                                    <li>Iberian Books Project: <a href="https://iberian.ucd.ie/">https://iberian.ucd.ie/</a>. </li>
                                    <li>Kissing the Rod: An Anthology of Seventeenth-Century Women’s Verse, ed. Germaine Greet et al. (London: Virago, 1988).</li>
                                    <li>Dorothy Latz, Glow-worm light: Writings of Seventeenth-century Recusant Women from Original Manuscripts (Salzburg: Institut für Anglistik und Amerikanistik, 1989).</li>
                                    <li>NEWW Women Writers: <a href="http://resources.huygens.knaw.nl/womenwriters/">http://resources.huygens.knaw.nl/womenwriters/</a>.</li>
                                    <li>Oxford Dictionary of National Biography: <a href="https://www.oxforddnb.com/">https://www.oxforddnb.com/</a>.</li>
                                    <li>The Perdita Project: <a href="https://web.warwick.ac.uk/english/perdita/html/">https://web.warwick.ac.uk/english/perdita/html/</a> (2005) and Perdita Manuscripts, 1500-1700 (Adam Matthew, 2008).</li>
                                    <li>Reading Early Modern Women: An Anthology of Texts in Manuscript and Print, 1550-1700, ed. Helen Ostovich and Elizabeth Sauer (New York & London: Routledge, 2004).</li>
                                    <li>Jane Stevenson, Women Latin Poets: Language, Gender, and Authority, from Antiquity to the Eighteenth Century (Oxford: Oxford University Press, 2005).</li>
                                    <li>Who Were The Nuns? A Prosopographical Study of the English Convents in Exile 1600-1800: <a href="https://wwtn.history.qmul.ac.uk/">https://wwtn.history.qmul.ac.uk/</a>.</li>
                                    <li>Women Writers Online: <a href="https://www.wwp.northeastern.edu/wwo/">https://www.wwp.northeastern.edu/wwo/</a>.</li>
                                    <li>Women’s Writing from the Low Countries 1200-1875: A Bilingual Anthology, ed. Lia van Gemert et al. (Amsterdam: Amsterdam University Press, 2010).</li>
                                </ul>
                                
                            </Grid>
                        </Grid>
                    </Grid>




                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Catalogue);