import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';
import { IconContext } from "react-icons";
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Grid from '@material-ui/core/Grid';
import Menu from './Menu';
import { Nav, Navbar } from "react-bootstrap";
import { IoLogoTwitter } from 'react-icons/io';
import { FaBullhorn } from 'react-icons/fa';



const logoImage = require('../images/logo-recirc.png');

const styles = theme => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        borderBottom: `2px solid white`,
        // backgroundColor: 'white',

    },
    inline: {

        display: 'inline'

    },
    flex: {
        display: 'inline',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    productLogo: {
        display: 'inline-block',
        borderLeft: `1px solid ${theme.palette.grey['A100']}`,
        marginLeft: 32,
        paddingLeft: 24
    },
    tagline: {
        display: 'inline-block',
        marginLeft: 10
    },
    iconContainer: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    iconButton: {
        display: 'inline',
        float: 'right',
        justifyContent: 'center'
    },
    logoButton: {
        float: 'centre'
    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    tabItem: {
        paddingTop: 20,
        paddingBottom: 20,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    paper: {
        padding: theme.spacing.unit * 3,
        textAlign: 'left',
        color: theme.palette.text.secondary,
        backgroundColor: 'lightgrey'
    },
    logoItem: {
        paddingTop: 20,
        // paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center'
    },
    logoWrapper: {
        borderBottom: "2px solid white"
    }
})

class Topbar extends Component {

    state = {
        value: 0,
        menuDrawer: false,
        isHome: false
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    mobileMenuOpen = (event) => {
        this.setState({ menuDrawer: true });
    }

    mobileMenuClose = (event) => {
        this.setState({ menuDrawer: false });
    }

    componentDidMount() {
        window.scrollTo(0, 0);        
    }

    current = () => {
        
        if(this.props.currentPath === '/home') {            
            return 0
        }
        if(this.props.currentPath === '/explore') {
            return 1
        }
        if(this.props.currentPath === '/cases') {
            return 2
        }
        if(this.props.currentPath === '/about') {
            return 3
        }
        if(this.props.currentPath === '/contact') {
            return 4
        }
        if(this.props.currentPath === '/team') {
            return 5
        }
        if (this.props.currentPath === '/presentations-and-publications') {
            return 6
        }
        if(this.props.currentPath === '/blog') {
            return 7
        }
        if(this.props.currentPath === '/conference2017') {
            return 8
        }
        

    }

    render() {

        const { classes } = this.props;
        const isHome = (this.props.isHome ? this.props.isHome : false);

        return (

            <AppBar position="absolute" color="default" className={classes.appBar}>
                <Navbar>

                    <Navbar.Toggle />

                    <Navbar.Collapse className="justify-content-end">
                        <Nav>
                            <IconContext.Provider value={{ className: 'react-icons' }}>
                                <FaBullhorn height="2.5em" />
                            </IconContext.Provider>
                            <Nav.Link href="/contact" className="pr-5">Beta Version: Report an Issue</Nav.Link>
                            <IconContext.Provider  value={{ className: 'react-icons' }}>
                                <IoLogoTwitter height="2.5em" />
                            </IconContext.Provider>
                            <Nav.Link href="http://twitter.com/recirc_">Twitter</Nav.Link>
                        { /**
                            * @TODO

                            <Nav.Link eventKey={2} href="/blog">
                                Visit the blog archive
                            </Nav.Link>
                        */ }
                        </Nav>

                    </Navbar.Collapse>
                </Navbar>
                <Toolbar>
                    <Grid container spacing={24} alignItems="baseline">
                        <Grid item xs={12} className={classes.flex}>

                            <div className={classes.logoItem}>
                                <Grid container justify="center">
                                    <Grid alignItems="center" justify="center" container className={classes.grid}>
                                        
                                      
                                        <Grid item xs={12} md={6}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Link to="/">
                                                    <img src={ logoImage } alt="RECIRC"/>
                                                </Link>
                                            </div>
                                        </Grid>
                                      
                                                    
                                    </Grid>
                                </Grid>
                            </div>
                            { !this.props.noTabs && (
                                <React.Fragment>

                                    <div className={classes.iconContainer}>
                                        <IconButton onClick={this.mobileMenuOpen} className={classes.iconButton} color="inherit" aria-label="Menu">
                                            <MenuIcon />
                                        </IconButton>
                                    </div>
                                    <div className={classes.tabContainer}>
                                        <SwipeableDrawer anchor="right" open={this.state.menuDrawer} onOpen={this.mobileMenuOpen} onClose={this.mobileMenuClose} >
                                            <AppBar title="Menu" />
                                            <List>
                                                {Menu.map((item, index) => (
                                                    <ListItem key={index} component={Link} to={{pathname: item.pathname, search: this.props.location.search}} button>
                                                        <ListItemText primary={item.label} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </SwipeableDrawer>
                                        <Tabs
                                            value={this.current() || this.state.value}
                                            indicatorColor="primary"
                                            textColor="primary"

                                            onChange={this.handleChange}>
                                            {Menu.map((item, index) => (
                                                <Tab key={index} component={Link} to={{pathname: item.pathname, search: this.props.location.search}} classes={{root: classes.tabItem}} label={item.label} />
                                            ))}
                                        </Tabs>
                                    </div>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        )
    }
}

export default withRouter(withStyles(styles)(Topbar))