import React,  { Component } from 'react';
import {  withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import { Container, Row, } from 'react-bootstrap'
import { FaChevronRight } from 'react-icons/fa';

import styles_css from '../style.module.css';

import Topbar from './Topbar';
import Footer from './Footer';

import { config } from '../config';



const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        width: 1200,
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
    gridMain: {
        maxWidth: 1200,
        
        // marginBottom: '20px',
        margin: '0 auto 20px'
    },
    
    exploreSubhead: {
        // lineHeight: '1.2em' 
    },
    
    box2: {
        marginBottom: 20,
        marginTop: 10,
        marginLeft: 40,
        marginRight: 40,
    },
    exploreBox: {
        textAlign: 'center',
        minHeight: 100
    },
    boxLink: {
        color: '#222',
        display: 'block',

        '&:hover': {
            color: '#000',
            background: '#eee',
            textDecoration: 'none',
        }
    },

    exploreDataBox: {
        paddingTop: 10,
        marginBottom: 10,
        height: 45,
        width: 180,
        justifyContent: 'flex-start',
        alignItems: 'left',
        marginRight: 1,
        marginLeft: 1,
        display: 'flex'
    },
    exploreDataBox1: {
        paddingTop: 10,
        marginBottom: 10,
        height: 45,
        width: 40,
        justifyContent: 'flex-start',
        alignItems: 'left',
        marginRight: 1,
        marginLeft: 1,
        display: 'flex'
    },
    exploreDataBox2: {
        paddingTop: theme.spacing.unit * 2,
        marginBottom: 10,
        height: 45,
        marginRight: 10,
        marginLeft: 60,
        textAlign: 'center',
        display: 'inline'
    },
});

const images = {
    'reception': require('../images/explore-receptions.png'),
    'source': require('../images/explore-sources.png'),
    'people': require('../images/explore-people.png'),
    'works': require('../images/explore-works.png')
};

class Explore extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: true,
            count: null
        };
    }

    componentDidMount()
    {
        fetch(config.apiUrl + '/config')
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    count: responseJson["count"]
                });

            })
            .catch((error) => {
                console.error(error);
                this.setState({
                    isLoading: false
                });
            });
    }


    render() {
        
        const { classes } = this.props;
        const currentPath = this.props.location.pathname
        const dataCounts = this.state.count;

        let works_count = null;
        if(dataCounts){
            works_count = dataCounts.works;
        }

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Container>
                        <header className={classes.gridMain}>
                            <Grid container item xs={12} className={classes.grid + ' mb-0'} justify="flex-start" alignItems="flex-start" spacing={24}>
                                <Grid item xs={12} md={8} style={{ display: 'flex', margin: '0 auto'} } className="mb-0 pb-0">
                                    <div style={{display: 'block', textAlign: 'center'}}>
                                        <Typography variant="h1" gutterBottom style={{ 'paddingTop': '0.5em', 'marginBottom': '.125em', fontSize: '56px' }}>Search the RECIRC Database</Typography>
                                        <p className={ styles_css.pageSubtitle + ' text-muted'}>
                                            Investigate which female authors were read, by whom, and how texts by women circulated.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={8} style={{ display: 'flex', margin: '0 auto'} } className="mb-0 pb-0">
                                <nav id={styles_css.exploreInternalNav}>
                                <ul className="nav">
                                    <li className="nav-item">
                                        <a href="#top" className="nav-link disabled">
                                            Database Sections:
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={`#receptions`} smooth className="nav-link">
                                            Receptions
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={`#sources`} smooth className="nav-link">
                                            Sources of Reception
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={`#people`} smooth className="nav-link">
                                            People
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to={`#works`} smooth className="nav-link">
                                            Writings by Women
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                            </Grid>
                        </header>
                    </Container>
                    <Row className={`${styles_css.exploreHighlight} ${styles_css.explore}`} id="receptions">
                        <Container>
                            <Grid container item xs={12} className={classes.grid + ' mt-0'} justify="flex-start" alignItems="flex-start" spacing={24}>
                                <Grid item md={6} style={{textAlign: 'center'}}>
                                    <Link className={classes.boxLink} to="/receptions">
                                        <img src={ images.reception } alt="Receptions Search Screenshot" />
                                    </Link>
                                </Grid>

                                <Grid item md={6} >

                                    <Link to="/receptions">
                                        
                                        <Typography variant="h2" style={{ alignItems: 'center' }} gutterBottom>
                                            Receptions
                                        </Typography>
                                    </Link>
                                        <Typography variant="subtitle1" className={classes.exploreSubhead + 'text-muted'}>
                                            Explore receptions and circulation
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            A reception is the record of engagement with a female author and/or her work. Circulation is the record of where a female-authored work went.
                                        </Typography>
                                        <Link to={`/receptions`} className={styles_css.exploreHomeBtn}>
                                            Search Receptions <FaChevronRight />
                                        </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Row>
                        
                    

                    <Row className={` ${styles_css.explore}`} id="sources">
                            <Container>
                                <Grid container item className={classes.grid + ' mt-0'} justify="flex-start" alignItems="flex-start" spacing={24}>
                                    
                                    <Grid item md={6} >
                                        <Link to="/sources">
                                            <Typography variant="h2" style={{ alignItems: 'center' }} gutterBottom>
                                                Sources of Reception
                                            </Typography>
                                        </Link>
                                            <Typography variant="subtitle1" className={classes.exploreSubhead + 'text-muted'}>
                                                Investigate the source documents in which receptions are found
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                                The kinds of sources we analysed include manuscript miscellanies, library catalogues, letters, convent chronicles, and translations. For the full list, select <em>Reception Source Type</em>.
                                            </Typography>
                                            <Link to={`/sources`} className={styles_css.exploreHomeBtn}>
                                                Search Sources <FaChevronRight />
                                            </Link>
                                    </Grid>
                                    <Grid item md={6} style={{ textAlign: 'center' }}>
                                        <Link className={classes.boxLink} to="/sources">
                                            <img src={ images.source } alt="Sources of Reception Screenshot" />
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Row>

                        <Row className={`${styles_css.exploreHighlight} ${styles_css.explore}`} id="people">
                        <Container>
                            <Grid container item xs={12} className={classes.grid + ' mt-0'} justify="flex-start" alignItems="flex-start" spacing={24}>
                                <Grid item md={6} style={{ textAlign: 'center' }}>
                                    <Link className={classes.boxLink} to="/people">
                                        <img src={ images.people } alt="People Search Screenshot" />
                                    </Link>
                                </Grid>

                                <Grid item md={6} >

                                    <Link to="/people">
                                        <Typography variant="h2" style={{ alignItems: 'center' }} gutterBottom>
                                            People
                                        </Typography>
                                    </Link>
                                        <Typography variant="subtitle1" className={classes.exploreSubhead + 'text-muted'}>
                                            Investigate which female authors were read or circulated, and by whom
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Our database includes three categories of people: female authors, owner/compiler/scribes (those who produced the source documents), and receivers (those who authored or signed the specific items of reception in the source documents).
                                        </Typography>
                                        <Link to={`/people`} className={styles_css.exploreHomeBtn}>
                                            Search People <FaChevronRight />
                                        </Link>
                                    
                                </Grid>
                            </Grid>
                        </Container>
                    </Row>

                    


                    <Row className={` ${styles_css.explore}`} id="works">
                        <Container>

                            <Grid container item className={classes.grid + ' mt-0'} justify="flex-start" alignItems="flex-start" spacing={24}>

                                <Grid item md={6} >
                                    <Link to="/works">
                                        <Typography variant="h2" style={{ alignItems: 'center' }} gutterBottom>
                                            Writings by Women
                                        </Typography>
                                    </Link>
                                        <Typography variant="subtitle1" className={classes.exploreSubhead + 'text-muted'}>
                                            Search { works_count } female-authored texts
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Our database includes all individual texts (e.g. poems) written by women in English and printed works written by non-anglophone women. This dataset can be used as a comprehensive finding aid for texts by female authors. XX% are linked to reception or circulation evidence found during the project
                                        </Typography>
                                        <Link to={`/works`} className={styles_css.exploreHomeBtn}>
                                            Search Writings <FaChevronRight />
                                        </Link>
                                    
                                </Grid>
                                <Grid item md={6} style={{ textAlign: 'center' }}>
                                    <Link className={classes.boxLink} to="/works">
                                        <img src={ images.works } alt="Writings by Women Screenshot" />
                                    </Link>
                                </Grid>
                            </Grid>
                        </Container>
                    </Row>


                    
                </div>
    
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withRouter(withStyles(styles)(Explore));