import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Typography } from '@material-ui/core';
import { FaHome } from 'react-icons/fa';

// images
import img_elizabeth_1 from '../../images/case-elizabeth-1.png';
import img_elizabeth_2 from '../../images/case-elizabeth-2.png';


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    },
    paper: {
        padding: '2em'
    }
})

class ReceptionElizabeth extends Component {

   
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper className={ classes.paper }>
                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reception of Elizabeth I</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>

                    <Typography variant="h1" className="pb-4">Reception of Elizabeth I</Typography>
                    
                    <div className="case-intro">
                        <p>
                            RECIRC has captured 569 receptions of <Link to="/people/person/2670">Queen Elizabeth I</Link>, more than any other female author in the database. The types of reception that occur are diverse - there are dedications, references to named author, references to specific works, transcriptions, poems etc. in miscellanies, letters, auction catalogues etc. 
                        </p>
                    </div>

                    <iframe className="mt-4 mb-4" width="910" height="500" src="https://www.youtube.com/embed/1NguGAbFYbo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                    <Typography variant="h2" className="pb-2">Elizabeth I as Author</Typography>
                    <p>
                        The RECIRC database contains 105 speeches, poems, and letters attributed to Elizabeth, circulated in 46 sources down to the 1730s. 
                    </p>
                    <ul>
                        <li>
                            To <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":84,"value":"reference-to-named-work","label":"Reference to Specific Work"},{"id":89,"value":"reference-to-unspecified-works","label":"Reference to Unspecified Work(s)"}],"termLabel":"Reference to Specific Work OR Reference to Unspecified Work(s)"}]&view=table'}>explore receptions of her works</Link>: Go to the <Link to="/receptions">advanced reception search</Link> and choose the field "Female Author" and enter "Elizabeth Tudor". Then, choose the field "Reception Type" and select "Reference to Specific Work" and "Reference to Unspecified work(s)". 

                            <br />
                            <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":84,"value":"reference-to-named-work","label":"Reference to Specific Work"},{"id":89,"value":"reference-to-unspecified-works","label":"Reference to Unspecified Work(s)"}],"termLabel":"Reference to Specific Work OR Reference to Unspecified Work(s)"}]&view=table'}>
                                <img src={img_elizabeth_1} alt="Screenshot of search for receptions of Queen Elizabeth I" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>
                    <p>
                        This produces a table of 89 results. If you would like to see how many are receptions of specific works by Elizabeth, you can refine by "Reception Type" to see that 84 of the 89 results are receptions of a specific work. You can also explore these receptions in more detail by clicking on any one of the receptions listed in the table.
                    </p>
                        
                    <Typography variant="h2" className="pb-2">Poetic Receptions</Typography>
                    
                    <p>
                        In total, there are 80 poetic receptions of Elizabeth I in the database. 
                    </p>

                    <ul>
                        <li>
                            To explore these poetic receptions: Go to the advanced search and choose the field "Female Author" and enter in "Elizabeth Tudor". Then, choose the field "Reception Type" and select "Poem".  <br />
                            <br/>

                            <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":90,"value":"praise-poem","label":"Poem"}],"termLabel":"Poem"}]&view=table'}>
                                <img src={img_elizabeth_2} alt="Screenshot of search for poetic receptions of Elizabeth I" className="mt-4 mb-4" />
                            </Link>
                        </li>
                    </ul>

                    <p>
                        One of the poetic receptions about Elizabeth is <Link to="/people/person/3566">Thomas Dekker's</Link> "On Queene Elizabeth carried to buriall by water." This poem recalls how, after her death on 24th March 1603, Elizabeth's body was brought by barge along the Thames from Richmond Palace to Whitehall, where it lay in state until her funeral on 28th April 1603: 
                    </p>

                    <blockquote style={{paddingLeft: '2em', fontStyle: 'italic'}}>
                        
                            On Queene Elizabeth carried to buriall by water<br/>
                        <br />
                        The Queene was brought by water to Whitehall	<br />
                        At euery stroak the oares did teares let fall		<br />
                        More clung, about the barge, fish under water	<br />
                        Wept out their pearles eies and sworne blind after	<br />
                        I think the barg[e]men might with easier thig[h]es	<br />
                        Have row’d her thither in her peoples eies	<br />
                        For howsoever thus my thoughts have scan[n’]d	<br />
                        Shee’d gone by water had shee come by land.  <br />
                        
                    </blockquote>
            
                    <ul>
                        <li>
                            To find the various transcriptions of Dekker's poem in the database, you can explore <Link to="/people/person/3566">Thomas Dekker's profile page</Link> or <Link to={'/receptions?query_type=must&term=[{"name":"author_id","label":"Female Author","filter":"equals","term":2670,"termLabel":"Elizabeth I"},{"name":"receiver_name","label":"Receiver","filter":"equals","term":3566,"termLabel":"Thomas Dekker"},{"name":"receptiontype","label":"Reception Type","filter":"in","term":[{"id":90,"value":"praise-poem","label":"Poem"}],"termLabel":"Poem"}]&view=table'}>perform the following advanced search</Link>: choose the field "Named Receiver" and enter "Thomas Dekker". Then, choose the field "Female Author" and enter "Elizabeth Tudor". Finally, choose the field “Reception Type” and select “Poem”.
                        </li>
                    </ul>
 
                     <p>
                        While the poem is penned by Dekker, there is an added layer of reception relating to Elizabeth. There are 11 different owner/compiler/scribes engaging with Elizabeth by <Link to="/cases/layers-and-agents-of-reception">transcribing this poem about her into their miscellany</Link>.
                     </p>   


                    

                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reception of Elizabeth I</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>

 
                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(ReceptionElizabeth);