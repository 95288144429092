import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import {Card} from 'react-bootstrap';
import Grid from '@material-ui/core/Grid'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Cell, LabelList , Label} from 'recharts';
import { config } from '../../config';

const styles = () => ({

})





class ReceptionGraph extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            receiver_people: [],
            isMarkerShown: false,
            sourceTypeDistribution: []
        };
    }


    componentDidMount() {
        let dist = config.sourceworkDistributions;
        this.setState({
            sourceTypeDistribution: dist,
        })
    }

    render() {

        let {sourceTypeDistribution} = this.state;
        let { reception_source } = this.props;

        if( sourceTypeDistribution.length === 0){
            return null;
        }

        let data = [],
            bars = [];

        
        sourceTypeDistribution.forEach((item) => {
            if (reception_source.source_type === item.source_type){
                bars.push(
                    <Bar dataKey='count' fill="#8884d8" barGap={0} barSize={5}>
                        <LabelList dataKey="count" position="top" />
                    </Bar>
                );
            }
        });

        bars.push(
            <Bar dataKey='count' fill="#ccc" barGap={0} barSize={5}>
                <LabelList dataKey="count" position="top" />
            </Bar>
        );

       

        // console.log(data);
        
        return (

            <Grid item xs={12} >
                <Card className="text-center mt-2">
                    <Card.Body >
                        <Card.Text className="text-primary">
                            <Typography variant="caption">
                                <strong>Distribution of <em>all</em> Reception Source Types in the database</strong> 
                            </Typography>
                            <p className={'text-muted'}>
                                <small>
                                    (This Source of Reception is of type: <em>{reception_source.source_type}</em>)
                                </small>
                            </p>
                        </Card.Text>

                        <BarChart width={550} height={250} barGap={0} data={sourceTypeDistribution} margin={{top: 15, right: 5, bottom: 5, left: 15}}>
                            <XAxis dataKey="source_type" tick={false}>
                                <Label value='Reception Source Type' position='center' style={{ fontSize: '.9em' }} />    
                            </XAxis>
                            <YAxis dataKey="count" style={{fontSize: '.5em' }}>
                                <Label value='Number of Sources' angle={-90} position='inside' offset={-10} style={{ fontSize: '.9em' }} />
                            </YAxis>
                            <Tooltip />
                            <Bar dataKey='count' barGap={0} barSize={5}>
                                <LabelList dataKey="count" position="top" style={{ fontSize: '10px' }} />
                                {
                                    sourceTypeDistribution.map((item, index) => {
                                        const color = (reception_source.source_type === item.source_type) ? '#f60' : '#aaa';
                                        return <Cell key={`cell-` + index} fill={color} />;
                                    })
                                }
                            </Bar>
                        </BarChart>



                    </Card.Body>
                </Card>
            </Grid>


        )
    }
}

export default withRouter(withStyles(styles)(ReceptionGraph))