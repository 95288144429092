import React  from 'react';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from '../Topbar';
import SourceResultsTable from './SourceResultsTable';
import SourceGraph from '../Network/SourceGraph';
import SourceSearch from './SourceSearch'
import Footer from '../Footer';
import {Alert, Card} from 'react-bootstrap';
import { FaBook, FaCogs} from 'react-icons/fa';
import styles_css from '../../style.module.css';
import { config } from '../../config';
import InterestingSearches from '../common/InterestingSearches';

const qs = require('query-string');
const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundSize: 'cover',
        backgroundPosition: '0 400px',
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    },
    gridMain: {
        width: 1300,
        paddingBottom: 30
    },
    gridRight: {
        padding: 20,
        width: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    inlining: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10
    },
    inliningBlock: {
        display: 'block',
        marginRight: 10,
        marginLeft: 25
    },
    inliningRight: {
        display: 'inline',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 10,
        marginLeft: 35
    },

})





class Sources extends React.Component {

    constructor(props, context) {
        super(props, context);


        this.handleChange = this.handleChange.bind(this);
        // this.handleRefine = this.handleRefine.bind(this);
        this.toggleResultsView = this.toggleResultsView.bind(this);

        this.state = {
            isLoading: false,
            showSearchResults: false,
            showNoResults: false,
            showNetwork: false,
            showTable: false,
            reception_types: '',
            receptionSources: '',
            receptionSourcesTypes: [],
            circulationtypes: [],
            languages: '',
            receptionSearchResponse: [],
            search_list: [],
            peopleSearchResponse: [],
            peopleList: [],
            receivers: [],
            person:[],
            collection:[],
            people_detail: [],
            updateRefineSearch: false,
            originalReceptions: [],
            search_terms: '',
            searchRows: [],
            error_message: ''
        };
    }

    componentDidMount() {
        if (this.props.history.action === "POP") {
            console.log("Back button pressed")
        }

        let queryType = ''
        let term_list = '';
        let view = 'table';

        queryType = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).query_type
        term_list = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term
        view = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).view;

        if (queryType !== undefined && term_list !== undefined) {

            view = (view === 'graph' ? 'graph' : 'table');

            let params = {
                "query_type": queryType,
                "term": term_list,
                "view": view
            }

            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            this.setState({term_list: term_list})
            let term_json = JSON.parse(term_list);
            let searchRow = []
            term_json.forEach(row => {
                let labels = row.termLabel;
                labels = labels.replace(/<\/?[^>]+(>|$)/g, "");
                let item = {
                    "name": labels,
                    "search_type": row.filter,
                    "search_value": row.label,
                    "linked": true
                }

                searchRow.push(item)
            })
            this.setState({searchRows: searchRow})
            // console.log(searchRow)
            this.props.history.push({
                pathname: '/sources',
                search: '?' + query
            })
            this.setState({ isLoading: true }, () => {
                fetch(config.apiUrl + '/search/reception-source/advanced?' + query)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.error !== undefined) {
                            this.handleChange(false, responseJson, false, query, true, this.state.isLoading);
                        } else {
                            this.handleChange(true, responseJson);
                            let showNetwork = false;
                            let showTable = true;

                            if(view === 'graph'){
                                showNetwork = true;
                                showTable = false;
                            }
                            this.setState({
                                showSearchResults: true,
                                showTable: showTable,
                                showNetwork: showNetwork,
                                updateSearchButton: false,
                                searchCount: responseJson.total,
                                isLoading: false,
                                query
                            });
                        }


                    }).catch((error) => {
                        this.handleChange(false, error, false, query, true, this.state.isLoading);
                });
            });
        }


        fetch( config.apiUrl + '/config')
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    isLoading: false,
                    reception_types: responseJson['receptionTypes'],
                    receptionSourcesTypes: responseJson["receptionSources"],
                    languages: responseJson['languages'],
                    circulationtypes: responseJson['circulationTypes'],
                    source_count: (responseJson['count']) ? responseJson['count']['reception_sources'] : null, 
                });

            });

    }

    componentDidUpdate(prevProps) {

        if (this.props.location.search !== prevProps.location.search) {
            let queryType = ''
            let term_list = ''
            let view = 'table';

            //console.log(this.props.location.search)
            queryType = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).query_type
            term_list = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).term
            view = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).view;

            console.log(queryType)
            if (queryType !== undefined && term_list !== undefined) {
                view = (view === 'graph' ? 'graph' : 'table');

                let params = {
                    "query_type": queryType,
                    "term": term_list,
                    'view': view
                }


                let term_json = JSON.parse(term_list);
                let searchRow = []
                term_json.map((row) => {
                    let labels = row.termLabel;
                    labels = labels.replace(/<\/?[^>]+(>|$)/g, "");
                    let item = {
                        "name": labels,
                        "search_type": row.filter,
                        "search_value": row.label
                    }

                    searchRow.push(item)
                })
                this.setState({searchRows: searchRow})

                let query = Object.keys(params)
                    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                    .join('&');
                this.setState({term_list: term_list})
                this.props.history.push({
                    pathname: '/sources',
                    search: '?' + query
                })
                this.setState({ isLoading: true }, () => {
                    fetch(config.apiUrl + '/search/reception-source/advanced?' + query)
                        .then((response) => response.json())
                        .then((responseJson) => {
                            if (responseJson.error != undefined) {
                                this.handleChange(false, responseJson, false, query, true, this.state.isLoading);
                            } else {
                                this.handleChange(true, responseJson);

                                let showNetwork = false;
                                let showTable = true;

                                if (view === 'graph') {
                                    showNetwork = true;
                                    showTable = false;
                                }

                                this.setState({
                                    showSearchResults: true,
                                    updateSearchButton: false,
                                    searchCount: responseJson.total,
                                    isLoading: false,
                                    showNetwork,
                                    showTable,
                                    query
                                });
                            }


                        }).catch((error) => {
                            this.handleChange(false, error, false, query, true, this.state.isLoading);
                    });
                });
            }
        }
    }


    toggleResultsView(showResultsAsTable)
    {
        this.setState({
            showNetwork: !this.state.showNetwork,
            showTable: !this.state.showTable
        });
    }


    search(person) {
        return fetch( config.apiUrl + `/person/${person}`)
            .then((response) => response.json())
            .then((responseJson) => {

                this.setState({
                    isLoading: false,
                    person: this.state.person.concat([responseJson]),
                });

            }).catch((error) =>{
                console.error(error);
            });

    }


    handleRemoveClick() {
        this.setState({
          peopleSearchResponse: [], 
          showNoResults: false, 
          showSearchResults: false, 
          originalReceptions: []
        });

    }

    handleChange(showSearchResults, receptionSearchResponse, isClearSearch, query, isError, isLoaded) {
        if (isError) {
            console.log(receptionSearchResponse)
            this.setState({
                isLoading: false,
                showSearchResults: false,
                showNoResults: true,
                error_message: receptionSearchResponse.message

            });

        }
        else if (isClearSearch) {
            this.handleRemoveClick()
        } else {
            if (!isError){
                this.setState({
                    search_list: [],
                    receptionSearchResponse: [],
                    peopleSearchResponse: [],
                    peopleList: [],
                    isLoading: true,
                    receivers: []
                })
                // set the search terms as a prop
                if(query !== undefined) {
                    this.props.history.push({
                        pathname: '/sources',
                        search: '?' + query
                    })
                }
                this.setState({
                    isLoading: false,
                    showSearchResults: true,
                    receptionSources: receptionSearchResponse,
                    receptionSearchResponse: receptionSearchResponse,
                    originalReceptions: receptionSearchResponse,
                    peopleSearchResponse: receptionSearchResponse,
                    search_terms: query,
                    query
                });

                 // if nothing is setting the results table or graph view
                // default to the table view
                if(!(this.state.showNetwork && this.state.showTable)){
                    this.setState({
                        showTable: true
                    });
                }
            }

        }

    }


    // handleRefine(showSearchResults, receptionSearchResponse) {

    //     this.setState({peopleSearchResponse: receptionSearchResponse,
    //         receptionSearchResponse: receptionSearchResponse,
    //         updateRefineSearch: true});


    // }



  render() {
    const { classes } = this.props;
    const currentPath = this.props.location.pathname;
    const { isLoading } = this.state;
    const { showSearchResults } = this.state;
    const { receptionSearchResponse } = this.state;
    // const { collection } = this.state;
    const { source_count } = this.state;

    const {showNetwork, showTable} = this.state;

    return (
      <React.Fragment>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        <div className={classes.root}>
          <Grid container spacing={24} justify="center">
            <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                <Grid container >
                    <Grid item xs={6} className={classes.grid}>

                        <FaBook />
                        <Typography className={styles_css.reception_typography} variant="h6">Sources of Receptions</Typography>


                    </Grid>

                    <Grid item xs={6} className={classes.gridRight}>
                        <FaCogs />
                            <Typography className={styles_css.reception_typography} variant="h6">
                                <Link to="/about-data">
                                    About this data
                            </Link>
                        </Typography>
                    </Grid>
                    <div className={classes.inliningBlock}>
                        <Typography variant="body2">
                            Explore {source_count } Reception Sources
                        </Typography>
                    </div>
                </Grid>
            </Grid>

              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>
                     <SourceSearch isLoading={isLoading}
                                    history={this.props.history}
                                   onChange={this.handleChange}
                                   languages={this.state.languages}
                                   people_detail={this.state.people_detail}
                                   reception_types={this.state.reception_types}
                                   receptionSourcesTypes={this.state.receptionSourcesTypes}
                                   circulationTypes={this.state.circulationtypes}
                                   showSearchResults={showSearchResults}
                                   receptionSearchResponse={receptionSearchResponse}
                                   searchRows={this.state.searchRows}
                                   resultCount={this.state.searchCount}
                                    showNetwork={showNetwork}    
                                    showTable={showTable}
                                   />
                      <Grid item xs={4} >
                          <Card>
                              <Card.Body>
                                    <InterestingSearches searchType="reception_source" allSearches={config.interestingSearches} />                                  
                              </Card.Body>
                          </Card>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                  <Grid container spacing={24}>
                  
                    { 
                        (showSearchResults  && showTable) && 
                            <SourceResultsTable
                                // onChange={this.handleChange}
                                person={this.state.person}
                                receivers={this.state.receivers}
                                isLoading={this.state.isLoading}
                                receptionSearchResponse={this.state.peopleSearchResponse}                                    
                                showTable={true}
                                showNetwork={false} 
                                onChange={this.toggleResultsView} 
                                query={this.state.query}
                            /> 
                    }

                    {
                        // network view
                        (showSearchResults && showNetwork) && 
                            <SourceGraph
                                person={this.state.person}
                                receivers={this.state.receivers}
                                isLoading={this.state.isLoading}
                                receptionSearchResponse={this.state.peopleSearchResponse} 
                                showTable={false} 
                                showNetwork={true} 
                                onChange={this.toggleResultsView} 
                            />
                    }

                    { // no results
                        this.state.showNoResults &&
                            <Grid item xs={12} >
                                <Card className={styles_css.table_no_results_card_table}>
                                    <Alert key={'alert'} variant={'danger'}>
                                        Error - {this.state.error_message}.
                                    </Alert>
                                </Card>
                            </Grid>
                      }
                  </Grid>


              </Grid>


          </Grid>
        </div>


          <Footer/>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(Sources);