import React  from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { withRouter, Link } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import {ButtonGroup, Button, Card} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import {Col} from 'react-bootstrap';
import PeopleSearchAutoComplete from './PeopleSearchAutoComplete';
import { FaInfoCircle, FaCheck, FaPlusCircle, FaMinusCircle, FaTimes, FaSearch } from 'react-icons/fa';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PersonSelectDialog from "./PersonSelectDialog";
import Picky from 'react-picky';
import 'react-picky/dist/picky.css'; // Include CSS
import styles from '../../style.module.css';
import {peopleSearchTermBuilder} from '../common/Utils'
import { config } from '../../config';
// import { thisExpression } from '@babel/types';



const types = [
    {
        value: 'Person',
        label: "Person",
        filter: ['All', 'Contains', "Equals", 'Is Nun', 'Has Aristocratic Title', 'Is Author', 'Is Receiver', 'Is Owner / Compiler / Scribe'],
        default: true
    },
    {
        value: 'Religious Order',
        label: 'Religious Order',
        filter: "Equals",
        default: true
    },
    {
        value: 'Religious House',
        label: "Religious House",
        filter: "Equals",
        default: true
    },
    {
        value: 'Sex',
        label: 'Sex',
        filter: ["Female", "Male", "Unknown"],
        default: true
    },
];

const filter = [

    {
        value: 'All',
        label: 'All',
        types: ['Person']
    },
    {
        value: 'Contains',
        label: 'Contains',
        types: ['Person']
    },
    {
        value: 'Equals',
        label: 'Equals',
        types: ['Person', 'Religious Order', "Religious House", 'Sex']
    },
    // {
    //     value: 'Male',
    //     label: 'Male',
    //     types: ['Sex']
    // },
    // {
    //     value: 'Female',
    //     label: 'Female',
    //     types: ['Sex']
    // },
    // {
    //     value: 'Unknown',
    //     label: 'Unknown',
    //     types: ['Sex']
    // },
    {
        value: 'Is Nun',
        label: 'Is Nun',
        types: ['Person']
    },
    {
        value: 'Has Aristocratic Title',
        label: 'Has Aristocratic Title',
        types: ['Person']
    },
    {
        value: 'Is Author',
        label: 'Is Author',
        types: ['Person']
    },
    {
        value: 'Is Receiver',
        label: 'Is Receiver',
        types: ['Person']
    },
    {
        value: 'Is Owner / Compiler / Scribe',
        label: 'Is Owner / Compiler / Scribe',
        types: ['Person']
    },
    {
        value: 'Has Pseudonyms',
        label: 'Has Pseudonyms',
        types: ['Person']
    }
];

const person = [{
    value: "Person",
    label: "search",
    types: 'Person',
    options: ['All', 'Contains', "Equals", 'Is Nun', 'Has Aristocratic Title', 'Is Author', 'Is Receiver', 'Is Owner / Compiler / Scribe', 'Has Pseudonyms']
}];

const religious_order = [{
    value: "Religious Order",
    label: "search",
    types: 'Religious Order',
    options: ['Annunciation', 'Augustinian', 'Benedictine', 'Bridgettine', 'Carmelite', 'Carthusian', 'Cistercian', 'Dominican', 'Fontevrault', 'Franciscan', 'Hieronymite', 'Jesuit',
        'Mary Ward Institute', 'Poor Clare', 'Secular clergy', 'Sepulchrine', 'Ursuline', 'Visitation']
}];


const religious_house = [{
    value: "Religious House",
    label: "search",
    types: 'Religious House',
    options: ['Aire (1629), Poor Clares: Convent of the Immaculate Conception', 'Antwerp (1612), Spanish Carmelites', 'Antwerp (1619), English Carmelites', 'Assisi, Poor Clares: San Damiano', 'Augsberg (1662), Mary Ward Institute',
        'Avila, Carmelites: Convent of the Incarnation', 'Avila, Carmelites: Convento de San Jos\u00e9', 'Bologna, Poor Clares: Corpus Domini', 'Bruges (1629), Augustinians: Convent of Nazareth', 'Brussels (1598), Benedictines: Monastery of the Glorious Assumption',
        'Brussels (1621) Nieuport (1637) Bruges (1662), English Franciscans', 'Brussels (1661), English Dominicans', 'Cambrai (1623), Benedictines: Our Lady of Consolation',
        'Dunkirk (1652), English Poor Clares', 'Dunkirk (1662), English Benedictines', 'Florence, Carmelites: St Mary of the Angels', 'Florence, Franciscans: San Girolamo sulla Costa di San Giorgio',
        'Galway (1641), Irish Poor Clares', 'Geneva, Poor Clares: Convent of Saint Clare', 'Ghent (1624), Benedictines: Immaculate Conception of our Blessed Lady', 'Gravelines (1609), Poor Clares: Convent of Nazareth',
        'Hoogstraten (1678), English Carmelites', 'Li\u00e8ge (1642), English Sepulchrines', 'Lierre (1648), English Carmelites', 'Lisbon (1594; medieval foundation, exile in Mechelen 1573, Rouen 1580), Bridgettines: Syon Abbey',
        'Lisbon, Dominicans: Convent of Nossa Senhora da Ros', 'Lough Ree (1630, from Dublin 1629), Irish Poor Clares: Bethlehem', 'Louvain (1609), Augustinians: St Monica\'s', 'Louvain, Augustinians: St Ursula\'s',
        'Madrid, Benedictines: Convent of San Pl\u00e1cido', 'Madrid, Cistercians: Encarnaci\u00f3n del Orden de San Bernardo', 'Mary Ward Institute', 'Milan, Dominicans: Santa Maria delle Grazie', 'Nimbschen, Cistercians: Marienthron',
        'Paray-le-Monial, Order of the Visitation of Holy Mary', 'Paris (1634), Augustinians: Our Lady of Sion', 'Paris (1651), Benedictines: Convent of our Blessed Lady of Good Hope', 'Paris (1658), Conceptionists (Franciscan): "Blue Nuns"',
        'Paris, Cistercians: Port-Royal-des-Champs Abbey', 'Pavia, Benedictines: Santa Maria Teodote', 'Pontoise (1652), English Benedictines', 'Rheims, Benedictines: St-Pierre-les-Dames', 'Rouen (1644), Poor Clares: Convent of Jesus-Mary-Joseph',
        'St Albans, Benedictines: Priory of St Mary of Sopwell', 'St Omer, Poor Clares: Walloon Convent ', 'Tongres, Sepulchrines: Convent of the Holy Sepulchre', 'Tournai, Augustinians: Abbey Notre-Dame des Pres',
        'Valencia, Poor Clares: Convento de la Sant\u00edsima Trinidad', 'Venice, Dominicans: Corpus Domini', 'York (1686), Mary Ward Institute', 'Ypres (1665, Irish from 1682), Benedictines: Monastery of Gratia Dei',
        'Zaragoza, Carmelites: Monastery of the Incarnation']
}];

const sex = [{
    value: "Sex",
    label: "search",
    types: 'Sex',
    options: ['Male', 'Female', 'Unknown']
}]


function jsUcfirst(string)
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}

class PeopleSearch extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleSearchClick = this.handleSearchClick.bind(this);
        this.handleAddClick = this.handleAddClick.bind(this);
        this.handleRemoveClick = this.handleRemoveClick.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSearchValue = this.handleSearchValue.bind(this);
        this.handleAdvancedClick = this.handleAdvancedClick.bind(this);
        this.handleBasicClick = this.handleBasicClick.bind(this);
        this.handleBasicSearch = this.handleBasicSearch.bind(this);

        this.state = {
            isLoading: false,
            showSearchResults: false,
            updateSearchButton: false,
            receptionSearchResponse: [],
            updateSearch: false,
            searchRow: [],
            selectedType: "",
            userInput: "",
            selectedFilter: "",
            searchEnabled: true,
            open: false,
            selectedValue: "",
            searchCount: 0,
            basicSearch: props.basic_search,
            searchId: 0,
            receptionTypeSearchTerm: [],
            query_type: 'must',
            current_terms: [],
            nun_list: [],
            enable_add_button: true

        };
    }



    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {

                this.setState({
                    isLoading: this.props.isLoading,
                    searchCount: this.props.resultCount,
                    basicSearch: this.props.isBasicSearch
                });
      
            if (this.props.searchRows !== prevProps.searchRows) {

                let searchRows = this.props.searchRows;

                if (searchRows.length > 0 && this.state.searchRow) {
                    this.setState({
                        // searchRow:  this.state.searchRow.concat(searchRows),
                        searchRow: searchRows,
                        updateSearch: true,
                        showSearchResults: true,
                        isLoading: this.props.isLoading,
                        searchCount: this.props.receptionSearchResponse.length,
                        basicSearch: this.props.isBasicSearch});
                }

            }
        }

    }

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    /**
     * Triggered when a name is clicked
     * in <PersonSelectDialog />
     * 
     */
    handleClose = value => {
        
        // console.log(value);

        this.setState({
            searchTerm: value.label,     // person name
            searchId: value.id,             // person id
            open: false                     // close dialog
        });
    };

    handleSearchClick(e) {
        e.preventDefault();
        this.setState({ isLoading: true }, () => {
            let display_name = this.state.searchRow;
            let religiousHouse = this.props.religiousHouse;
            let religiousOrder = this.props.religiousOrder;
            let queryType = this.state.query_type;
            let current_terms = this.state.current_terms;
            let term_list = peopleSearchTermBuilder(current_terms, display_name, religiousOrder, religiousHouse, queryType)
            let params = {
                "query_type": queryType,
                "term": term_list
            }
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            this.setState({term_list: term_list})
            display_name.map(() => {

                fetch(config.apiUrl + '/search/person/advanced?' + query)
                    .then((response) => response.json())
                    .then((responseJson) => {
                        if (responseJson.error != undefined) {
                            this.props.onChange(false, responseJson, false, query, true, false);
                            this.setState({
                                isLoading: false
                            });
                        } else {
                            this.props.onChange(true, responseJson, false, query, false, this.state.isLoading);
                            this.setState({
                                showSearchResults: true,
                                updateSearchButton: false,
                                searchCount: responseJson.total,
                                isLoading: false
                            });
                        }


                    }).catch((error) => {
                        console.error(error);
                        this.props.onChange(false, error, false, query, true, this.state.isLoading);
                });
                return "";
            });


        })
    }

    handleBasicSearch(e) {
        e.preventDefault();
        this.setState({ isLoading: true }, () => {
            let display_name = this.state.searchTerm;
            console.log(display_name)
            fetch(config.apiUrl + '/person/search?term=' + display_name)
                .then((response) => response.json())
                .then((responseJson) => {
                    let search_id = '';

                    if (responseJson.data[0]._id !== undefined) {
                        console.log(responseJson.data[0]._id)
                        search_id = responseJson.data[0]._id.substring(responseJson.data[0]._id.length-4, responseJson.data[0]._id.length)
                    }
                    this.setState({
                        searchId:search_id
                    });
                    this.props.history.push('/people/person/' + search_id)

                }).catch((error) => {
                    console.error(error);
            });
            return "";
        })
    }



    getResultCount() {
        this.setState({ isLoadingResults: true }, () => {
            let display_name = this.state.searchRow;
            let religiousHouse = this.props.religiousHouse;
            let religiousOrder = this.props.religiousOrder;
            let queryType = this.state.query_type;
            let current_terms = this.state.current_terms;
            console.log(display_name)
            let term_list =  peopleSearchTermBuilder(current_terms, display_name, religiousOrder, religiousHouse, queryType)
            console.log(term_list)
            let params = {
                "query_type": queryType,
                "term": term_list
            }
            let query = Object.keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&');
            this.setState({term_list: term_list})
            display_name.map(() => {

                fetch(config.apiUrl + '/search/person/advanced?' + query)
                    .then((response) => response.json())
                    .then((responseJson) => {

                        console.log(responseJson)
                        if (responseJson.toString().startsWith("No receptions found"))
                        {
                            this.setState({
                                searchCount: 0,
                                showSearchResults: true,
                            });

                        }
                        else {
                            this.setState({
                                searchCount: responseJson.total,
                                isLoadingResults: false
                            });
                        }


                    })
                    .catch((error) => {
                        console.error(error);
                    });
                return "";
            });


        })
    }



    handleAddClick(e) {
        e.preventDefault();
        let people = [];
        let multiSelect = [];
        let people_detailed = this.props.people_detail;
        if( e.target.elements.item(1).value === "Religious Order") {
            multiSelect.push(this.state.searchTerm);
        } else if( e.target.elements.item(1).value === "Religious House") {
            multiSelect.push(this.state.searchTerm);
        } else if( e.target.elements.item(1).value === "Sex") {
            multiSelect.push(this.state.searchTerm);
        }
        let searchVals = {}
        if(multiSelect.length > 0)
        {

            searchVals = {
                name: multiSelect,
                search_type: "In",
                search_value: e.target.elements.item(1).value,
                id_val: ""
            }

        } else {
            let id_term = people_detailed.filter(p => {
                if (p != null && e.target.elements.item(5) != null) {
                    if (p.label === e.target.elements.item(5).value) {
                        return p.id
                    }
                }
                return ''
            });
            let name = ''
            if (e.target.elements.item(5) != null) {
                name = e.target.elements.item(5).value
            }
            searchVals = {
                name: name,
                search_type: e.target.elements.item(3).value,
                search_value: e.target.elements.item(1).value,
                person: id_term
            }
        }

        this.getResultCount();
        people.push(searchVals);
        this.setState({
            updateSearch: true,
            updateSearchButton: true,
            searchRow: this.state.searchRow.concat(people),
            selectedType: "",
            selectedFilter: "",
            searchTerm: "",
            selectedValue: '',
            searchEnabled: true,
            enable_add_button: true
        });
    }

    handleAdvancedClick() {
        this.props.onChange(true, "", true);
        this.setState({
            basicSearch: false,
            searchRow: [],
            updateSearch: false,
            selectedType: "",
            selectedFilter: "",
            userInput: "",
            showSearchResults: false,
            selectedValue: ''
        });
    }

    handleBasicClick() {
        this.props.onChange(true, "", true);
        this.setState({
            searchRow: [],
            updateSearch: false,
            selectedType: "",
            selectedFilter: "",
            userInput: "",
            showSearchResults: false,
            selectedValue: '',
            basicSearch: true
        });
    }

    handleRemoveClick(idx) {
        if (this.state.searchRow.filter((s, sidx) => idx !== sidx).length === 0){
            this.setState({
                updateSearch: false
            })
        }
        this.setState({
            searchRow: this.state.searchRow.filter((s, sidx) => idx !== sidx),

        });
        this.getResultCount()
    }

    handleResetClick() {

        this.props.onChange(true, "", true);
        this.setState({
            searchRows: [],
            searchRow: [],
            updateSearch: false,
            selectedType: "",
            selectedFilter: "",
            userInput: "",
            showSearchResults: false,
            selectedValue: '',
            current_terms: [],
            enable_add_button: true,
            searchCount: 0
        });

        this.props.history.push({ search: '' });
    }

    handleTypeChange(e) {
        e.preventDefault();

    }



    handleChange = prop => event => {

        let type_value = event.target.value;
        let filter_list = ['Is Nun', 'All', 'Has Aristocratic Title', 'Male', 'Female', 'Unknown', 'Is Author', 'Is Receiver', 'Is Owner / Compiler / Scribe', 'Has Pseudonyms']

        if (type_value != null) {
            if (filter_list.includes(type_value)) {
                this.setState({ searchEnabled: false, enable_add_button: false });
            } else {
                this.setState({ searchEnabled: false, enable_add_button: true });
            }

        }

        this.setState({ [prop]: event.target.value });
    };

    handleChangeFilter = prop => event => {

        let type_value = event.target.value;
        if (type_value != null) {
            this.setState({ searchEnabled: false, enable_add_button: false });
        }

        this.setState({ [prop]: event.target.value });
    };

    handleSearchValue(value, id) {
        this.setState({ searchTerm: value });
        this.setState({ searchId: id,
                        enable_add_button: false});
    };

    handleChangeReceptionType = prop => event => {
        let type_value = event;
        if (type_value != null) {
            this.setState({ searchEnabled: false, enable_add_button: false });
        }
        this.setState({ [prop]: type_value });
    };

    toggleChangeOfSearchType(e) {
        this.setState({ query_type: e });
        this.getResultCount();
    }



    render() {
        const { classes } = this.props;
        const { updateSearch } = this.state;
        const { showSearchResults } = this.state;
        const { searchEnabled } = this.state;
        const { selectedType } = this.state;
        const { searchTerm } = this.state;
        const { searchCount } = this.state;
        const { allPeople } = this.props;
        const { people_count } = this.props;
        const { isLoadingConfig } = this.props;
        const { people_detail } = this.props;

        const { enable_add_button } = this.state;

        let initial_state = "";
        let rowCount = false;
        if (this.state.searchRow.length > 1) {
            rowCount = true
        }

        if (!this.state.basicSearch && this.state.selectedType === "") {
            initial_state = (
                <div>
                    <FormControl size="sm" style={{width: "100%" }}>
                        <TextField variant="outlined"
                                   disabled={true}
                                   label="Set Search Terms"
                                   size="sm"

                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">3.</InputAdornment>,
                                       classes: {
                                           input: classes.resize,
                                       },
                                   }}
                                   type="text"

                                   value={''}
                        />

                    </FormControl>

                </div>)

        }

        return (


            <Grid item xs={8} >
                <Card style={{ }}>
                    <Card.Body style={{ }}>
                        {this.state.basicSearch  && (
                            <div className={styles.search_card_div}>

                                <Typography variant="h6" gutterBottom>
                                    Who are you looking for
                                </Typography>

                                <div className={styles.search_card_div}>

                                    <Form ref="form">

                                        <Form.Row >
                                            <Form.Group  as={Col} md="8" controlId="person_named">
                                                <div >

                                                    <PeopleSearchAutoComplete searchType="basic" searchEnabled={false} onChange={this.handleSearchValue}
                                                                              searchTerm={searchTerm}  selectedValue={this.state.selectedValue} suggestions={allPeople}/>

                                                    {!isLoadingConfig && 
                                                        <Typography variant="caption" gutterBottom>
                                                            {/* <button class="btn btn-link" onClick={this.handleClickOpen}> */}
                                                            <a href="#" style={{ textDecoration: "underline", cursor: 'pointer' }} onClick={this.handleClickOpen}>
                                                                <FaSearch/> Browse {people_count} names
                                                            </a>
                                                            {/* </button> */}
                                                            <PersonSelectDialog
                                                                people={people_detail}
                                                                selectedValue={this.state.selectedValue}
                                                                open={this.state.open}
                                                                onClose={this.handleClose}
                                                            />
                                                        </Typography>
                                                    }

                                                </div>
                                            </Form.Group>
                                            <Form.Group size="sm" style={{textAlign: 'right'}} as={Col} md="2" controlId="person_submit">

                                                <ButtonGroup size="sm">
                                                    <Link to={`/people/person/${this.state.searchId}`}>
                                                        <Button onClick={this.handleBasicSearch}  size="sm" type="submit" style={{textAlign: 'right'}}

                                                                variant="secondary">
                                                            <span>
                                                                <FaSearch/>
                                                            </span> Search</Button>
                                                    </Link>
                                                </ButtonGroup>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group size="sm" style={{textAlign: 'left'}} as={Col} md="3" controlId="person_submit">

                                                <ButtonGroup size="sm">
                                                    <Button size="sm" onClick={this.handleAdvancedClick} type="text" variant="link" style={{textAlign: 'left'}}>

                                                        Advanced Search</Button>
                                                </ButtonGroup>
                                            </Form.Group>
                                        </Form.Row>
                                    </Form>


                                </div>
                            </div>
                        )}
                        {!this.state.basicSearch  && (
                            <div className={styles.search_card_div}>
                                <Typography variant="h6" gutterBottom>
                                    Build your own search of people
                                </Typography>

                                <div className={styles.search_card_div}>

                                    <Form ref="form" onSubmit={this.handleAddClick}>

                                        <Form.Row >
                                            <Form.Group as={Col} md="3" controlId="person_select">

                                                <TextField
                                                    select
                                                    variant="outlined"
                                                    label="Choose a Field"
                                                    value={this.state.selectedType}
                                                    onChange={this.handleChange('selectedType')}
                                                    className={styles.search_advanced_reference_textfield}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">1.</InputAdornment>,
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}>
                                                    {types.map(option => (
                                                        <MenuItem selected={option.default} key={option.value} value={option.value || ''}>
                                                            {option.label}
                                                        </MenuItem>

                                                    ))}
                                                </TextField>
                                            </Form.Group>
                                            <Form.Group  as={Col} md="3" controlId="person_input">
                                                <TextField
                                                    select
                                                    variant="outlined"
                                                    label="Choose Filter"
                                                    value={this.state.selectedFilter}
                                                    onChange={this.handleChange('selectedFilter')}
                                                    className={styles.search_advanced_reference_textfield}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">2.</InputAdornment>,
                                                        classes: {
                                                            input: classes.resize,
                                                        },
                                                    }}>
                                                    {filter.map(option => {
                                                            let optionTypes = option.types;
                                                            if (optionTypes.includes(this.state.selectedType)) {
                                                                return (
                                                                    <MenuItem key={option.value} value={option.value || ''}>
                                                                        {option.label}
                                                                    </MenuItem>)
                                                            }
                                                        return '';
                                                        }
                                                    )}
                                                </TextField>
                                            </Form.Group>
                                            <Form.Group  as={Col} md="4" controlId="person_named">
                                                {
                                                    person.map(() => {

                                                        let searchEnabledAutoComplete = (!searchEnabled && !this.state.selectedFilter.length > 0)
                                                        console.log(this.state.selectedFilter);
                                                        if ("Person" === this.state.selectedType && this.state.selectedFilter === "Equals") {

                                                            initial_state = (
                                                                <div >

                                                                    <PeopleSearchAutoComplete searchType="advanced" searchEnabled={searchEnabledAutoComplete} onChange={this.handleSearchValue}
                                                                                              searchTerm={searchTerm}  selectedValue={this.state.selectedValue} suggestions={allPeople}/>

                                                                    <Typography variant="caption" gutterBottom>
                                                                        <a href="#" className={styles.search_advanced_person_link} onClick={this.handleClickOpen}>
                                                                            <FaSearch/> Choose name from a list of ({allPeople.length} names)
                                                                        </a>
                                                                        <PersonSelectDialog
                                                                            people={people_detail}
                                                                            selectedValue={this.state.selectedValue}
                                                                            open={this.state.open}
                                                                            onClose={this.handleClose}
                                                                        />
                                                                    </Typography>

                                                                </div>)
                                                        } else if ("Person" === this.state.selectedType && this.state.selectedFilter === "Contains") {

                                                            initial_state = (
                                                                <div>
                                                                    <TextField
                                                                        disabled={searchEnabled}
                                                                        variant="outlined"
                                                                        label="Set Search Terms"
                                                                        value={this.state.searchTerm || ''}
                                                                        onChange={this.handleChangeFilter('searchTerm')}
                                                                        className={styles.search_advanced_reference_textfield}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">3.</InputAdornment>,
                                                                            classes: {
                                                                                input: classes.resize
                                                                            },
                                                                        }}>
                                                                    </TextField>
                                                                </div>)
                                                        }
                                                        return '';
                                                    })}

                                                {
                                                    religious_house.map((input) => {
                                                        if ("Religious House" === this.state.selectedType) {
                                                            initial_state = (
                                                                <Picky
                                                                    disabled={searchEnabled}
                                                                    options={input.options}
                                                                    value={this.state.searchTerm || []}
                                                                    multiple={true}
                                                                    onChange={this.handleChangeReceptionType('searchTerm')}
                                                                    dropdownHeight={250}
                                                                />);
                                                        }
                                                        return '';
                                                    })
                                                }
                                                {
                                                    religious_order.map((input) => {
                                                        if ("Religious Order" === this.state.selectedType) {
                                                            initial_state = (
                                                                <Picky
                                                                    options={input.options}
                                                                    value={this.state.searchTerm || []}
                                                                    multiple={true}
                                                                    onChange={this.handleChangeReceptionType('searchTerm')}
                                                                    dropdownHeight={250}
                                                                />
                                                            )
                                                        }
                                                        return '';
                                                    })
                                                }
                                                {
                                                    sex.map((input) => {
                                                        if ("Sex" === this.state.selectedType) {
                                                            initial_state = (
                                                                <Picky
                                                                    options={input.options}
                                                                    value={this.state.searchTerm || []}
                                                                    multiple={true}
                                                                    onChange={this.handleChangeReceptionType('searchTerm')}
                                                                    dropdownHeight={250}
                                                                />)
                                                        }
                                                        return '';
                                                    })
                                                }
                                                {initial_state}
                                            </Form.Group>
                                            <Form.Group size="sm" className={styles.search_basic_submit_form} as={Col} md="2" controlId="person_submit">

                                                <ButtonGroup size="sm">
                                                    <Button size="sm" type="submit" disabled={enable_add_button} className={styles.search_basic_submit_button}

                                                            variant="secondary">
                                                            <span>
                                                                <FaPlusCircle/>
                                                            </span> Add</Button>
                                                </ButtonGroup>
                                            </Form.Group>

                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group size="sm" style={{textAlign: 'left'}} as={Col} md="3" controlId="person_submit">

                                                <ButtonGroup size="sm">
                                                    <Button size="sm" onClick={this.handleBasicClick} type="text" variant="link" style={{textAlign: 'left'}}>

                                                        Basic Search</Button>
                                                </ButtonGroup>
                                            </Form.Group>
                                        </Form.Row>
                                        {selectedType.startsWith("Type of Person") && (
                                            <Form.Row >


                                            </Form.Row>)}
                                        {selectedType.startsWith("Reception Type") && (
                                            <Form.Row >
                                            </Form.Row>)}
                                    </Form>


                                </div>
                            </div>
                        )}
                        {updateSearch && (
                            <Form>
                                <Card>
                                    <Card.Header>
                                        <Typography variant="h6" id="tableTitle">
                                            Selected Search Fields
                                        </Typography>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card key="adv_search">
                                            <Table  className={styles.search_advanced_field_table}>
                                                <TableBody >
                                        {this.state.searchRow.map((row, idx) => {
                                            let rowValue = "";
                                            if (row.name instanceof Array) {
                                                row.name.map((rowval) => {
                                                    if (rowval != undefined) {
                                                        console.log(rowval)
                                                        rowval.map((rw) => {
                                                            if (rowValue !== "") {
                                                                rowValue = rowValue.concat(" OR " + rw)
                                                            }
                                                            else {
                                                                rowValue = rowValue.concat(rw)
                                                            }
                                                            return ""
                                                        })
                                                    }
                                                    return ""
                                                })
                                            } else {
                                                rowValue = row.name
                                            }
                                            return (
                                                <TableRow key={idx}>
                                                    <TableCell>{row.search_value}</TableCell>
                                                    <TableCell>
                                                        <Chip
                                                            label={row.search_type}
                                                            color="primary"
                                                        />
                                                    </TableCell>
                                                    <TableCell>{rowValue}</TableCell>
                                                    <TableCell align="right">
                                                        <FaMinusCircle disabled={showSearchResults} onClick={() => this.handleRemoveClick(idx)}/>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        )}
                                            {rowCount && (
                                                    <TableRow key="Logic">
                                                        <TableCell></TableCell>
                                                        <TableCell></TableCell>
                                                        <TableCell align="right">
                                                            <fieldset>
                                                                <Form.Group>
                                                                    <Form.Check inline
                                                                                type={'radio'}
                                                                                id={`and`}
                                                                                defaultChecked={true}
                                                                                label={"Match ALL terms (AND)"}
                                                                                name="formHorizontalRadios"
                                                                                onChange={() => this.toggleChangeOfSearchType("must")}

                                                                    />
                                                                    <Form.Check inline
                                                                                type={'radio'}
                                                                                id={`or`}
                                                                                label={"Match ANY terms (OR)"}
                                                                                name="formHorizontalRadios"
                                                                                onChange={() => this.toggleChangeOfSearchType("should")}
                                                                    />
                                                                </Form.Group>
                                                            </fieldset>
                                                        </TableCell>
                                                    </TableRow>)}
                                                </TableBody>
                                            </Table>
                                        </Card>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Form.Row className={styles.search_advanced_field_form}>
                                            <Form.Group  controlId="person_named">
                                                <Button style={{ backgroundColor: '#17a2b852', border: 'none', marginTop: '2px' }} variant="info"
                                                        disabled={true}>
                                                    <Typography variant="caption">
                                                        <FaInfoCircle/> Will return {searchCount} result(s).
                                                    </Typography>
                                                </Button>
                                            </Form.Group>
                                            <Form.Group style={{}} controlId="person_named">
                                                <ButtonGroup>
                                                    <Button style={{backgroundColor:'#c4e0af'}} variant="secondary"
                                                            //disabled={showSearchResults}
                                                            onClick={this.handleSearchClick}>

                                                             {this.state.isLoadingResults ?
                                                                 <CircularProgress size={12} className={classes.progress} /> :
                                                                 <Typography className={styles.search_advanced_search_complete_button} variant="caption"><FaCheck/> Search</Typography>
                                                             }

                                                    </Button>
                                                </ButtonGroup>
                                            </Form.Group>
                                            <Form.Group controlId="person_input">
                                                <Button style={{backgroundColor:'white'}} variant="secondary"
                                                        onClick={() => this.handleResetClick()} >

                                                    <Typography variant="caption">
                                                        <FaTimes></FaTimes> Reset Search
                                                    </Typography>
                                                </Button>
                                            </Form.Group>
                                        </Form.Row>
                                        {this.state.isLoading &&
                                            <LinearProgress />
                                        }
                                    </Card.Footer>
                                </Card>
                            </Form>
                        )}

                    </Card.Body>
                </Card>

            </Grid>
        )
    }
}

export default  withRouter(withStyles(styles)(PeopleSearch));

