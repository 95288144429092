import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import SourceWorkDetails from './SourceWorkDetails';
import Typography from '@material-ui/core/Typography'
import {Card, Table} from 'react-bootstrap'
import styles from '../../style.module.css';
import { config } from '../../config';
// import { Link } from '@material-ui/core';




class SourceDetails extends React.Component {

    constructor(props, context) {
        super(props, context);



        this.state = {
            isLoading: false,
            receiver_people: [],
            peopleSearchResponse: [],
            author: [],
            peopleList: [],
            receivers: []
        };
    }



    render() {

        const { reception_source, reception_sources } = this.props;
        
        let title = reception_source.title;
        // let source_type = reception_source.source_type;
        
        let rsw_source_type = [];
        if(reception_sources){
            let source_type = reception_sources.filter(src => {
                if(src.value === reception_source.source_type){
                    return src;
                }
            });

            if(source_type.length > 0){
                source_type = source_type[0];
            }

            
            let term = [{
                "name": "source_type",
                "label": "Reception Source Type",
                "filter": "in",
                "term": [
                    {
                        "id": source_type.id,
                        "value": source_type.value,
                        "label": source_type.label
                    }
                ],
                "termLabel": source_type.label
            }];

            let rsw_type = (
                <Link key={`ss-` + source_type.id} to={`/sources?query_type=must&term=` + encodeURIComponent(JSON.stringify(term))} target='_blank'>
                    {source_type.label}
                </Link>
            );
            rsw_source_type.push(rsw_type);
        }
        

        let reference = reception_source.reference;
        // let title_assigned = (reception_source.title_assigned ? 'Title assigned by project' : false);
        
        let languages = '';
        if(Array.isArray(reception_source.languages)){
            languages = reception_source.languages.map((lang) => {
                return lang.label;
            });
            if(languages.length > 0){
                languages = languages.join(', ');
            }
        }
        

        let place_of_reception = (reception_source.place_of_reception_address ? reception_source.place_of_reception_address : false );
        if(!place_of_reception){
            place_of_reception = (reception_source.place_of_reception ? reception_source.place_of_reception : '' );
        }
        
        let date_of_reception = reception_source.date_of_reception_source;

        let table = <tbody></tbody>;
        
        
        
        table = (
                <tbody key={'display_name_table'} className={styles.table_results_font_size}>
                        { title && 
                            <tr key="title">
                                <td className={styles.person_details_table_first_name}>Title</td>
                                <td>{title}</td>
                            </tr>
                        }
                        {rsw_source_type &&
                            <tr key="source_type">
                                <td className={styles.person_details_table_first_name}>Type of Source</td>
                                <td>{rsw_source_type}</td>
                            </tr>
                       }

                       {reference && 
                            <tr key="reference">
                                <td className={styles.person_details_table_first_name}>Document Reference</td>
                                <td>{reference}</td>
                            </tr>
                       }                      

                       {languages &&
                            <tr key="languages">
                                <td className={styles.person_details_table_first_name}>Languages</td>
                                <td>{languages}</td>
                            </tr>
                       }

                       { place_of_reception &&
                            <tr key="place_of_reception">
                                <td className={styles.person_details_table_first_name}>Place of Reception</td>
                                <td>{place_of_reception}</td>
                            </tr>
                       }

                       {date_of_reception &&
                            <tr key="date_of_reception">
                                <td className={styles.person_details_table_first_name}>Date of Reception</td>
                                <td>{date_of_reception}</td>
                            </tr>
                       }
                    </tbody>
                );
        // });

        

        return (
                <Card>
                    <Card.Header >
                        <div className={styles.reception_captured_card_header} >
                            <Typography variant="h6">
                                <strong>Bibliographic Details</strong> 
                            </Typography>                            
                        </div>
                    </Card.Header>
                    <Card.Body >                        
                            <Table striped size={'sm'} responsive>
                                {table}
                            </Table>
                        {/* </Card> */}

                    </Card.Body>

                    <Card.Footer className={styles.reception_captured_card_header}>
                        <SourceWorkDetails 
                            receptionmanuscript={reception_source.receptionmanuscript}
                            receptionworks={reception_source.receptionworks}
                            receptionextratextual={ reception_source.receptionextratextual }
                        />
                    </Card.Footer>
                </Card>
        )
    }
}

export default withRouter(withStyles(styles)(SourceDetails))