import React from 'react';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

import Grid from '@material-ui/core/Grid'
import {Card, CardGroup} from 'react-bootstrap'




class ReceptionExplore extends React.Component {

    constructor(props, context) {
        super(props, context);



        this.state = {
            isLoading: false            
        };
    }


    componentDidMount() {

    }


    render() {
        let { reception_source } = this.props;
        let receivedAuthorIds = [];

        if(!reception_source){
            return null;
        }

        let reception_count = 0;
        if (reception_source.receptions ){
            reception_count = reception_source.receptions.length;

            // how many uniqe female authors are received in this source?
            reception_source.receptions.forEach(reception => {
                if(receivedAuthorIds.indexOf(reception.author.id) === -1 ){
                    receivedAuthorIds.push(reception.author.id);
                }
            });
        }

        let percentile_label = false;

        if(reception_source.metrics && reception_source.metrics.length > 0){
            let metrics = reception_source.metrics.filter( m => {
                return m.key === 'source_reception_percentile_rank';
            });
            if(metrics.length > 0){
                let metric = metrics[0];
                percentile_label = Math.floor(metric.measure.value);
                let val = metric.measure.value;
                if (val < 30) {
                    percentile_label = 'Bottom 30%';
                } else if (val < 50) {
                    percentile_label = 'Bottom 50%';
                } else if (val < 75) {
                    percentile_label = 'Top 50%';
                } else if (val < 90) {
                    percentile_label = 'Top 25%';
                } else if (val < 95) {
                    percentile_label = 'Top 10%';
                } else if (val < 97) {
                    percentile_label = 'Top 5%';            
                } else if (val < 99) {
                    percentile_label = 'Top 2%';
                } else if (val < 100) {
                    percentile_label = 'Top 1%';
                }
            }
        }

        return (

            <Grid item xs={12} >
                <CardGroup border="dark">
                    <Card className="text-center">
                        <Card.Body style={{ alignItems: 'space-between' }}>

                            <Card.Title>Contains</Card.Title>
                            <Card.Text style={{ fontSize: '1.75em', color: '#222 important', fontFamily: '"Crimson Text", "Georgia", "serif"' }}>
                                {reception_count }
                            </Card.Text>
                            <Card.Text>
                                <small>receptions in our data</small>
                            </Card.Text>
                            <Card.Text>
                                <Link smooth to={`#receptions-captured`} className="nav-link">
                                    <small>View Them</small>
                                </Link>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="text-center">
                        <Card.Body>

                            <Card.Title>Reception of</Card.Title>
                            <Card.Text style={{ fontSize: '1.75em', color: '#222 important', fontFamily: '"Crimson Text", "Georgia", "serif"' }}>
                                {receivedAuthorIds.length }
                            </Card.Text>
                            <Card.Text>
                                <small>female authors in this source</small>
                            </Card.Text>

                            <Card.Text>
                                <Link smooth to={`#receptions-captured`} className="nav-link">
                                    <small>View Them</small>
                                </Link>
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    {percentile_label && 
                        <Card className="text-center">
                            <Card.Body style={{ }}>

                                <Card.Title>Is in the</Card.Title>
                                <Card.Text style={{ fontSize: '1.75em', color: '#222 important', fontFamily: '"Crimson Text", "Georgia", "serif"' }}>
                                    { percentile_label }
                                </Card.Text>
                                <Card.Text>
                                    <small>of sources with connected receptions.</small>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    }
                </CardGroup>

            </Grid>


        )
    }
}

export default withRouter(ReceptionExplore)