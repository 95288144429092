import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import { CardGroup} from 'react-bootstrap'
import PersonMetric from './PersonMetric';



class PersonExplore extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            shouldShuffle: true
        };
    }


    componentDidMount()
    {
        this.createMetricPanels();
    }

    componentDidUpdate(prevProps) {
        
        if (this.props.person !== prevProps.person) {
            this.setState({
                shouldShuffle: true
            });
            this.createMetricPanels();
        }

    }

    createMetricPanels()
    {
        const { metrics, person } = this.props;
        let { shouldShuffle } = this.state;
        let metricsShuffled;
        let metricOutput = [];
        if (metrics && (shouldShuffle === true)) {

            metricsShuffled = this.shuffle(metrics);
            if (metricsShuffled.length > 3) {
                metricsShuffled = metricsShuffled.slice(0, 3);
            }

            metricOutput = metricsShuffled.map(metric => {
                return <PersonMetric key={`person-metric-` + metric.key} metric={metric} person={person} />
            });

            this.setState({
                metricOutput,
                shouldShuffle: false
            });
        }

    }


    shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }

        return a;
    }


    render() {
        
        const {metricOutput} = this.state;

        return (

            <Grid item xs={12} >
                <CardGroup border="dark">
                    { metricOutput }
                </CardGroup>
            </Grid>


        )
    }
}

export default withRouter(PersonExplore)