

import React, { Component } from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './App.css';
import Routes from './routes'



import { blue, indigo } from '@material-ui/core/colors'

const serifFonts = ['"Crimson Text"', '"Georgia"', '"serif"'].join(',');
const sansSerifFonts = ['"Open Sans"', '"Helvetica"', '"Arial"', 'sans-serif'].join(',');

const theme = createMuiTheme({
    palette: {
        common: {
            black: '#222'
        },
        background: {
            default: '#EDF1F3',
            paper: '#fafafa'
        },
        text: {
            primary: '#222',
            hint: '#dbdbdb'
        },
        divider: '#dbdbdb',
        secondary: {
            main: blue[900]
        },
        primary: {
            main: indigo[700]
        },
        activeNav: {
            main: '#D2A74D'
        }
        
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        useNextVariants: true,
        fontFamily: sansSerifFonts,
        body: {
            fontFamily: sansSerifFonts
        },
        p: {
            fontSize: '.88em',
            lineHeight: '1.6em',
            fontFamily: sansSerifFonts
        },
        h1: {
            fontFamily: serifFonts,
            fontSize: '3em'
        },
        h2: {
            fontFamily: serifFonts,
            fontSize: '2.4em'
        },
        h3: {
            fontFamily: serifFonts,
            fontSize: '2em'
        },
        h4: {
            fontFamily: serifFonts,
            fontSize: '1.6em'
        },
        h5: {
            fontFamily: serifFonts,
            fontSize: '1.4em'
        },
        subtitle1: {
            fontFamily: serifFonts,
            fontSize: '1.4em',
            lineHeight: '1.2em',
            marginBottom: '1em'
        }
    }
});


class App extends Component {
    render() {
        return (
            <div>

                <MuiThemeProvider theme={theme}>


                    <Routes />

                </MuiThemeProvider>
            </div>
        );
    }
}

export default App;