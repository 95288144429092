import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { Typography } from '@material-ui/core';
import { FaHome } from 'react-icons/fa';

// images
import img_hartlib_1 from '../../images/case-hartlib-1.png';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1000
    },
    paper: {
        padding: '2em'
    }
})

class HartlibCircle extends Component {

   
    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Paper className={ classes.paper }>
                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">The Hartlib Circle</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>

                    <Typography variant="h1" className="pb-4">The Hartlib Circle</Typography>
                    
                    <p className="case-intro">
                        The Hartlib circle was an intellectual correspondence network based around <Link to="/people/person/3245">Samuel Hartlib</Link> (c. 1600-1662) that was active between 1641 and 1662. The Hartlib Papers are a large archive of original letters sent to Hartlib by correspondents from across Britain, Europe and beyond, of transcripts copied by him and his scribes for further distribution and future reference, and of his own notebooks. This ensures that Hartlib's fingerprints are on all the material, and for this reason Hartlib has been categorised as an owner/compiler/scribe of all the receptions in this archive. 
                    </p>

                    <p>
                        Research on this archive by RECIRC has revealed a greater range and depth of female participation in Hartlib's network than has been known before. From the letters and Hartlib's diary, RECIRC has <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.owners.id","label":"Owner/Compiler/Scribe","filter":"equals","term":3245,"termLabel":"Samuel Hartlib"}]&view=table'}>captured 549 receptions of female authors</Link>. These receptions are of 29 different female authors and are by 26 identified people classed as "receivers" - <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.owners.id","label":"Owner/Compiler/Scribe","filter":"equals","term":3245,"termLabel":"Samuel Hartlib"}]&view=graph'}>all of whom are represented in the network</Link>, illustrated below. 
                    </p>
                    <p>
                        <br />
                        <Link to={'/receptions?query_type=must&term=[{"name":"receptionsourcework.owners.id","label":"Owner/Compiler/Scribe","filter":"equals","term":3245,"termLabel":"Samuel Hartlib"}]&view=graph'}>
                            <img src={img_hartlib_1} alt="Screenshot of Hartlib Network" className="mt-4 mb-4" />
                        </Link>
                    </p>
                    
                    <p>
                        You will notice that Samuel Hartlib appears in the visualisation twice. This is because we have captured the <Link to="/cases/layers-and-agents-of-reception">two roles he played within this archive as separate nodes</Link>. The larger node is Hartlib as owner/compiler scribe linking him to all the material in his archive. The smaller node is Hartlib's role as an item level receiver and captures instances where he engages with female authors by actively writing about them or by annotating or transcribing letters by a female author. See, for example, <Link to="/receptions/reception/2429">Reception #2429</Link>. 
                    </p>

                    <ul>
                        <li>
                            To find the instances <Link to={'/receptions?query_type=must&term=[{"name":"receiver_name","label":"Receiver","filter":"equals","term":3245,"termLabel":"Samuel Hartlib"}]&view=table'}>where Samuel Hartlib is a receiver</Link> go to advanced search, select ‘receiver’ and enter "Samuel Hartlib". 
                        </li>
                    </ul>


                    <Typography variant="h2" className="pb-2">Female authors most written about</Typography>
                    <p>The top 5 female authors written about in the Hartlib papers are as follows:</p>
                    <ol>
                        <li><Link to="/people/person/3195">Katherine Jones, Lady Ranelagh</Link></li>
                        <li><Link to="/people/person/3194">Dorothy Moore Dury</Link></li>
                        <li><Link to="/people/person/2670">Elizabeth I</Link></li>
                        <li><Link to="/people/person/2156">Elizabeth Grey, Countess of Kent</Link></li>
                        <li><Link to="/people/person/2625">Henrietta Maria Stuart</Link> / <Link to="/people/person/2624">Elizabeth of Bohemia</Link> (both 11 receptions)</li>
                    </ol>
                    

                    <Grid container >
                        <Grid item xs={12} className={classes.grid}>

                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}><FaHome /> <span className="sr-only">Home</span></Link>
                                    </li>
                                    <li className="breadcrumb-item"><Link to={`/cases`}>Case Studies</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">The Hartlib Circle</li>
                                </ol>
                            </nav>

                        </Grid>
                    </Grid>
                    

                </Paper>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(HartlibCircle);