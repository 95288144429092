import React from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Button, Card, Form } from 'react-bootstrap'
import styles from '../../style.module.css';

import { Sigma } from 'react-sigma';
import NetworkSource from './NetworkSource';
import ResultsViewNav from '../common/ResultsViewNav';


class SourceGraph extends React.Component {

    constructor(props, context) {
        super(props, context);
        
        this.switchResultsView = this.switchResultsView.bind(this);

        let graphSettings = {
            settings: {
                clone: false,
                minNodeSize: 3,
                maxNodeSize: 20,
                // defaultNodeColor: '#ec5148',    
                defaultNodeBorderColor: '#dbdbdb',

                drawEdges: true,
                borderSize: 2,
                enableEdgeHovering: true,
                edgeHoverPrecision: 5,
                edgeHoverHighlightNodes: 'circle',
                edgeHoverSizeRatio: 3,
                edgeHoverExtremities: true
            }
        }

        this.state = {
            isLoading: false,
            isActive: false,
            network: false,
            graphSettings: graphSettings            
        };       


    }

    switchResultsView(showResultsAsTable) {
        this.props.onChange(showResultsAsTable)
    }

    componentDidMount()
    {
        let {receptionSearchResponse} = this.props;
        
        if (receptionSearchResponse && receptionSearchResponse.network){
            this.setState({
                network: receptionSearchResponse.network.graph
            });
            // console.log(receptionSearchResponse.network.graph);
        }
    }
    
    componentDidUpdate(prevProps)
    {
        if(prevProps !== this.props){
            let {receptionSearchResponse} = this.props;
            
            if (receptionSearchResponse && receptionSearchResponse.network){
                this.setState({
                    network: receptionSearchResponse.network.graph
                });
                // console.log(receptionSearchResponse.network.graph);
            }
        }
    }


    render()
    {
        let {receptionSearchResponse} = this.props;
        let { network, graphSettings } = this.state;

        if (!network || !receptionSearchResponse.data ){
            return null;
        }

        console.log(network, graphSettings, receptionSearchResponse);
        
        return (
            <Grid item xs={12} >
                <Card>
                    <Card.Header >
                        <div className={styles.table_results_header}>

                            <ResultsViewNav
                                resultCount={receptionSearchResponse.data.length}
                                showTable={this.props.showTable}
                                showNetwork={this.props.showNetwork}
                                onChange={this.switchResultsView}
                            />

                            <div className={styles.table_results_right_widget}>
                                <Typography variant="caption"><strong>Export</strong> </Typography>
                                <Button size="sm" className={styles.table_results_gephi_button} variant="secondary">
                                    Graph File (.gefx)
                                </Button>
                                <Form.Control className={styles.table_results_csv_button} size="sm" as="select">
                                    <option>CSV File</option>
                                </Form.Control>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body className={styles.table_results_card_body}>
                        <Card className={styles.table_results_card_table}>

                            <Sigma renderer="canvas" style={{minHeight: "800px"}}>
                                <NetworkSource graph={network} settings={graphSettings.settings} />
                            </Sigma>
                        </Card>
                    </Card.Body>
                </Card>
            </Grid>
        );
    }
}

export default withRouter(SourceGraph)