import React from 'react';
import { Link } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import  VirtualReference  from "./VirtualReference";
import { FaTimes } from "react-icons/fa";

/**
 * @uses https://material-ui.com/components/popper/
 * 
 */
 class SourceNodePopover extends React.Component {

     constructor(props, context) {
        super(props, context);

        this.state = {
             open: false,
             event: null,
             anchorEl: null,
             person: null
         };

        this.handleClickClosePopover = this.handleClickClosePopover.bind(this);
     }

     handleClickClosePopover(e) {

         this.setState({
             open: false
         });
     }

    managePopup()
    {
        let { event, show, person  } = this.props;
        // console.log(event);
        
        // create the "fake" element, using
        // location/size data from the node click event
        let virtualReferenceElement = new VirtualReference(
            event.data.captor.clientX + 10, 
            event.data.captor.clientY - 100, 
            event.data.node['renderer2:size']
        );

        const getBoundingClientRect = () => virtualReferenceElement.getBoundingClientRect();

        this.setState({
            open: show,
            anchorEl: {
                clientWidth: getBoundingClientRect().width,
                clientHeight: getBoundingClientRect().height,
                getBoundingClientRect
            },
            person: person,
            event: event
        });
    }

    componentDidMount()
    {
        if (this.props.event !== null) {
            this.managePopup();
        }
    }

    componentDidUpdate(prevProps)
    {
        // if we have a person, and it's not the 
        // same one as last time...
        if (( !prevProps.person || (this.props.person.id !== prevProps.person.id)) && this.props.event !== null) {        
        // if ( !prevProps.person && this.props.event !== null) {
            this.managePopup();
        }
    }

    render(){
        let { person } = this.props;
        let { open } = this.state;
    
        
        const id = open ? 'faked-reference-popper-' + person.id : undefined;

        return (
        
                <Popper 
                    id={id} 
                    className="popover node-popover"
                    open={this.state.open} 
                    anchorEl={this.state.anchorEl} 
                    transition 
                    placement="bottom"
                    // disablePortal={true}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: 'undefined',
                        }
                        // },
                        // arrow: {
                        //     enabled: true,
                        //     element: arrowRef,
                        // }
                    }}
                >
                    {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                    <Typography variant="h6" className="popover-header">
                                        {person.label}

                                        <button onClick={e => this.handleClickClosePopover(e)} className="float-right btn btn-xs">
                                            <FaTimes />
                                            <span className="sr-only">Close</span>
                                        </button>

                                    </Typography>
                                    <Typography variant="body1" className="popover-body">
                                        <small>
                                            <Link to={`/people/person/` + person._id}>
                                                View full profile
                                            </Link>
                                        </small>
                                    </Typography>                            
                            </Paper>
                        </Fade>
                    )}
                </Popper>
        
        );
    }
 }

export default SourceNodePopover;