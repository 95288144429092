import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
// import { config } from '../../config';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Card, Table} from 'react-bootstrap'
import styles from '../../style.module.css';


class PersonDetails extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            receiver_people: [],
            peopleSearchResponse: [],
            author: [],
            peopleList: [],
            receivers: [],
            person: {}
        };
    }

    // search(person) {
    //     return fetch(config.apiUrl + `/person/${person}`)
    //         .then((response) => response.json())
    //         .then((responseJson) => {

    //             //console.log(responseJson)
    //             this.setState({
    //                 isLoading: false,
    //                 receiver_people: responseJson,
    //                 person: responseJson
    //             }, function(){

    //             });

    //         })
    //         .catch((error) =>{
    //             console.error(error);
    //         });

    // }

    componentDidMount() {
       this.setState({
           person: this.props.person
       });

    //    console.log(this.state.person);
    }

    componentDidUpdate(prevProps) {

        if (this.props.person !== prevProps.person) {
            // this.search(this.props.person)
            this.setState({
                person: this.props.person
            });
        }


    }


    render() {

        const { person } = this.props;
        
        let houseLabel = [];
        let orderLabel = [];
        let marriedname = false;
        let aristocratictitle = false;
        let alias = false;
        let pseudonym = false;

        if (person ){

            
            if( person.religiousorder){
                orderLabel = person.religiousorder.map((item) => {
                    return (
                        // replace this with something that builds
                        // a link to /people using peopleSearchTermBuilder() in Util.js
                        // <Link to={`/people?query_type=must&term=%5B%7B%22name%22%3A%22religiousorder.id%22%2C%22label%22%3A%22Religious%20Order%22%2C%22filter%22%3A%22in%22%2C%22term%22%3A%5B%7B%22id%22%3A26%2C%22value%22%3A%22Benedictine%22%2C%22label%22%3A%22Benedictine%22%2C%22%24%24hashkey%22%3A%22%22%7D%5D%2C%22termLabel%22%3A%22Benedictine%22%7D%5D`}>
                             item.label 
                        // </Link>
                    );
                })
            }

            if (person.religioushouse){
                houseLabel = person.religioushouse.map((item) => {
                    return item.label;
                })
            }

            
            if (person.marriedname.length > 0){
                marriedname = person.marriedname.map((name) => {
                    return (
                        <li key={`married-name` + name.married_name}>
                            {name.married_name}
                        </li>
                    );
                }); 
            }
            
            if (person.aristocratictitle.length > 0){
                aristocratictitle = person.aristocratictitle.map((name) => {
                    return (
                        <li key={`aristocratic_title` + name.aristocratic_title}>
                            {name.aristocratic_title}
                        </li>
                    );
                }); 
            }
            
            if (person.alias.length > 0){
                alias = person.alias.map((name) => {
                    return (
                        <li key={`alias-` + name.alias}>
                            {name.alias}
                        </li>
                    );
                }); 
            }
            
            if (person.pseudonym.length > 0){
                pseudonym = person.pseudonym.map((name) => {
                    return (
                        <li key={`pseudonym-` + name.pseudonym}>
                            {name.pseudonym}
                        </li>
                    );
                }); 
            }
        }


        return (

            <Grid item xs={12} md={6} >
                <Card style={ {minHeight: '540px'} }>
                    <Card.Header>
                        <div className={styles.reception_captured_card_header}>
                            <div >
                                <Typography variant="h6"><strong>Biographical Details</strong> </Typography>
                            </div>

                        </div>
                    </Card.Header>
                    <Card.Body >
                        <Card className={styles.table_no_results_card_table}>
                            <Table striped bordered size={'sm'} responsive>
                                <thead>

                                </thead>
                                <tbody className={styles.table_results_font_size}>

                                    {person["first_name"] &&
                                        <tr key={person["first_name"]} >
                                            <th scope="row" className={styles.person_details_table}>First Name</th>
                                            <td>{person["first_name"]}</td>
                                        </tr>
                                    }
                                    
                                    {person["other_name"] &&
                                        <tr key={person["other_name"]} >
                                            <th scope="row" className={styles.person_details_table}>Other Name</th>
                                            <td>{person["other_name"]}</td>
                                        </tr>
                                    }

                                    {person["maiden_name"] &&
                                        <tr key={person["maiden_name"] + "-maiden"}>
                                            <th scope="row" className={styles.person_details_table}>Maiden Name</th>
                                            <td>{person["maiden_name"]}</td>
                                        </tr>
                                    }
                                    {marriedname &&
                                        <tr key={"marriedname"}>
                                            <th scope="row" className={styles.person_details_table}>Married Name(s)</th>
                                            <td>
                                                <ul>
                                                    {marriedname}
                                                </ul>
                                            </td>
                                        </tr>
                                    }

                                    {aristocratictitle &&
                                        <tr key={"aristocratictitle"}>
                                            <th scope="row" className={styles.person_details_table}>Aristocratic Title(s)</th>
                                            <td>
                                                <ul>
                                                    {aristocratictitle}
                                                </ul>
                                            </td>
                                        </tr>
                                    }

                                    {person["professional_ecclesiastical_title"] &&
                                        <tr key="ecclesiastical">
                                            <th scope="row" className={styles.person_details_table}>Professional / Ecclesiastical Title</th>
                                            <td>{person["professional_ecclesiastical_title"]}</td>
                                        </tr>
                                    }
                                    
                                    
                                    
                                    { alias &&
                                        <tr key={"alias"}>
                                            <th scope="row" className={styles.person_details_table}>Alias</th>
                                            <td>
                                                <ul>
                                                    {alias}
                                                </ul>
                                            </td>
                                        </tr>
                                    }
                                    
                                    {person["initials"] &&
                                        <tr key="ecclesiastical">
                                            <th scope="row" className={styles.person_details_table}>Initials</th>
                                            <td>{person["initials"]}</td>
                                        </tr>
                                    }

                                    { pseudonym &&
                                        <tr key={"pseudonym"}>
                                            <th scope="row" className={styles.person_details_table}>Pseudonym</th>
                                            <td>
                                                <ul>
                                                    {pseudonym}
                                                </ul>
                                            </td>
                                        </tr>
                                    }

                                    { person["name_in_religion"] &&
                                        <tr key={person["name_in_religion"] + "-religion"}>
                                            <th scope="row" className={styles.person_details_table}>Name in Religion</th>
                                            <td>{person["name_in_religion"]}</td>
                                        </tr>
                                    }

                                    {person["date_of_birth"] && 
                                        <tr key={person["date_of_birth"]}>
                                            <th scope="row" className={styles.person_details_table}>Date of Birth</th>
                                            <td>{person["date_of_birth"]}</td>
                                        </tr>
                                    }

                                    {person["date_of_death"] &&
                                        <tr key={person["date_of_death"]}>
                                            <th scope="row" className={styles.person_details_table}>Date of Death</th>
                                            <td>{person["date_of_death"]}</td>
                                        </tr>
                                    }

                                    {person["professed"] &&
                                        <tr key={person["professed"]}>
                                            <th scope="row" className={styles.person_details_table}>Professed</th>
                                            <td>{person["professed"]}</td>
                                        </tr>
                                    }
                                   
                                    {person["flourishing"] &&
                                        <tr key={person["flourishing"]}>
                                            <th scope="row" className={styles.person_details_table}>Flourishing</th>
                                            <td>{person["flourishing"]}</td>
                                        </tr>
                                    }
                                    
                                    {person["institutional_authorship"] &&
                                        <tr key={person["institutional_authorship"]}>
                                        <th scope="row" className={styles.person_details_table}>Institutional / collective authorship</th>
                                            <td>True</td>
                                        </tr>
                                    }

                                    { (orderLabel.length > 0) &&
                                        <tr key={"order"}>
                                            <th scope="row" className={styles.person_details_table}>Religious Order</th>
                                            <td>                                            
                                                {orderLabel}
                                            </td>
                                        </tr>
                                    }

                                    { (houseLabel.length > 0) &&
                                        <tr key={"house"}>
                                            <td className={styles.person_details_table} >Religious House</td>
                                            <td>{houseLabel}</td>
                                        </tr>
                                    }

                                </tbody>
                            </Table>
                        </Card>

                    </Card.Body>
                    {person.external_data && 
                        <Card.Footer>
                            <div className={styles.reception_captured_card_header}>
                                <div >
                                    <Typography variant="h6">
                                        Data from external sources
                                    </Typography>
                                </div>
                            </div>
                            <div >
                                <Typography variant="caption"><strong>Placeholder</strong> </Typography>
                            </div>
                        </Card.Footer>
                    }
                </Card>

            </Grid>


        )
    }
}

export default withRouter(withStyles(styles)(PersonDetails))