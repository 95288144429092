import React  from 'react';
import {config} from '../../config';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EnhancedTableHead from "../common/EnhancedTableHead";

import { HashLink as Link } from 'react-router-hash-link';
import {Button, Card } from 'react-bootstrap';
import { FaChevronRight, FaBookOpen, FaChevronCircleRight, FaInfoCircle } from 'react-icons/fa';
import Topbar from '../Topbar';
import styles_css from '../../style.module.css';
import Footer from '../Footer';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    paper: {
        padding: theme.spacing.unit * 3,

        color: theme.palette.text.secondary
    },
    box: {
        marginBottom: 280,
        height: 45
    },
    paperSearch: {
        padding: theme.spacing.unit * 3,
        textAlign: 'center',
        justify: "center",
        color: theme.palette.text.primary
    },
    grid: {
        maxWidth: 1000,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'

    },
    gridMain: {
        maxWidth: 1300,
        paddingBottom: 30
    },

    boxSearch: {
        marginBottom: 10,
        padding: theme.spacing.unit * 3,
        borderTop: `1px solid black`,
        borderBottom: `1px solid black`,
        borderLeft: `1px solid black`,
        borderRight: `1px solid black`,
        height: 200,

    },
    gridRight: {
        padding: 20,
        maxWidth: 1000,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    inlining: {
        display: 'inline',
        marginRight: 10,
        marginLeft: 10
    },
    inliningBlock: {
        display: 'block',
        marginRight: 10,
        marginLeft: 25
    },
    inliningRight: {
        display: 'inline',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginRight: 10,
        marginLeft: 35
    },

})

const DialogTitle = withStyles(theme => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },
}))(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
}))(MuiDialogContent);


class Work extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            work: [],
            order: 'asc',
            orderBy: 'source_title_text',
            open: false          
        };
    }


    search(workId) {

        return fetch( config.apiUrl + `/work/${workId}`)
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    work: responseJson,
                    isLoading: false
                }, function(){

                });

            })
            .catch((error) =>{
                console.error(error);
            });

    }

    componentDidMount() {
        this.setState({
            'isLoading': true
        });

        this.search(this.props.match.params.workId);
    }


    handleRequestSort = (event, property) => {
        const isDesc = this.state.orderBy === property && this.state.order === 'desc';

        this.setState({
            order: (isDesc ? 'asc' : 'desc'),
            orderBy: property
        });
    };

    desc(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    stableSort(array, cmp) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = cmp(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map(el => el[0]);
    }

    getSorting(order, orderBy) {
        return order === 'desc' ? (a, b) => this.desc(a, b, orderBy) : (a, b) => -this.desc(a, b, orderBy);
    }

    /**
     * Transform search result response into object
     * that can both be sorted, and contains React components (such
     * as Links) for display purposes
     */
    createData = (data) => {
        return data.map(data => {
            
            let owners = '';
            let owners_text = '';

            let receiver = '';
            let receiver_text = '';

            if (data.receptionsourcework.owners && data.receptionsourcework.owners.length > 0) {

                owners = data.receptionsourcework.owners.map((p, idx, arr) => {
                    let sep = '';
                    if (arr.length > 1 && idx < arr.length - 1) {
                        sep = ', ';
                    }

                    owners_text += p.display_name + ' ';

                    return (
                        <span key={p.id}>
                            <Link to={`/people/person/` + p.id}>
                                {p.display_name}
                            </Link> {sep}
                        </span>
                    );
                });

            }

            if (data.receivername) {
                receiver_text = data.receivername.display_name;
                receiver =
                    (<Link to={`/people/person/` + data.receivername.id}>
                        {data.receivername.display_name}
                    </Link>);
            }

            let source_title_text = data.receptionsourcework.title;
            let title = (
                <Link to={`/sources/source/${data.receptionsourcework.id}`}>
                    {data.receptionsourcework.title}
                </Link>
            ); 


            return {
                'id': data.id,              
                'receiver_text': receiver_text,
                'receiver': receiver,
                'owners_text': owners_text,
                'owners': owners,
                'source_title_text': source_title_text,
                'source_title': title,
            }
        });
    };

    handleClickOpen = () => {
        this.setState({
            open: true,
        });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname;
        const { work } = this.state;
        const { isLoading } = this.state;
       
        // ID here matches the keys for data sorting set
        // in this.createData()
        const headCells = [
            { id: 'id', numeric: false, disablePadding: true, label: 'Reception' },
            { id: 'receiver_text', numeric: false, disablePadding: true, label: 'Receiver' },
            { id: 'owners_text', numeric: false, disablePadding: true, label: 'Owner / Compiler / Scribe' },
            { id: 'source_title_text', numeric: false, disablePadding: true, label: 'Source Work Title' }
        ];

        let author = '';

        if(work.person){
            author = work.person.map(function(p, i, arr){
                let sep = ''; 
                if(arr.length > 1 && i < arr.length - 1){
                    sep = ', ';
                }

                return (
                    <span key={p.id}>
                        <Link to={`/people/person/` + p.id}>
                            { p.display_name }
                        </Link> { sep }
                    </span>
                );
            });
        }

        let hasReceptions = false;
        let receptionContents = '';
        if(work.reception && work.reception.length > 0){
            hasReceptions = true;

            let rows = this.createData(work.reception);
            receptionContents = this.stableSort(rows, this.getSorting(this.state.order, this.state.orderBy))
                
                .map((data) => {                                                       

                    return (
                        <TableRow key={`reception-` + data.id}>
                            <TableCell> 
                                <Link to={`/receptions/reception/${data.id}`} className="btn btn-sm btn-light">
                                    <FaChevronCircleRight style={{ marginRight: '5px' }} /> View
                                </Link>    
                            </TableCell>                                
                            <TableCell>{ data.receiver }</TableCell>
                            <TableCell>{ data.owners }</TableCell>
                            <TableCell>{ data.source_title}</TableCell>
                        </TableRow>
                    );
                    
                });
        }


       return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container spacing={24} justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                            <Grid container >
                                <Grid item xs={12} md={8} className={classes.grid}>

                                    <FaBookOpen />
                                    <Typography className={styles_css.reception_typography} variant="h6">
                                        <Link to={`/works`}>Works</Link>
                                    </Typography>
                                    <FaChevronRight />
                                    <Typography className={styles_css.reception_typography_works} variant="h6">
                                        {work.title}
                                    </Typography>


                                </Grid>
                                <Grid item xs={12} md={4} className={classes.gridRight}>

                                    { hasReceptions &&
                                        <Typography className={styles_css.reception_grid_card_body} variant="caption">
                                            Jump to:
                                        </Typography>
                                    }

                                    { hasReceptions &&
                                        <Typography className={styles_css.reception_grid_card_body} variant="caption">
                                            <Link smooth to={`#receptionsTitle`}>Connected Receptions</Link>
                                        </Typography>
                                    }

                                   <Button onClick={this.handleClickOpen} className={styles_css.citation_button} size="sm" variant="secondary">
                                        Citing this Data
                                    </Button>

                                   <Dialog
                                       onClose={this.handleClose}
                                       aria-labelledby="customized-dialog-title"
                                       open={this.state.open}
                                   >
                                       <DialogTitle onClose={this.handleClose}>
                                           Citing this data
                                                    </DialogTitle>
                                       <DialogContent>
                                           <Typography gutterBottom>
                                               Data is published under a <a href="https://creativecommons.org/licenses/by-nc/4.0/">Creative Commons Attribution-Non Commercial 4.0 International License (CC-BY-NC 4.0)</a>.
                                                            Further information on how to use and cite this data is available from the <Link to="/about-data/data-use">About the Data</Link> page.
                                                        </Typography>
                                           <Typography gutterBottom>
                                               To cite this data in your work, please use the following:
                                                        </Typography>
                                           <Typography style={{ padding: '1em 2em', backgroundColor: '#e8edf4', color: 'black' }} gutterBottom>
                                               RECIRC: The Reception &amp; Circulation of Early-Modern Women's Writing, 1550-1700,
                                                            URL: {window.location.href}, Accessed: <em>&lt;Today's Date&gt;</em>
                                           </Typography>
                                       </DialogContent>
                                   </Dialog>

                                </Grid>

                            </Grid>

                        </Grid>
                        
                        { !isLoading && 
                            <Grid spacing={24} alignItems="center" justify="center" container className={classes.gridMain}>
                                
                                   <Grid item xs={12} md={8}>
                                        <Card>                                    
                                            <Card.Header>
                                                <Typography variant="h6" id="tableTitle">
                                                    Work Details
                                                </Typography>
                                            </Card.Header>
                                            <Card.Body >
                                                <table className="table table-sm table-striped">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row">Title</th>
                                                            <td>{ work.title }</td>
                                                        </tr>
                                                    
                                                        
                                                        <tr>
                                                            <th scope="row">Author</th>
                                                            <td>{ author }</td>
                                                        </tr>
                                                        

                                                        { work.date && 
                                                            <tr>
                                                                <th scope="row">Date</th>
                                                                <td>{ work.date }</td>
                                                            </tr>
                                                        }

                                                        { work.place_of_composition && 
                                                            <tr>
                                                                <th scope="row">Place of Composition</th>
                                                                <td>{ work.place_of_composition }</td>
                                                            </tr>                                                                                                                
                                                        }

                                                        { work.first_line &&
                                                            <tr>
                                                                <th scope="row">First Line / Incipit</th>
                                                                <td>{ work.first_line }</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </Card.Body>
                                        </Card>
                                   </Grid>
                                
                                { hasReceptions &&
                                    <Grid item xs={12}>
                                        <Card>                                        
                                            <Card.Header>
                                                <Typography variant="h6" id="receptionsTitle">
                                                    <a name="receptionsTitle"></a>
                                                    Receptions related to this work
                                                </Typography>
                                                <p className="text-muted">
                                                    <small>
                                                        <FaInfoCircle/> {work.reception.length } receptions found
                                                    </small>
                                                </p>
                                            </Card.Header>
                                            <Card.Body>
                                                <table className="table table-sm table-striped">
                                                    <EnhancedTableHead
                                                        headCells={headCells}
                                                        order={this.state.order}
                                                        orderBy={this.state.orderBy}
                                                        onRequestSort={this.handleRequestSort}
                                                    />
                                                    {/* <thead>
                                                        <tr>
                                                            <th>Reception</th>
                                                            <th>Receiver</th>
                                                            <th>Owner / Compiler / Scribe</th>
                                                            <th>Source Work Title</th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody className={styles_css.table_results_font_size}>
                                                       { receptionContents }
                                                    </tbody>
                                                </table>
                                            </Card.Body>
                                        </Card>
                                   </Grid>
                                }
                                
                            </Grid>
                        }

                    </Grid>
                </div>


                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Work);