import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter, Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography'
import {Card, Carousel} from 'react-bootstrap'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { FaFileImage } from 'react-icons/fa';
import styles from '../../style.module.css';
import { config } from '../../config';

const logos = {
    'folger-logo.png': require('../../images/folger-logo.png'),
    'bl-logo.gif': require('../../images/bl-logo.gif'),
    'eebo-logo.png': require('../../images/eebo-logo.png'),
    'bodleian-logo.png': require('../../images/bodleian-logo.png'),
    'ehri-logo.png': require('../../images/ehri-logo.png'),
    'ht-logo.svg': require('../../images/ht-logo.svg'),
    'google-books-logo.png': require('../../images/google-books-logo.png'),
    'rosenbach-logo.svg': require('../../images/rosenbach-logo.svg'),
};


class ReceptionImage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,
            receiver_people: [],
            peopleSearchResponse: [],
            author: [],
            peopleList: [],
            receivers: []
        };
    }

    rotate() {
        let newRotation = this.state.rotation + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotation: newRotation,
        })
    }
    
    
    render() {

        const { reception } = this.props;

        let images = [];
        let image_pdf = [];
        let transcription_array = [];
        let no_images = false;
        let no_transcription = false;
        let source = false;
        let source_logo = false;
        
        if (reception ) {
            
            if (reception.receptionimage && reception.receptionimage.length > 0){
                reception.receptionimage.forEach(image => {
                    // don't want pdfs added to the carousel
                    if(image.filename.indexOf('.pdf') === -1){
                        images.push(image.filename)
                    }else{
                        image_pdf.push(image.filename);
                    }
                });
            }else{
                no_images = true;
            }
        
            // add line breaks / formatting to quotation
            if (reception.quotation_reception !== null && reception.quotation_reception !== '') {

                transcription_array = reception.quotation_reception.split(/\n/).map((paragraph, idx) => {
                    if (paragraph.startsWith('p.') || paragraph.startsWith('sig.')) {
                        return (
                            <Typography key={`para-` + idx} className={styles.reception_image_text_large} variant="caption">
                                <strong>[{paragraph}]</strong>
                                <br /><br />
                            </Typography>
                        );
                    }else{
                        return (
                            <Typography key={`para-` + idx} className={styles.reception_image_text_large} variant="caption">                           
                                {paragraph}
                                <br /><br />
                            </Typography>
                        );
                    }
                });
                // console.log(transcription_array);
            }else{
                no_transcription = true;
            }

            
            // link to image source
            if (reception.source_image_link){
                source = (
                    <tr>
                        <th scope="row" style={{ minWidth: '150px' }}>
                            <small>Link to image source</small></th>
                        <td>
                            <a href={reception.source_image_link} target="_blank" rel="noopener noreferrer">
                                <small>{reception.source_image_link}</small>
                            </a>
                        </td>
                    </tr>
                );
            }else if(reception.source_image_text){
                source = (
                    <tr>
                        <th scope="row" style={{ minWidth: '150px' }}><small>Link to image source</small></th>
                        <td>
                            <small>
                                {reception.source_image_text}
                            </small>
                        </td>
                    </tr>
                );
            }

            // logo of organisation where source image
            // came from
            if(reception.source_logo){
                source_logo = (
                    <tr>
                        <th scope="row" style={{minWidth: '150px'}}><small>Image Source</small></th>
                        <td>
                            <img src={logos[reception.source_logo]} alt="Logo of organisation where manuscript was sourced" style={{maxWidth: '150px'}} className={'pl-4'} />
                        </td>
                    </tr>
                );
            }
        }

        

        if (images.length === 0) {
            images.push(config.noImageAvailablePath)
        }
        
        let image_carousel = images.map((image) => {
            return (
                <Carousel.Item key={image}>
                    <img
                        className="d-block w-100"
                        src={image}
                        alt={image}
                    />
                    <Carousel.Caption> </Carousel.Caption>
                </Carousel.Item>
            );
        })


        let image_pdf_output = image_pdf.map((image, idx) => {
            return (
                <div className={'pt-4'}>
                    <h6>Link(s) to PDF-version images</h6>
                
                    <ListItem key={`img-` + idx}>
                        <ListItemIcon>
                            <FaFileImage/>
                        </ListItemIcon>
                        <ListItemText>
                            <a href={image} target="_blank" rel="noopener noreferrer">
                                Link to PDF file
                            </a>
                        </ListItemText>
                    </ListItem>
                </div>
            );
        });

        
        // If we don't have any images or transcription
        // don't show the panel...
        if( no_images && no_transcription){
            return null;
        }


        return (
            <Card id="transcription">
                
                <Card.Header className={styles.reception_image_card_header}>
                    <Typography variant="h6">Image &amp; Transcription</Typography>
                </Card.Header>
                <Card.Body className={styles.reception_image_card_body}>
                    <Grid spacing={24} container>
                        <Grid style={{ padding: '20', borderWidth: 0 }} item xs={12} md={6} >
                            <Card border="light" className={styles.reception_grid_card_body}>
                                <Card.Body>
                                    <Card.Text>
                                        { transcription_array }
                                    </Card.Text>
                                </Card.Body>


                            </Card>
                            <Card border="light" className={styles.reception_grid_card_body}>
                                <Card.Body style={{ overflow: 'scroll'}}>
                                    <table className="table table-sm" style={{ maxWidth: '100%'}}>
                                        <tbody>
                                            {source_logo }
                                            { source }
                                        </tbody>
                                    </table>
                                    

                                    
                                            
                                </Card.Body>
                            </Card>
                        </Grid>
                        <Grid className={styles.reception_grid_body} item xs={12} md={6} >
                            <Card border="light" className={styles.reception_grid_card_body}>
                                <Carousel interval={null} indicators={(images.length > 1) ? true : false} controls={ (images.length > 1) ? true : false} >
                                    {image_carousel}
                                </Carousel>
                                
                                { images.length > 1 && 
                                    <button className="btn btn-sm">

                                    </button>
                                }

                                { image_pdf_output }

                            </Card>
                        </Grid>

                    </Grid>
                </Card.Body>


            </Card>



        )
    }
}

export default withRouter(withStyles(styles)(ReceptionImage))