import React,  { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Topbar from './Topbar';
import Footer from './Footer';
import styles_css from '../style.module.css';

const banner = require('../images/img-about.jpg');

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        paddingBottom: 200
    },
    grid: {
        width: 1200,
        marginTop: 10,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)'
        }
    },
})

class Contact extends Component {

    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">

                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12}>
                                <img src={banner} alt="" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>
                            <Grid item xs={12} md={8}>
                                

                                <Typography variant="h1" className="text-center mb-4">Contact</Typography>

                                <Typography variant="h3" className="pb-3">General Queries and Comments</Typography>
                                <p>
                                    For general queries or comments, please contact RECIRC's Principal Investigator:<br /> <strong>Prof Marie-Louise Coolahan</strong> at marielouise.coolahan@nuigalway.ie                                    
                                </p>

                                
                                <Typography variant="h3" className="pb-3">Technical Issues</Typography>
 
                                <p>
                                    If you have encountered any problems or unexpected issues with the database, please report them using the form below:
                                </p>

                                <div className="mb-4 mt-4">
                                    <iframe width="100%" height="1600px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=hrHjE0bEq0qcbZq5u3aBbOvMv90vk3BMtvg_jK2Nwn5URDlJVTRCVzZDRUxQMlNVWDJKR0dQTFBFUy4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style={{border: 'none', maxWidth:'100%', maxHeight:'100vh'}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
                                </div>
                            </Grid>
                        </Grid>                        
                    </Grid>
                  
                </div>
                <Footer/>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Contact);