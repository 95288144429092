import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withRouter } from 'react-router-dom';

import { TwitterTimelineEmbed } from 'react-twitter-embed';



const styles = theme => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey['100']}`,
        backgroundColor: 'white',

    },
    inline: {

        display: 'inline'

    },
    flex: {
        display: 'inline',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    productLogo: {
        display: 'inline-block',
        borderLeft: `1px solid ${theme.palette.grey['A100']}`,
        marginLeft: 32,
        paddingLeft: 24
    },
    tagline: {
        display: 'inline-block',
        marginLeft: 10
    },
    iconContainer: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 6,
    },
    iconButton: {
        display: 'inline',
        float: 'right',
        justifyContent: 'center'
    },
    logoButton: {
        float: 'centre'
    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    tabItem: {
        paddingTop: 20,
        paddingBottom: 20,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    logoItem: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center'
    }
})

class Twitter extends Component {

    state = {
        value: 0,
        menuDrawer: false
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    mobileMenuOpen = (event) => {
        this.setState({ menuDrawer: true });
    }

    mobileMenuClose = (event) => {
        this.setState({ menuDrawer: false });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    current = () => {
        if(this.props.currentPath === '/home') {
            return 0
        }
        if(this.props.currentPath === '/dashboard') {
            return 1
        }
        if(this.props.currentPath === '/signup') {
            return 2
        }
        if(this.props.currentPath === '/wizard') {
            return 3
        }
        if(this.props.currentPath === '/cards') {
            return 4
        }

    }

    render() {


        return (
            <TwitterTimelineEmbed
                sourceType="profile"
                screenName="recirc_"
                options={{height: 400}}
            />
        )
    }
}

export default withRouter(withStyles(styles)(Twitter))