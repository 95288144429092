import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage'
import MUIDataTable from "mui-datatables";


const styles = {

};


class TablePaginationActions extends React.Component {
    handleFirstPageButtonClick = event => {
        this.props.onChangePage(event, 0);
    };

    handleBackButtonClick = event => {
        this.props.onChangePage(event, this.props.page - 1);
    };

    handleNextButtonClick = event => {
        this.props.onChangePage(event, this.props.page + 1);
    };

    handleLastPageButtonClick = event => {
        this.props.onChangePage(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };

    render() {
        const { classes, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={classes.root}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </div>
        );
    }
}

TablePaginationActions.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    theme: PropTypes.object.isRequired,
};


class PersonSelectDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        
        this.handleListItemClick = this.handleListItemClick.bind(this);

        this.state = {
            page: 0,
            rowsPerPage: 5,
            setSearch: ''
        };
    }

    
    handleClose = () => {
        this.props.onClose(this.props.selectedValue);
    };

    handleListItemClick = value => {
        this.props.onClose(value);
        console.log(value);
    };




    render() {
        const { people, ...other } = this.props;
        console.log(other);
        const columns = [{name: 'label', label: "Name"}];
       
        const options = {
            responsive: "scroll",
            selectableRows: false,
            sort: true,
            filter: false,
            print: false,
            download: false,
            viewColumns: false,
            onRowsSelect: function(index){
                // console.log(index);
                other.onClose(people[index[0].dataIndex]);
            }
            ,
            onRowClick: function (value, index){
                console.log(value, index);
                other.onClose(people[index.dataIndex]);
            }
        };

        return (
            <Dialog onClose={this.handleClose}  {...other}>

                    <MUIDataTable
                        title={"Search People"}
                        data={people}
                        columns={columns}
                        options={options}
                        // onClick={event => this.handleListItemClick(event.target.value)}
                        // onRowClick={event => this.handleListItemClick(event.target.value)}

                    />



            </Dialog>
        );
    }
}

PersonSelectDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

export default withStyles(styles)(PersonSelectDialog);


