import React,  { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link, withRouter } from 'react-router-dom';

import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Grid from '@material-ui/core/Grid';

import Menu from './Menu';
import Logos from './common/Logos';


const styles = theme => ({
    appBar: {
        position: 'relative',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.grey['100']}`,
        backgroundColor: 'white',

    },
    inline: {

        display: 'inline'

    },
    flex: {
        display: 'inline',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    productLogo: {
        display: 'inline-block',
        borderLeft: `1px solid ${theme.palette.grey['A100']}`,
        marginLeft: 32,
        paddingLeft: 24
    },
    tagline: {
        display: 'inline-block',
        marginLeft: 10
    },
    iconContainer: {
        display: 'none',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing.unit * 6,
    },
    iconButton: {
        display: 'inline',
        float: 'right',
        justifyContent: 'center'
    },
    logoButton: {
        float: 'centre'
    },
    tabContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    tabItem: {
        paddingTop: 20,
        paddingBottom: 20,
        minWidth: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    logoItem: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'center'
    }
})

class Footer extends Component {

    state = {
        value: 0,
        menuDrawer: false
    };

    handleChange = (event, value) => {
        this.setState({ value });
    };

    mobileMenuOpen = (event) => {
        this.setState({ menuDrawer: true });
    }

    mobileMenuClose = (event) => {
        this.setState({ menuDrawer: false });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    current = () => {
        if(this.props.currentPath === '/home') {
            return 0
        }
        if(this.props.currentPath === '/dashboard') {
            return 1
        }
        if(this.props.currentPath === '/signup') {
            return 2
        }
        if(this.props.currentPath === '/wizard') {
            return 3
        }
        if(this.props.currentPath === '/cards') {
            return 4
        }

    }

    render() {

        const { classes } = this.props;

        return (
            <footer className={classes.footer}>
                <Logos cols={3}/>
                <Toolbar>
                    <Grid container spacing={24} alignItems="baseline">
                        <Grid item xs={12} className={classes.flex}>

                            { !this.props.noTabs && (
                                <React.Fragment>

                                    <div className={classes.iconContainer}>
                                        <IconButton onClick={this.mobileMenuOpen} className={classes.iconButton} color="inherit" aria-label="Menu">
                                            <MenuIcon />
                                        </IconButton>
                                    </div>
                                    <div className={classes.tabContainer}>
                                        <SwipeableDrawer anchor="right" open={this.state.menuDrawer} onOpen={this.mobileMenuOpen} onClose={this.mobileMenuClose} >
                                            <AppBar title="Menu" />
                                            <List>
                                                {Menu.map((item, index) => (
                                                    <ListItem key={index} component={Link} to={{pathname: item.pathname, search: this.props.location.search}} button>
                                                        <ListItemText primary={item.label} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </SwipeableDrawer>
                                        <Tabs
                                            value={this.current() || this.state.value}
                                            indicatorColor="primary"
                                            textColor="primary"

                                            onChange={this.handleChange}>
                                            {Menu.map((item, index) => (
                                                <Tab key={index} component={Link} to={{pathname: item.pathname, search: this.props.location.search}} classes={{root: classes.tabItem}} label={item.label} />
                                            ))}
                                        </Tabs>
                                    </div>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Toolbar>
            </footer>
        )
    }
}

export default withRouter(withStyles(styles)(Footer))