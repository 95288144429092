import React from "react";
import PropTypes from "prop-types";
import {Paper, FormControl, TextField}  from '@material-ui/core';
import ListGroup from 'react-bootstrap/ListGroup'
import InputAdornment from '@material-ui/core/InputAdornment';
import styles from '../../style.module.css';
import {config} from "../../config";



class ReceptionSearchAutoComplete extends React.Component {
    static propTypes = {
        suggestions: PropTypes.instanceOf(Array)
    };

    static defaultProps = {
        suggestions: []
    };



    constructor(props, context) {
        super(props, context);

        this.state = {
            // The active selection's index
            activeSuggestion: 0,
            // The suggestions that match the user's input
            filteredSuggestions: [],
            // Whether or not the suggestion list is shown
            showSuggestions: false,
        };
    }

    componentDidUpdate(prevProps){
        if (this.props.searchTerm !== prevProps.searchTerm) {

        }
    }

    // Event fired when the input value is changed
    onChange = e => {
        //const { suggestions } = this.props;
        const userInput = e.currentTarget.value;
        let suggestions = []

        // fetch(config.apiUrl + '/person/search?term=' + userInput)
        fetch(config.apiUrl + '/person/autocomplete?term=' + userInput)
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.length === 0)
                {
                    this.setState({
                        searchCount: 0,
                        showSearchResults: true,
                    });

                }
                else {
                    // console.log(responseJson)
                    responseJson.forEach(item => {
                        let name = item._source.display_name;
                        suggestions.push(name);
                    });

                    this.setState({
                        suggestions
                    });
                }


            })
            .catch((error) => {
                console.error(error);
            });
        // Filter our suggestions that don't contain the user's input
        console.log(suggestions)
        // const filteredSuggestions = suggestions.filter(
        //     suggestion => {
        //
        //         if (suggestion != null) {
        //             return suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        //         }
        //         return ''
        //     }
        //
        // );

        // Update the user input and filtered suggestions, reset the active
        // suggestion and make sure the suggestions are shown
        this.props.onChange(userInput);
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: suggestions,
            showSuggestions: true
        });
    };

    // Event fired when the user clicks on a suggestion
    onClick = e => {
        // Update the user input and reset the rest of the state
        this.props.onChange(e.currentTarget.innerText);
        this.setState({
            activeSuggestion: 0,
            filteredSuggestions: [],
            showSuggestions: false
        });
    };

    // Event fired when the user presses a key down
    onKeyDown = e => {
        const { activeSuggestion, filteredSuggestions } = this.state;

        // User pressed the enter key, update the input and close the
        // suggestions
        if (e.keyCode === 13) {
            this.setState({
                activeSuggestion: 0,
                showSuggestions: false
            });
        }
        // User pressed the up arrow, decrement the index
        else if (e.keyCode === 38) {
            if (activeSuggestion === 0) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion - 1 });
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestion - 1 === filteredSuggestions.length) {
                return;
            }

            this.setState({ activeSuggestion: activeSuggestion + 1 });
        }
    };

    render() {
        const { searchTerm } = this.props;
        const { searchEnabled } = this.props;
        const { selectedValue } = this.props;
        const { searchType } = this.props;
        const {
            onChange,
            onClick,
            onKeyDown,
            state: {
                filteredSuggestions,
                showSuggestions
            }
        } = this;

        let suggestionsListComponent;
        let inputAdornment = "3."
        if (searchType === 'basic')
            inputAdornment = "1."

        if (showSuggestions && searchTerm) {
            if (filteredSuggestions.length) {
                suggestionsListComponent = (
                    <Paper >
                        <ListGroup  className={styles.search_autocomplete_list_group} >
                        {filteredSuggestions.map((suggestion, index) => {
                            return (
                                <ListGroup.Item size="sm" as="li" variant="light" action
                                    className={"list-unstyled"}
                                    key={suggestion + index}
                                    onClick={onClick}

                                >
                                    {suggestion}
                                </ListGroup.Item>
                            );

                        })}
                        </ListGroup>
                    </Paper>
                );
            } else {
                suggestionsListComponent = (
                    <div className={"no-suggestions"}>
                        <em>No suggestions</em>
                    </div>
                );
            }
        }

        return (
            <FormControl size="sm"  className={styles.search_autocomplete_list_form} >
                <TextField variant="outlined"
                           disabled={searchEnabled}
                           label="Type a name"
                           size="sm"
                           className={styles.search_autocomplete_list_textfield}
                           InputProps={{
                               startAdornment: <InputAdornment position="start">{inputAdornment}</InputAdornment>,

                           }}
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    autoComplete="off"
                    value={searchTerm || selectedValue || ''}
                />
                {suggestionsListComponent}
            </FormControl>
        );
    }
}

export default (ReceptionSearchAutoComplete);