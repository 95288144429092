import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CardItem from './cards/CardItem';
import Topbar from './Topbar';
import Footer from './Footer';
import styles_css from '../style.module.css';

import blogData from '../config/blog-index.json';
import { FaUser, FaCalendarAlt } from 'react-icons/fa';


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: 20,
        padding: 20,
        paddingBottom: 200
    },
    grid: {
        maxWidth: 1200
    },
    discoverTitle: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        display: 'flex',
        minHeight: '175px',
    },
    paper: {
        padding: '2em',
        marginBottom: '2em'
    },
    paperData: {
        padding: theme.spacing.unit * 2,
        textAlign: 'left',
        justifyContent: 'flex-start',
        // color: theme.palette.text.secondary,
        display: 'flex',
        minHeight: 120
    },
})

class Blog extends Component {

    


    render() {
        const { classes } = this.props;
        const currentPath = this.props.location.pathname

        const posts = [];
        console.log(blogData);
        blogData.forEach(post => {
            let fulldate = new Date(post.date);
            // date = Date(date);
            fulldate = fulldate.toLocaleString();
            let date = fulldate.split(',');
            date = date[0];

            let p = (                
                <Paper key={`post-` + post.id} className={classes.paper}>
                    <Grid container item xs={12} className={classes.grid + ' mb-0'} justify="flex-start" alignItems="flex-start" spacing={24}>
                        <Grid item xs={12} md={8} style={{ display: 'flex', margin: '0 auto' }} className="mb-0 pb-0">
                            <div  className={classes.box}>
                                { post.jetpack_featured_media_url && 
                                    <figure style={{ width: '100%', textAlign: 'center'}}>
                                        <Link to={post.link}>
                                            <img src={post.jetpack_featured_media_url} alt='' style={{ maxHeight: '400px'}} />
                                        </Link>
                                    </figure>
                                }
                                <Link to={ post.link }>
                                    <Typography variant="h4" gutterBottom dangerouslySetInnerHTML={{__html: post.title.rendered }} />
                                </Link>
                                <p className="text-muted">
                                    <FaUser/> { post.author }  

                                    <FaCalendarAlt className="ml-5" /> {date}
                                </p>
                                <div dangerouslySetInnerHTML={ {__html: post.excerpt.rendered}} />                        
                            </div>                
                        </Grid>
                    </Grid>
                </Paper>
            );
            // let p = (<p key={post.id}>{post.title.rendered}</p>);
            posts.push(p);
        });

       

        return (
            <React.Fragment>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                <div className={classes.root}>
                    <Grid container justify="center">
                        <Grid spacing={24} alignItems="center" justify="center" container className={classes.grid}>



                            <Grid container item xs={12} className={classes.grid + ' mb-0'} justify="flex-start" alignItems="flex-start" spacing={24}>
                                <Grid item xs={12} md={8} style={{ display: 'flex', margin: '0 auto' }} className="mb-0 pb-0">
                                        <Typography variant="h1" gutterBottom style={{ 'paddingTop': '0.5em', 'marginBottom': '1em', fontSize: '48px', textAlign: "center", width: '100%' }}>RECIRC Blog</Typography>                                      
                                </Grid>
                            </Grid>
                        </Grid>

                        {posts}

                    </Grid>
                </div>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(Blog);